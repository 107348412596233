import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, useTheme } from '@mui/material';
import DataGridV2 from '../../../components/DataGridV2';
import { Add } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { apps, LOCAL_STORAGE_GRID_KEYS } from '../../../_constants';
import { getLocalStorageItem, groupBy, setLocalStorageItem } from '../../../_helpers';
import { breadcrumbAction, tabNavigationAction, templateGridActions, toastActions } from '../../../_actions';
import { getTemplateGridNavigationItem, getTemplatesGridColumns } from './util';
import { createTemplate } from '../../../apis/systemMaintenanceApis';
import { getTemplateTypes } from '../util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import i18n from '../../../i18n';

const TemplatesGrid = () => {
    const app = useSelector((state) => state.app);
    const keyPrefix = app === apps.aftercare ? "AFTERCARE" : app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme()
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [errors, setErrors] = useState({})
    const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION[keyPrefix], undefined
    ))
    const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        desc: '',
        projectId: '',
        templateType: '',
    });
    const [loading, setLoading] = useState(false)

    const apiRef = useGridApiRef();
    const dispatch = useDispatch()
    const { systemMaintenance: { templateStore: { templateList } } } = useSelector(state => state)
    const { loading: buildingLoading, all: allBuildings } = useSelector(state => state.buildings);
    const columns = getTemplatesGridColumns({ t, history, theme, apiRef, keyPrefix, dispatch })
    const templateTypes = getTemplateTypes(t)

    const getProjectLabel = (projectInfo) => projectInfo?.projectName
    const getProjectId = (projectInfo) => projectInfo?.projectId

    const projectsList = useMemo(() => {
        const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
        return projectsSoFar.map((projectInfo) => ({ label: getProjectLabel(projectInfo[0]), value: getProjectId(projectInfo[0]) }))
    }, [allBuildings])

    useEffect(() => {
        const templateGridNavigation = getTemplateGridNavigationItem(t)
        dispatch(tabNavigationAction.setNavigationTab(templateGridNavigation.tabs))
        dispatch(breadcrumbAction.setBreadcrumbItem(templateGridNavigation.breadCrumbs))
        }, [dispatch, t])

    useEffect(() => {
        if (!buildingLoading) {
            dispatch(templateGridActions.fetchTemplatesList({t,app}))
        }
    }, [app, buildingLoading, dispatch, t, i18n.language]);

    const onPaginationModelChange = useCallback((newPaginationModel) => {
        setPaginationModel(newPaginationModel)
        setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION[keyPrefix],
            newPaginationModel
        );
    }, [keyPrefix])

    const getToolbarProps = useMemo(() => {
        const toolbarProps = {
            toolbarStartActions: [
                {
                    title: t("general.add"),
                    icon: Add,
                    onClick: () => setIsCreateTemplateDialogOpen(true)
                }
            ],
        };
        return toolbarProps;
    }, [dispatch, t]);

    const toggleCreateTemplateDialog = () => {
        setIsCreateTemplateDialogOpen(!isCreateTemplateDialogOpen)
        setFormData({
            desc: '',
            projectId: '',
            templateType: '',
        })
        setErrors({})
    }

    const handleCreateTemplateBtn = async () => {
        if (validateTemplateDetails() && Object.keys(errors).length === 0) {
            try {
                setLoading(true)
                const response = await createTemplate({
                    ...formData,
                    "templateType": parseInt(formData.templateType)
                })
                dispatch(templateGridActions.fetchTemplatesList({ t, app }))
                dispatch(toastActions.success(t("template.create.success")))
                if (response.status === 200) {
                    history.push(app === apps.huisinfo ? "/systeemonderhound/sjablonen/" + response?.data?.id : "/nazorg/systeemonderhound/sjablonen/" + response?.data?.id)
                }
            } catch (error) {
                dispatch(toastActions.success(t("general.api.error")))
            }
            finally {
                setLoading(false)
                toggleCreateTemplateDialog()
            }
        }
    }

    if (buildingLoading || loading)
        return (
            (<Stack
                sx={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <CircularProgress size={25} />
            </Stack>)
        );

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        clearError(name)
    };

    const clearError = (name) => {
        const nameParts = name.split('.');
        const key = nameParts[0];
        const nestedKey = nameParts.slice(1).join('.');
        setErrors((prevErrors) => {
            if (nestedKey) {
                const newErrors = { ...prevErrors };
                delete newErrors[key]?.[nestedKey];
                if (Object.keys(newErrors[key] || {}).length === 0) {
                    delete newErrors[key];
                }
                return newErrors;
            } else {
                const { [key]: _, ...rest } = prevErrors;
                return rest;
            }
        });
    };

    const validateTemplateDetails = () => {
        const errors = {};
        ['templateType', 'projectId', 'desc'].forEach((field) => {
            if (!formData?.[field]?.toString().trim()) {
                errors[field] = t("general.field.error");
            }
        });

        // Update errors in state
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }));
        return Object.keys(errors).length === 0;
    };
    
  const getTemplatesPrintAndDownload = ({ isAllSelected = true }) => {
    const userSelectedRows = isAllSelected ? templateList?.data : rowSelectionModel?.map((id) => templateList?.data?.find(item => item?.id === id));
    const results = userSelectedRows?.map((item) => {
      let result = {}
      Object.keys(item).forEach((key) => {
        if (key === "templateType") {
          result[key] = t(`template.type.${item[key]}`);
        } else if (key === "active") {
          result[key] = item[key] ? t("general.yes") : t("general.no");
        } else {
          result[key] = item[key];
        }});
      return result
    })
    return results
  }

    return (
        <Box height={"100%"}>
            <DataGridV2
                localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_SEARCH_TEXT[keyPrefix]}
                localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER[keyPrefix]}
                localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT[keyPrefix]}
                localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_ORDER[keyPrefix]}
                localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_WIDTH[keyPrefix]}
                localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_VISIBILITY[keyPrefix]}
                apiRef={apiRef}
                loading={buildingLoading || templateList?.loading}
                checkboxSelection
                rows={templateList?.data}
                columns={columns}
                toolbarProps={{
                    ...getToolbarProps,
                    selectedRows: rowSelectionModel,
                    title: t("general.template"),
                    getData: getTemplatesPrintAndDownload,
                }}
                pagination
                {...(!!paginationModel && { paginationModel })}
                onPaginationModelChange={onPaginationModelChange}
                filterMode="client"
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
            />

            <Dialog
                open={isCreateTemplateDialogOpen}
                onClose={toggleCreateTemplateDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: "none",
                        width: 600,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t("template.create")}
                </DialogTitle>
                <DialogContent sx={{ overflow: "visible" }}>
                    <Stack spacing={2}>
                        <TextField
                            id="outlined-multiline-flexible"
                            name='desc'
                            label={t("general.template.name")}
                            value={formData?.desc}
                            onChange={handleChange}
                            size='small'
                            fullWidth
                            error={errors?.desc}
                            helperText={errors?.desc}
                        />

                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">
                                {t('general.type')}
                            </InputLabel>
                            <Select
                                size='small'
                                name="templateType"
                                fullWidth
                                input={<OutlinedInput label={t('general.type')} />}
                                value={formData.templateType}
                                error={errors?.templateType}
                                onChange={handleChange}
                            >
                                {templateTypes?.map((template) => <MenuItem value={template?.value}>{template?.label}</MenuItem>)}
                            </Select>

                            <FormHelperText error={errors.templateType}>{errors?.templateType}</FormHelperText>
                        </FormControl>

                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">
                                {t("general.project")}
                            </InputLabel>
                            <Select
                                size='small'
                                name="projectId"
                                fullWidth
                                input={<OutlinedInput label={t("general.project")} />}
                                value={formData?.projectId}
                                onChange={handleChange}
                                error={errors?.projectId}
                            >
                                {projectsList?.map((project) => <MenuItem value={project?.value}>{project?.label}</MenuItem>)}
                            </Select>
                            <FormHelperText error={errors.projectId}>{errors?.projectId}</FormHelperText>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCreateTemplateDialog}>{t("general.cancel")}</Button>
                    <Button onClick={handleCreateTemplateBtn} autoFocus>
                        {t("general.continue")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default TemplatesGrid
