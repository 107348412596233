import React, { useEffect, useState } from 'react'
import { ZoomIn } from '@mui/icons-material';
import { Box, Divider, Grid2 as Grid, Stack, Typography, Paper, CircularProgress, useTheme, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
import { getCommunicationDetails } from '../../apis/communicationApi';
import {dateFormatter, formatDate } from '../../_helpers';
import RowDetails from '../../components/RowDetails';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../_constants';
import Preview from './Preview';
import { getCommunicationDetailsNavigationItem, getStatusColor } from './util';
import { breadcrumbAction, tabNavigationAction } from '../../_actions';
import { useDispatch } from 'react-redux';
import TechInfo from '../../components/TechInfo';

const CommunicationDetails = () => {
  const { communicationId } = useParams();
  const location = useLocation()
  const { t } = useTranslation();
  const theme = useTheme()
  const dispatch = useDispatch()
  const [communicationDetails, setCommunicationDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [previewState, setPreviewState] = useState({ open: false, data: undefined, type: "" })

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getCommunicationDetails(communicationId);
        if (response.status === 200) {
          setCommunicationDetails(response.data)
        }
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false);
      }
    })();
  }, [communicationId]);

  useEffect(() => {
    const communicationDetailsNavigation = getCommunicationDetailsNavigationItem({ t, communicationDetails, communicationId })
    dispatch(tabNavigationAction.setNavigationTab(communicationDetailsNavigation.tabs, communicationDetailsNavigation.tabs.findIndex((tab) => tab.to === location.pathname)))
    dispatch(breadcrumbAction.setBreadcrumbItem(communicationDetailsNavigation.breadCrumbs))
  }, [dispatch, t, communicationId, communicationDetails, location.pathname])


  const communicationGeneralInfo = [
    {
      title: t('general.method'),
      value: t(`communication.method.${communicationDetails?.communicationSoort}`)
    },
    {
      title: t("communication.type"),
      value: t(`communication.type.${communicationDetails?.communicationType}`)
    },
    {
      title: t("general.status"),
      value: <Typography variant='caption' component="p" sx={{
        color: getStatusColor(communicationDetails?.communicationStatus, theme)
      }}>{t(`communication.status.${communicationDetails?.communicationStatus}`)}</Typography>,
    },
  ];

  const communicationRegardsInfo = [
    {
      title: t('general.template'),
      value: communicationDetails?.templateName
    },
    {
      title: t("general.subject"),
      value: communicationDetails?.subject
    },
  ]

  const communicationEmployeeInfo = [
    {
      title: t('general.employee'),
      value: communicationDetails?.employeeName
    },
    {
      title: t("general.department"),
      value: communicationDetails?.departmentName
    },
    {
      title: t('communication.sender'),
      value: communicationDetails?.senderEmail
    },
  ]

  const subjectInfo = [
    {
      title: t("general.project"),
      value: communicationDetails?.projectNoAndName
    },
    {
      title: t("general.object"),
      value: communicationDetails.buildingNoIntern
    },
    {
      title: t('general.repairRequest'),
      value: communicationDetails.repairRequestNumber
    },
  ]

  const communicationMomentInfo = [
    {
      title: t("general.date"),
      value: communicationDetails?.date ? formatDate(new Date(communicationDetails?.date)) : ''
    },
    {
      title: t('general.startTime'),
      value: communicationDetails?.timeStart
    }
  ]

  {/* TODO: add below section in future */ }
  // const templateInfo = [
  //   {
  //     title: t('communication.templateId'),
  //     value: communicationDetails?.person1?.lastName
  //   },
  //   {
  //     title: t('general.template.name'),
  //     value: communicationDetails.buildingStatus
  //   },
  //   {
  //     title: t('communication.documentId'),
  //     value: communicationDetails?.buildingNoExtern
  //   },
  // ]

  const taskInfo = [
    {
      title: t('communication.taskName'),
      value: communicationDetails.taskName
    },
    {
      title: t('communication.createdBy'),
      value: communicationDetails.taskCreatedBy
    },
    {
      title: t('communication.createdOn'),
      value: communicationDetails.taskCreatedOn ? dateFormatter(communicationDetails.taskCreatedOn) : ''
    },
    {
      title: t('general.startDate'),
      value: communicationDetails?.taskStartDate ? formatDate(new Date(communicationDetails?.taskStartDate))  : ""
    },
    {
      title: t('general.endDate'),
      value: communicationDetails?.taskEndDate ? formatDate(new Date(communicationDetails?.taskEndDate)) : ''
    },
  ]

  const reminderInfo = [
    {
      title: t("general.date"),
      value: communicationDetails?.date ? formatDate(new Date(communicationDetails?.date)) : ''
    },
    {
      title: t('communication.reminderFor'),
      value: communicationDetails.reminderForEmployeeName
    },
  ]

  const extraInfo = [
    {
      title: t('general.email'),
      value: communicationDetails.senderEmail
    },
    {
      title: t("general.type"),
      value: t(`communication.type.${communicationDetails?.communicationType}`)
    },
    {
      title: t('communication.group'),
      value: communicationDetails.group
    },
  ]
  
  return (
    <>
      {isLoading ? <Stack
        sx={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          p: theme.spacing(1.25),
          height: "50vh"
        }}>
        <CircularProgress size={30} />
      </Stack> : <Stack
        spacing={2}
        sx={{
          flex: 1,
          bgcolor: grey[100],
          px: { xs: 0, md: 2 },
          py: { xs: 2, md: 2 }
        }}>
        <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
          <Typography
            variant="h6"
            sx={{
              px: 2,
              py: 1.5
            }}>
            {t("general.communication")}
          </Typography>
          <Divider />
          <Grid container>
            <Grid
              sx={{ borderRight: { xs: "none", md: `1px dashed ${grey[200]}` } }}
              size={{ xs: 12, md: 4 }}>
              <Grid container spacing={0.5} sx={{ px: 2 }}
              >
                <Grid size={{ xs: 12 }} >
                  <Typography variant="subtitle2" py={1.5}>
                    {t('general.general')}
                  </Typography>
                </Grid>
                {communicationGeneralInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
                <Grid size={{ xs: 12 }} py={3}>
                  <Grid size={{ xs: 12 }} pb={1.5}>
                    <Typography variant="subtitle2" >{t("general.subject")}</Typography>
                  </Grid>
                  {communicationRegardsInfo?.map((detail) => {
                    return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                  })}
                </Grid>
                <Grid size={{ xs: 12 }} pb={1.5} >
                  <Typography variant="subtitle2">
                    {t('general.employee')}
                  </Typography>
                </Grid>
                {communicationEmployeeInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
              </Grid>

            </Grid>
            <Grid sx={{ borderRight: { xs: "none", md: `1px dashed ${grey[200]}` } }}
              size={{ xs: 12, md: 4 }}>
              <Grid container spacing={0.5} sx={{ px: 2 }} >
                <Grid size={{ xs: 12 }}>
                  <Typography variant="subtitle2" sx={{ pt: { xs: 3, md: 1.5 }, pb: { xs: 1.5, md: 1.5 } }} >
                    {t("communication.moment")}
                  </Typography>
                </Grid>
                {communicationMomentInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
                <Grid pt={3} pb={2} size={{ xs: 12, md: 12 }}>
                  <Box>
                    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                      <Typography
                        variant="subtitle2"
                        sx={{

                          mb: { xs: 1.5, md: 0 }
                        }}>
                        {t("general.preview")}
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "center",
                          alignItems: "center"
                        }}>
                        <IconButton size="small" onClick={() => setPreviewState({ open: true, data: communicationDetails?.emailMessage, type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL })} >
                          <ZoomIn />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        borderRadius: 1,
                        height: 300,
                        bgcolor: grey[100],
                        overflow: "auto",
                        p: 1.5
                      }}>
                      <Box onClick={() => setPreviewState({ open: true, data: communicationDetails?.emailMessage, type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL })} sx={{ cursor: "pointer", fontSize: 12 }} dangerouslySetInnerHTML={{
                        __html: `${communicationDetails?.emailMessage || ''}`
                      }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 4
              }}>
              <Grid container spacing={0.5}
                sx={{
                  px: 2,
                  pb:2
                }}
              >
                <Grid size={{ xs: 12 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      pt: { xs: 3, md: 1.5 },
                      pb: { xs: 1.5, md: 1.5 }
                    }}
                  >
                    {t("communication.taskInformation")}
                  </Typography>
                </Grid>
                {taskInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
                {/* TODO: add below section in future */}
                {/* <Grid size={{ xs: 12 }} pt={3} pb={1.5}>
                  <Typography variant="subtitle2">
                    Template information
                  </Typography>
                </Grid>
                {templateInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })} */}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
          <Typography
            variant="h6"
            sx={{
              px: 2,
              py: 1.5
            }}>
            {t("general.subject")}
          </Typography>
          <Divider />
          <Grid container sx={{
            py: 1
          }} size={{ xs: 12 }}>
            <Grid
              sx={{
                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
              }}
              size={{
                xs: 12,
                md: 4
              }}>
              <Grid container spacing={0.5}
                sx={{
                  px: 2
                }}
              >
                <Grid size={{ xs: 12 }}>
                  <Stack direction="row" sx={{
                    justifyContent: "space-between"
                  }}>
                    <Typography
                      variant="subtitle2" py={1.5} >
                      {t("general.subject")}
                    </Typography>
                  </Stack>
                </Grid>
                {subjectInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
          <Typography
            variant="h6"
            sx={{
              px: 2,
              py: 1.5
            }}>
            {t("communication.reminder")}
          </Typography>
          <Divider />
          <Grid container sx={{
            py: 1
          }} size={{ xs: 12 }}>
            <Grid
              sx={{
                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
              }}
              size={{
                xs: 12,
                md: 4
              }}>
              <Grid container spacing={0.5}
                sx={{
                  px: 2
                }}
              >
                <Grid size={{ xs: 12 }}>
                  <Stack direction="row" sx={{
                    justifyContent: "space-between"
                  }}>
                    <Typography
                      variant="subtitle2" py={1.5}
                    >   {t("communication.reminder")}
                    </Typography>

                  </Stack>
                </Grid>
                {reminderInfo?.map((detail) => {
                  return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
        <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} data={communicationDetails} extraInfo={extraInfo} />
        </Paper>
      </Stack>
      }

      <Preview previewState={previewState} setPreviewState={setPreviewState} />
    </>
  );
}

export default CommunicationDetails