import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, StyledEngineProvider, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import DataGridToolbar from './DataGridToolbar';
import { getLocalStorageItem, setLocalStorageItem } from '../../_helpers';
import { getDataTableTextLocale } from "../../_helpers/textHelper"
import { getColumnsOrderFromLocalStorage } from '../../_helpers/getGridContextMenuList';

const DataGridV2 = ({slotProps, slots, hideExportBtn=false,toolbarProps, columns, rows, apiRef, filterMode = "server",customRowHeight=80,MobileCustomRowComponent, columnsForExport, rowCount, localSearchIdentifier, localColumnFilterIdentifier, localColumnSortIdentifier, localColumnOrderIdentifier, localColumnWidthIdentifier, localColumnVisibilityIdentifier, initialState,localDataGridViewIdentifier, mappingOfOldFieldToNewField = {}, ...rest }) => {
   const isListViewAvailable = Boolean(MobileCustomRowComponent)
  const { i18n } = useTranslation();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [density, setDensity] = useState("compact")
  // State management with localStorage
  const [currentView, setCurrentView] = useState("grid");
  const [filterModel, setFilterModel] = useState(() => getLocalStorageItem(localColumnFilterIdentifier, undefined
  ))
  const [sortModel, setSortModel] = useState(() => getLocalStorageItem(
    localColumnSortIdentifier, undefined
  ))
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => getLocalStorageItem(
    localColumnVisibilityIdentifier, undefined
  ))

  useEffect(() => {
    const view = !isTabletOrMobile ? 'grid' : getLocalStorageItem(localDataGridViewIdentifier, isListViewAvailable && isTabletOrMobile ? "list" : "grid");
    setCurrentView(view);
  }, [isListViewAvailable, isTabletOrMobile, localDataGridViewIdentifier]);

  const handleToggleView = useCallback(() => {
    setCurrentView(prevView => {
      const newView = prevView === 'grid' ? 'list' : 'grid';
      setLocalStorageItem(localDataGridViewIdentifier, newView);
      return newView;
    });
  }, [localDataGridViewIdentifier])
  // Locale text configuration
  const localeText = useMemo(() => isTabletOrMobile ? {
    toolbarColumns: "",
    toolbarFilters: "",
    toolbarDensity: "",
    toolbarExport: "",
  } : {}, [isTabletOrMobile]);

  const defaultInitialState = useMemo(() => ({
    columns: {
      columnVisibilityModel: getLocalStorageItem(localColumnVisibilityIdentifier) || {},
    },
  }), [localColumnVisibilityIdentifier]);

  const locale = useMemo(() => {
    return { ...getDataTableTextLocale(i18n.language), ...localeText };
  }, [i18n.language, localeText]);

  const getSlots = useMemo(() => {
    const toolbarSlot = {
      toolbar: (props) => (
        <DataGridToolbar {...props} {...toolbarProps} totalRecords={rowCount || rows?.length || 0} handleToggleView={handleToggleView} apiRef={apiRef} columns={columns} filterMode={filterMode} columnsForExport={columnsForExport} rows={rows} localSearchIdentifier={localSearchIdentifier} hideExportBtn={hideExportBtn} isListViewAvailable={isListViewAvailable} currentView={currentView} />
      ),
    };

    const rowSlot = isTabletOrMobile && isListViewAvailable && currentView === "list" ? {
      row: (rest) => <MobileCustomRowComponent {...rest} />,
    } : {};

    const columnHeaderProps = currentView === "list" && isListViewAvailable ? { columnHeaders: () => null } : {}

    return { ...toolbarSlot, ...rowSlot, ...slots, ...columnHeaderProps };
  }, [isListViewAvailable, apiRef, columns, columnsForExport, currentView, filterMode, handleToggleView, isTabletOrMobile, localSearchIdentifier, rowCount, rows, slots, toolbarProps, hideExportBtn]);

  // Handlers
  const onFilterModelChange = useCallback((newFilterModel) => {
    if (filterMode === 'client') {
      setFilterModel(newFilterModel)
      setLocalStorageItem(localColumnFilterIdentifier, newFilterModel);
    }
  }, [filterMode, localColumnFilterIdentifier])

  const onSortModelChange = useCallback((newSortModel) => {
    if (filterMode === 'client') {
      setSortModel(newSortModel)
      setLocalStorageItem(
        localColumnSortIdentifier,
        newSortModel
      )
    }
  }, [filterMode, localColumnSortIdentifier])

  const onColumnVisibilityModelChange = useCallback((newColumnVisibilityModel) => {
    setColumnVisibilityModel(newColumnVisibilityModel)
    setLocalStorageItem(
      localColumnVisibilityIdentifier,
      newColumnVisibilityModel
    );
  }, [localColumnVisibilityIdentifier])

  const onColumnOrderChange = useCallback((params) => {
    const mapValue = getLocalStorageItem(localColumnOrderIdentifier) || columns.map((column) => column.field);
    const currentOrder = [...mapValue]
    const element = currentOrder.splice(params.oldIndex - 1, 1)[0];
    currentOrder.splice(params.targetIndex - 1, 0, element);
    setLocalStorageItem(localColumnOrderIdentifier, currentOrder)
  }, [columns, localColumnOrderIdentifier])

  const onColumnWidthChange = useCallback((params) => {
    const localColumnWidth = getLocalStorageItem(localColumnWidthIdentifier, [])
    const columnIndex = localColumnWidth.findIndex(item => item.field === params.colDef.field);
    if (columnIndex !== -1) {
      localColumnWidth[columnIndex].width = params.width;
    } else {
      localColumnWidth.push({ field: params.colDef.field, width: params.width });
    }
    setLocalStorageItem(localColumnWidthIdentifier, localColumnWidth);
  }, [localColumnWidthIdentifier])

  const processedColumns = useMemo(() => {
    let filterColumns = [...getColumnsOrderFromLocalStorage({ columns, localStorageKey: localColumnOrderIdentifier, mappingOfOldFieldToNewField })]
    if(currentView === "list" && isListViewAvailable ){
      filterColumns =  filterColumns.map((column)=> ({...column, minWidth:0,maxWidth:0 ,width:0}))
    }else{
      const localColumnWidth = getLocalStorageItem(localColumnWidthIdentifier, []).filter(item => !!item.field);
      localColumnWidth.forEach(item => {
        const columnIndex = filterColumns.findIndex(filterItem => filterItem.field === item.field && !filterItem.forceApplyWidth );
        if (columnIndex !== -1) {
          filterColumns[columnIndex].width = item.width;
          delete filterColumns[columnIndex].minWidth;
          delete filterColumns[columnIndex].flex;
        }
      });
    }
    return filterColumns.filter(c => !c?.hidden); // remove hidden columns
  }, [columns, currentView, isListViewAvailable, localColumnOrderIdentifier, localColumnWidthIdentifier, mappingOfOldFieldToNewField,]);
  return (<>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box sx={{
          height: "100%"
        }}>
          <DataGridPro
            initialState={{ ...(initialState ? initialState : defaultInitialState) }}
            filterModel={rest?.filterModel || filterModel}
            onFilterModelChange={rest?.onFilterModelChange || onFilterModelChange}
            sortModel={rest?.sortModel || sortModel}
            onSortModelChange={onSortModelChange}
            columnVisibilityModel={rest?.columnVisibilityModel || columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            onColumnOrderChange={rest?.onColumnOrderChange || onColumnOrderChange}
            onColumnWidthChange={onColumnWidthChange}
            apiRef={apiRef}
            filterMode={filterMode}
            columns={processedColumns}
            rows={rows}
            rowCount={rowCount}
            density={isTabletOrMobile&& isListViewAvailable && currentView === "list" ? "standard" :density}
            onDensityChange={(density)=>setDensity(density)}
            slots={getSlots}
            rowHeight={( isTabletOrMobile && isListViewAvailable && currentView  === "list" ? customRowHeight : 34)}
            localeText={locale}
            slotProps={{
              ...slotProps,
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  allColumns: false,
                  fileName: "Print",
                  hideFooter: true,
                  hideToolbar: true,
                },
              },
            }}
            {...(currentView === "list" && isListViewAvailable ? { columnHeaderHeight: 0 } : {})}
            sx={[{
              background: "white",
              fontSize: "12px",
              lineHeight: 1.43,
              letterSpacing: "0.01071em",

              ".MuiDataGrid-virtualScrollerRenderZone": {
                width: "100%",
              },
              ".MuiTablePagination-input": {
                display: 'inline-flex !important'
              },
              "& .MuiDataGrid-row:hover": {
                "& .img-col-data": {
                  transform: "scale(3)",
                  position: "absolute",
                  zIndex: "1000000",
                },
                "& .img-col-first-data": {
                  marginTop: '10px',
                },
              },
              ".MuiCheckbox-colorPrimary ": {
                color: grey[500]
              },
              ".grey": {
                backgroundColor: grey[50]
              },
              ".MuiDataGrid-selectedRowCount":{
                [theme.breakpoints.down("sm")]:{
                  margin:0
                }
              }
            },
            ]}
            pageSizeOptions={[5, 10, 20, 50, 75, 100]}
            disableVirtualization
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'grey' : ''
            }
            {...rest}
          />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  </>);
}

export default DataGridV2

const theme = createTheme({
  components: {
    PrivateSwitchBase: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          ".MuiDataGrid-panelWrapper": {
            maxWidth: "100%"
          }
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
            fontWeight: "bold",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "12px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "20.57px",
          },
        },
      },
    },
  }
});