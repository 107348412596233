import React, { useEffect, useState } from 'react'
import { CheckBox, CheckBoxOutlineBlank, ZoomIn } from '@mui/icons-material';
import { Box, Divider, Grid2 as Grid, Stack, Typography, Paper, CircularProgress, useTheme, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { getCommunicationDetails, getContactDetails } from '../../../apis/communicationApi';
import {formatDate } from '../../../_helpers';
import RowDetails from '../../../components/RowDetails';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../../_constants';
import Preview from '../Preview';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getContactDetailsNavigationItem } from '../util';
import { breadcrumbAction, tabNavigationAction } from '../../../_actions';
import TechInfo from '../../../components/TechInfo';

const ContactDetails = () => {
    const { contactId, communicationId } = useParams();
    const dispatch = useDispatch()
    const location = useLocation();
    const { t } = useTranslation();
    const [contactDetails, setContactDetails] = useState({})
    const [previewState, setPreviewState] = useState({ open: false, data: undefined, type: "" })
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme()
    const [communicationDetails, setCommunicationDetails] = useState({})

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await getCommunicationDetails(communicationId);
                if (response.status === 200) {
                    setCommunicationDetails(response.data)
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
                setIsLoading(false);
            }
        })();
    }, [communicationId]);

    useEffect(() => {
        if (!isLoading) {
            const communicationDetailsNavigation = getContactDetailsNavigationItem({ t, communicationDetails, communicationId, contactId, contactDetails })
            dispatch(tabNavigationAction.setNavigationTab(communicationDetailsNavigation.tabs, communicationDetailsNavigation.tabs.findIndex((tab) => tab.to === location.pathname.split('/').slice(0, -1).join('/'))))
            dispatch(breadcrumbAction.setBreadcrumbItem(communicationDetailsNavigation.breadCrumbs))
        }
    }, [communicationId, dispatch, t, location.pathname, contactId, isLoading, communicationDetails, contactDetails])

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await getContactDetails(contactId);
                if (response.status === 200) {
                    setContactDetails(response.data)
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
                setIsLoading(false);
            }
        })();
    }, [contactId]);

    const contactGeneralInfo = [
        {
            title: t('general.contact'),
            value: t(`communication.contact.addressee.${contactDetails.addressee}`)
        },
        {
            title: t("general.name"),
            value: contactDetails?.name
        },
        {
            title: t("general.email"),
            value: contactDetails?.email
        },
        {
            title: t('general.email.cc'),
            value: contactDetails?.emailCc
        },
        {
            title: t('general.email.bcc'),
            value: contactDetails?.emailBcc
        },
        {
            title: t('communication.contact.formal'),
            value: contactDetails?.formal ? <CheckBox color='disabled' /> : <CheckBoxOutlineBlank color='disabled' />
        },
    ];

    const contactInfo = [
        {
            title: t('communication.contact.emailSent'),
            value: contactDetails?.emailSentOn ? formatDate(new Date(contactDetails?.emailSentOn), true, "-", false) : ""
        },
    ]

    return (
        <>
            {isLoading ? <Stack
                sx={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    p: theme.spacing(1.25),
                    height: "50vh"
                }}>
                <CircularProgress size={30} />
            </Stack> : <Stack height={"100%"}
                spacing={2}
                sx={{
                    flex: 1,
                    bgcolor: grey[100],
                    px: { xs: 0, md: 2 },
                    py: { xs: 2, md: 2 }
                }}>
                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <Typography
                        variant="h6"
                        sx={{
                            px: 2,
                            py: 1.5
                        }}>
                        {t("general.contact")}
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid
                            sx={{
                                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                            }}
                            size={{
                                xs: 12,
                                md: 4
                            }}>
                            <Grid container spacing={0.5} sx={{
                                px: 2
                            }}
                            >
                                <Grid size={{ xs: 12 }} >
                                    <Typography variant="subtitle2" py={1.5}>
                                        {t('general.general')}
                                    </Typography>
                                </Grid>
                                {contactGeneralInfo?.map((detail) => {
                                    return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                                })}
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                            }}
                            size={{
                                xs: 12,
                                md: 4
                            }}>
                            <Grid container spacing={0.5}
                                sx={{
                                    px: 2
                                }}
                            >
                                <Grid size={{ xs: 12 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            pt: { xs: 3, md: 1.5 },
                                            pb: { xs: 1.5, md: 1.5 }
                                        }}
                                    >
                                        {t('general.contact')}
                                    </Typography>
                                </Grid>
                                {contactInfo?.map((detail) => {
                                    return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                                })}
                                <Grid pt={3} pb={2}
                                    size={{
                                        xs: 12,
                                        md: 12
                                    }}>
                                    <Box >
                                        <Stack direction="row" sx={{
                                            justifyContent: "space-between"
                                        }}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    mb: { xs: 1.5, md: 0 }
                                                }}>
                                                {t('communication.report')}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                sx={{
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <IconButton disabled={!contactDetails?.emailMessage} onClick={() => setPreviewState({ open: true, data: contactDetails?.emailMessage, type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL })} size="small">
                                                    <ZoomIn />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Box
                                            sx={{
                                                borderRadius: 1,
                                                height: 300,
                                                bgcolor: grey[100],
                                                overflow: "auto",
                                                p: 1.5
                                            }}>
                                            {contactDetails?.emailMessage && <Box onClick={() => setPreviewState({ open: true, data: contactDetails?.emailMessage, type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL })} sx={{ cursor: "pointer", fontSize: 12 }} dangerouslySetInnerHTML={{
                                                __html: `${contactDetails?.emailMessage || ''}`
                                            }} />}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
                    <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} data={contactDetails} />
                </Paper>
            </Stack>
            }
            <Preview previewState={previewState} setPreviewState={setPreviewState} />
        </>
    );
}

export default ContactDetails

