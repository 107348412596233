import { Stack, Typography,TextField} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';


const Guarantee = ({contractDetails,updateContractDetails, loadingKey, isDisabledEditing}) => {

  const [contractCostPercentage, setContractCostPercentage] = useState(0)
  const [landCostPercentage, setLandCostPercentage] = useState(0)
  const {t} = useTranslation()

  const getValue = (total , percentage)=>{
    return  ( (Number(percentage ||0) / 100 ) * Number(total || 0) ).toFixed(2)
  }


  useEffect(() => {
    setContractCostPercentage(contractDetails?.constructionCostSecurityDepositPercentage || 0)
    setLandCostPercentage(contractDetails?.landCostSecurityDepositPercentage || 0)

  }, [contractDetails])
  
  return (
    (<div><Typography variant='subtitle1' sx={{
            mb: 1.5
          }}>{t("general.warranty")}</Typography>
      <Typography variant='caption'>{t("contract.details.guarantee.downPaymentPurchasePrice")}</Typography>
      <Stack
        direction="row"
        sx={{
          columnGap: 1.5,
          my: 1.5
        }}>
      {loadingKey === "constructioncostsecuritydepositpercentage" ?    <Loader /> : 
      <NumericFormat
      label={t("general.percentage")}
      InputLabelProps={{
        shrink: true,
      }}
        size="small"
        fullWidth
        customInput={TextField}
        fixedDecimalScale={true}
        allowNegative={false}
        thousandSeparator="."
        decimalSeparator=","
        value={contractCostPercentage || ""}
        variant="outlined"
        isAllowed={(values)=>{
          const {formattedValue, value:changedValue } = values;
          const value = Number(changedValue)
          return value <= 100 && value>=0
        }}
        onValueChange={(values)=>{
          const { formattedValue, value:changedValue } = values;
          const value = Number(changedValue)
          console.log({value})
          if(value <= 100 && value>=0){
            setContractCostPercentage(value)
          }
        }}
        onBlur={(e)=>{
          updateContractDetails("constructioncostsecuritydepositpercentage" , e.target.value , "constructionCostSecurityDepositPercentage")
        }}
        disabled={isDisabledEditing}
        align="center"
        decimalScale={2}
      />    
    
    }
        <TextField
          fullWidth
          label={t("general.financial.euro")}
          size='small'
          type="text"
          disabled
          value={getValue(contractDetails?.contractPriceInclTax , contractCostPercentage)}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
      </Stack>
      <Typography variant='caption'>{t("contract.details.guarantee.downPaymentContractPrice")}</Typography>
      <Stack
        direction="row"
        sx={{
          columnGap: 1.5,
          mt: 1.5
        }}>
      {loadingKey === "landcostsecuritydepositpercentage" ?    <Loader /> : 
      
      <NumericFormat
      label={t("general.percentage")}
      InputLabelProps={{
        shrink: true,
      }}
        size="small"
        fullWidth
        customInput={TextField}
        fixedDecimalScale={true}
        allowNegative={false}
        thousandSeparator="."
        decimalSeparator=","
        value={landCostPercentage ? landCostPercentage:""}
        variant="outlined"
        isAllowed={(values)=>{
          const {  value:changedValue } = values;
          const value = Number(changedValue)
          return value <= 100 && value>=0
        }}
        onValueChange={(values)=>{
          const { value:changedValue } = values;
          const value = Number(changedValue)
          console.log({value})
        
          if(value <= 100 && value>=0){
            setLandCostPercentage(value)
          }
        }}
        onBlur={(e)=>{
          updateContractDetails("landcostsecuritydepositpercentage" , e.target.value , "landCostSecurityDepositPercentage")
        }}
        disabled={isDisabledEditing}
        align="center"
        decimalScale={2}
      />
        }
        <TextField
          fullWidth
          label={t("general.financial.euro")}
          size='small'
          type="text"
          disabled
          value={getValue(contractDetails?.landPriceInclTax , landCostPercentage)}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
      </Stack></div>)
  );
}

export default Guarantee