import { Typography } from '@mui/material';
import { store } from '../../..';
import { formatDate, groupBy } from '../../../_helpers';
import { getTaskAutomationGridNavigationItem } from '../TaskAutomationGrid/utils';
import { apps } from '../../../_constants';

const getGeneralSettings = (t) => {
    const { buildings: { all: allBuildings }, systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    if (!taskDetails) return [];
    const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
    const projectDetails = projectsSoFar.find((projectInfo) => projectInfo?.[0]?.projectId === taskDetails.projectId)

    return [
        {
            title: t('taskAutomation.generalSettings.automationFlow'),
            value: taskDetails?.name || ''
        },
        {
            title: t('general.project'),
            value: projectDetails?.[0]?.projectName || ''
        },
        {
            title: t('general.objects.select'),
            value: taskDetails?.objects?.map((o)=>o.name)?.join(", ") || ''
        },
        {
            title: t('taskAutomation.generalSettings.selectOwner'),
            value: taskDetails?.owner || ''
        },

        {
            title: t('general.startDate'),
            value: taskDetails?.startDate ? formatDate(new Date(taskDetails?.startDate), true, "-", false) || '' : ""
        },
        {
            title: t('general.endDate'),
            value: taskDetails?.endDate ? formatDate(new Date(taskDetails?.endDate), true, "-", false) || '' : ""
        },
        {
            title: t('general.active'),
            value: <Typography variant='caption' color={taskDetails.active ? "success" : "error"}>{taskDetails.active ?  t("general.yes") :  t("general.no")}</Typography> || ''
        }
    ]
}


const getDynamicSetting = (t) => {
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    if (!taskDetails) return [];
    return [
        {
            title: t('taskAutomation.selectDateField'),
            value: typeof taskDetails.dateField === "number" ? t("taskAutomation.dateFieldType."+taskDetails?.dateField) : ""
        },
        {
            title: t('taskAutomation.dynamicSettings.daysBeforeAfter'),
            value: typeof taskDetails.daysBeforeAfter === "number" ? t("taskAutomation.dateOffsetType." + taskDetails?.daysBeforeAfter) : ''
        },
        {
            title: t('general.days'),
            value: taskDetails.days || ''
        },
        {
            title: t('taskAutomation.runTime'),
            value: taskDetails?.timeOfDay || ''
        },

    ]
}

const getEmailSettings = (t) => {
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    if (!taskDetails) return [];

    return [
        {
            title: t('taskAutomation.availableTemplates'),
            value: taskDetails?.emailTemplate?.desc || ''
        },
        {
            title: t('general.documentCode'),
            value: taskDetails?.emailTemplate?.documentCode || ''
        },
        {
            title: t('general.subject'),
            value: taskDetails?.emailTemplate?.subject || ''
        },
        {
            title: t('general.email.cc'),
            value: taskDetails?.cc || ''
        },
        {
            title: t('general.email.bcc'),
            value: taskDetails?.bcc || ''
        },
        {
            title: t('general.attachments'),
            value: <Typography variant='caption' color='primary'>{taskDetails?.generalAttachment?.name}</Typography> || ''
        },
    ]
}

const getDocumentSettings = (t) => {
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    if (!taskDetails) return [];

    return [
        {
            title: t('taskAutomation.availableTemplates'),
            value: taskDetails?.documentTemplate?.desc || ''
        },
        {
            title: t('general.documentCode'),
            value: taskDetails?.documentTemplate?.documentCode || ''
        },
        {
            title: t('general.description'),
            value: taskDetails?.documentTemplate?.subject || ''
        },
    ]
}


export const getGeneralSettingsInfo = (t)=> [
    {
        title:t('general.general'),
        tabIndex: 0,
        details: getGeneralSettings(t),
        isHiddenListItemIcon: true,
        isHiddenCheckBox: true,
        isDisabled: false
    },
    {
        title: t('taskAutomation.dynamicSettings'),
        details: getDynamicSetting(t),
        tabIndex: 0,
        isHiddenListItemIcon: true,
        isHiddenCheckBox: true,
        isDisabled: false
    },
]

export const getTemplateSettingInfo = (t) => {
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    if (!taskDetails) return [];
    return [
        { 
            id: "emailSettings",
            title: t('taskAutomation.templateSettings.emailSettings'),
            tabIndex: 1,
            details: getEmailSettings(t),
            isPreviewDisplay: true,
            isDisabled: false,  
            isEditDisabled:taskDetails?.active
        },
        {
            id: "document",
            title: t('taskAutomation.templateSettings.document'),
            details: getDocumentSettings(t),
            tabIndex: 1,
            isHiddenListItemIcon: true,
            isPreviewDisplay: true,
            isDisabled: !taskDetails?.documentTemplate?.id,
            isEditDisabled:taskDetails?.active
        },
    ]
}


export const getTaskAutomationDetailsNavigationItem = (t)=>{
    const  {  systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    const {app} = store.getState();
    const taskAutomationNavigationItems = getTaskAutomationGridNavigationItem(t)
    const breadCrumbTitle = taskDetails?.number + " - " + taskDetails?.name
    const to =  app === apps.huisinfo ? "/systeemonderhound/taakautomatisering/"+ taskDetails?.id : app === apps.aftercare ? "/nazorg/systeemonderhound/taakautomatisering/"+taskDetails?.id : '#'
    return {
        breadCrumbs: [ ...taskAutomationNavigationItems?.breadCrumbs , { title: t("taskAutomation.title") + " - " +breadCrumbTitle, breadCrumbTitle : breadCrumbTitle , to}],
        tabs: [...taskAutomationNavigationItems?.tabs ,{ title: t('general.form') , to}]
    }
}