import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridV2 from "../../../components/DataGridV2";
import { apps, LOCAL_STORAGE_GRID_KEYS, } from "../../../_constants";
import { getLocalStorageItem, setLocalStorageItem } from "../../../_helpers";
import { LabelColumnV2, LinkColumnV2 } from "../../../components/DataGridV2/DataColumnsV2";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";
import { getCommunicationDetails, getContacts } from "../../../apis/communicationApi";
import { getContactGridNavigationItem } from "../util";
import { breadcrumbAction, tabNavigationAction } from "../../../_actions";

const ContactsGrid = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const app = useSelector((state) => state.app);
  const { communicationId } = useParams();
  const { all = [], loading: buildingLoading } = useSelector(
    (state) => state.buildings
  );
  const apiRef = useGridApiRef();
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" :  app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
  const [rowsData, setRowsData] = useState([]);
  const [isRowsDataLoading, setIsRowsDataLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_PAGINATION[keyPrefix], undefined
  ));
  const [communicationDetails, setCommunicationDetails] = useState({})

  useEffect(() => {
    (async () => {
      setIsRowsDataLoading(true);
      try {
        const response = await getCommunicationDetails(communicationId);
        if (response.status === 200) {
          setCommunicationDetails(response.data)
        }
        setIsRowsDataLoading(false)
      } catch (err) {
        console.log(err)
        setIsRowsDataLoading(false);
      }
    })();
  }, [communicationId]);

  useEffect(() => {
    if (!isRowsDataLoading) {
      const contactGridNavigation = getContactGridNavigationItem({ t, communicationId, communicationDetails })
      dispatch(tabNavigationAction.setNavigationTab(contactGridNavigation.tabs, contactGridNavigation.tabs.findIndex((tab) => tab.to === location.pathname)))
      dispatch(breadcrumbAction.setBreadcrumbItem(contactGridNavigation.breadCrumbs))
    }
  }, [communicationDetails, communicationId, dispatch, isRowsDataLoading, location.pathname, t])

  useEffect(() => {
    setIsRowsDataLoading(true)
    if (!buildingLoading) {
      (async () => {
        try {
          const allContacts = (await getContacts(communicationId)).data ?? [];
          setRowsData(allContacts)
          setIsRowsDataLoading(false)
        } catch (err) {
          console.error(err);
          setIsRowsDataLoading(false)
        }
      })();
    }
    return () => {
      setRowsData([]);
    };
  }, [all, app, buildingLoading]);

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_FILTER[keyPrefix],
    sortKey: LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_SORT[keyPrefix],
    i18n,
    t,
    apiRef,
    mode: "client"
  })

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_PAGINATION[keyPrefix],
      newPaginationModel
    );
  }, [keyPrefix])

  const columns = useMemo(() => {
    return [
      {
        field: "addressee",
        download: true,
        headerName: t('general.contact'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (<LabelColumnV2 label={t(`communication.contact.addressee.${rowData.addressee}`)}
            cellSubMenuOptions={{ options: getOptions({ field: "addressee", isQuickFilterDisplay: Boolean(rowData.addressee) }), extraData: { fieldName: "addressee" } }}
            request={rowData}
          />
          )
        },
      },
      {
        field: "name",
        download: true,
        headerName: t('general.name'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LinkColumnV2 to={app === apps.huisinfo ? `/communicatie/${communicationId}/contact/` + params?.row?.id : `/nazorg/communicatie/${communicationId}/contact/` + params?.row?.id} label={rowData?.name}
              cellSubMenuOptions={{ options: getOptions({ field: "name", isQuickFilterDisplay: Boolean(rowData.name) }), extraData: { fieldName: "name", } }}
              request={rowData}
            />
          )
        },
      },
    ];
  }, [getOptions, t]);

  const getContactsForPrintAndDownload = ({ isAllSelected = true }) => {
    const userSelectedRows = isAllSelected ? rowsData : rowSelectionModel?.map((id) => rowsData.find(item => item?.id === id));
    const results = userSelectedRows?.map((item) => {
      let result = {}
      Object.keys(item).forEach((key) => {
        result = {
          ...result,
          [key]: key === "addressee" ? t(`communication.contact.addressee.${item[key]}`) : item[key]
        }
      })
      return result
    })
    return results
  }

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_SEARCH_TEXT[keyPrefix]}
        localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_FILTER[keyPrefix]}
        localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_SORT[keyPrefix]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_ORDER[keyPrefix]}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_WIDTH[keyPrefix]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.CONTACTS_COMMUNICATION_COLUMN_VISIBILITY[keyPrefix]}
        apiRef={apiRef}
        loading={buildingLoading || isRowsDataLoading}
        checkboxSelection
        rows={rowsData}
        columns={columns}
        pagination
        {...(!!paginationModel && { paginationModel })}
        onPaginationModelChange={onPaginationModelChange}
        toolbarProps={{
          title: t("general.contact"),
          selectedRows: rowSelectionModel,
          getData: getContactsForPrintAndDownload,
        }}
        filterMode="client"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
      />
    </Container>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));
export default ContactsGrid;