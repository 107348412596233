import { BREADCRUMB_NAVIGATION } from "../_constants";

const clearBreadCrumb = () => {
    return {
        type: BREADCRUMB_NAVIGATION.CLEAR
    };
}

const setBreadcrumbItem = (data)=>{
 
    return {type: BREADCRUMB_NAVIGATION.SET_ITEM , data: data}
}




export const breadcrumbAction = {
    clearBreadCrumb,
    setBreadcrumbItem
}