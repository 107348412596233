import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TaskAutomationDetails from './TaskAutomationDetails';
import { breadcrumbAction, tabNavigationAction, taskDetailsActions } from '../../../_actions';
import Loader from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { getTaskAutomationDetailsNavigationItem } from './utils';
import { useTranslation } from 'react-i18next';

const TaskDetails = () => {
  const { loadingTaskDetails, taskDetails } = useSelector(state => state.systemMaintenance.taskDetailsStore)
  const dispatch = useDispatch()
  const { taskAutomationId } = useParams();
 
   const { t } = useTranslation();


   useEffect(() => {
      const taskAutomationDetailsNavigation = getTaskAutomationDetailsNavigationItem(t)
      dispatch(tabNavigationAction.setNavigationTab(taskAutomationDetailsNavigation.tabs))
      dispatch(breadcrumbAction.setBreadcrumbItem(taskAutomationDetailsNavigation.breadCrumbs))
    }, [dispatch, t, taskDetails])

    useEffect(()=>{return taskDetailsActions.clearTaskDetailsStore},[])

  useEffect(() => {
    if (taskAutomationId) {
      dispatch(taskDetailsActions.fetchAutomationDetails(taskAutomationId))
    }
  }, [dispatch, taskAutomationId])

  return <Stack  height={"100%"} >
    {loadingTaskDetails && <Loader />}
    {!loadingTaskDetails && <TaskAutomationDetails />}
  </Stack>
}

export default TaskDetails
