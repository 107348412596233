import React from 'react'
import { CheckCircle, Visibility } from '@mui/icons-material'
import { Box, Checkbox, CircularProgress, FormControlLabel, IconButton, Typography, Stack, FormGroup, Card } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useTheme } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { TASK_AUTOMATION } from '../../../../_constants'
import { taskAutomationActions } from '../../../../_actions'
import { URLS } from '../../../../apis/urls'
import { useTranslation } from 'react-i18next'

const DocumentTemplate = ({ setIsPreview }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { t }= useTranslation()

    const { systemMaintenance: { taskStore: { drawerInfo } } } = useSelector(state => state)
    const documentTemplates = drawerInfo?.documentTemplates


    const Loader = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}>
            <CircularProgress size={30} />
        </Box>
    );

    if (documentTemplates?.loading) {
        return documentTemplates?.isDocumentLabelSelected ? <Loader /> : <></>;
    }
    return <>
            <>
                <FormGroup>
                    <FormControlLabel
                        sx={{ mb: 0 }}
                        control={
                            <Checkbox
                                name="isTemplateChecked"
                                checked={documentTemplates?.isDocumentLabelSelected}
                                onChange={(event) => dispatch({ type: TASK_AUTOMATION.SET_DOCUMENT_LABEL_SELECT, data: event.target.checked })}
                            />
                        }
                        label={<Typography fontWeight={500} variant="body2">{t("taskAutomation.selectDocumentTemplate")}</Typography>}
                    />
                </FormGroup>

                {documentTemplates?.isDocumentLabelSelected && (
                    <Stack direction="row" sx={{ columnGap: 2, overflow: "auto" }}>
                        {documentTemplates?.loading && documentTemplates?.isDocumentLabelSelected ? (
                            <Box sx={{ height: 200, width: "100%" }}>
                                <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: "100%", width: "100%" }}>
                                    <CircularProgress size={"1.5rem"} />
                                </Stack>
                            </Box>
                        ) : (
                            <>
                                {documentTemplates?.templates?.length <= 0 && <Stack width={'100%'} alignItems={'center'}>
                                    <Typography variant="body1" align="center" >
                                        {t('taskAutomation.noDocumentTemplate')}
                                    </Typography>
                                </Stack>}
                                {documentTemplates?.templates?.map((template, index) => {
                                    const currentSelectedTemplate = documentTemplates.selectedTemplate?.id === template?.id
                                    const selectedTemplateBg = currentSelectedTemplate ? { opacity: 1, background: "rgba(0, 0, 0, 0.25)" } : { opacity: 0 }
                                    return (
                                        <Box key={index}>
                                            <Box sx={{ position: 'relative', display: 'inline-block', width: 123, height: 149, borderRadius: 0.5, ':hover': { cursor: 'pointer', opacity: 1 } }}>
                                                <Card sx={{ width: "100%", height: "100%" }}>
                                                    <Box
                                                        component="img"
                                                        src={`${URLS.GET_TEMPLATE_THUMBNAIL}${template?.id}`}
                                                        alt="Image"
                                                        sx={{ display: "block", width: "100%", height: "100%" }}
                                                    />
                                                    <Box sx={{ position: "absolute", top: 0, left: 0, width: 123, height: 149, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 0.5, ...selectedTemplateBg, backdropFilter: 'blur(2px)', ':hover': { opacity: 1, background: "rgba(0, 0, 0, 0.25)" } }}>
                                                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography variant="caption" sx={{ textAlign: 'center', color: 'common.white', overflow: "hidden", textOverflow: "ellipsis", maxWidth: "6rem" }}>
                                                                {template.desc}
                                                            </Typography>
                                                            <Stack direction='row' sx={{ columnGap: 1 }}>
                                                                <IconButton onClick={() => {
                                                                    dispatch(taskAutomationActions.selectDocumentTemplates(documentTemplates?.selectedTemplate && currentSelectedTemplate ? undefined : template))
                                                                }
                                                                } sx={{ p: 0 }}>
                                                                    <CheckCircle sx={[currentSelectedTemplate ? { color: blue[700] } : { color: theme.palette.common.white }]} />
                                                                </IconButton>
                                                                <IconButton sx={{ p: 0 }} onClick={() => {
                                                                    setIsPreview({ open: true, data: template, type: "DOCUMENT" })
                                                                }}>
                                                                    <Visibility sx={{ color: theme.palette.common.white }} />
                                                                </IconButton>
                                                            </Stack>
                                                        </Stack>
                                                    </Box>
                                                </Card>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </>
                        )}
                    </Stack>
                )}

            </>
    </>
}

export default DocumentTemplate
