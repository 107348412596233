import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {  grey,  } from "@mui/material/colors";
import React, {
useEffect,useState,
} from "react";
import { DragIndicator, Close } from "@mui/icons-material";

import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import {
  Button,
  Typography,
  Grid2 as Grid,
  FormControlLabel,
  Checkbox,
  Stack,
  Badge,
  Popover,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { jsonToExcel } from "../../Pages/Aftercare/Grid/util";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const SortableItem = SortableElement(
  ({ column, itemIndex, handleChange, selectedColumnsNames, ...rest }) => {
    const classes = useStyles();
    return (
      (<Grid
        className={classes.listItem}
        id={`draggableItem-${column.field}`}
        size={{xs:6}}>
        <DragIndicator className={classes.dragIndicator} />
        <span className={classes.orderText}>
          {(itemIndex + 1).toString().padStart(2, "0")}
        </span>
        <FormControlLabel
          color="primary"
          fontSize="15px"
          className={classes.formLabel}
          classes={{ label: classes.formLabelContent }}
          control={
            <Checkbox
              onChange={handleChange}
              checked={selectedColumnsNames.includes(column.field)}
              color="primary"
              name={column.field}
            />
          }
          label={
            column?.headerName?.trim() ? (
              column?.headerName
            ) : column?.renderHeader ? (
              <column.renderHeader />
            ) : (
              column.field
            )
          }
        />
      </Grid>)
    );
  }
);

const SortableList = SortableContainer(
  ({ columnsData, handleChange, selectedColumnsNames }) => {
    const classes = useStyles();
    return (
      <Grid
        container
        className={classes.gridContainer}
        sx={{ overflowY: "auto", maxHeight: "400px" }}
      >
        {columnsData.map((column, index) => (
          <SortableItem
            index={index}
            key={`${column.field}`}
            itemIndex={index}
            column={column}
            handleChange={handleChange}
            selectedColumnsNames={selectedColumnsNames}
          />
        ))}
      </Grid>
    );
  }
);

const ExportModal = ({
  rowsSelected,
  type,
  anchorEl,
  getData,
  columns,
  totalRecords,
  openExportModal,
  handleExportModal,
  ...props
}) => {
  const handleClose = () => {
    handleExportModal();
    setActivePage(0);
  };
  const [activePage, setActivePage] = useState(0);

  const [selectedColumnsNames, setSelectedColumnsNames] = useState([]);
  const [exportOption, setExportOption] = useState();
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchWidthDownMd = useMediaQuery(muiTheme.breakpoints.down("md"));

  const handleSelectOption = (pageNumber, selected) => {
    setActivePage(pageNumber);
    setExportOption(selected);
  };

  const handleChange = (e) => {
    const data = Object.assign([], selectedColumnsNames);
    const index = data.findIndex((a) => a === e.target.name);
    if (index >= 0) data.splice(index, 1);
    else data.push(e.target.name);
    setSelectedColumnsNames(data);
  };

  useEffect(() => {
    setDisplayedColumns(columns.filter((column)=>column.download));
  }, []);

  useEffect(() => {
    setSelectedColumnsNames([]);
  }, [activePage]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedOrderColumns = arrayMove(
      Object.assign([], displayedColumns),
      oldIndex,
      newIndex
    );
    if (oldIndex !== newIndex) setDisplayedColumns(updatedOrderColumns);
  };

  const standardOrder = () => {
    setDisplayedColumns(columns);
  };

  const downloadExcel = async () => {
    try {
      setExcelLoading(true);
      let data = [];
      let modifiedData = await getData({
        selectedColumnsNames,
        isAllSelected: exportOption === "ALL",
      });

      const printedColumns = displayedColumns.filter((a) =>
        selectedColumnsNames.includes(a.field)
      );

      modifiedData?.forEach((item) => {
        let convertedItem = {};
        Object.keys(item).forEach((key) => {
          convertedItem = {
            ...convertedItem,
            [key]: item[key]?.toString()?.slice(0, 32766) ,
          };
        });
        data.push(convertedItem);
      });
      
      data = data?.map((c, i) => {
        return printedColumns.reduce((p, curr) => {
          if (curr.download !== false) {
            return {
              ...p,
              [curr.headerName]:
                curr.field === "priority" && c[curr.field]  
                  ? t("repairRequest.priority." + c[curr.field])
                  : Array.isArray(c[curr.field])
                    ? c[curr.field].join(", ")
                    : c[curr.field],
            };
          }

          return p;
        }, {});
      });

      jsonToExcel(data);
      handleClose();
    } catch (e) {
      console.log({ e });
    } finally {
      setExcelLoading(false);
    }
  };

  const selectedColumns = () => {
    setSelectedColumnsNames(displayedColumns.map((a) => a.field));
  };

  const unSelectedColumns = () => {
    setSelectedColumnsNames([]);
  };
  const handleClick = () => {
    downloadExcel();
  };
  const ContentBody = () => {
    return (
      (<div className={classes.popoverRoot}>
        <Box className={classes.exportModalWrapper}>
          {activePage === 0 && (
            <>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                <Typography
                  id="modal-modal-title"
                  className={classes.exportModalTitle}
                  variant="h6"
                  component="h6"
                >
                  {t("dataGrid.exportModal.title")}
                </Typography>
                <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                  <Close />
                </Box>
              </Stack>
              <Stack
                direction="column"
                spacing={3}
                className={classes.rowsSelectedWrapper}
              >
                <Stack
                  onClick={() =>
                    rowsSelected?.length > 0 && handleSelectOption(1, "SELECTED")
                  }
                  className={classes.selectedRowWrapper}
                >
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.rowTitle}
                      >
                        {t("dataGrid.exportModal.seletedRows.title")} <span className={classes.Badge}> <Badge
                          badgeContent={rowsSelected?.length}
                          color="primary"
                        >
                          {" "}
                        </Badge></span>
                      </Typography>
                    </Box>
                  </Stack>
                  <Box>
                    <Typography className={classes.rowSubTitle}>
                      {t("dataGrid.exportModal.seletedRows.subTitle")} (
                      {rowsSelected?.length})
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  onClick={() => handleSelectOption(1, "ALL")}
                  className={classes.selectAllRowWrapper}
                >
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.rowTitle}
                      >
                        {t("dataGrid.exportModal.allRows.title")}
                        <span className={classes.Badge}> <Badge
                          badgeContent={totalRecords}
                          color="primary"
                        >
                          {" "}
                        </Badge></span>
                      </Typography>
                    </Box>

                  </Stack>
                  <Box>
                    <Typography className={classes.rowSubTitle}>
                      {t("dataGrid.exportModal.allRows.subTitle")} (
                      {totalRecords})
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                className={classes.cancleButtonWrapper}
              >
                <Button variant="outlined" size="small" onClick={handleClose}>
                  {t("general.cancel")}
                </Button>
              </Stack>
            </>
          )}
          {activePage === 1 && (
            <>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                <Typography
                  className={classes.exportModalTitle}
                  id="modal-modal-title"
                  variant="h5"
                  component="h6"
                >
                  {t("dataGrid.exportModal.title")}
                </Typography>
                <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                  <Close />
                </Box>
              </Stack>
              <Box className={classes.buttonWrapper} >
                <Button
                  className={classes.selectColumnButton}
                  variant="text"
                  onClick={selectedColumns}
                >
                  {t("dataGrid.exportModal.selectAll")}
                </Button>
                <Button
                  className={classes.selectColumnButton}
                  variant="text"
                  onClick={unSelectedColumns}
                >
                  {t("dataGrid.exportModal.deselectAll")}
                </Button>
                <Button
                  className={classes.selectColumnButton}
                  variant="text"
                  onClick={standardOrder}
                >
                  {t("dataGrid.exportModal.standardOrder")}
                </Button>
              </Box>
              <Box
                className={classes.allColumnWrapper}

              >
                <SortableList
                  transitionDuration={400}
                  columnsData={displayedColumns}
                  axis="xy"
                  distance={10}
                  onSortEnd={onSortEnd}
                  handleChange={handleChange}
                  selectedColumnsNames={selectedColumnsNames}
                />
              </Box>
              <Box className={classes.actionButtonWrapper}>
                <Button
                  className={classes.actionButton}
                  variant="contained"
                  onClick={() => handleSelectOption(0)}
                  disabled={excelLoading}
                >
                  {t("general.button.previous")}
                </Button>
                <LoadingButton
                  className={classes.actionButton}
                  onClick={handleClick}
                  variant="contained"
                  loading={excelLoading}
                  disabled={selectedColumnsNames.length === 0}
                >
                  {t("general.export")}
                </LoadingButton>
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  onClick={handleClose}
                  disabled={excelLoading}
                >
                  {t("general.cancel")}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </div>)
    );
  };

  return (
    <div>
      {matchWidthDownMd ? (
        <Popover
          open={openExportModal}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 250, left: 400 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.popover}
        >
          <ContentBody />
        </Popover>
      ) : (
        <Modal
          open={openExportModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ContentBody />
        </Modal>
      )}
    </div>
  );
};

export default ExportModal;

const useStyles = makeStyles((theme) => ({
  exportModalWrapper: {
    background: "white",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      padding: theme.spacing(4),
    },
  },
  exportModalTitle: {
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      fontWeight: 500
    }
  },
  cancleButtonWrapper: {
    marginTop: theme.spacing(16),
    justifyContent: "end",
    alignItems: "center"
  },
  Badge: {
    marginLeft: "20px !important",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px !important",
    }
  },
  allColumnWrapper: {
    [theme.breakpoints.up("md")]: {
      displaY: "flex",
      bgcolor: grey[50],
      borderRadius: theme.spacing(3),
      alignItems: "center",
      marginTop: theme.spacing(2),
      justifyContent: "center"
    }
  },
  actionButtonWrapper: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(11),
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
  },
  selectColumnButton: {
    letterSpacing: "1px",
    fontSize: "12px",
    size: "small",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      size: "medium",
      fontSize: "14px",
    },
  },
  actionButton: {
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.4),
    size: "small",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0.6),
      marginRight: theme.spacing(0.6),
      padding: theme.spacing(0.5, 3),
      size: "small",
    },
  },
  selectAllRowWrapper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.2),
    cursor: "pointer",
    "&:hover": { background: grey[50] }
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  },
  selectedRowWrapper: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(0.2),
      cursor: "pointer",
      "&:hover": { background: grey[50] }
    },
  },
  rowSubTitle: {
    fontSize: 14,
  },
  rowTitle: {
    fontSize: "18px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      marginRight: theme.spacing(1),
    },
  },
  rowsSelectedWrapper: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  popover: {
    "& .MuiPopover-paper": {
      [theme.breakpoints.only("xs")]: {
        top: "auto !important",
        bottom: "0px !important",
        left: "0px !important",
        maxWidth: "100% !important",
        width: "100%",
        boxShadow: theme.shadows[24],
        borderRadius: 20,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
  popoverRoot: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },


  formLabel: {
    margin: 0,
    marginLeft: -5,
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  formLabelContent: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "13px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  listItem: {
    margin: `0 !important`,
    opacity: `1 !important`,
    zIndex: 1500,
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
  },
  dragIndicator: {
    width: 17,
    height: 17,
    fontSize: 12,
    cursor: "grab",
  },
  orderText: {
    fontSize: 12,
    lineHeight: "12px",
  },
  gridContainer: {
    width: 500,
    maxWidth: "100%",
    padding: theme.spacing(1),
  },
}));
