import React, { useEffect,useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

const EditDialogTabNavigation = ({  onChangeTab ,tabValue}) => {
  const {t} = useTranslation()
  const tabItems = [
    { label: t('general.general'), path: "#", key: 0 },
    { label: t('taskAutomation.templateSettings'), path: "#", key: 1 },
    { label: t("general.techInfo"), path: "#", key: 2 },
];

  const [value, setValue] = useState(tabValue || 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChangeTab(newValue)
  };
  useEffect(()=>{setValue(tabValue)},[tabValue])
  return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        indicatorColor="primary"
        aria-label="scrollable tabs"
        sx={{
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
          },
          boxShadow: 2,
          px:2
        }}
      >
        {tabItems.map((tab) => (
          <Tab
            key={tab.key}
            label={
                tab.label
            }
            sx={{
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>
  );
};
export default EditDialogTabNavigation;