import {
  AppBar,
  Button,
  alpha,
  Grid2 as Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, GetApp, Lock, Warning } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Route, Switch, useHistory } from "react-router-dom";
import SurveysLists from "../components/SurveysLists";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { surveyActions } from "../../../_actions";
import { SurveyType } from "../../../_helpers/enumsHelper";
import RepairRequestForm from "../RepairRequestForm";
import { useState } from "react";
import { apps, surveyConstants } from "../../../_constants";
import PdfPreview from "../components/PdfPreview";
import PreviewAndSign from "../components/PreviewAndSign";
import { useRedirectionPath } from "../../../_helpers/redirectHelper";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import {
  useCanCompleteSecondSignature,
  useCanLockedInspection,
  useInternetStatusDetector,
} from "../../../_helpers";
import { CircularProgress } from "@mui/material";
import SignatureDetail from "./components/SignatureDetail";

export default function Signature(props) {
  const app = useSelector((state) => state.app);
  const classes = useStyles({ app });
  const history = useHistory();
  const { t } = useTranslation();
  const isOnline = useInternetStatusDetector();

  //Redux State
  const { selected } = useSelector((state) => state.buildings);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { availableSurveyModules } = useSelector(
    (state) => state.availableSurveyModules
  );
  const { user } = useSelector((state) => state.authentication);
  const {
    isSecondSignatureInitiatedLoading,
    inspections,
    selectedInspection,
    repairRequests,
    loadingRepairRequests,
    assignmentLockIds,
  } = useSelector((state) => state.surveying);

  const dispatch = useDispatch();
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({
    pages: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [inspectionId, setInspectionId] = useState(null);
  const [isRepairRequestPage, setIsRepairRequestPage] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = React.useState(false);
  const [isPdfPreview, setIsPdfPreview] = useState(false);
  const [isOpenSignature, setIsOpenSignature] = useState(false);
  const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
  const [canGoBack, setCanGoBack] = useState(null);
  const redirectPatch = useRedirectionPath(SurveyType.SecondSignature);
  const canCompleteSurvey = useCanCompleteSecondSignature();
  const canSubmit =
    repairRequests && repairRequests.length
      ? repairRequests.every((r) => r.completedByBuyer === true)
      : true;
  const canLockedInspection = useCanLockedInspection();

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes("tweede-handtekening")) {
        dispatch(
          surveyActions.success({
            type: surveyConstants.GET_INSPECTIONS_SUCCESS,
            inspections: [],
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (redirectPatch) history.push(redirectPatch);
  }, [availableSurveyModules, user]);

  useEffect(() => {
    if (selected && inspectionId && !selectedInspection) {
      dispatch(surveyActions.getSurveyDetailAction(inspectionId, true));
    }
  }, [inspectionId]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      setSelectedDrawingFiles({
        ...drawingFiles[0],
        groupBy: drawingFiles[0].isInternal ? "Internal" : "External",
      });
    } else {
      setSelectedDrawingFiles({});
    }
  }, [drawingFiles]);

  useEffect(() => {
    if (selectedInspection) {
      dispatch(
        dossiersActions.getDrawingFiles(
          selectedInspection.projectId,
          selectedInspection.buildingId
        )
      );
    }
  }, [selectedInspection]);

  const startSecondSignature = () => {
    dispatch(
      surveyActions.startSecondSignatureAction(selectedInspection.surveyId)
    );
  };

  const handleGoBack = () => {
    let url = props.match.url;
    if (isRepairRequestPage) {
      setIsRepairRequestPage(false);
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
    } else if (isPdfPreview) {
      setIsPdfPreview(false);
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
    } else if (
      (selectedInspection && selectedInspection.surveyId) ||
      inspectionId
    ) {
      setInspectionId(null);
      dispatch(
        surveyActions.customDispatcher({
          type: surveyConstants.GET_INSPECTION_DETAIL_SUCCESS,
          data: null,
        })
      );
    }
    history.replace(url);
  };

  const pdfPreviewHandler = () => {
    history.push(`${props.match.url}/${inspectionId}/preview`);
  };

  const signatureHandler = () => {
    setIsSignatureModalOpen(!isSignatureModalOpen);
  };

  const redirectTo1stSignature = () => {
    history.push(
      `${props.match.url.replace("tweede-handtekening", "opleveringen")}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`,
      { isSecondSignature: true }
    );
  };

  const canShowBackButton =
    props &&
    props.match &&
    props.location &&
    props.match.url !== props.location.pathname;
  const survey =
    inspections.find(
      (a) => a.surveyId === (selectedInspection && selectedInspection.surveyId)
    ) || {};
  const lockInfo =
    assignmentLockIds &&
    assignmentLockIds.find(
      (a) =>
        a.lockId === (selectedInspection && selectedInspection.lockId) &&
        a.isSecondSignature
    );
  const isDownloadedSurvey =
    selectedInspection &&
    lockInfo &&
    lockInfo.userId === user.id &&
    selectedInspection.lockId;
  const { isCracked } = survey;

  return (
    (<div className={classes.appbar}>
      <Grid
        className={clsx(
          classes.blocksContainer,
          selectedInspection && selectedInspection.surveyId
        )}
        size={{xs:12}}>
        {inspectionId && selectedInspection && selectedInspection.surveyId && (
          <AppBar
            position="static"
            color="transparent"
            className={classes.header}
          >
            <Toolbar
              variant="dense"
              className={classes.surveyMomentsHeaderSecond}
            >
              {
                <React.Fragment>
                  {canShowBackButton && (
                    <Grid>
                      <IconButton
                        edge="start"
                        aria-label="GoBack"
                        color="inherit"
                        onClick={handleGoBack}
                        size="large"
                      >
                        <ArrowBack className={classes.arrowBlackIcon} />
                      </IconButton>
                    </Grid>
                  )}
                  <div
                    className={clsx(classes.grow, classes.bold)}
                    style={{ display: "flex" }}
                    noWrap
                  >
                    <div className={classes.appHeader}>
                      <Typography className={classes.appBarText}>
                        {` ${t("general.secondSignature")}: ${
                          selectedInspection.buildingNoIntern
                        }`}
                      </Typography>

                      <div className={classes.detailIconContainer}>
                        {isDownloadedSurvey && (
                          <Tooltip
                            title={t(`general.downloaded`, {
                              moduleType: t("general.secondSignature"),
                            })}
                          >
                            <GetApp color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {isCracked && (
                          <Tooltip
                            title={t(`general.locked`, {
                              moduleType: t("general.secondSignature"),
                            })}
                          >
                            <Warning color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {!lockInfo && selectedInspection.lockId && (
                          <Tooltip
                            title={t(`general.lockBroken`, {
                              moduleType: t("general.secondSignature"),
                            })}
                          >
                            <Lock color="primary" className={classes.icon} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    {inspectionId &&
                      !isRepairRequestPage &&
                      !isPdfPreview &&
                      selectedInspection && (
                        <div className={classes.signButtonContainer}>
                          <Button
                            className={classes.signButton}
                            variant="contained"
                            color="primary"
                            onClick={redirectTo1stSignature}
                            disabled={!isOnline}
                          >
                            {t("general.delivery")}
                          </Button>
                          {!canCompleteSurvey &&
                            (!selectedInspection.isSecondSignatureInitiated ? (
                              <Button
                                className={classes.signButton}
                                variant="contained"
                                color="primary"
                                disabled={
                                  isSecondSignatureInitiatedLoading ||
                                  canLockedInspection ||
                                  !selectedInspection.hasRights
                                }
                                onClick={startSecondSignature}
                              >
                                {isSecondSignatureInitiatedLoading ? (
                                  <CircularProgress
                                    className={classes.loading}
                                    size={20}
                                  />
                                ) : (
                                  t("survey.startSecondSignature")
                                )}
                              </Button>
                            ) : (
                              <Tooltip
                                title={
                                  canSubmit
                                    ? ""
                                    : t(
                                        "survey.secondSignature.approveRepairRequestWarning"
                                      )
                                }
                              >
                                <span>
                                  <Button
                                    disabled={
                                      !canSubmit ||
                                      loadingRepairRequests ||
                                      canLockedInspection ||
                                      !selectedInspection.hasRights
                                    }
                                    className={clsx(
                                      classes.signButton,
                                      !canSubmit && classes.disabledNavButtons
                                    )}
                                    variant="contained"
                                    color="primary"
                                    onClick={pdfPreviewHandler}
                                  >
                                    {t("survey.button.previewAndSign")}
                                  </Button>
                                </span>
                              </Tooltip>
                            ))}
                        </div>
                      )}

                    {isPdfPreview && !canCompleteSurvey && (
                      <Button
                        disabled={canLockedInspection}
                        className={classes.signButton}
                        variant="contained"
                        color="primary"
                        onClick={signatureHandler}
                      >
                        {t("general.sign")}
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              }
            </Toolbar>
          </AppBar>
        )}
        <div
          className={clsx(
            classes.scrollContainer,
            selectedInspection && classes.calculateHeight
          )}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <Switch>
              <Route
                exact
                path={`${props.match.path}`}
                render={(rest) => (
                  <SurveysLists
                    data={(inspections || []).filter(
                      (a) =>
                        a.surveyType === SurveyType.Delivery &&
                        a.isSecondSignature
                    )}
                    moduleType={SurveyType.Delivery}
                    title={t("general.secondSignature")}
                    gridDataTitle={t("general.secondSignature")}
                    parentUrl={props.match.url}
                    canShowPdfColumn
                    moduleTitle={t("general.secondSignature")}
                    isSecondSignatureSurvey
                    pathKey="tweede-handtekening"
                    {...rest}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId`}
                render={(rest) => (
                  <SignatureDetail
                    {...rest}
                    isSecondSignature
                    canGoBack={canGoBack}
                    setIsOpenSignature={setIsOpenSignature}
                    isDownloadedSurvey={isDownloadedSurvey}
                    showPdfIcon
                    setOpenRepairRequestMap={setOpenRepairRequestMap}
                    openRepairRequestMap={openRepairRequestMap}
                    pdfPreview={isPdfPreview}
                    showActionColumn
                    setId={(id) => setInspectionId(id)}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId/preview`}
                render={(rest) => (
                  <PdfPreview
                    signatureModal={isSignatureModalOpen}
                    id={inspectionId}
                    isDownloadedSurvey={isDownloadedSurvey}
                    isSecondSignature
                    setPdfPreview={(data) => setIsPdfPreview(data)}
                    offlinePDFOnly={isDownloadedSurvey}
                    setIsOpenSignature={setIsOpenSignature}
                    close={() => signatureHandler()}
                    setId={(id) => setInspectionId(id)}
                    {...rest}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Grid>
      <RepairRequestForm
        repairRequests={repairRequests}
        moduleType={SurveyType.SecondSignature}
        setSelectedDrawingFiles={setSelectedDrawingFiles}
        setCurrentPage={setCurrentPage}
        surveyId={selectedInspection && selectedInspection.surveyId}
        buildingId={selectedInspection && selectedInspection.buildingId}
        selectedDrawingFiles={selectedDrawingFiles}
        projectId={selectedInspection && selectedInspection.projectId}
        currentPage={currentPage}
        redirectToMomentDetail={console.log}
        onPreview={console.log}
        setID={(id) => setInspectionId(id)}
        openRepairRequestMap={openRepairRequestMap}
        onClose={(reload) => {
          reload && setCanGoBack(new Date().getTime());
          setOpenRepairRequestMap(false);
          setCurrentPage(1);
        }}
      />
      <PreviewAndSign
        {...props}
        closeModalHandler={() => setIsOpenSignature(false)}
        openEmailModal={isOpenSignature}
        setIsOpenSignature={setIsOpenSignature}
        isSecondSignature={true}
      />
    </div>)
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  header: {
    boxShadow: "none",
  },
  surveyMomentsHeaderSecond: {
    padding: theme.spacing(0, 2),
  },
  appbar: {
    height: "100%",
    overflow: "auto",
  },
  scrollContainer: {
    height: "100%",
    // overflow: 'auto'
  },
  blocksContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&::before": {
      content: '""',
      [theme.breakpoints.down("md")]: {
        background: theme.palette.common.white,
      },
      backgroundImage: ({ app }) =>
        app === apps.aftercare ? null : 'url("/Content/Background/House-900x604.svg")',
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center bottom",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "fixed",
    },
  },
  calculateHeight: {
    height: "calc(100% - 48px) !important",
  },
  loading: {
    color: theme.palette.common.white,
  },
  signButtonContainer: {
    display: "flex",
  },
  signButton: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 12,
      padding: theme.spacing(0.2, 0.6),
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
      padding: theme.spacing(0.2, 0.6),
    },
    fontWeight: "normal",
    marginLeft: 8,
    whiteSpace: "nowrap",
  },
  disabledNavButtons: {
    borderColor: `${alpha(theme.palette.common.white, 0.5)} !important`,
    color: `${alpha(theme.palette.common.white, 0.5)} !important`,
  },
  appHeader: {
    display: "flex",
    // maxWidth: '50%',
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  arrowBlackIcon: {
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  appBarText: {
    fontSize: 16,
    marginLeft: 10,
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
