import {
  AppBar,
  Button,
  CircularProgress,
  Grid2 as Grid,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, GetApp, Lock, Warning } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Route, Switch, useHistory } from "react-router-dom";
import SurveysLists from "../components/SurveysLists";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../../_actions";
import { SurveyType } from "../../../_helpers/enumsHelper";
import RepairRequestForm from "../RepairRequestForm";
import { useState } from "react";
import { IconButton } from "@mui/material";
import {
  apps,
  surveyConstants,
  userAccountTypeConstants,
} from "../../../_constants";
import PreDeliveriesDetail from "./components/PreDeliveriesDetail";
import { useRedirectionPath } from "../../../_helpers/redirectHelper";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import PdfPreview from "../components/PdfPreview";
import {
  useCanLockedInspection,
  useInternetStatusDetector,
} from "../../../_helpers";

export default function PreDeliveries(props) {
  const app = useSelector((state) => state.app);
  const classes = useStyles({ app });
  const history = useHistory();
  const { t } = useTranslation();
  //Redux State
  const { selected } = useSelector((state) => state.buildings);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { availableSurveyModules } = useSelector(
    (state) => state.availableSurveyModules
  );
  const { user } = useSelector((state) => state.authentication);
  const {
    repairRequests,
    loadingRepairRequests,
    inspections,
    isSurveyUpdating,
    selectedInspection,
    inspectionLoading,
    assignmentLockIds,
  } = useSelector((state) => state.surveying);

  const dispatch = useDispatch();
  const [inspectionId, setInspectionId] = useState(null);
  const [isRepairRequest, setIsRepairRequest] = useState(false);
  const [deliveryDetail, setDeliveryDetail] = useState(false);
  const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
  const [offlineRepairRequestData, setOfflineRepairRequestData] =
    useState(null);
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({
    pages: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [canGoBack, setCanGoBack] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(false);
  const [updateKey, setUpdateKey] = useState(false);
  const [isUpdatingStatus, setUpdatingStatus] = useState(false);
  const redirectPatch = useRedirectionPath(SurveyType.PreDelivery);
  const canLockedInspection = useCanLockedInspection();
  const survey =
    inspections.find(
      (a) => a.surveyId === (selectedInspection && selectedInspection.surveyId)
    ) || {};
  const { isCracked } = survey;
  const lockInfo =
    assignmentLockIds &&
    assignmentLockIds.find(
      (a) =>
        a.lockId === (selectedInspection && selectedInspection.lockId) &&
        !a.isSecondSignature
    );
  const isDownloadedSurvey =
    selectedInspection &&
    lockInfo &&
    lockInfo.userId === user.id &&
    selectedInspection.lockId;
    const isOnline = useInternetStatusDetector();

  useEffect(() => {
    if (updateKey && !isSurveyUpdating) {
      setUpdateKey(false);
    }
  }, [isSurveyUpdating, updateKey]);

  useEffect(() => {
    if (redirectPatch && user && user.type !== userAccountTypeConstants.buyer)
      history.push(redirectPatch);
  }, [availableSurveyModules, user]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes("voorschouwingen")) {
        dispatch(
          surveyActions.success({
            type: surveyConstants.GET_INSPECTIONS_SUCCESS,
            inspections: [],
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (selected && inspectionId && !selectedInspection) {
      dispatch(surveyActions.getSurveyDetailAction(inspectionId, false));
      dispatch(surveyActions.getRepairRequests(inspectionId));
    }
  }, [inspectionId]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      setSelectedDrawingFiles({
        ...drawingFiles[0],
        groupBy: drawingFiles[0].isInternal ? "Internal" : "External",
      });
    } else {
      setSelectedDrawingFiles({});
    }
  }, [drawingFiles]);

  useEffect(() => {
    if (selectedInspection) {
      dispatch(
        dossiersActions.getDrawingFiles(
          selectedInspection.projectId,
          selectedInspection.buildingId
        )
      );
    }
  }, [selectedInspection]);

  const handleGoBack = () => {
    let url = props.match.url;
    if (isRepairRequest) {
      setIsRepairRequest(false);
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
    } else if (pdfPreview) {
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
      setPdfPreview(false);
    } else if (
      (selectedInspection && selectedInspection.surveyId) ||
      inspectionId
    ) {
      setInspectionId(null);
      dispatch(
        surveyActions.customDispatcher({
          type: surveyConstants.GET_INSPECTION_DETAIL_SUCCESS,
          data: null,
        })
      );
    }
    history.replace(url);
  };

  const previewHandler = () => {
    history.push(`${props.match.url}/${inspectionId}/preview`);
  };

  const startPreDelivery = async () => {
    setUpdatingStatus(true);
    await dispatch(
      surveyActions.updateSurvey(
        {
          ...selectedInspection,
          status: 1,
          surveyId: selectedInspection.surveyId,
        },
        selectedInspection.surveyType
      )
    );
    setUpdatingStatus(false);
  };

  const canShowBackButton =
    props &&
    props.match &&
    props.location &&
    props.match.url !== props.location.pathname;

  return (
    (<div className={classes.appbar}>
      <Grid
        className={clsx(
          classes.blocksContainer,
          selectedInspection &&
            selectedInspection.surveyId &&
            classes.calculateHeight
        )}
        size={{xs:12}}>
        {inspectionId && selectedInspection && selectedInspection.surveyId && (
          <AppBar
            position="static"
            color="transparent"
            className={classes.surveyMomentsMainHeaderSecond}
          >
            <Toolbar
              variant="dense"
              className={classes.surveyMomentsHeaderSecond}
            >
              {
                <React.Fragment>
                  {canShowBackButton && (
                    <Grid>
                      <IconButton
                        edge="start"
                        aria-label="GoBack"
                        color="inherit"
                        onClick={handleGoBack}
                        size="large"
                      >
                        <ArrowBack className={classes.arrowBlackIcon} />
                      </IconButton>
                    </Grid>
                  )}
                  <div
                    className={clsx(classes.grow, classes.bold)}
                    style={{ display: "flex" }}
                    noWrap
                  >
                    <div className={classes.appHeader}>
                      <Typography className={classes.appBarText}>
                        {t("survey.preDeliveryWithBuildingNo", {
                          no: selectedInspection.buildingNoIntern,
                        })}
                      </Typography>
                      <div className={classes.detailIconContainer}>
                        {isDownloadedSurvey && (
                          <Tooltip
                            title={t(`general.downloaded`, {
                              moduleType: t("general.preDelivery"),
                            })}
                          >
                            <GetApp color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {isCracked && (
                          <Tooltip
                            title={t(`general.locked`, {
                              moduleType: t("general.preDelivery"),
                            })}
                          >
                            <Warning color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {!lockInfo && selectedInspection.lockId && (
                          <Tooltip
                            title={t(`general.lockBroken`, {
                              moduleType: t("general.preDelivery"),
                            })}
                          >
                            <Lock color="primary" className={classes.icon} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  {inspectionId && !isRepairRequest && selectedInspection && (
                    <div>
                      {selectedInspection.status === 0 && (
                        <Button
                          className={classes.signButton}
                          variant="contained"
                          color="primary"
                          disabled={
                            canLockedInspection || !selectedInspection.hasRights
                          }
                          onClick={startPreDelivery}
                        >
                          {isSurveyUpdating && isUpdatingStatus ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                            />
                          ) : (
                            t("survey.button.start")
                          )}
                        </Button>
                      )}
                    </div>
                  )}
                  {!inspectionLoading &&
                    deliveryDetail &&
                    selectedInspection &&
                    selectedInspection.status === 1 && (
                      <div className={classes.signButtonContainer}>
                        <Button
                          onClick={() => {
                            setUpdateKey(true);
                            dispatch(
                              surveyActions.updateSurvey(
                                { ...selectedInspection, status: 2 },
                                selectedInspection.surveyType,
                                false,
                                0
                              )
                            );
                          }}
                          disabled={
                            (isSurveyUpdating && updateKey) ||
                            canLockedInspection ||
                            !selectedInspection.hasRights
                          }
                          className={classes.signButton}
                          variant="contained"
                          color="primary"
                        >
                          {isSurveyUpdating && updateKey ? (
                            <CircularProgress size={20} />
                          ) : (
                            t("general.button.complete")
                          )}
                        </Button>
                        <Button
                          className={classes.signButton}
                          variant="contained"
                          disabled={
                            canLockedInspection ||
                            loadingRepairRequests ||
                            !selectedInspection.hasRights
                          }
                          color="primary"
                          onClick={previewHandler}
                        >
                          {t("general.preview")}
                        </Button>
                      </div>
                    )}
                </React.Fragment>
              }
            </Toolbar>
          </AppBar>
        )}
        <div
          className={clsx(
            classes.scrollContainer,
            selectedInspection && classes.calculateHeight
          )}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <Switch>
              <Route
                exact
                path={`${props.match.path}`}
                render={(rest) => (
                  <SurveysLists
                    {...rest}
                    showTimeColumns
                    canShowPdfColumn
                    moduleType={SurveyType.PreDelivery}
                    onlyOfflinePDFVersion
                    preDeliveryOrInspectionPDF
                    gridDataTitle={t("general.preDelivery")}
                    title={t("general.preDelivery")}
                    parentUrl={props.match.url}
                    data={(inspections || []).filter(
                      (a) => a.surveyType === SurveyType.PreDelivery
                    )}
                    pathKey={"voorschouwingen"}
                    moduleTitle={t("general.preDelivery")}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId`}
                render={(rest) => (
                  <PreDeliveriesDetail
                    canGoBack={canGoBack}
                    openRepairRequestMap={openRepairRequestMap}
                    setOpenRepairRequestMap={(data) => {
                      setOpenRepairRequestMap(true);
                      data && setOfflineRepairRequestData(data);
                    }}
                    setDeliveryDetail={(data) => setDeliveryDetail(data)}
                    setId={(id) => setInspectionId(id)}
                    {...rest}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId/preview`}
                render={(rest) => (
                  <PdfPreview
                    id={inspectionId}
                    onlyShowPDF
                    isDownloadedSurvey={isDownloadedSurvey}
                    showMeldingImage={isOnline}
                    hideSignatureFromPDF
                    offlinePDFOnly
                    setPdfPreview={(data) => setPdfPreview(data)}
                    setId={(id) => setInspectionId(id)}
                    {...rest}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Grid>
      <RepairRequestForm
        repairRequests={repairRequests}
        isUpdate={!!offlineRepairRequestData}
        values={offlineRepairRequestData}
        moduleType={SurveyType.PreDelivery}
        setSelectedDrawingFiles={setSelectedDrawingFiles}
        setCurrentPage={setCurrentPage}
        surveyId={selectedInspection && selectedInspection.surveyId}
        buildingId={selectedInspection && selectedInspection.buildingId}
        projectId={selectedInspection && selectedInspection.projectId}
        selectedDrawingFiles={selectedDrawingFiles}
        currentPage={currentPage}
        redirectToMomentDetail={console.log}
        onPreview={console.log}
        setID={(id) => setInspectionId(id)}
        openRepairRequestMap={openRepairRequestMap}
        onClose={(reload) => {
          reload && setCanGoBack(new Date().getTime());
          setCurrentPage(1);
          offlineRepairRequestData && setOfflineRepairRequestData(null);
          setOpenRepairRequestMap(false);
        }}
        drawingLocation={
          offlineRepairRequestData && {
            xrel: +offlineRepairRequestData["DrawingPinLocationModel.Xrel"],
            yrel: +offlineRepairRequestData["DrawingPinLocationModel.Yrel"],
            dossierFileId:
              offlineRepairRequestData["DrawingPinLocationModel.DossierFileId"],
          }
        }
      />
    </div>)
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  surveyMomentsMainHeaderSecond: {
    boxShadow: "none",
  },
  surveyMomentsHeaderSecond: {
    padding: theme.spacing(0, 2),
  },
  loading: {
    color: theme.palette.common.white,
  },
  appbar: {
    height: "100%",
    overflow: "auto",
  },
  scrollContainer: {
    height: "100%",
    // height: 'calc(100% - 58px) !important',
    // overflow: 'auto'
  },
  blocksContainer: {
    position: "relative",
    height: "100%",
    // height: 'auto',
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&::before": {
      content: '""',
      [theme.breakpoints.down("md")]: {
        background: theme.palette.common.white,
      },
      backgroundImage: ({ app }) =>
        app === apps.aftercare ? null : 'url("/Content/Background/House-900x604.svg")',
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center bottom",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "fixed",
    },
  },
  calculateHeight: {
    height: "calc(100% - 48px) !important",
  },
  signButtonContainer: {
    display: "flex",
  },
  signButton: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      padding: theme.spacing(0.2, 1.9),
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
      padding: theme.spacing(0.2, 1.9),
    },
    fontWeight: "normal",
    marginLeft: 8,
    whiteSpace: "nowrap",
  },
  appHeader: {
    display: "flex",
    // maxWidth: '50%',
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  arrowBlackIcon: {
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  appBarText: {
    color: theme.palette.common.black,
    fontSize: 16,
    marginLeft: 10,
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  detailIconContainer: {
    marginLeft: 5,
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        fontSize: 15,
      },
    },
  },
}));
