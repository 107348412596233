import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, Drawer, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next';
import MainHeader from './MainHeader';
import PreviewTemplate from './PreviewTemplate';
import GenerateTask from './GenerateTask';
import { useDispatch, useSelector } from 'react-redux';
import { taskAutomationActions, toastActions } from '../../../_actions';
import { createTaskAutomation } from '../../../apis/systemMaintenanceApis';
import { throttle } from '../../../_helpers/throttle';
import { history } from '../../../_helpers';
import { apps } from '../../../_constants';

const SelectTemplatesDrawer = () => {
  const [isPreview, setIsPreview] = useState({ open: false, type: "", data: null });
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { systemMaintenance: { taskStore: { drawerInfo } } } = useSelector(state => state)
  const [loading, setLoading] = useState(false)
  const isDisabled = drawerInfo?.taskName && drawerInfo?.emailTemplates?.selectedTemplate && drawerInfo?.selectedProjectDetails?.selectedProjectLabel
  const app = useSelector((state) => state.app);

  const handleTaskAutomation = async () => {
    const payload = {
      name: drawerInfo?.taskName,
      projectId: drawerInfo?.selectedProjectDetails?.selectedProjectId,
      emailTemplateId: drawerInfo?.emailTemplates?.selectedTemplate?.id,
      documentTemplateId: drawerInfo?.documentTemplates?.selectedTemplate?.id,
    }
    if (drawerInfo?.attachment?.name?.length && drawerInfo?.attachment?.content?.length) {
      payload.attachment = {
        name: drawerInfo?.attachment?.name,
        content: drawerInfo?.attachment?.content
      };
    }
    try {
      setLoading(true)
      const response = await createTaskAutomation(payload);
      if(response.status === 200){
        history.push(app === apps.huisinfo ? "/systeemonderhound/taakautomatisering/" + response.data.id  : app === apps.aftercare ?  "/nazorg/systeemonderhound/taakautomatisering/" + response.data.id  : '#')
      }
    } catch (error) {
      console.log(error)
      dispatch(toastActions.error(t("error.header")))
    }
    finally {
      dispatch(taskAutomationActions.toggleTaskDrawer(false))
      dispatch(taskAutomationActions?.clearDrawerInfo())
      dispatch(taskAutomationActions.fetchTaskLists(t, app))
      setLoading(false)
      setIsPreview({ open: false })
    }
  }

  return (<>
    <Drawer
      hideBackdrop
      anchor="right"
      open={drawerInfo?.toggleTaskDrawer}
      PaperProps={{
        sx: {
          width: {
            xs: "100%", md: 508
          },
          height: "100%"
        },
      }}
      onClose={() => {
        dispatch(taskAutomationActions?.toggleTaskDrawer(false))
        dispatch(taskAutomationActions?.clearDrawerInfo())
      }}>

      <MainHeader />
      <Divider />
      <Stack
        justifyContent={"space-between"}
        spacing={1}
        sx={[{
          height: "calc(100% - 58px)"
        }, isPreview?.data?.id ? {
          p: 1
        } : {
          px: 2, py: 3
        }]}>
        {isPreview?.open && <PreviewTemplate isPreview={isPreview} setIsPreview={setIsPreview} />}
        {!isPreview?.open && <GenerateTask setIsPreview={setIsPreview} />}
        {!isPreview?.open && ( <Stack direction="row" spacing={1} >
          <Button fullWidth variant="outlined" color='inherit' onClick={() => {
            dispatch(taskAutomationActions?.toggleTaskDrawer(false))
            dispatch(taskAutomationActions?.clearDrawerInfo())
            setIsPreview({ open: false })
          }} >{t("general.cancel")}</Button>
          <LoadingButton disabled={!isDisabled} fullWidth loading={loading} variant="contained" onClick={throttle(handleTaskAutomation)}>{t("general.continue")}</LoadingButton>
        </Stack>
      )}
      </Stack>
    </Drawer> </>
  )
}

export default SelectTemplatesDrawer