import { TASK_DETAILS } from '../_constants';

const initialState = {
  loadingTaskDetails: false,
  taskDetails: undefined,
  taskHistory: {
    loading: false,
    data: []
  },
  taskFailedHistory: {
    loading: false,
    data: []
  }
}

export function taskDetailsReducer(state = initialState, action) {
  switch (action.type) {

    case TASK_DETAILS.SET_LOADING_TASK_DETAILS:
      return { ...state, loadingTaskDetails: action.data }

    case TASK_DETAILS.SET_TASK_DETAILS_DATA:
      return { ...state, taskDetails: action.data, loadingTaskDetails: false }

    case TASK_DETAILS.SET_LOADING_HISTORY_DETAILS:
      return { ...state, taskHistory: { ...state.taskHistory, loading: action.data } }

    case TASK_DETAILS.SET_TASK_HISTORY_DATA:
      return { ...state, taskHistory: action.data }

      case TASK_DETAILS.SET_LOADING_TASK_ITEM_BY_STATUS:
        return { ...state, taskFailedHistory: { ...state.taskFailedHistory, loading: action.data } }
  
    case TASK_DETAILS.SET_DATA_TASK_ITEM_BY_STATUS:
      return { ...state, taskFailedHistory: { ...state.taskFailedHistory, data: action.data.resData, loading: action.data.loading } };

    case TASK_DETAILS.CLEAR:
      return initialState
    default:
      return state
  }
}