import React from 'react'
import { Box, Grid2, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Edit, ZoomIn } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RowDetails from '../../../components/RowDetails';
import { TemplateTypes } from '../../../_helpers';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../../_constants';
import { URLS } from '../../../apis/urls';

const TemplateInfo = ({ item, handleEditTemplateModal, setEditAndPreviewTemplate }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { templateDetails } = useSelector(state => state.systemMaintenance.templateDetailsStore)

  return (
    <Grid2
      sx={{
        borderRight: { xs: "none", md: `1px dashed ${grey[200]}`, display: "flex", flexDirection: "column", justifyContent: "space-between" },
      }}
      size={{
        xs: 12,
        md: 4
      }}>
      <Grid2 container spacing={0.5} sx={{
        px: 2, opacity: item?.isDisable && 0.3
      }}
      >
        <Grid2 size={{ xs: 12 }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="subtitle2">
                {item?.title}
              </Typography>
            </Stack>
            {item?.isHiddenEditIcon && <Stack direction={"row"} alignItems={"center"}>
              <IconButton onClick={() => {
                handleEditTemplateModal(item?.type)
              }
              }
                disabled={item?.isDisable}
              >
                <Edit />
              </IconButton>
            </Stack>}
          </Stack>
        </Grid2>
        {item?.details?.map((detail, index) => {
          return <Grid2 key={index} size={{ xs: 12 }}>
            <RowDetails title={detail?.title} value={!item?.isDisable ? detail.value : ""} />
          </Grid2>;
        })}

      </Grid2>
      {item?.isPreviewDisplay && (
        <Grid2 size={{ xs: 12 }} sx={{
          px: 2, py: 1.5,
        }}>
          <Stack direction="row" sx={{
            justifyContent: "space-between"
          }}>
            <Typography
              variant="subtitle2"
              sx={{
                mt: { xs: 3, md: 0 },
                mb: { xs: 1.5, md: 0 },
              }}
            >
              {t("general.template")}{" "}
            </Typography>
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  setEditAndPreviewTemplate({
                    open: true,
                    type:
                      templateDetails?.templateType == TemplateTypes.Email
                        ? SYSTEM_MAINTENANCE_CONSTANTS?.PREVIEW_EMAIL
                        : templateDetails?.templateType == TemplateTypes.Document
                          ? SYSTEM_MAINTENANCE_CONSTANTS?.PREVIEW_DOCUMENT
                          : "",
                  });
                }}
                aria-label="ZoomIn"
                disabled={!templateDetails?.template}
                size="small"
              >
                <ZoomIn htmlColor={templateDetails?.template && theme.palette.action.active} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setEditAndPreviewTemplate({
                    open: true,
                    type:
                      templateDetails?.templateType == TemplateTypes.Email
                        ? SYSTEM_MAINTENANCE_CONSTANTS?.EDIT_EMAIL
                        : templateDetails?.templateType == TemplateTypes.Document
                          ? SYSTEM_MAINTENANCE_CONSTANTS?.EDIT_DOCUMENT
                          : "",
                  });
                }}
                aria-label="Edit"
                size="small"
              >
                <Edit htmlColor={templateDetails?.template && theme.palette.action.active} />
              </IconButton>
            </Stack>
          </Stack>
          <Box
            sx={{
              borderRadius: 1,
              height: 300,
              bgcolor: grey[100],
              p: 1.5,
              overflow: "auto",
            }}
          >
            {templateDetails?.templateType == TemplateTypes.Email && templateDetails?.template && (
              <Box
                onClick={() => {
                  setEditAndPreviewTemplate({
                    open: true,
                    type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL
                  });
                }}
                sx={{ fontSize: 12, cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: `${templateDetails?.template}`,
                }}
              />
            )}

            {templateDetails?.templateType == TemplateTypes.Document && templateDetails?.template && (
              <Box
                component="img"
                src={`${URLS.GET_TEMPLATE_THUMBNAIL + templateDetails?.id}?v=${new Date().getTime()}`}
                alt={t("general.image")}
                sx={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditAndPreviewTemplate({
                    open: true,
                    type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT
                  });
                }}
              />
            )}
          </Box>
        </Grid2>
      )}
    </Grid2>
  )
}

export default TemplateInfo