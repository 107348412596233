import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Close } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar, Typography, Dialog, DialogContent, useMediaQuery, useTheme, Box } from '@mui/material'
import { useTranslation } from 'react-i18next';
import DataGridV2 from '../../../components/DataGridV2';
import { apps, LOCAL_STORAGE_GRID_KEYS } from '../../../_constants';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useSelector, useDispatch } from 'react-redux';
import { getLocalStorageItem, nl2br, setLocalStorageItem } from '../../../_helpers';
import i18n from '../../../i18n';
import { getGridContextMenuList } from '../../../_helpers/getGridContextMenuList';
import { LabelColumnV2 } from '../../../components/DataGridV2/DataColumnsV2';
import { taskDetailsActions } from '../../../_actions';

const TaskHistoryFailed = ({ handleClose, taskHistoryId }) => {
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const dispatch = useDispatch()
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" : app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION[keyPrefix], undefined
  ))
  const { taskFailedHistory } = useSelector(state => state.systemMaintenance.taskDetailsStore)
  
  useEffect(() => {
    dispatch(taskDetailsActions.fetchTaskItemsByStatus(taskHistoryId, 2,t));
  }, [dispatch]);

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER[keyPrefix],
    sortKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT[keyPrefix],
    i18n,
    t,
    apiRef,
    mode: "client"
  })

  const columns = useMemo(() => {
    return [
      {
        field: "buildingNoIntern",
        headerName: t("general.object"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.buildingNoIntern}
              cellSubMenuOptions={{ options: getOptions({ field: "buildingNoIntern", isQuickFilterDisplay: Boolean(rowData.buildingNoIntern) }), extraData: { fieldName: "buildingNoIntern" } }}
              request={rowData}
            />
          );
        },
      },
      {
        field: "title",
        headerName: t("general.reason"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.title}
              cellSubMenuOptions={{ options: getOptions({ field: "title", isQuickFilterDisplay: Boolean(rowData.title) }), extraData: { fieldName: "title" } }}
              request={rowData}
              tooltipLabel={rowData.title}
            />
          );
        },
      },
      {
        field: "description",
        headerName: t("general.description"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.description}
              cellSubMenuOptions={{ options: getOptions({ field: "description", isQuickFilterDisplay: Boolean(rowData.description) }), extraData: { fieldName: "description" } }}
              request={rowData}
              tooltipLabel={nl2br(rowData.description)}
            />
          );
        },
      }
    ];

  }, [getOptions, t])

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION[keyPrefix],
      newPaginationModel
    );
  }, [keyPrefix])

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "none",
          width: 1200,
          height: { md: 650 }
        }
      }}
      fullScreen={isMobileOrTab}
    >
      <AppBar position='relative' sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2 }} variant="h6" component="div">
            {t('taskAutomation.history.failed')}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent sx={{ mt: 0.3, p: 2 }}>
        <Box >
          <DataGridV2
            localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_SEARCH_TEXT[keyPrefix]}
            localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER[keyPrefix]}
            localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT[keyPrefix]}
            localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_ORDER[keyPrefix]}
            localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_WIDTH[keyPrefix]}
            localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_VISIBILITY[keyPrefix]}
            apiRef={apiRef}
            loading={taskFailedHistory?.loading}
            checkboxSelection
            rows={taskFailedHistory?.data}
            columns={columns}
            pagination
            {...(!!paginationModel && { paginationModel })}
            onPaginationModelChange={onPaginationModelChange}
            toolbarProps={{
              title: t('taskAutomation.history.failed'),
              selectedRows: rowSelectionModel
            }}
            filterMode="client"
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TaskHistoryFailed;
