import {ExpandLess, ExpandMore} from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import React, {useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subMenuActions } from '../_actions'
import { Link } from "react-router-dom";
import { history } from '../_helpers';

const SubMenu = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const dispatch = useDispatch()
  const subMenu = useSelector(state => state.subMenu);
  const { menuItems } = subMenu;
  const openPopUp = Boolean(subMenu.open);
  const id = openPopUp ? 'simple-popover' : undefined;
  const handleClick = (itemId) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  const handleSubMenuClose = () => {
    dispatch(subMenuActions.clearSubMenu()); 
  };
  return (
    <Popover
      id={id}
      open={subMenu.open}
      onClose={handleSubMenuClose}
      anchorEl={subMenu.anchorEl}
      anchorOrigin={{
        vertical:  { xs: 'top', sm: 'bottom' } , 
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: { xs: 'bottom', sm: 'top' },
        horizontal: 'left',
      }}
    >
      {menuItems?.map((value) => (
        <List component='nav' disablePadding key={value.id} sx={{ width: 256}}>
          <ListItemButton  component={value.to ? Link : 'div' } to={value.to} selected={history.location.pathname === value.to}  onClick={() => handleClick(value.id)}> 
            <ListItemIcon>{value.icon}</ListItemIcon>
            <ListItemText onClick={value?.onClick} primary={value.itemText} />
            {value.subItems ? (expandedItem === value.id ? <ExpandLess /> : <ExpandMore />) : null}
          </ListItemButton>

          {value.subItems && (
            <Collapse in={expandedItem === value.id} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {value.subItems.map((subItem, index) => {
                  return (
                    <ListItemButton
                      key={index}
                      component={subItem.to ? Link : 'div' }
                      to={subItem.to}
                      selected={history.location.pathname.startsWith(subItem.to)}
                      onClick={subItem?.onClick}
                    >
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.itemText} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          )}
        </List>
      ))}
    </Popover>
  );
};

export default SubMenu;
