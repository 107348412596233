import React from 'react'
import { CheckCircle, Visibility } from '@mui/icons-material'
import { Box, Checkbox, CircularProgress, FormControlLabel, IconButton, Typography, Stack, FormGroup, Card } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useTheme } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { taskAutomationActions } from '../../../../_actions'
import { useTranslation } from 'react-i18next'

const EmailTemplate = ({  setIsPreview }) => {
    const { t } = useTranslation()
    const {systemMaintenance:{taskStore :{drawerInfo } }} = useSelector(state => state)
    const emailTemplates = drawerInfo?.emailTemplates

    const dispatch = useDispatch()
    const theme = useTheme();

    const Loader = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1
            }}>
            <CircularProgress size={30} />
        </Box>
    );

    if (emailTemplates?.loading) {
        return <Loader />;
    }

    return (
        <> <FormGroup>
                <FormControlLabel sx={{ mb: 0 }} control={<Checkbox checked />} label={<Typography fontWeight={500} variant='body2'>{t('taskAutomation.selectEmailTemplate')}</Typography>} />
            </FormGroup>

                <Stack
                    direction='row'
                    sx={{
                        columnGap: 2,
                        overflow: "auto"
                    }}>
                {emailTemplates?.templates?.length <= 0 && <Stack width={'100%'} alignItems={'center'}>
                    <Typography variant="body1" align="center" >
                        {t('taskAutomation.noEmailTemplate')}
                    </Typography>
                </Stack>}
                    {
                       emailTemplates?.templates?.map((template, index) => {
                            const currentSelectedTemplate = emailTemplates.selectedTemplate?.id === template?.id
                            const selectedTemplateBg = currentSelectedTemplate ? { opacity: 1, background: "rgba(0, 0, 0, 0.25)" } : { opacity: 0 }
                            return (
                                <Box key={index}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            width: 123,
                                            height: 174,
                                            borderRadius: 0.5,
                                            ':hover': { cursor: 'pointer', opacity: 1 }
                                        }}>
                                        <Card sx={{ width: "100%", height: "100%" }}>
                                            <Stack direction={"column"} justifyContent={"start"} px={1} py={2}  >
                                                <Typography
                                                    variant="body2"
                                                    textOverflow={"ellipsis"}
                                                    overflow={"hidden"}
                                                    mb={1}
                                                    >
                                                    {template.desc}
                                                </Typography>
                                                <Typography variant='caption' color='textSecondary'  sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 2,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}>{template.subject}</Typography>
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: 123,
                                                        height: 174,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 0.5,
                                                        ...selectedTemplateBg,
                                                        backdropFilter: 'blur(2px)',
                                                        ':hover': { opacity: 1, background: "rgba(0, 0, 0, 0.25)" }
                                                    }}>
                                                    <Stack
                                                        sx={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "6rem"
                                                            }}>{template.name}</Typography>
                                                        <Stack direction='row' sx={{
                                                            columnGap: 1
                                                        }}>
                                                            <IconButton onClick={() => {
                                                                dispatch(taskAutomationActions.selectEmailTemplates( emailTemplates.selectedTemplate && currentSelectedTemplate ? undefined : template ))
                                                            }
                                                            } sx={{ p: 0 }}>
                                                                <CheckCircle sx={[currentSelectedTemplate ? {
                                                                    color: blue[700]
                                                                } : {
                                                                    color: theme.palette.common.white
                                                                }]} />
                                                            </IconButton>
                                                            <IconButton sx={{ p: 0 }} onClick={() => {
                                                                setIsPreview({ open: true, data: template, type: "EMAIL" })
                                                            }}>
                                                                <Visibility sx={{ color: theme.palette.common.white }} />
                                                            </IconButton>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Card>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                </Stack>
            </>
    )
}

export default EmailTemplate
