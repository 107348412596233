import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Divider} from '@mui/material'
import { useHistory } from "react-router-dom";
import TabNavigation from './TabNavigation';
import Breadcrumbs from './Breadcrumbs';
import { tabNavigationAction } from '../_actions';
import EllipsisTypography from './EllipsisTypography';

const MainHeader = () => {
  const { items, activeBreadCrumb } = useSelector(state => state.breadcrumbNavigation)
  const { activeTab, tabs } = useSelector(state => state.tabNavigation)
  const history = useHistory();
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    dispatch(tabNavigationAction.setNavigationTab(tabs, 1))
    history.push(tabs[newValue]?.to)
  };

  return (
    <Stack>
      <Stack mx={2} my={0.6} spacing={0.5}>
        <Breadcrumbs />
        <EllipsisTypography variant={"h6"}  title={items?.[activeBreadCrumb]?.title} />
      </Stack>
      <Divider sx={{ opacity: "0.6" }} />
      <TabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleChange} />
    </Stack>
  )
}

export default MainHeader
