import axios from "axios";
import { URLS } from "./urls";

export const getEmailTemplates = (projectId) => axios.get(URLS.GET_EMAIL_TEMPLATES + projectId);
export const getDocumentTemplates = (projectId) => axios.get(URLS.GET_DOCUMENT_TEMPLATES + projectId);
export const createTaskAutomation = data => axios.post(URLS.CREATE_TASK_AUTOMATION, data);
export const getTaskDetails = (taskId) => axios.get(URLS.GET_TASK_DETAILS + taskId);

export const getTaskList= (app) => axios.get(`${URLS.GET_TASKS_LIST}?app=${app}`);
export const getTaskHistory = (taskId) => axios.get(URLS.GET_TASK_HISTORY + taskId);
export const getTaskItemsByStatus = (taskId, status) =>axios.get(`${URLS.GET_TASK_ITEMS_BY_STATUS}${taskId}?status=${status}`);
export const updateTaskAutomation = (taskId,data) => axios.post(URLS.UPDATE_TASK_AUTOMATION_DETAILS + taskId, data);

// Template Creation
export const getTemplates = (data) => axios.post(`${URLS.GET_TEMPLATES}`, data);
export const getTemplateDetails = (templateId) => axios.get(URLS.GET_TEMPLATE_DETAILS + templateId);
export const createTemplate = data => axios.post(URLS.CREATE_TEMPLATE, data);
export const updateTemplate = (templateId,data) => axios.post(URLS.UPDATE_TEMPLATE + templateId, data);