import React from 'react'
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid2, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import RowDetails from './RowDetails';
import { useTranslation } from 'react-i18next';
import { dateFormatter} from '../_helpers';

const TechInfo = ({ title, subtitle, data, extraInfo }) => {
    const { t } = useTranslation();
    
    const details = [
        {
            title: t("general.insertedOn"),
            value: dateFormatter(data?.createdOn)
        },
        {
            title: t('general.insertedBy'),
            value: data?.createdBy
        },
        {
            title: t('general.modifiedOn'),
            value: dateFormatter(data?.modifiedOn)
        },
        {
            title: t('general.modifiedBy'),
            value: data?.modifiedBy
        },
        {
            title: t('general.primaryKey'),
            value: data?.id
        },
        ...(extraInfo || [])
    ]
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">{title}</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Grid2 container>
                    <Grid2
                        sx={{
                            borderRight: { xs: 'none', md: `1px dashed ${grey[200]}` }
                        }}
                        size={{
                            xs: 12,
                            md: 4
                        }}>
                        <Grid2 container spacing={0.5}>
                            {subtitle && <Grid2 size={{ xs: 12 }}>
                                <Typography variant='subtitle2'>{subtitle}</Typography>
                            </Grid2>}
                            {details?.map((detail, index) => {
                                return <Grid2 key={index} size={{ xs: 12 }}><RowDetails title={detail?.title} value={detail?.value} /></Grid2>;
                            })}
                        </Grid2>
                    </Grid2>
                </Grid2>
            </AccordionDetails>
        </Accordion>
    )
}

export default TechInfo