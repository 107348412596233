import {
    Avatar,
    Checkbox,
    Hidden,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { URLS } from "../../apis/urls";
import { apps } from "../../_constants";
import clsx from "clsx";
import { formatDate, history } from "../../_helpers";
import { LocationOnOutlined, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../components/CustomRowStyles";
import { useSelector } from "react-redux";


const AfterCareWorkOrderRow = (props) => {
    const {handleCheckboxChange, selectedCheckBox=[], listToMapRedirectHandler}=props
    const {firstFileId,buildingNoIntern,is48HoursReminder,buildingNoExtern,completed,overdue,description,id,name,resolverStatus,addressText,drawingPinLocation,targetCompletionDate,addressPlace,explanation}=props?.row
    const app = useSelector(state => state.app);
    const { selected } = useSelector(state => state.buildings);
    const theme = useTheme()
    const classes = { ...useRowStyles({ app }), ...useStyles() };
    const { t } = useTranslation();
    
    const attachmentSrc =
       firstFileId
          ? URLS.GET_ATTACHMENT_THUMBNAIL + firstFileId
          : null;
    
    return (
        <React.Fragment >
                <TableRow onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push({
                        pathname: (app === apps.aftercare ? "/nazorg/werkbon/" : `/werk/${selected?.projectNo}/werkbon/`)+id,
                        state: { fromWorkOrderGrids: true }
                    });
                }} className={clsx(classes.customGridRow,classes.row)}>
                    <TableCell>
                        <div className={classes.customSmallDeviceRowContainer}>
                            <div className={classes.verticallyCenterContainer}>
                                <div className={classes.relativeContainer}>
                                    <Avatar
                                        sx={{
                                             [theme.breakpoints.only("xs")]: {
                                                  width: (app === apps.resolverModule ? '75px !important' : 'inherit'),
                                                  height:  (app === apps.resolverModule ? '75px !important' : 'inherit'),
                                                },
                                        }}
                                        src={attachmentSrc}
                                        className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')}>
                                        {buildingNoExtern}
                                    </Avatar>
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={clsx(classes.requestTitle, classes.grow)}>
                                            {description}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography noWrap className={classes.flexContainer}>
                                            {overdue ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : is48HoursReminder ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" className={clsx(classes.warning, classes.marginRight_5)} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : ("")}
                                        <Checkbox
                                            size="small"
                                            className={clsx(
                                                classes.customCheckbox,
                                                classes.noPaddingContainer
                                            )}
                                            checked={selectedCheckBox?.includes(id)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() => {
                                                handleCheckboxChange(id);
                                            }}
                                        />
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)} >
                                    <div>
                                        <Typography className={classes.requestTypeLabel}>
                                            { app === apps.resolverModule ? explanation : name }
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            className={classes.requestStatusLabel}
                                            style={{ color:completed ? "#2DA800" :overdue ? "#f00" : "#5C5C5C" }}>
                                            {t(`resolver.status.${resolverStatus}`)}
                                        </Typography>
                                    </div>
                                </div>
                            <Hidden smUp>
                            {app !== apps.constructionQuality && (
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography   className={clsx(classes.requestBuildingLabel,classes.overflowEllipse)}>
                                            <span>{addressText} {addressPlace}</span>
                                        </Typography>
                                    </div>
                                </div>
                             )}
                            </Hidden>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}  >
                                    <div className={classes.requestContainer}>
                                        <div>
                                            <Typography className={classes.requestBuildingLabel}>
                                                <IconButton
                                                    color="primary"
                                                    disabled={!drawingPinLocation}
                                                    className={clsx(classes.locationIconContainer, classes.locationButton, !drawingPinLocation && classes.disabledLocationBtn)}
                                                    aria-label="Repair request map redirect"
                                                    component="span"
                                                     onClick={(e) => {
                                                        e.stopPropagation();
                                                        listToMapRedirectHandler(e, props.row);
                                                    }}
                                                    size="large">
                                                    <LocationOnOutlined className={clsx(classes.locationIcon, classes.locationButtonText)} />
                                                </IconButton>
                                                <span>
                                                    {buildingNoIntern}
                                                </span>
                                            <Hidden smDown>
                                            {app !== apps.constructionQuality && (
                                                <>
                                             <div style={{ marginLeft: 10 }} className={classes.werkbonnenContainer}>
                                                    <span>{addressText}</span>
                                                </div>
                                                <div style={{ marginLeft: 10 }} className={classes.werkbonnenContainer}>
                                                    <span>{addressPlace}</span>
                                                </div>
                                                </>
                                            )}
                                            </Hidden>
                                                
                                            </Typography>
                                        </div>
                                       {app === apps.constructionQuality && 
                                       <div className={classes.labelSpacing}>
                                       <Typography  className={classes.requestTypeLabel}>
                                           {name}
                                       </Typography>
                                   </div>
                                       }  
                                    </div>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel} >
                                            {formatDate(new Date(targetCompletionDate))}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    );
};

export default AfterCareWorkOrderRow;

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    descriptionContainer: {
        flexDirection: "row"
    },
    offlineIconContainer: {
        position: "absolute",
        right: 0,
        top: 0
    },
    offlineIcon: {
        fontSize: 14,
        fill: theme.palette.common.white
    },
    avatarThumbnail: {
        background: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 'bold',
        borderRadius: 6,
        boxShadow: "0px 0px 3px #eee"
    },
    imageBadgeContainer1: {
        bottom: 0,
        right: 0,
        borderRadius: 0,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 6,
        fontSize: 10,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    overflowEllipse: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        whiteSpace: "normal",
      },
    locationButton: {
        padding: 0,
        height: 18,
        width: 18,
    },
    locationButtonText: {
        fontSize: 14,
    },
    disabledLocationBtn: {
        background: `${theme.palette.grey[400]} !important`
    },
    requestContainer: {
        display: 'flex'
    },
    labelSpacing: {
        marginLeft: 5
    },
    row: {
        "& td": {
          padding: "2px",
          width: "100%",
        },
        display: "flex",
      },
}));
