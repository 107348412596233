import { TOAST } from '../_constants';

export const toastActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: TOAST.SUCCESS, message };
}

function error(message) {
    return { type: TOAST.ERROR, message };
}

function clear() {
    return { type: TOAST.CLEAR };
}