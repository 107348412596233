import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Container, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridV2 from "../../../components/DataGridV2";
import { apps, LOCAL_STORAGE_GRID_KEYS, } from "../../../_constants";
import { formatDate, getLocalStorageItem, setLocalStorageItem } from "../../../_helpers";
import { HoverImage, LabelColumnV2, LinkColumnV2 } from "../../../components/DataGridV2/DataColumnsV2";
import { Description } from "@mui/icons-material";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";
import { getAttachments, getCommunicationDetails } from "../../../apis/communicationApi";
import { getAttachmentGridNavigationItem } from "../util";
import { breadcrumbAction, tabNavigationAction } from "../../../_actions";

const AttachmentGrid = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const location = useLocation();
  const { communicationId } = useParams();
  const theme = useTheme()
  const app = useSelector((state) => state.app);
  const { all = [], loading: buildingLoading } = useSelector(
    (state) => state.buildings
  );
  const apiRef = useGridApiRef();
  const [rowsData, setRowsData] = useState([]);
  const [isRowsDataLoading, setIsRowsDataLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" :  app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_PAGINATION[keyPrefix], undefined
  ));
  const [communicationDetails, setCommunicationDetails] = useState({})

  useEffect(() => {
    (async () => {
      setIsRowsDataLoading(true);
      try {
        const response = await getCommunicationDetails(communicationId);
        if (response.status === 200) {
          setCommunicationDetails(response.data)
        }
        setIsRowsDataLoading(false)
      } catch (err) {
        console.log(err)
        setIsRowsDataLoading(false);
      }
    })();
  }, [communicationId]);

  useEffect(() => {
    if (!isRowsDataLoading) {
      const attachmentGridNavigation = getAttachmentGridNavigationItem({ t, communicationId, communicationDetails })
      dispatch(tabNavigationAction.setNavigationTab(attachmentGridNavigation.tabs, attachmentGridNavigation.tabs.findIndex((tab) => tab.to === location.pathname)))
      dispatch(breadcrumbAction.setBreadcrumbItem(attachmentGridNavigation.breadCrumbs))
    }
  }, [communicationDetails, communicationId, dispatch, isRowsDataLoading, location.pathname, t])

  useEffect(() => {
    setIsRowsDataLoading(true)
    if (!buildingLoading) {
      (async () => {
        try {
          const res = (await getAttachments(communicationId));
          const resData = res?.data
          const attachmentRowsData = resData.map(({ header, description, id, date, attachmentName }) => ({
            id,
            header,
            description,
            date,
            attachmentName,
            searchField: formatDate(new Date(date))
          }));
          setRowsData(attachmentRowsData)
          setIsRowsDataLoading(false)
        } catch (err) {
          console.error(err);
          setIsRowsDataLoading(false)
        }
      })();
    }
    return () => {
      setRowsData([]);
    };
  }, [all, app, buildingLoading]);

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_PAGINATION[keyPrefix],
      newPaginationModel
    );
  }, [keyPrefix])

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_FILTER[keyPrefix],
    sortKey: LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_SORT[keyPrefix],
    i18n,
    t,
    apiRef,
    getValueForCopy: (rowData, extraData) => {
      let text = ""
      switch (extraData.fieldName) {
        case "date":
          text = formatDate(new Date(rowData.date))
          break;
        default:
          text = rowData[extraData.fieldName]
          break;
      }
      return text
    },
    mode: "client"
  })

  const columns = useMemo(() => {
    return [ 
      {
        field: "header",
        download: true,
        headerName: t('general.header'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LinkColumnV2 to={app === apps.huisinfo ? `/communicatie/${communicationId}/bijlage/` + params?.row?.id : `/nazorg/communicatie/${communicationId}/bijlage/` + params?.row?.id} label={rowData.header}
              cellSubMenuOptions={{ options: getOptions({ field: "header", isQuickFilterDisplay: Boolean(rowData.header) }), extraData: { fieldName: "header", } }}
              request={rowData}
            />
          )
        },
      },
      {
        download: false,
        print: true,
        field: "imageId",
        headerName: t("general.image"),
        editable: false,
        disableColumnMenu: true,
        flex: 1,
        align: "center",
        filterable: false,
        minWidth: 45,
        maxWidth: 60,
        sortable: false,
        renderCell: (params) => {
          const imageId = params?.row?.id;
          return (
            <HoverImage defaultSize={20} imageId={imageId} index={params.id} />
          );
        },
        renderHeader: () => {
          return (
            (<Box component="div" sx={{
              textAlign: "center"
            }}>
              <Description htmlColor={theme.palette.action.active} />
            </Box>)
          );
        },
      },
      {
        field: "description",
        download: true,
        headerName: t('general.description'),
        flex: 1,
        minWidth: 120,
        getApplyQuickFilterFn: (searchVal) => {
          return (value, row) => {
            return value?.toLowerCase().includes(searchVal?.toLowerCase()) || row?.searchField?.toLowerCase().includes(searchVal?.toLowerCase());
          };
        },
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.description}
              cellSubMenuOptions={{ options: getOptions({ field: "description", isQuickFilterDisplay: Boolean(rowData.description) }), extraData: { fieldName: "description" } }}
              request={rowData}
            />
          )
        },
      },
      {
        field: "date",
        type: "date",
        headerName: t("general.date"),
        flex: 1,
        minWidth: 90,
        align: "start",
        valueGetter: (params) => {
          const request = params.row;
          if (!request) return "";
          return new Date(request.date);
        },
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return <LabelColumnV2 date={rowData.date} cellSubMenuOptions={{ options: getOptions({ field: "date", isQuickFilterDisplay: Boolean(rowData.date) }), extraData: { fieldName: "date", type: 'date' } }}
            request={rowData}
          />;
        },
      },

      {
        field: "attachmentName",
        download: true,
        headerName: t('general.attachments'),
        flex: 1,
        minWidth: 130,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.attachmentName}
              cellSubMenuOptions={{ options: getOptions({ field: "attachmentName", isQuickFilterDisplay: Boolean(rowData.attachmentName) }), extraData: { fieldName: "attachmentName", } }}
              request={rowData}
            />
          )
        },
      }
    ];
  }, [t, getOptions]);

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_SEARCH_TEXT[keyPrefix]}
        localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_FILTER[keyPrefix]}
        localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_SORT[keyPrefix]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_ORDER[keyPrefix]}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_WIDTH[keyPrefix]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.ATTACHMENT_COMMUNICATION_COLUMN_VISIBILITY[keyPrefix]}
        apiRef={apiRef}
        loading={buildingLoading || isRowsDataLoading}
        checkboxSelection
        rows={rowsData}
        columns={columns}
        pagination
        {...(!!paginationModel && { paginationModel })}
        onPaginationModelChange={onPaginationModelChange}
        toolbarProps={{
          title: t("general.attachments"),
          selectedRows: rowSelectionModel
        }}
        filterMode="client"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
      />
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));

export default AttachmentGrid;