import { TAB_NAVIGATION } from "../_constants";

const clearTabNavigationStore = (data) => {
    return {
        type: TAB_NAVIGATION.CLEAR,
        data
    };
}

const setNavigationTab = (tabs, activeTab) => {
    return {
        type: TAB_NAVIGATION.SET_TABS,
        data: { tabs, activeTab }
    }
}



export const tabNavigationAction = {
    clearTabNavigationStore,
    setNavigationTab
}