import {
    getAvailableSurveyModules,
    getSurveys
} from '../apis/surveyingApis';
import {
    apps,
    commonConstants,
    userAccountTypeConstants
} from '../_constants';
import {
    commonService
} from '../_services';
import {
    getAvailableModulesInDB,
    groupBy,
    storeAvailableModulesInDB,
    SurveyType
} from '../_helpers';
import axios from 'axios';
import {
    getWorkOrders
} from '../apis/aftercareApis';

export const commonActions = {
    getBuildings,
    selectApp,
    selectBuilding,
    getDashboardCount,
    updateRights,
    meldingenTableFilter,
    availableSurveyModules,
    getWorkOrderCounts,
    getCurrentVersionDate
};

var getAvailableSurveyModulesController = null;


function getBuildings(app) {
    return dispatch => {
        dispatch(request());
        commonService.getBuildings(app)
            .then(
                buildings => dispatch(success(buildings)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return {
            type: commonConstants.GETALL_REQUEST
        }
    }

    function success(buildings) {
        return {
            type: commonConstants.GETALL_SUCCESS,
            buildings
        }
    }

    function failure(error) {
        return {
            type: commonConstants.GETALL_FAILURE,
            error
        }
    }
}

function selectApp(app) {
    return {
        type: commonConstants.CHANGE_APP_SUCCESS,
        app: app
    };
}

function getCurrentVersionDate(date = new Date()) {
    return {
        type: commonConstants.GET_CURRENT_VERSION_DATE,
        currentVersionDate: date
    };
}

function availableSurveyModules(projectId) {
    const isOnline = window.navigator.onLine;
    return async (dispatch, getState) => {
        const {
            app,
            buildings: {
                selected
            },
            authentication: {
                user
            }
        } = getState();
        dispatch(loading());
        if (isOnline) {
            if (getAvailableSurveyModulesController)
                getAvailableSurveyModulesController.cancel('canceled');
            getAvailableSurveyModulesController = axios.CancelToken.source();
            if (app !== apps.aftercare) {
                getAvailableSurveyModules(projectId, (user && user.type === userAccountTypeConstants.buyer) && selected.buildingId, getAvailableSurveyModulesController).then(async res => {
                    if (app !== null && app >= 0) {
                        await storeAvailableModulesInDB({
                            projectId: projectId ? projectId : '',
                            app,
                            modules: res.data
                        })
                    }
                    dispatch(customDispatcher(res.data));
                }).catch(er => {
                    dispatch(customDispatcher([]));
                });
            } else {
                const surveysCounts = [];
                for (let i = 0; i < 4; i++) {
                    if (i === SurveyType.Inspection) continue;
                    const isSecondSignature = i === SurveyType.SecondSignature;
                    const {
                        data
                    } = await getSurveys({
                        surveyType: isSecondSignature ? SurveyType.Delivery : i,
                        isSecondSignature,
                        index: 0,
                        rowsPerPage: 1
                    });
                    if (data.totalResults)
                        surveysCounts.push({
                            moduleType: i,
                            canCreate: false
                        });
                }
                await storeAvailableModulesInDB({
                    projectId: projectId ? projectId : '',
                    app,
                    modules: surveysCounts
                })
                dispatch(customDispatcher(surveysCounts));
            }
        } else {
            if (app !== null)
                if (app !== apps.aftercare) {
                    getAvailableModulesInDB({
                        projectId: projectId || "",
                        app
                    }).then(async res => {
                        dispatch(customDispatcher(res.modules));
                    }).catch(er => {
                        dispatch(customDispatcher([]));
                    });
                } else {
                    const surveysCounts = [];
                    for (let i = 0; i < 4; i++) {
                        if (i === SurveyType.Inspection) continue;
                        const isSecondSignature = i === SurveyType.SecondSignature;
                        const data = await getSurveys({
                            surveyType: isSecondSignature ? SurveyType.Delivery : i,
                            isSecondSignature: isSecondSignature
                        });
                        if (data && data.length)
                            surveysCounts.push({
                                moduleType: i,
                                canCreate: false
                            });
                    }
                    dispatch(customDispatcher(surveysCounts));
                }
        }
    }

    function customDispatcher(modules) {
        return {
            type: commonConstants.GET_AVAILABLE_SURVEY_MODULES,
            modules,
        };
    }

    function loading(modules) {
        return {
            type: commonConstants.GET_AVAILABLE_SURVEY_MODULES_LOADING,
        };
    }

}

function meldingenTableFilter(list) {
    return {
        type: commonConstants.CHANGE_MELDINGEN_FILTER_SUCCESS,
        list
    };
}

function selectBuilding(building) {
    return {
        type: commonConstants.CHANGE_SUCCESS,
        building: building
    };
}

function updateRights(buildingId = null) {
    return {
        type: commonConstants.UPDATE_RIGHTS_SUCCESS,
        buildingId
    };
}

function getDashboardCount(app, building) {
    return (dispatch, getState) => {
        const { authentication: { user }, buildings: { all } = {} } = getState();
        const isBuyer = user && user.type === userAccountTypeConstants.buyer;
        const projectIds = isBuyer ? [] : Object.keys(groupBy(all, "projectId") || {});
        dispatch(loading())
        commonService.getDashboardCount(app, isBuyer && building.buildingId, projectIds)
            .then(
                dashboardCount => {
                    if (dashboardCount) {
                        dispatch(success(dashboardCount))
                    } else {
                        dispatch(failure('Undefined'))
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function loading() {
        return {
            type: commonConstants.GETCOUNT_LOADING
        }
    }

    function success(dashboardCount) {
        return {
            type: commonConstants.GETCOUNT_SUCCESS,
            dashboardCount
        }
    }

    function failure(error) {
        return {
            type: commonConstants.GETCOUNT_ERROR,
            error
        }
    }
}

function getWorkOrderCounts(projectIds, app) {
    const projectIdsArray = Array.isArray(projectIds)  ? projectIds   : projectIds   ? [projectIds]  : [];            
    return dispatch => {
        dispatch({
            type: commonConstants.GET_WORK_ORDERS_COUNTS_LOADING
        })
        getWorkOrders({
            object:{app},
            rowsPerPage: 1,
            conditions: [
                ...(projectIdsArray.length > 0
                    ? [{
                        field: 'projectId',
                        operator: 'isAnyOf',
                        value:projectIdsArray
                    }]
                    : []), 
            ],
        }).then(({
            data
        }) => {
            dispatch({
                type: commonConstants.GET_WORK_ORDERS_COUNTS_SUCCESS,
                workOrderCounts: data.totalResults
            })
        }).catch(er => {
            dispatch({
                type: commonConstants.GET_WORK_ORDERS_COUNTS_ERROR
            })
        })
    };
}