import { Clear, CloudUpload } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_AUTOMATION } from '../../../../_constants';
import { taskAutomationActions, toastActions } from '../../../../_actions';
import { toBase64 } from '../../../../_helpers';


const AttachDocument = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { systemMaintenance: { taskStore: { drawerInfo } } } = useSelector(state => state)
    const fileName = drawerInfo?.attachment?.name
    const maxFileSizeInMB = 8;

    const isLabelSelected = drawerInfo?.attachment?.isAttachmentLabelSelected

    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length === 1) {
            let file = selectedFiles[0];
            if (file.size > maxFileSizeInMB * 1024 * 1024) {
                dispatch(taskAutomationActions.setAttachmentFileName(""))
                // TODO: need to cechl
                event.target.value = '';
                dispatch(toastActions.error( t("general.filesLargerThanMBNotAllowed", { maxFileSizeInMB: maxFileSizeInMB })))
                return;
            }
            const url = await toBase64(file)
            dispatch(taskAutomationActions.setAttachmentFileName(file.name))
            dispatch(taskAutomationActions.setAttachmentFileContent(url?.split(",")?.[1]))
        }
    }

    return (
        <Stack spacing={1}>
            <FormGroup>
                <FormControlLabel
                    sx={{ mb: 0 }}
                    control={
                        <Checkbox
                            name="isAttachChecked"
                            checked={isLabelSelected}
                            onChange={(event) => dispatch({ type: TASK_AUTOMATION.SET_ATTACHMENT_LABEL_SELECT, data: event.target.checked })}
                        />
                    }
                    label={<Typography fontWeight={500} variant="body2">{t("taskAutomation.attachDocument")}</Typography>}
                />
            </FormGroup>

            {isLabelSelected && (
                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                        key={fileName}
                        sx={{
                            minWidth: 140,
                          }} 
                    >
                        {t('general.attachments')}
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </Button>
                    <Typography
                        maxWidth={"50%"}
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer"
                        }}
                        variant='body2'
                        color={"info"}
                    >
                        {fileName}
                    </Typography>
                    {fileName?.length ? <IconButton onClick={() => {
                        dispatch(taskAutomationActions.setAttachmentFileName(""))
                        dispatch(taskAutomationActions.setAttachmentFileContent(""))
                    }} ><Clear color='error' /></IconButton> : ""}
                </Stack>
            )}
        </Stack>
    );
}

export default AttachDocument;
