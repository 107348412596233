import { useCallback, useMemo, useRef, useState } from "react";
import { Alert, Menu, MenuItem, ListItemIcon, ListItemText, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ColumnsSelectorV2 from "./ColumnsSelectorV2";
import ExportModal from "./ExportModal";
import { AssignmentOutlined, FilterAlt, Person, ViewListSharp, ViewModuleSharp } from "@mui/icons-material";
import { debounce, getLocalStorageItem, setLocalStorageItem } from "../../_helpers";
import MobileGridHeader from "./MobileGridHeader";
import DesktopGridHeader from "./DesktopGridHeader";

const DataGridToolbar = ({
  apiRef,
  columns,
  filterMode = "server",
  totalRecords,
  getData,
  taskMenuListItems = [],
  toolbarStartActions= [],
  toolbarEndActions = [],
  additionalRightSideButtons=[],
  handleSearch,
  searchText: initialSearch,
  quickFilterItems = [],
  selectedRows = [],
  currentView,
  columnsForExport,
  hideExportBtn,
  rows,
  title,
  localSearchIdentifier,
  handleToggleView,
  isListViewAvailable,
  ...rest
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  const { t } = useTranslation();
  const searchInputRef = useRef(null);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "error"
  })
  const [openColumnsSelector, setOpenColumnsSelector] = useState(false);
  const [columnSelectorType, setColumnSelectorType] = useState(null);
  const [openExportModal, setOpenExportModal] = useState(false);
  const muiTableRef = useRef();
  const [taskAnchorEl, setTaskAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState(() => getLocalStorageItem(
    localSearchIdentifier,
    ""));
  const isTaskMenuOpen = Boolean(taskAnchorEl);
  
  const handleExportModal = useCallback(() => {
    setOpenExportModal(prev => !prev);
  }, []);

  const handleTaskMenuOpen = useCallback((event) => {
    setTaskAnchorEl(event.currentTarget);
  }, []);

  const handleTaskMenuClose = useCallback(() => {
    setTaskAnchorEl(null);
  }, []);

  const handleOpenFilterMenu = useCallback((event) => {
    setFilterAnchorEl(event.currentTarget);
  }, []);
  const handleColumnsForExport = useCallback((isAllDisplay = false) => {
    return columnsForExport ? columnsForExport(isAllDisplay) : columns;
  }, [columns, columnsForExport]);

  const additionalActions = useMemo(() => {
    return taskMenuListItems.length > 0
      ? [{
          title: t("general.task"),
          icon: AssignmentOutlined,
          onClick: handleTaskMenuOpen
        }]
      : [];
  }, [taskMenuListItems, handleTaskMenuOpen, t]);

  const toggleListGridBtn = useMemo(()=>{
    return isListViewAvailable && isTabletOrMobile 
    ? [{
      title: '',
      icon: currentView === "list" ? ViewModuleSharp : ViewListSharp ,
      onClick: handleToggleView,
      iconColor:'default'
    }]
  : [];
  },[isListViewAvailable, currentView, handleToggleView, isTabletOrMobile])
  const quickFilterItemBtn = useMemo(()=>{
    const selectedFiltersCount = quickFilterItems.filter(item => item.isSelected).length;
    return quickFilterItems.length > 0  && isTabletOrMobile 
    ? [{
      title: '',
      icon: FilterAlt ,
      badgeCount: selectedFiltersCount,
      onClick: handleOpenFilterMenu,
      iconColor:'default'
    }]
  : [];
  },[handleOpenFilterMenu, isTabletOrMobile, quickFilterItems])
  const updatedToolbarEndActions = useMemo(() => {
    return [
      ...additionalActions,
      ...toolbarEndActions,
    ];
  }, [additionalActions, toolbarEndActions])

  const rightGroupedButtons = useMemo (()=>{
    return [
      ...additionalRightSideButtons,
      ...quickFilterItemBtn,
      ...toggleListGridBtn,
    ]
  },[additionalRightSideButtons, quickFilterItemBtn, toggleListGridBtn])
const closeSnackbar = useCallback(() => {
  setToast({ open: false, message: "", type: "error" });
}, []);

const defaultGetData = () => selectedRows.length > 0 ? rows.filter(row => selectedRows.includes(row.id)) : rows

const debouncedSearch = useMemo(() => {
  return debounce((value) => {
    setSearchText(value);
    setLocalStorageItem(localSearchIdentifier, value);
    apiRef?.current?.setQuickFilterValues(value ? [value] : []);
  }, 1000);
}, [apiRef, localSearchIdentifier]);

const handleSearchChange = useCallback((value) => {
  if (handleSearch) {
    handleSearch(value);
  } else if(filterMode === "client" && localSearchIdentifier) {
    debouncedSearch(value); 
  }
}, [handleSearch, filterMode, localSearchIdentifier, debouncedSearch]);

  return (
    <>
      {isTabletOrMobile ? (
        <MobileGridHeader
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          handleSearch={handleSearchChange}
          searchText={searchText}
          setSearchText={setSearchText}
          quickFilterItems={quickFilterItems}
          handleExportModal={handleExportModal}
          toolbarStartActions={toolbarStartActions}
          toolbarEndActions={updatedToolbarEndActions}
          currentView={currentView}
          filterAnchorEl={filterAnchorEl}
          setFilterAnchorEl={setFilterAnchorEl}
          searchInputRef={searchInputRef}
          hideExportBtn={hideExportBtn}
          rightGroupedButtons={rightGroupedButtons}          
        />
      ) : (
        <DesktopGridHeader
          hideExportBtn={hideExportBtn}
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          handleSearch={handleSearchChange}
          searchText={searchText}
          setSearchText={setSearchText}
          quickFilterItems={quickFilterItems}
          handleExportModal={handleExportModal}
          toolbarStartActions={toolbarStartActions}
          toolbarEndActions={updatedToolbarEndActions}
          currentView={currentView}
          additionalRightSideButtons={additionalRightSideButtons}
          searchInputRef={searchInputRef}          
        />
      )}

      {openExportModal && <ExportModal
        anchorEl={openColumnsSelector}
        getData={getData || defaultGetData}
        totalRecords={totalRecords}
        type={columnSelectorType}
        columns={handleColumnsForExport(true)}
        rowsSelected={selectedRows}
        openExportModal={openExportModal}
        handleExportModal={handleExportModal}
      />}

      {openColumnsSelector && (
        <ColumnsSelectorV2
          anchorEl={openColumnsSelector}
          title={title}
          getData={getData || defaultGetData}
          onClose={() => {
            setColumnSelectorType(null);
            setOpenColumnsSelector(null);
          }}
          type={columnSelectorType}
          muiTableRef={muiTableRef}
          columns={handleColumnsForExport()}
          rowsSelected={selectedRows}
        />
      )}
      
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={toast.message}
      >
        <Alert
          onClose={closeSnackbar}
          severity={toast.type}
        >
          {toast.message}
        </Alert>
      </Snackbar>
      {
        taskMenuListItems?.length > 0 &&
        <Menu
          id="task-menu"
          anchorEl={taskAnchorEl}
          open={isTaskMenuOpen}
          onClose={handleTaskMenuClose}
          MenuListProps={{
            'aria-labelledby': 'task-button',
          }}
        >
          {
            taskMenuListItems.map((singleItem) => {
              return (
                <MenuItem disabled={singleItem.disabled} onClick={(event) => {
                  singleItem?.onClick(event)
                }}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary={singleItem.title} />
                </MenuItem>
              )
            })
          }
        </Menu>
      } 
    </>
  );
};

export default DataGridToolbar;
