import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid2, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import React, { useMemo } from 'react'
import dayjs from "dayjs"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAutomationDateFields, getAutomationForDays } from './utils'
import { AutomationForDays } from '../../../_helpers'
import moment from 'moment'

const GeneralSettings = ({ values, handleChange, errors, clearError, activeCheckboxState, setActiveCheckboxState }) => {

    const { t } = useTranslation();
    const automationDateFields = getAutomationDateFields(t)
    const automationForDays = getAutomationForDays(t)
    const { buildings: { all: allBuildings } } = useSelector(state => state)

    const projectName = useMemo(() => allBuildings?.find((building) => building.projectId === values.projectId)?.projectName, [allBuildings, values.projectId])

    const selectedProjectAllBuildings = useMemo(() => {
        return allBuildings?.filter((b) => b.projectId === values.projectId)
    }, [allBuildings, values.projectId])

    const buildingValue = useMemo(() => values?.objects?.map((obj) => obj?.id), [values?.objects, selectedProjectAllBuildings])

    return (
        <>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {t('taskAutomation.generalSettings')}
            </Typography>
            <Divider sx={{
                my: 0
            }} />
            <Grid2 container sx={{
                py: 1
            }} spacing={{ xs: 2, md: 0 }}>
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}` } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>{t('general.general')}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('taskAutomation.generalSettings.automationFlow')}
                                        disabled={values?.active}
                                        value={values?.name}
                                        autoComplete='off'
                                        size='small'
                                        fullWidth
                                        error={errors?.name}
                                        helperText={errors?.name}
                                        onChange={(e) => {
                                            handleChange({ keyName: "name", value: e.target.value })
                                            clearError("name")
                                        }} />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        disabled
                                        id="outlined-multiline-flexible"
                                        label={t('general.project')}
                                        value={projectName}
                                        size='small'
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControl size='small' fullWidth error={!!errors.objects} >
                                        <InputLabel id="demo-multiple-checkbox-label">{t('general.objects.select')}</InputLabel>
                                        <Select
                                            disabled={values?.active}
                                            multiple
                                            size='small'
                                            value={buildingValue || []}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        height: 200,
                                                    },
                                                },
                                            }}

                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                handleChange({ keyName: "objects", value: value?.map((id) => ({ id })), })
                                                clearError("objects")
                                            }}
                                            input={<OutlinedInput label={t('general.objects.select')} />}
                                            renderValue={(selected) => selected?.map((id) => selectedProjectAllBuildings?.find((building) => building?.buildingId === id)?.buildingNoIntern)?.join(', ') || ""}
                                        >
                                            {selectedProjectAllBuildings.map((building, index) => (
                                                <MenuItem key={building.buildingId} value={building.buildingId} dense>
                                                    <Checkbox size='small'
                                                        checked={buildingValue?.includes(building?.buildingId)}
                                                    />
                                                    <ListItemText primary={building.buildingNoIntern} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={errors.objects}>{errors?.objects}</FormHelperText>
                                    </FormControl>
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        disabled
                                        id="outlined-multiline-flexible"
                                        label={t('taskAutomation.generalSettings.selectOwner')}
                                        value={values?.owner}
                                        size='small'
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <DatePicker
                                        minDate={dayjs().toDate()}
                                        variant="standard"
                                        format="dd-MM-yyyy"
                                        disabled={values?.active}
                                        id="start-date"
                                        label={t('general.startDate')}
                                        slotProps={{
                                            openPickerButton: { edge: "end", size: "small" },
                                            textField: {
                                                variant: "outlined",
                                                error: errors?.startDate,
                                                helperText: errors?.startDate,
                                                size: "small",
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                }
                                            },
                                        }}

                                        value={values?.startDate ? new Date(values.startDate) : null}
                                        onChange={(date) => {
                                            handleChange({ keyName: "startDate", value: dayjs(new Date(date).toJSON()).format("YYYY-MM-DD") })
                                            clearError("startDate")
                                        }}
                                        onError={errors?.startDate}

                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <DatePicker
                                        minDate={
                                            values?.startDate
                                                ? dayjs(values?.startDate).toDate()
                                                : dayjs().toDate()
                                        }
                                        disabled={values?.active || !values?.startDate}
                                        variant="standard"
                                        format="dd-MM-yyyy"
                                        id="end-date"
                                        label={t('general.endDate')}
                                        autoOk
                                        value={values?.endDate ? new Date(values.endDate) : null}
                                        slotProps={{
                                            openPickerButton: { edge: "end", size: "small" },
                                            textField: {
                                                variant: "outlined",
                                                error: errors?.endDate,
                                                helperText: errors?.endDate,
                                                size: "small",
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                }
                                            },
                                        }}
                                        onChange={(date) => {
                                            handleChange({ keyName: "endDate", value: dayjs(new Date(date).toJSON()).format("YYYY-MM-DD") })
                                            clearError("endDate")
                                        }}
                                        onError={errors?.endDate}
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <FormControlLabel disabled={activeCheckboxState.isDisabled} control={<Checkbox checked={activeCheckboxState.isChecked} onChange={(e) => {
                                        setActiveCheckboxState({ ...activeCheckboxState, isChecked: e.target.checked })
                                    }} />} label={t('general.active')} />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}` } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>
                                {t('taskAutomation.dynamicSettings')}
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            {t('taskAutomation.selectDateField')}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            value={values?.dateField?.toString()}
                                            disabled={values?.active}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                const selectedField = automationDateFields.find(field => field.value === selectedValue);
                                                handleChange({
                                                    keyName: "dateField",
                                                    value: selectedField,
                                                    extraData: selectedField?.value?.toString() || ''
                                                });
                                                clearError("dateField")
                                            }}
                                            input={<OutlinedInput label={t('taskAutomation.selectDateField')} />}
                                        >
                                            {automationDateFields.map((fields) => (
                                                <MenuItem key={fields.value} value={fields.value} dense>
                                                    <ListItemText primary={fields.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={errors.dateField}>{errors?.dateField}</FormHelperText>
                                    </FormControl>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            {t('taskAutomation.dynamicSettings.daysBeforeAfter')}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            disabled={values?.active}
                                            value={values?.daysBeforeAfter}
                                            input={<OutlinedInput label={t('taskAutomation.dynamicSettings.daysBeforeAfter')} />}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                const selectedItem = automationForDays.find(item => item.value === selectedValue);
                                                handleChange({
                                                    keyName: "daysBeforeAfter",
                                                    value: selectedItem,
                                                    extraData: selectedItem?.value?.toString() || ""
                                                });
                                                clearError(["daysBeforeAfter" , "days"])
                                            }}
                                        >
                                            {automationForDays.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    <ListItemText primary={item.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={errors.daysBeforeAfter}>{errors?.daysBeforeAfter}</FormHelperText>
                                    </FormControl>
                                </Grid2>
                                {(values?.daysBeforeAfter?.toString() === AutomationForDays.After.toString() || values?.daysBeforeAfter?.toString() === AutomationForDays.Before.toString()) && <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        type='number'
                                        label={t('general.days')}
                                        value={values?.days}
                                        disabled={values?.active}
                                        autoComplete='off'
                                        fullWidth
                                        size='small'
                                        error={errors?.days}
                                        helperText={errors?.days}
                                        onChange={(e) => {
                                            const newValue = e.target.value
                                            const numberVal = Number(newValue)
                                            if (!newValue || (numberVal !== 0 && numberVal <= 365)) {
                                                handleChange({ keyName: "days", value: newValue?.toString() });
                                            }
                                            clearError("days")
                                        }}
                                    />
                                </Grid2>}
                                <Grid2 size={{ xs: 12 }}>
                                    <TimePicker
                                        maxTime={new Date(0, 0, 0, 23, 45)}
                                        disabled={values?.active}
                                        sx={{ width: "100%" }}
                                        label={t('taskAutomation.runTime')}
                                        value={values?.timeOfDay ? moment(values?.timeOfDay, "HH:mm:ss").toDate() : null}
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                helperText: errors?.timeOfDay,
                                                error:errors?.timeOfDay
                                            },
                                        }}
                                        onChange={newValue => {
                                            handleChange({ keyName: "timeOfDay", value: newValue ? moment(newValue).format("HH:mm"):undefined })
                                            clearError("timeOfDay")
                                        }}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>

            </Grid2>
        </>
    )
}

export default GeneralSettings