import { LabelColumnV2, LinkColumnV2 } from "../../../components/DataGridV2/DataColumnsV2";
import { LOCAL_STORAGE_GRID_KEYS, apps } from "../../../_constants";
import i18n from "../../../i18n";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";
import { store } from "../../..";
import { getTemplateTypes } from "../util";

export const getTemplatesGridColumns = ({ t,history, theme, apiRef, keyPrefix }) => {
    const {app} = store.getState()
    const getOptions = getGridContextMenuList({
        filterKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER[keyPrefix],
        sortKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT[keyPrefix],
        i18n,
        t,
        apiRef,
        getValueForCopy: (rowData, extraData) => {
        let text = ""
        switch (extraData.fieldName) {
            case "templateType":
            text = t(`template.type.${rowData.templateType}`)
            break;
            case "active":
            text = rowData[extraData.fieldName] ? t("general.yes") : t("general.no")
            break;
            default:
            text = rowData[extraData.fieldName]
            break;
        }
        return text
        },
        mode: "client"
    })
    
    return [
        {
            field: "desc",
            headerName: t("general.template.name"),
            flex: 1,
            download: true,
            minWidth: 90, 
            getApplyQuickFilterFn: (searchVal) => {
                return (value, row) => {
                    return value?.toLowerCase().includes(searchVal?.toLowerCase()) || row?.searchField?.toLowerCase().includes(searchVal?.toLowerCase());
                };
            },
            renderCell: (params) => {
                const rowData = params.row;
                return <LinkColumnV2 to={app === apps.huisinfo ? "/systeemonderhound/sjablonen/" + params?.row?.id : "/nazorg/systeemonderhound/sjablonen/" + params?.row?.id} label={rowData.desc}
                    cellSubMenuOptions={{ options: getOptions({ field: "desc", isQuickFilterDisplay: Boolean(rowData.desc) }), extraData: { fieldName: "desc", } }}
                    request={rowData}
                />
            },
        },
        {
            field: "templateType",
            headerName: t("general.type"),
            flex: 1,
            minWidth: 90,
            type: "singleSelect",
            valueOptions: getTemplateTypes(t),
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2 label={t(`template.type.${rowData?.templateType}`)}
                    cellSubMenuOptions={{ options: getOptions({ field: "templateType", isQuickFilterDisplay: Boolean(rowData?.templateType?.toString()) }), extraData: { fieldName: "templateType", type: "singleSelect" } }}
                    request={rowData}
                />
            },
        },
        {
            field: "projectName",
            headerName: t("general.project"),
            flex: 1,
            minWidth: 100,
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2 label={rowData.projectName}
                    cellSubMenuOptions={{ options: getOptions({ field: "projectName", isQuickFilterDisplay: Boolean(rowData.projectName) }), extraData: { fieldName: "projectName" } }}
                    request={rowData}
                />
            },
        },
        {
            field: "documentCode",
            headerName: t('general.documentCode'),
            flex: 1,
            minWidth: 100,
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2 label={rowData.documentCode}
                    cellSubMenuOptions={{ options: getOptions({ field: "documentCode", isQuickFilterDisplay: Boolean(rowData.documentCode) }), extraData: { fieldName: "documentCode" } }}
                    request={rowData}
                />
            },
        },
        {
            field: "active",
            headerName: t('general.active'),
            flex: 1,
            minWidth: 90,
            download: true,
            type: "boolean",
            renderCell: (params) => {
                const rowData = params.row;
                const isActive = rowData.active ===  true;
                return (
                    <LabelColumnV2
                        color={isActive ? theme.palette.success.main : theme.palette.error.main}
                        label={isActive ? t("general.yes") : t("general.no")}
                        cellSubMenuOptions={{ options: getOptions({ field: "active", isQuickFilterDisplay: typeof rowData.active === "boolean" }), extraData: { fieldName: "active" ,type : "boolean"} }}
                        request={rowData}  
                    />
                );
            },
        }
    ];
}

export const getTemplateGridNavigationItem = (t)=>{
    const {app} =store.getState()
    const to = app === apps.huisinfo ? "/systeemonderhound/sjablonen" : app === apps.aftercare ? "/nazorg/systeemonderhound/sjablonen" : '#'
    return {
        breadCrumbs: [{ breadCrumbTitle: t('general.template'),title:t('general.template'), to}],
        tabs: [{title:t('general.list') , to}]
    }
}
