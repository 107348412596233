import { Divider, Grid2, Typography } from '@mui/material'
import React, { useState } from 'react'
import CardInfo from '../CardInfo/CardInfo'
import EditAndPreview from '../../../../components/EditAndPreview';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions, toastActions } from '../../../../_actions';
import { updateTaskAutomation } from '../../../../apis/systemMaintenanceApis';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../../../_constants';
import { URLS } from '../../../../apis/urls';

const CardInfoWrapper = ({ title, details, handleEditTaskModal }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { taskAutomationId } = useParams()
    const { taskDetails } = useSelector(state => state.systemMaintenance.taskDetailsStore)
    const [editAndPreviewTemplate, setEditAndPreviewTemplate] = useState({ open: false, data: null, type: "" });
    const [loading, setLoading] = useState(false)

    const info = {
        template: taskDetails?.documentTemplate?.template,
        initialContent: `${taskDetails?.emailTemplate?.template}`,
        subject: taskDetails?.documentTemplate?.subject,
        title:[SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL, SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL].includes(editAndPreviewTemplate?.type) ? taskDetails?.emailTemplate?.desc : taskDetails?.documentTemplate?.desc,
        documentUrl : URLS.GET_TASK_DOCUMENT_PREVIEW + taskAutomationId
    }

    const updateTaskAutomationDetails = async (data) => {
        try {
            setLoading(true)
            await updateTaskAutomation(taskAutomationId, data)
        } catch (error) {
        }
        finally {
            setLoading(false)
            setEditAndPreviewTemplate({ data: null, type: "", open: false })
            dispatch(taskDetailsActions.fetchAutomationDetails(taskAutomationId))
        }
    }

    const handleSave = (plainText, emailEditorValue, container) => {
        switch (editAndPreviewTemplate?.type) {
            case "EDIT_EMAIL":
                if (plainText?.length === 0) {
                    dispatch(toastActions.error(t("general.field.error")))

                } else {
                    updateTaskAutomationDetails([{ id: "emailtemplate", name: emailEditorValue }])
                    dispatch(toastActions.success(t("taskAutomation.toast.updateDetails.success", { title: t('general.email') })))
                }
                break;
            case "EDIT_DOCUMENT":
                updateTaskAutomationDetails([{ id: "documenttemplate", name: container?.current?.documentEditor?.serialize() }])
                dispatch(toastActions.success(t("taskAutomation.toast.updateDetails.success", { title: t('taskAutomation.templateSettings.document') })))
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {title}
            </Typography>
            <Divider />
            <Grid2 container sx={{
                py: 1
            }}>
                {details?.map((item) => {
                    return <CardInfo
                        key={item.title}
                        item={item}
                        handleEditTaskModal={handleEditTaskModal}
                        setEditAndPreviewTemplate={setEditAndPreviewTemplate}
                    />
                })}
            </Grid2>
            {editAndPreviewTemplate.open && <EditAndPreview editAndPreviewTemplate={editAndPreviewTemplate} setEditAndPreviewTemplate={setEditAndPreviewTemplate} loading={loading} handleSave={handleSave} info={info} />}
        </div>
    )
}

export default CardInfoWrapper
