import { TASK_AUTOMATION } from '../_constants';

const initialState = {
  taskList: {
    loading: false,
    data: []
  },
  drawerInfo: {
    selectedProjectDetails: {
      selectedProjectInfo: undefined,
      selectedProjectLabel: undefined,
      selectedProjectId: undefined
    },
    taskName: "",
    toggleTaskDrawer: false,
    emailTemplates: {
      loading: false,
      templates: [],
      selectedTemplate: undefined
    },
    documentTemplates: {
      loading: false,
      templates: [],
      selectedTemplate: undefined,
      isDocumentLabelSelected:false,
    },
    attachment:{
      name:"",
      content:"",
      isAttachmentLabelSelected:false
    }
  }

}

export function taskAutomationReducer(state = initialState, action) {
  switch (action.type) {

    case TASK_AUTOMATION.SET_PROJECT_INFO:
      return { ...state, drawerInfo: { ...state.drawerInfo, selectedProjectDetails: action.data } }
    case TASK_AUTOMATION.SET_TASK_NAME:
      return { ...state, drawerInfo: { ...state.drawerInfo, taskName: action.data } }
    case TASK_AUTOMATION.SET_LOADING_EMAIL_TEMPLATES:
      return { ...state, drawerInfo: { ...state.drawerInfo, emailTemplates: { ...state.drawerInfo.emailTemplates, loading: action.data } } }
    case TASK_AUTOMATION.SET_EMAILS_TEMPLATES_DATA:
      return { ...state, drawerInfo: { ...state.drawerInfo, emailTemplates: action.data } }
    case TASK_AUTOMATION.SELECT_EMAIL_TEMPLATES:
      return { ...state, drawerInfo: { ...state.drawerInfo, emailTemplates: { ...state.drawerInfo.emailTemplates, selectedTemplate: action.data } } }

    case TASK_AUTOMATION.SET_LOADING_DOCUMENT_TEMPLATES:
      return { ...state, drawerInfo: { ...state.drawerInfo, documentTemplates: {...state.drawerInfo.documentTemplates, loading: action.data }}}
    case TASK_AUTOMATION.SET_DOCUMENT_TEMPLATES_DATA:
      return { ...state, drawerInfo: { ...state.drawerInfo, documentTemplates: action.data } }
    case TASK_AUTOMATION.SELECT_DOCUMENT_TEMPLATES:
      return { ...state, drawerInfo: { ...state.drawerInfo, documentTemplates: { ...state.drawerInfo.documentTemplates, selectedTemplate: action.data } } }

    case TASK_AUTOMATION.SET_ATTACHMENT_FILE_NAME:
      return { ...state, drawerInfo: { ...state.drawerInfo, attachment: { ...state.drawerInfo.attachment, name: action.data } } }
    case TASK_AUTOMATION.SET_ATTACHMENT_FILE_CONTENT:
      return { ...state, drawerInfo: { ...state.drawerInfo, attachment: { ...state.drawerInfo.attachment, content: action.data } } }
    case TASK_AUTOMATION.TOGGLE_TASK_DRAWER:
      return { ...state, drawerInfo: { ...state.drawerInfo, toggleTaskDrawer: action.data } }

    case TASK_AUTOMATION.SET_TASK_LIST_LOADING:
      return { ...state, taskList: { ...state.taskList, loading: action.data } }
    case TASK_AUTOMATION.SET_TASK_LIST: {
      return { ...state, taskList: action.data }
    }

    case TASK_AUTOMATION.SET_DOCUMENT_LABEL_SELECT:
      return { ...state, drawerInfo: { ...state.drawerInfo, documentTemplates: {...state.drawerInfo.documentTemplates, isDocumentLabelSelected: action.data }}}

    case TASK_AUTOMATION.SET_ATTACHMENT_LABEL_SELECT:
      return { ...state, drawerInfo: { ...state.drawerInfo, attachment: {...state.drawerInfo.attachment, isAttachmentLabelSelected: action.data }}}

    case TASK_AUTOMATION.CLEAR:
      return initialState;

    case TASK_AUTOMATION.CLEAR_DRAWER_INFO:
      return { ...state, drawerInfo: initialState.drawerInfo }

      default:
      return state
  }
}