import React, { useState, memo, useEffect, useMemo } from "react";
import { Badge, Button,  Grid2 as Grid, Tooltip, Typography, IconButton, Box, Avatar, Popover, CardContent } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ArrowRightAlt, Chat, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { formatDate } from "../../_helpers";
import CellMenu from "../CellMenu";
import { URLS } from "../../apis/urls";

export const PriorityIconColumnV2 = React.memo(({ value, cellSubMenuOptions, request, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setPosition({
        x: event.clientX,
        y: event.clientY
      })
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, anchorEl])

  return (
    <Grid
      container
      {...contextProps}
      style={{ minWidth: "30px", width: "100%", height: "100%" }}
      sx={[{
        alignItems: "center",
        justifyContent: "center"
      }, ...(Array.isArray(contextProps.sx) ? contextProps.sx : [contextProps.sx])]}>
      {!request.isAfterCareEmployeeOrSiteManager ? <div className={classes.isBlankCellOfPriority}>-</div> :
        value === 2 ? (
          <Tooltip title={t("repairRequest.priority.2")}>
            <ArrowRightAlt className={classes.rotate45AntiClockwise} color="error" />
          </Tooltip>
        ) : value === 1 ? (
          <Tooltip title={t("repairRequest.priority.1")}>
            <ArrowRightAlt color="primary" />
          </Tooltip>
        ) : value === 0 ? (
          <Tooltip title={t("repairRequest.priority.0")}>
            <ArrowRightAlt className={classes.rotate45Clockwise} />
          </Tooltip>
        ) : (
          ""
        )
      }
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Grid>
  );
});


export const Attention = ({ rowData, t, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Typography
      noWrap
      component="div"
      onContextMenu={handleContextMenu}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        minWidth: "60px"
      }}>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center"
        }}>
        {rowData.isRequiredHandling === true && (
          <PriorityHigh color="secondary" size="small" />
        )}
      </Grid>
      <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "isRequiredHandling", type: "boolean" } }} row={rowData } anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
    </Typography>
  </>);
}

export const HoverImage = ({ imageId, defaultSize, index }) => {
  const [size, setSize] = useState(defaultSize)

  useEffect(() => {
    setSize(defaultSize)
  }, [defaultSize])

  return (<>
    {imageId &&
      <Box >
        <Box
          className={`img-col-data ${index === 0 ? "img-col-first-data" : ""}`}
          style={{ backgroundImage: `url(${imageId ? URLS.GET_ATTACHMENT_THUMBNAIL + imageId : null})`, backgroundSize: "cover" }}
          sx={[{
            boxShadow: 1,
            borderRadius: 1,
            width: size,
            height: size,

            "&:hover": {
              transform: "scale(5)",
              position: "absolute",
              zIndex: "1000000"
            }
          }, index === 0 ? {
            "&:hover": {
              marginTop: '30px'
            }
          } : {
            "&:hover": {
              marginTop: 0
            }
          }]}>
        </Box>
      </Box>}
  </>);
}

export const Overdue = ({ rowData, t, options,canDisplayValue=true, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Typography
      noWrap
      component="div"
      onContextMenu={handleContextMenu}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%"
      }}>
      {!canDisplayValue ? (
          <Typography>-</Typography>
      ) : (
        <>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center"
            }}>
            {rowData.overdue ? (
              <Schedule fontSize="small" color="error" />
            ) : rowData.is48HoursReminder ? (
              <Schedule fontSize="small" color="warning" />
            ) : (
            ""
            )}
          </Grid>
        </>
      )}
      <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "overdue", type: "boolean" } }} row={rowData} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
    </Typography>
  </>);
}

export const Messages = ({ request, t, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })
  
  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Typography
      noWrap
      component="div"
      onContextMenu={handleContextMenu}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        pt: 1
      }}>
            {
                request?.unreadMessagesCount > 0 &&
                <Badge sx={{ "& .MuiBadge-badge":{height: 14, minWidth: 14 }}}  badgeContent={request.unreadMessagesCount} color="secondary"><Chat fontSize="12px" /></Badge>
            }
      <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "unreadMessagesCount", type: "number" } }} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
    </Typography>
  </>);
}

export const LinkColumnV2 = memo(({ label, cellSubMenuOptions, request, isNumeric, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setPosition({
        x: event.clientX,
        y: event.clientY
      })
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, anchorEl])

  return (
    (<Box className={classes.cellContainer} {...contextProps}>
      <Button
        style={{ padding: 0 }}
        component={Link}
        sx={[{
          fontSize: '12px',
          height: '100%',
          width: '100%'
        }, isNumeric ? {
          justifyContent: 'right'
        } : {
          justifyContent: 'left'
        }, (theme) => ({ '&:hover': { color: theme.palette[props.color ?? 'primary'].dark } })]}
        {...props}
      >
        <span className={clsx(classes.content)}>
          {label}
        </span>
        <div className={classes.spacer}> {label}</div>
        <span>&nbsp;</span>
      </Button>
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={label} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Box>)
  );
});

export const MultiLabelColumnV2 = memo(({ data = [], onClick = () => 0, cellSubMenuOptions,
  request, ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [userListPopover, setUserListPopover] = useState({
    element: null,
    data: null,
  });

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setPosition({
        x: event.clientX,
        y: event.clientY
      })
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, anchorEl])

  if (!data || !data.length) return <Box   {...contextProps} {...rest}>  {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}</Box>;

  const labels = [...new Map(data.map((item) => [item.name, item])).values()];
  const clickHandler = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(user)
  }
  return (
    (<Box className={classes.cellContainer}
      {...contextProps}
      {...rest}
    >
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          position: "relative",
          display: "flex",
          alignItems: "center"
        }}>
        <span className={clsx(classes.shortenText, classes.content)} onClick={(e) => clickHandler(e, labels[0])}>
          {labels[0].name}
        </span>
        <UserLists
          open={!!userListPopover.element}
          data={userListPopover.data}
          onClose={(e) => {
            e.stopPropagation()
            setUserListPopover({ element: null, data: null })
          }}
          onClick={clickHandler}
          anchorEl={userListPopover.element}
        />
        <div className={classes.spacer}> {labels[0].name}</div>
        <span>&nbsp;</span>
      </Box>
      {labels.length - 1 > 0 && (
        <Box
          sx={{
            mr: 1,
            display: "flex",
            alignItems: "center"
          }}>
          <Avatar
            sx={{ cursor: "pointer" }}
            className={classes.smallAvatar}
            onClick={(e) => {
              e.stopPropagation()
              setUserListPopover({ element: e.currentTarget, data: labels })
            }}>
            +{labels.length - 1}
          </Avatar>
        </Box>
      )}
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={labels[0].name} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Box>)
  );
});

export const LabelColumnV2 = memo(({
  spanClass = {},
  color = "",
  label,
  tooltipLabel = "",
  date,
  showIcon,
  Icon,
  onLabelClick,
  clickable,
  onClick,
  isAvatar,
  avatarUrl,
  cellSubMenuOptions,
  request,
  isNumeric,
  isBlankCell = false,
  ...rest
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const contextProps = useMemo(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      setPosition({
        x: event.clientX,
        y: event.clientY
      })
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, anchorEl])

  return (<>
    <Box className={isNumeric ? clsx(classes.cellContainer, classes.justifyContentRight): classes.cellContainer} {...contextProps}  {...rest} >
      {isBlankCell ? <div className={classes.isBlankCell}>-</div> :
        showIcon ? (
          <>
            <Tooltip
              title={
                !tooltipLabel ? (
                  ""
                ) : (
                  <span className={classes.labelTooltip}>{tooltipLabel}</span>
                )
              }
            >
              <span style={{ color }} className={clsx(classes.label, onLabelClick && classes.cursor)} onClick={() => onLabelClick && onLabelClick(label)}>
                {clickable ? (
                  <IconButton
                    color="primary"
                    className={classes.mapIconContainer}
                    aria-label="Repair request map redirect"
                    component="span"
                    onClick={onClick}
                    size="large">
                    <Icon className={classes.mapIcon} />
                  </IconButton>
                ) : (
                  isAvatar ? (
                    <div className={classes.avatarIconContainer}>
                      <Avatar src={avatarUrl} className={classes.avatarIcon} />
                    </div>
                  ) : <Icon className={classes.mapIcon} />
                )}
                <Box
                  component='span'
                  sx={[{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    position: "relative",
                    lineHeight: 1,
                    alignItems: "center"
                  }, isNumeric ? {
                    justifyContent: 'right'
                  } : {
                    justifyContent: 'left'
                  }]}>
                  {date ? formatDate(new Date(date)) : label}
                </Box>
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip
              title={
                !tooltipLabel ? (
                  ""
                ) : (
                  <span className={classes.labelTooltip}>{tooltipLabel}</span>
                )
              }
            >
              <Typography
                noWrap
                variant="body2"
                component="span"
                style={{ color }}
                className={clsx(classes.iconLabel, classes.label, classes.content, spanClass)}
              >
                {Icon && <div className={classes.customIconContainer}></div>}
                <span className={clsx(Icon ? classes.iconTextLabel : classes.textLabel, onLabelClick && classes.cursor)} onClick={(e) => {
                  onLabelClick && onLabelClick(label);
                }}>
                  {date ? formatDate(new Date(date)) : label}
                </span>
              </Typography>
            </Tooltip>
          </>
        )
      }
      {!showIcon && !isBlankCell && <div className={classes.spacer}> {date ? formatDate(new Date(date)) : label}</div>}
      <span>&nbsp;</span>
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={label} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Box>
  </>);
});

export const ImageColumn = memo(({ src, size = 20 }) => {
  const [url, setUrl] = useState();
  const [base64, setBase64] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (src) {
      setUrl(src);
      if (typeof (src) === "string" && src.startsWith("data:")) {
        setBase64(src);
      }
    };
  }, [src]);

  if (!src) return <div style={{ height: `${size + 4}px` }} />;

  return (
    (<div>
      <span className={classes.centerContainer}>
        {<Box
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${url})`, width: size, height: size }}
          sx={{
            boxShadow: 1,
            borderRadius: 1
          }}>
          {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
        </Box>}
        {src && (
          <aside
            className={clsx(classes.popoverImage, classes.fixedContainer)}
          >
            <Box
              className={classes.thumbnailAbsolute}
              style={{ backgroundImage: `url(${src})` }}
              sx={{
                boxShadow: 1,
                borderRadius: 1
              }}>
              {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
            </Box>
          </aside>
        )}
      </span>
    </div>)
  );
});

export const UserLists = memo(({
  open,
  anchorEl: anchorElModal,
  onClick,
  data,
  ...props
}) => {
  const classes = useStyles();

  return data ? (
    <Popover
      id="mouse-over-popover"
      style={{ overflow: "hidden" }}
      anchorEl={anchorElModal}
      open={!!anchorElModal && data && data.length}
      disableRestoreFocus
      classes={{ root: classes.root, paper: classes.paper }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      <div>
        {data && (
          <CardContent className={classes.cardContentStyle}>
            {data.map((user) => {
              return (
                <span className={classes.headerContent} onClick={(e) => onClick(e, user)}>{user.name}</span>
              );
            })}
          </CardContent>
        )}
      </div>
    </Popover>
  ) : null;
});

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 7,
    minWidth: 150,
  },
  cardContentStyle: {
    padding: theme.spacing(0, 2),
    paddingBottom: `${theme.spacing(0)} !important`,
    display: "flex",
    flexDirection: "column",
  },
  cardList: {
    padding: theme.spacing(0.4, 0, 0.4, 0),
  },
  cardAvatar: {
    marginRight: `8px !important`,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18
  },
  avatarIconContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  avatarIcon: {
    marginRight: 5,
    width: 18,
    '& > svg': {
      height: 14,
      width: 14
    }
  },
  customIconContainer: {
    minWidth: 22,
  },
  iconTextLabel: {
    width: "calc(100% - 29px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textLabel: {
    width: "100%",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    width: "100%",
    height: "100%"
  },
  justifyContentRight: {
    justifyContent: 'right'
  },
  mapIconContainer: {
    padding: theme.spacing(0.25),
  },
  tooltipImageContainer: {
    "& > img": {
      width: "100%",
    },
  },
  thumbnailAbsolute: {
    width: 20,
    height: 20,
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    position: "absolute",
    opacity: 0,
    marginBottom: 2,
  },
  thumbnail: {
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    marginBottom: 2,
    overflow: "hidden"
  },
  rotate45Clockwise: {
    transform: "rotate(45deg)",
  },
  rotate45AntiClockwise: {
    transform: "rotate(-45deg)",
  },
  isBlankCell: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },
  isBlankCellOfPriority: {
    maxWidth: "18px", 
    width: "100%",
     height: "100%", 
     display: "flex", 
     justifyContent: "center"
  },
  smallAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
    position: "relative",
    border: "2px solid white",
    right: 0,
    zIndex: 4,
  },
  shortenText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      paddingLeft: 5
    },
    cursor: "pointer"
  },
  trimResolverLabel: {
    maxWidth: "calc(100% - 25px) !important"
  },
  userListAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
  },
  headerContent: {
    fontSize: `14px !important`,
    margin: 4,
    cursor: "pointer"
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "inherit",
    display: "flex",
    alignItems: "center"
  },
  wrapContainer: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  labelTooltip: {
    fontSize: 14,
  },
  popoverImage: {
    "&:hover": {
      "& > div": {
        width: "160px !important",
        height: "160px !important",
      },
    },
  },
  centerContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainer: {
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  base64Image: {
    height: "100%",
    width: "100%",
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1
  },
  spacer: {
    height: 0,
    overflow: "hidden",
  },
  iconLabel: {
    display: "flex",
    alignItems: "center",
    width: '100%'
  },
  cursor: {
    cursor: "pointer"
  }
}));