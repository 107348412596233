import React, { useEffect, useRef, useState } from 'react'
import { searchMergedFields } from '../_helpers/searchMergedFields';
import { getMimeTypefromString } from '../_helpers';
import { AccountTree, Close, ContentCopy, DeleteForeverSharp, ExpandLess, ExpandMore, MoreVert, ReplaySharp, Save, Search } from '@mui/icons-material';
import { getEmptySfdt, getMergedFields } from '../Pages/TaskAutomation/SelectTemplatesDrawer/util';
import { toastActions } from '../_actions';
import { copyToClipboard } from '../_helpers/copyToClipboardHelper';
import { AppBar, Box, Button, Collapse, Dialog, DialogContent, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Paper, Popover, Toolbar, Tooltip, ListItemIcon, Stack, useTheme } from '@mui/material';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../_constants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import EllipsisTypography from './EllipsisTypography';
import { RichTextEditorComponent, HtmlEditor, Inject, Toolbar as RTE_Toolbar, Image, Link, QuickToolbar } from "@syncfusion/ej2-react-richtexteditor";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import DocumentEditorContainer from './DocumentEditorContainer';
import { makeStyles } from '@mui/styles';

const EditAndPreview = ({
    editAndPreviewTemplate,
    setEditAndPreviewTemplate,
    info,
    handleSave,
    loading,
}) => {

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    
    const editorRef = useRef(null);
    const [mergeFieldsAnchorEl, setMergeFieldsAnchorEl] = useState(null);
    const [buttonsAnchorEl, setButtonsAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [openMergedFields, setOpenMergedFields] = useState({});

    const { template, initialContent, subject, title, documentUrl } = info
    const dispatch = useDispatch()
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };
    
    const handleMergeField = (event) => {
        if (mergeFieldsAnchorEl) {
            setMergeFieldsAnchorEl(null);
        } else {
            setMergeFieldsAnchorEl(event.currentTarget);
        }
    };

    const handleButtonPopup = (event) => {
        if (buttonsAnchorEl) {
            setButtonsAnchorEl(null);
        } else {
            setButtonsAnchorEl(event.currentTarget);
        }
    };

    const handleRestoreBtn = () => {
        if (editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.EDIT_DOCUMENT) {
            if (!template) {
                container?.current?.documentEditor?.open(getEmptySfdt())
            } else {
                container?.current?.documentEditor?.open(template)
            }
        }
        else if (editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL) {
            setEmailEditorValue(initialContent)
        }
    }

    const handleDeleteBtn = () => {
        if (editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.EDIT_DOCUMENT) {
            // FIXME: We need to apply better solution in future for clearing document editor
            container?.current.documentEditor.open(getEmptySfdt());
        }
        else if (editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL) {
            setEmailEditorValue("")
        }
    }

    const openMergeFields = Boolean(mergeFieldsAnchorEl);
    const mergeFieldsId = openMergeFields ? 'simple-popover' : undefined;

    const openButtonsPopUp = Boolean(buttonsAnchorEl);
    const buttonsPopUpId = openButtonsPopUp ? 'simple-popover' : undefined;

    const getCopyTextComponent = ({ clipBoardValue = "", toastValue = "" }) => {
        return (
            <Tooltip title={t("general.copy")}>
                <IconButton
                    color="inherit"
                    aria-label="copy"
                    size="small"
                    onClick={() => {
                        copyToClipboard(`[${clipBoardValue}]`).then(() => {
                            dispatch(toastActions.success(t("general.copiedText", { text: toastValue || clipBoardValue })));
                            setMergeFieldsAnchorEl(null);
                        });
                    }}
                >
                    <ContentCopy fontSize='small' htmlColor={theme.palette.action.active} />
                </IconButton>
            </Tooltip>
        );
    };

    const mergeFields = getMergedFields({ t, getCopyTextComponent })

    const handleMergedFieldCollapse = (index) => {
        setOpenMergedFields(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const buttons = [
        { text: t('general.delete').toUpperCase(), icon: <DeleteForeverSharp />, onClick: handleDeleteBtn },
        { text: t('general.restore').toUpperCase(), icon: <ReplaySharp />, onClick: handleRestoreBtn },
    ]
    
    const [emailEditorValue, setEmailEditorValue] = useState(initialContent);
    const toolbarSettings = {
        items: [
            "Undo",
            "Redo",
            "|",
            "FontName",
            "FontSize",
            "|",
            "Bold",
            "Italic",
            "Underline",
            "CreateLink",
            "FontColor",
            "BackgroundColor",
        ],
    };

    useEffect(() => {
        setEmailEditorValue(initialContent)
    }, [initialContent])

    const handleEditorChange = () => {
        const currentValue = editorRef.current.getHtml();
        setEmailEditorValue(currentValue);
    };

    let container = useRef();

    const filteredMergedFields = searchMergedFields(mergeFields, searchText);
 
    const docs = documentUrl ? [{
        uri: documentUrl,
        fileType: getMimeTypefromString(".pdf"),
        fileName: subject
    }] : [];

    const plainText = editorRef?.current?.getText()?.replace(/\n/g, '')?.trim();
      
    return (
        <>
            {loading && <Loader backdrop={true} />}
            <Dialog onClose={() => setEditAndPreviewTemplate({ data: null, type: "", open: false })} fullScreen open={editAndPreviewTemplate?.open} >
                <AppBar position='relative'>
                    <Toolbar>
                        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setEditAndPreviewTemplate({ open: false, data: null, type: "" })}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                <EllipsisTypography component="div" variant={"h6"} sx={{ ml: 2 }} title={title} />
                            </Stack>
                            {(![SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL, SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT].includes(editAndPreviewTemplate?.type)) && <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Button
                                    onClick={handleMergeField}
                                    size="small"
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'inline-flex',
                                        },
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    variant="text" color="inherit"
                                    startIcon={<AccountTree />}
                                >
                                    {t('general.mergeFields')}
                                </Button>

                                <IconButton onClick={handleMergeField} sx={{ display: { sm: "none" } }} color='inherit' >
                                    <AccountTree />
                                </IconButton>
                                <Button onClick={handleDeleteBtn} size='small' sx={{
                                    display: { xs: 'none', sm: 'inline-flex' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                }} color='inherit' startIcon={<DeleteForeverSharp />}>
                                    {t('general.delete')}
                                </Button>
                                <Button onClick={handleRestoreBtn} size='small' sx={{ display: { xs: 'none', sm: 'inline-flex' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} color='inherit' startIcon={<ReplaySharp />}>
                                    {t('general.restore')}
                                </Button>
                                <Button
                                    sx={{ display: { xs: 'none', sm: 'inline-flex' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}
                                    size='small'
                                    variant="text"
                                    color='inherit'
                                    startIcon={<Save />}
                                    onClick={() => handleSave(plainText,emailEditorValue,container)}
                                >
                                    {t("general.save")}
                                </Button>
                                <IconButton
                                    sx={{ display: { sm: "none" } }}
                                    color='inherit'
                                    onClick={() => handleSave(plainText,emailEditorValue,container)}
                                >
                                    <Save />
                                </IconButton>
                                <IconButton
                                    sx={{ display: { sm: "none" } }}
                                    color='inherit'
                                    onClick={handleButtonPopup}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Stack>}
                        </Stack>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ p: 0 }}>
                    {loading && <Loader backdrop={true} />}
                    {editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL &&
                        <RichTextEditorComponent
                            height="100%"
                            width="100%"
                            toolbarSettings={toolbarSettings}
                            value={emailEditorValue}
                            ref={editorRef}
                            change={handleEditorChange}
                        >
                            <Inject services={[HtmlEditor, RTE_Toolbar, Image, Link, QuickToolbar]} />
                        </RichTextEditorComponent>}

                    {editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL &&
                        <div dangerouslySetInnerHTML={{
                            __html: initialContent
                        }} />}


                    {editAndPreviewTemplate?.type === SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT && <DocViewer
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: true,
                            },
                            pdfVerticalScrollByDefault: true
                        }}
                        documents={docs}
                        activeDocument={docs[0]}
                        pluginRenderers={DocViewerRenderers}
                        theme={{
                            disableThemeScrollbar: true,
                        }}
                        className={classes.documentComponent}
                    />}
                    {editAndPreviewTemplate?.type ===
                        SYSTEM_MAINTENANCE_CONSTANTS?.EDIT_DOCUMENT && (
                            <DocumentEditorContainer
                                ref={(scope) => {
                                    if (!container?.current) {
                                        container.current = scope;
                                        container?.current?.documentEditor?.open(template)
                                    }
                                }}
                            />
                        )}
                </DialogContent>
            </Dialog>
            <Popover
                id={mergeFieldsId}
                open={openMergeFields}
                anchorEl={mergeFieldsAnchorEl}
                onClose={() => {
                    setMergeFieldsAnchorEl(null)
                    setSearchText("")
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: { xs: '100%', sm: 360 } }}>
                    <Box p={2}>
                        <Paper
                            component="form"
                            sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <IconButton color='primary' type="button" sx={{ p: '5px' }} aria-label="search">
                                <Search />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={t("general.search") + "..."}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </Paper>
                    </Box>
                    <List dense>
                        {filteredMergedFields.map((item, index) => (
                            <Box key={index}>

                                <ListItemButton onClick={() => handleMergedFieldCollapse(index)}>
                                    <ListItem>
                                        <ListItemText primary={item.text} />
                                        {item.children.length > 0 && (
                                            <IconButton>
                                                {openMergedFields[index] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        )}
                                    </ListItem>
                                </ListItemButton>
                                <Collapse in={openMergedFields[index]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding dense>
                                        {item.children.map((child, childIndex) => (
                                            <ListItemButton key={childIndex} sx={{ pl: 5 }}>
                                                <ListItemText primary={child.text} />
                                                <IconButton onClick={child?.icon?.onClick} aria-label="copy">
                                                    {child.icon}
                                                </IconButton>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>
            </Popover>

            <Popover
                id={buttonsPopUpId}
                open={openButtonsPopUp}
                anchorEl={buttonsAnchorEl}
                onClose={() => setButtonsAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: 190 }}>

                    <List dense >
                        {buttons?.map((item, index) => {
                            return <>
                                <ListItemButton key={index} onClick={item?.onClick} sx={{ py: 0 }}>
                                    <ListItemIcon >
                                        <IconButton aria-label="icon">
                                            {item?.icon}
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText primary={item?.text} />
                                </ListItemButton>
                            </>
                        })}
                    </List>
                </Box>
            </Popover>
        </>
    )
}

export default EditAndPreview

const useStyles = makeStyles((theme) => ({
    documentComponent: {
        '& div:nth-last-child(1)': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400]
            },
        },
        '& #pdf-controls': {
            background: theme.palette.common.white,
            zIndex: 3
        }
    },
}));