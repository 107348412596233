import React from 'react'
import { Close } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { taskAutomationActions } from '../../../_actions'
import { useTranslation } from 'react-i18next'

const MainHeader = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    return (
        <Stack
            direction='column'
            justifyContent={"space-between"}
        >
            <Stack direction="row" sx={{
                justifyContent: 'space-between',
                width: "100%",
                px: 2.5,
                py: 1,
                alignItems: "center"
            }} columnGap={1}>
                <Typography variant='h6'>{t('taskAutomation.generateTask')}</Typography>
                <IconButton onClick={() => {
                    dispatch(taskAutomationActions.toggleTaskDrawer(false))
                    dispatch(taskAutomationActions.clearDrawerInfo())
                }}>
                    <Close />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default MainHeader
