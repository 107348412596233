import axios from 'axios';
// Store cancel tokens for each request
const cancelTokenSources = new Map();
export const createCancelableRequest = (requestFunction, requestId, ...params) => {
  // Check if there's a previous request with the same requestId and cancel it
  if (cancelTokenSources.has(requestId)) {
    const previousCancelToken = cancelTokenSources.get(requestId);
    previousCancelToken.cancel('Previous request canceled due to a new request.');
    cancelTokenSources.delete(requestId); // Clean up the old cancel token
  }
  // Create a new cancel token for the current request
  const cancelTokenSource = axios.CancelToken.source();
  cancelTokenSources.set(requestId, cancelTokenSource);
  try {
    return requestFunction(requestId, ...params, { cancelToken: cancelTokenSource.token });
  } catch (error) {
    console.error(`Request failed for ${requestId}:`, error);
  }
};