import { Stack } from '@mui/material';
import TemplateCreationDetails from './TemplateDetails';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { breadcrumbAction, tabNavigationAction, templateDetailsActions } from '../../../_actions';
import Loader from '../../ContactDetails/Components/Loader';
import { getTemplateDetailsNavigationItem } from './util';
import { useTranslation } from 'react-i18next';
 

const TemplateDetailsWrapper = () => {
  const { loadingTemplateDetails, templateDetails } = useSelector(state => state.systemMaintenance.templateDetailsStore)
  const { templateId  } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch()

  useEffect(() => {
    if (templateId) {
      dispatch(templateDetailsActions.fetchTemplateDetails(templateId))
    }
  }, [dispatch, templateId])

    useEffect(() => {
        const templateDetailsNavigation = getTemplateDetailsNavigationItem(t)
        dispatch(tabNavigationAction.setNavigationTab(templateDetailsNavigation.tabs))
        dispatch(breadcrumbAction.setBreadcrumbItem(templateDetailsNavigation.breadCrumbs))
      }, [dispatch,t,templateDetails])
  

  return <Stack  height={"100%"} >
    {loadingTemplateDetails && <Loader />}
    {!loadingTemplateDetails && <TemplateCreationDetails />}
  </Stack>
}

export default TemplateDetailsWrapper
