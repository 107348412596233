import { ContentCopy, FilterAlt } from "@mui/icons-material";
import { getDataTableTextLocale } from "./textHelper";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid-pro";
import { getLocalStorageItem, setLocalStorageItem } from "./storageHelper";
import { applyClientSideCellFilter, applyClientSideCellSort } from "../components/DataGridV2/utils";

export const getGridContextMenuList = (args) => {
  const { handleCellSort, handleCellFilter, filterKey, i18n, t, apiRef, getValueForFilter = () => { }, sortKey,
    getValueForCopy = (rowData, extraData) => {
      navigator.clipboard.writeText(rowData[extraData.fieldName])
    }, mode="server" } = args || {}
  const sortModel =  getLocalStorageItem(sortKey, undefined)
    
  return (innerArgs) => {
    const { sortable = true, filterable = true, copy = true, field , isQuickFilterDisplay = true} = innerArgs || {};
    const locale = getDataTableTextLocale(i18n.language);

    // Reusable onClick handler function for sorting
    const handleSortClick = ({ fieldName, sortDirection }) => {
      if (mode === 'client') {
        const sortModel = { field: fieldName, sort: sortDirection }
        applyClientSideCellSort({ apiRef, sortModel });
      } else {
        handleCellSort && handleCellSort(sortDirection !== "unsort" ? { field: fieldName, sort: sortDirection } : undefined, (newSortModel) => {
          setLocalStorageItem(
            sortKey,
            newSortModel
          );
        });
      }
    };

    const ascSortItem = {
      label: locale.columnMenuSortAsc,
      icon: <GridArrowUpwardIcon fontSize="small" />,
      onClick: (record, extraData) => {
        handleSortClick({fieldName:extraData.fieldName, sortDirection:"asc"});
      },
    }

    const dscSortItem = {
      label: locale.columnMenuSortDesc,
      icon: <GridArrowDownwardIcon fontSize="small" />,
      onClick: (record, extraData) => {
        handleSortClick({fieldName: extraData.fieldName, sortDirection:"desc"});
      },
    }

    const unSortItem = {
      label: locale.columnMenuUnsort,
      onClick: (record, extraData) => {
        handleSortClick({ sortDirection: "unsort"});
      },
    }

    let sortOptions = []

    if (sortable) {
      if (sortModel?.[0]?.field === field) {
        if (sortModel?.[0]?.sort === "asc") {
          sortOptions = [dscSortItem, { ...unSortItem, isDividerAfter: true }]
        } else {
          sortOptions = [ascSortItem, { ...unSortItem, isDividerAfter: true }]
        }
      } else {
        sortOptions = [ascSortItem, { ...dscSortItem, isDividerAfter: true }]
      }
    }

    let filterOptions = []

    if(filterable){
      filterOptions.push({
        label: locale.columnMenuFilter,
        icon: <FilterAlt />,
        isDividerAfter: true,
        onClick: (_, extraData) => {
          apiRef.current.showFilterPanel(extraData.fieldName);
        },
      })

      if(isQuickFilterDisplay){
        filterOptions.push({
          label: t("general.quickFilter"),
          icon: <FilterAlt />,
          onClick: (data, extraData) => {
            let quickFilters = []
            const addFilter = ({ value, fieldId, type }) => quickFilters.push({ fieldId: fieldId || extraData.fieldName, value, operator: Array.isArray(value) && value.length > 1 ? "isAnyOf" : undefined, type: type || extraData.type })
    
            const value = getValueForFilter(data, extraData) || data?.[extraData?.fieldName]
            addFilter({ value })
            if(mode === "client"){
              applyClientSideCellFilter({
                filterKey,
                apiRef, data : quickFilters
              });
            }else{
              handleCellFilter?.(quickFilters, (filterModal) => {
                setLocalStorageItem(
                  filterKey,
                  filterModal
                );
              })
            }
          },
        })
      }
    }

    const copyOption = copy ? [{
      label: t("general.copy"),
      icon: <ContentCopy fontSize="small" />,
      onClick: (...args) => {
        const [data, extraData] = args || {}
        const text = getValueForCopy(...args) || data?.[extraData?.fieldName];
        navigator.clipboard.writeText(text || "")
      }
    },] : [];

    return [...sortOptions, ...filterOptions, ...copyOption];
  }

};

// Function to get columns order from local storage and adjust the columns accordingly
export const getColumnsOrderFromLocalStorage = ({ columns, localStorageKey, mappingOfOldFieldToNewField = {} }) => {
  const localColumnOrder = getLocalStorageItem(localStorageKey, [])?.filter((columnName) => !!columnName);

  if (localColumnOrder.length > 0) {    
    // Step 1: Get newly added columns (columns that are in initialColumns but not in localColumnOrder)
    const newlyAddedColumns = getNewlyAddedColumnsFieldName(columns.map((column) => column.field), localColumnOrder);
    
    // Step 2: Map columns to their new order based on localColumnOrder
    const setInOrder = localColumnOrder.map(columnKey => 
      columns.find(column => column.field === (mappingOfOldFieldToNewField[columnKey] || columnKey))
    ).filter((column) => !!column);

    // Step 3: Create a new ordered columns array by adding the columns in their proper position
    const orderedColumns = [];

    // Insert columns from localColumnOrder into orderedColumns in the correct order
    setInOrder.forEach((column) => orderedColumns.push(column));

    // Specific case for location: Insert 'locationIcon' before 'location' if 'location' has changed its position
    const locationIndex = orderedColumns.findIndex(column => column.field === 'location');

    // If 'location' is found and 'locationIcon' is not yet inserted
    if (locationIndex !== -1 && !orderedColumns.some(column => column.field === 'locationIcon')) {
      const locationIconColumn = columns.find(col => col.field === 'locationIcon');
      if (locationIconColumn) {
        // Insert 'locationIcon' before 'location'
        orderedColumns.splice(locationIndex, 0, locationIconColumn);
      }
    } else {
      // Step 4: Insert newly added columns at their correct position
      Object.entries(newlyAddedColumns).forEach(([index, fieldName]) => {
        const column = columns.find(col => col.field === fieldName);
        if (column) {
          orderedColumns.splice(index, 0, column);
        }
      });
    }
    setLocalStorageItem(localStorageKey, orderedColumns.map((column) => column.field));
    return orderedColumns;
  }
  return columns;
};

const getNewlyAddedColumnsFieldName = (initialColumns, localColumnOrder) => {
  let newlyAddedColumns = {};

  for (let index = 0; index < initialColumns.length; index++) {
    const initialColumn = initialColumns[index];
    if (!localColumnOrder?.includes(initialColumn)) {
      newlyAddedColumns = { ...newlyAddedColumns, [index]: initialColumn };
    }
  }

  return newlyAddedColumns;
};