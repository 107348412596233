import React, { useCallback, useEffect, useState } from 'react'
import { Cancel, Close, Save } from '@mui/icons-material'
import { AppBar, Button, Dialog, DialogContent, IconButton, Paper, Stack, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import GeneralSettings from './GeneralSettings';
import TemplatesSetting from './TemplatesSetting';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions, toastActions } from '../../../_actions';
import { updateTaskAutomation } from '../../../apis/systemMaintenanceApis';
import Loader from '../../../components/Loader'
import { AutomationForDays } from '../../../_helpers';
import { areArraysEqual } from '../../../_helpers/areArrayEqual';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import EditDialogTabNavigation from '../TaskAutomationDetails/TabNavigation';
import TechInfo from '../../../components/TechInfo';
import EllipsisTypography from '../../../components/EllipsisTypography';

const EditTaskDetailsDialog = ({ dialogStatus , handleDialog }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const {taskAutomationId} = useParams()
    const { taskDetails } = useSelector(state => state.systemMaintenance.taskDetailsStore)
    const [loading, setLoading] = useState(false)
    const [tabValue, setTabValue] = useState(dialogStatus?.tabId);
    const [values, setValues] = useState(() => taskDetails)
    const [errors, setErrors] = useState({})
    const [activeCheckboxState, setActiveCheckboxState] = useState({
        isDisabled: true,
        isChecked: values.active
    });
    
    const isMobileOrTab = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    useEffect(() => {
        const validateTaskAutomationDetailsValues = () => {
            const { objects, startDate, name, endDate, dateField, daysBeforeAfter, days, emailTemplate, timeOfDay } = values || {};

            if (Object.keys(errors).length > 0) {
                return false
            }

            // Check for necessary values
            if (!objects?.length || !startDate || !name.trim() || !endDate || !dateField?.toString() || !daysBeforeAfter?.toString() || !emailTemplate?.subject?.trim() || !timeOfDay) {
                return false;
            }

            // Check if 'days' is required for certain 'daysBeforeAfter' values
            if ((daysBeforeAfter?.toString() === AutomationForDays.After?.toString() || daysBeforeAfter?.toString() === AutomationForDays.Before?.toString()) && !days) {
                return false;
            }

            return true;
        };

        if (validateTaskAutomationDetailsValues()) {
            setActiveCheckboxState({
                ...activeCheckboxState,
                isDisabled: false
            })
        } else {
            setActiveCheckboxState({
                ...activeCheckboxState,
                isDisabled: true
            })
        }
    }, [values])


    const handleChange = ({ keyName, value, extraData }) => {
        switch (keyName) {

            case "dateField":
                if (value) {
                    setValues({ ...values, [keyName]: extraData })
                } else {
                    setValues({ ...values, [keyName]: null })
                }
                break;

            case "emailTemplate.subject":
                setValues({ ...values, emailTemplate: { ...values?.emailTemplate, subject: value } })
                break;

            case "daysBeforeAfter":
                if (value) {
                    setValues({ ...values, ...(value.value ===AutomationForDays.On ? {days:undefined}:{} ) ,[keyName]: extraData })
                } else {
                    setValues({ ...values, [keyName]: null })
                }
                break;

            default:
                setValues({ ...values, [keyName]: value })
                break;
        }
    };

    const handleTabChange = (value) => {
        setTabValue(value)
    }

    const updateTaskAutomationDetails = async (data) => {
        try {
            setLoading(true)
            await updateTaskAutomation(taskAutomationId, data)
            dispatch(toastActions.success(t("taskAutomation.detailsUpdated")))
        } catch (error) {
            dispatch(toastActions.error(t("general.failedToUpdate")))
        }
        finally {
            setLoading(false)
            handleDialog("")
            dispatch(taskDetailsActions.fetchAutomationDetails(taskAutomationId))
        }
    }

    const clearError = (names) => {
        let results = {}
        const namesArray = Array.isArray(names) ? names : [names]
        const newErrors = {...errors}
        for (let i = 0; i < namesArray.length; i++) {
            const name = namesArray[i];
            const nameParts = name.split('.');
            const key = nameParts[0];
            const nestedKey = nameParts.slice(1).join('.');
                if (nestedKey) {
                    delete newErrors[key]?.[nestedKey];
                    if (Object.keys(newErrors[key] || {}).length === 0) {
                        delete newErrors[key];
                    }
                    results= {...results , ...newErrors};
                } else {
                    if(newErrors.key){
                        delete newErrors[key];
                        results ={...results, ...newErrors};
                    }
                }
        }
        setErrors(results)
    };
    const validateTaskAutomationDetails = () => {
        const newErrors = {};

        // General setting field validation
        const { objects, name, startDate, endDate, dateField, daysBeforeAfter, days, emailTemplate, timeOfDay } = values || {};

        if (objects?.length === 0) {
            newErrors.objects = t("general.field.error");
        }
        if (!name?.trim()) {
            newErrors.name = t("general.field.error");
        }
        if (!startDate) {
            newErrors.startDate = t("general.field.error");
        }
        if (!endDate) {
            newErrors.endDate = t("general.field.error");
        }
        if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
            newErrors.startDate = newErrors.endDate = t("general.field.error");
        }

        // Dynamic setting fields validation
        if (!dateField?.toString()) {
            newErrors.dateField = t("general.field.error");
        }
        if (!daysBeforeAfter?.toString()) {
            newErrors.daysBeforeAfter = t("general.field.error");
        }else if(Number(daysBeforeAfter) !== AutomationForDays.On && !days){
            newErrors.days = t("general.field.error");
        }

        const splittedTimeOfDay = timeOfDay?.split(":");
        if (!timeOfDay || (splittedTimeOfDay?.[0] === "23" && splittedTimeOfDay?.[1]>45)) {
            newErrors.timeOfDay = t("general.field.error");
        }

        // Email template setting fields validation
        if (!emailTemplate?.subject?.trim()) {
            newErrors.emailTemplate = { subject: t("general.field.error") };
        }
        // Update errors in state
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors,
        }));

        return{isValid: Object.keys(errors).length === 0 , errors: {...errors ,...newErrors}};
    };

    const handleSave = () => {
        const validateFields = validateTaskAutomationDetails()
        if (validateFields.isValid && Object.keys(validateFields.errors).length === 0) {
            const diff = []
            Object.keys(values).map((keyName) => {
                switch (keyName) {
                    case "objects":
                        if (!areArraysEqual(values?.[keyName], taskDetails?.[keyName])) {
                            diff.push({ id: "objectids", name: values?.[keyName]?.map((o) => o.id)?.join(",") })
                        }
                        break;

                    case "emailTemplate":
                        if (values?.emailTemplate?.subject != taskDetails?.emailTemplate?.subject) {
                            diff.push({ id: "emailsubject", name: values?.emailTemplate?.subject })
                        }
                        break;

                    case "generalAttachment":
                        if (values?.[keyName]?.name !== taskDetails?.[keyName]?.name && values?.[keyName]?.content !== taskDetails?.[keyName]?.content) {
                            diff.push({ id: "attachmentfilename", name: values?.[keyName].name })
                            diff.push({ id: "attachmentfilecontent", name: values?.[keyName].content })
                        }
                        break;
                    case "active":
                        if (activeCheckboxState.isChecked !== taskDetails?.active) {
                            diff.push({ id: "active", name: activeCheckboxState.isChecked.toString() })
                        }
                        break;

                    default: {
                        if (values?.[keyName] != taskDetails?.[keyName]) {
                            diff.push({ id: keyName == "cc" ? "emailcc" : keyName === "bcc" ? "emailbcc" : keyName, name: values?.[keyName] })
                        }
                        break;
                    }
                }
            })
           if( diff.length > 0) {
             updateTaskAutomationDetails(diff)
            }
           else{
            handleDialog("")
           }
        }else{
            if(["emailTemplate", "cc", "bcc"].some((key) => Object.keys(validateFields.errors).includes(key))) {
                setTabValue(1)
            }else{
                setTabValue(0) 
            }
        }
    }
    

    return (
        <>
            <Dialog
                open={dialogStatus?.open}
                onClose={(event, reason) => reason === 'backdropClick' ? event.stopPropagation() : handleDialog("")} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: "none",
                        width: 1200,
                        height: { md: 650 }
                    }
                }}
                fullScreen={isMobileOrTab} >
                <AppBar position='relative' sx={{ boxShadow: 'none' }} >
                    <Toolbar >
                        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => handleDialog("")}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                  <EllipsisTypography variant={"h6"} sx={{ml:2}}  component="div" title={t('taskAutomation.title')} />
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Button onClick={() => handleDialog("")} size='small'  sx={{ display: {xs: 'none',sm:"inline-flex"} }} variant="text" color='inherit' startIcon={<Cancel />} >
                                    { t("general.cancel")}
                                </Button>
                                <IconButton onClick={() => handleDialog("")} sx={{ display: {sm:"none"} }} color='inherit'  >
                                   <Cancel/>
                                </IconButton>
                                <Button onClick={handleSave} sx={{ display: {xs: 'none',sm:"inline-flex"} }} size='small' variant="text" color='inherit' startIcon={<Save />}>
                                    { t("general.save")}
                                </Button>
                                <IconButton onClick={handleSave} sx={{ display: {sm:"none"} }} color='inherit'  >
                                   <Save/>
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <EditDialogTabNavigation tabValue={tabValue} onChangeTab={handleTabChange} />
                <DialogContent sx={{ bgcolor: grey[100], mt: 0.3, p: 2 }}>
                    <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    {loading && <Loader backdrop />}
                    {tabValue === 0 && <GeneralSettings handleChange={handleChange} values={values} errors={errors} clearError={clearError} activeCheckboxState={activeCheckboxState} setActiveCheckboxState={setActiveCheckboxState} />}
                    {tabValue === 1 && <TemplatesSetting values={values} handleChange={handleChange} errors={errors} setErrors={setErrors} clearError={clearError} />}
                    {tabValue === 2 && <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} details={values} />}
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditTaskDetailsDialog
