import React, { forwardRef } from "react";
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";
 
const DocumentEditorContainer = forwardRef(
  (
    {
      id = "container",
      serviceUrl = "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/",
      enableToolbar = true,
      height = "100%",
    },
    ref
  ) => {
    return (
      <DocumentEditorContainerComponent
        id={id}
        ref={ref}
        serviceUrl={serviceUrl}
        enableToolbar={enableToolbar}
        height={height}
      />
    );
  }
);
 
export default DocumentEditorContainer;