export function areArraysEqual(arr1, arr2) {
    // First check if the lengths of both arrays are the same
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Now, check if each object in arr1 is equal to the corresponding object in arr2
    for (let i = 0; i < arr1.length; i++) {
        if (!areObjectsEqual(arr1[i], arr2[i])) {
            return false;
        }
    }

    return true;
}

function areObjectsEqual(obj1, obj2) {
    // Check if both objects are deeply equal (same keys and values)
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, the objects are not equal
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check each key and its corresponding value
    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}