import { amber, green, grey } from "@mui/material/colors";

export const isReadOnly = (status) => status !== 1;

export const SurveyType = {
	PreDelivery: 0,
	Delivery: 1,
	Inspection: 2,
	SecondSignature: 3,
	WKB: 4,
}

export const  AutomationDateField = 
{
	PreDelivery : 0,
	Delivery : 1,
	SecondSignature : 2,
	EndOfMaintenancePeriod : 3
}


export const AutomationForDays = {

	On : 0,
	Before : 1,
	After : 2,
}

export const CommunicationMethods = {
	Correspondence: 0,
	Email: 1,
	TelephoneCall: 2,
	Conversation: 3,
	Visit: 4,
	Meeting: 5,
	Upload: 6,
	Others: 7,
  };

  export const CommunicationTypes = {
	Incoming: 0,
	Outgoing: 1,
	NotApplicable: 2,
  };

  export const CommunicationStatus = {
	Draft: 0,
	Sent: 1,
	Registered: 2,
  };

  export const CommunicationContactAddressee = {
	Organisation: 0,
	Relation: 1,
	Person: 2,
	Buyer: 3,
	Building: 4,
  };
  
  export const TemplateTypes = {
	Correspondence: 0,
	Email: 1,
	Document: 2,
  };
  
export const AgendaAppointmentParticipantStatus = {
	/// <summary>
	/// uitgenodigd
	/// </summary>
	Invited: 0,
	/// <summary>
	/// bevestigd
	/// </summary>
	Confirmed: 1,
	/// <summary>
	/// geweigerd
	/// </summary>
	Refused: 2,
	/// <summary>
	/// geannuleerd
	/// </summary>
	Cancelled: 3
}

export const RepairRequestCarryTypes = {
	0: "Voorschouw",
	1: "Opleverpunt",
	2: "Inspectie",
	3: "Overige",
	4: "Wkb",
}

export const ModeOfSigningKeys = {
	PrintAndSign: 10,
	DigitalSign:20,
	DigitalSignWithIdin:30
}

export const ContractStatusKeys = {
	New: 0,
	InProgress : 1,
	WaitingForSign:2,
	Completed:3,
	Rejected:4
}

export const LoginRequestStatusKeys = {
	New: 0,
	InProgress: 1,
	Approved: 2,
	Rejected: 3
}

export const SurveyStatus = {
	0: "survey.status.0",
	1: "survey.status.1",
	2: "survey.status.2",
	3: "survey.status.3"
};

export const internalSurveyingMomentStatus = {
	0: "wkb.surveyingMoment.internalStatus.0",
	1: "wkb.surveyingMoment.internalStatus.1",
	2: "wkb.surveyingMoment.internalStatus.2",
	3: "wkb.surveyingMoment.internalStatus.3"
};

export const internalAssignmentStatus = {
	0: "wkb.assignment.internalStatus.0",
	1: "wkb.assignment.internalStatus.1",
	2: "wkb.assignment.internalStatus.2",
	3: "wkb.assignment.internalStatus.3"
}

export const ModulesConstants = {
	0: "module.0",
	1: "module.1",
	2: "module.2",
	3: "module.3",
	4: "module.4"
};

export const tabsViewTypes = {
	0: "day",
	1: "week",
	2: "month",
	3: "agenda",
};

export const viewTypes = {
	day: 0,
	week: 1,
	month: 2,
	agenda: 3,
};

export const wbMomentStatus = {
	notApproved: 'Niet akkoord',
	approved: 'akkoord',
	notApplicable: 'N.v.t.'
};

export const isCompletedSurvey = (status) => (status === 2 || status === 3);
export const isCompleteAssignment = (status) => (status === 1 || status === 2 || status === 3);
export const getWBStatus = ({ wbStatus, completedAssignments, totalAssignments }) => {
	if (wbStatus) return wbMomentStatus.approved;
	if (completedAssignments !== totalAssignments) return wbMomentStatus.notApplicable;
	if (!wbStatus) return wbMomentStatus.notApproved;
};

export const WKB_API_INTERVAL_TIME = 1000 * 60;


export const statusColors = {
	"In behandeling": "#FA6B00",
	"Nieuw": "#3F51B5",
	"Afgehandeld": "#008135",
}

export const resolverStatus = {
	New: 0,
	Informed: 1,
	Pending: 2,
	TurnedDown: 3,
	Completed: 4,
  };

	export const repairRequestStatusValue = {
		NEW:"Nieuw",
		IN_PROGRESS:"In behandeling",
		COMPLETED : "Afgehandeld",
		TURNED_DOWN :"Afgewezen"
	}

	export const buildingManagerRole = {
		0:"Objectgebruiker",
		1:"Opdrachtgever",
		2:"Vve",
		3:"Vve beheerder",
		4:"vastgoedbeheerder",
		5:"Medewerker",
		6:"Overige",
	}

	export const repairRequestReceivedVia = {
		0:"Telefoon",
		1:"Email",
		2:"Website",
		3:"App",
		4:"Brief",
		5:"Formulier",
		6:"Fax",
		7:"Medewerker",
	}



export const chatColor =
{
	/// <summary>
	/// Buyer/Renter/PropertyManager Color White
	/// </summary>
	0: { main: "", hover: "" },//Empty string means use default MUI standard colors
	/// <summary>
	/// SubContractor Color Green
	/// </summary>
	1: { main: green[50], hover: green[100] },//Empty string means use default MUI standard colors
	/// <summary>
	/// Internal Color Yellow
	/// </summary>
	2: { main: amber[50], hover: amber[100] },//Empty string means use default MUI standard colors
}