import { SUBMENU } from "../_constants";

const initialState = {
    anchorEl: null,
    open: false,
    menuItems: [],
};

export const subMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMENU.OPEN: {
            return {
                ...state,
                open: true,
                anchorEl: action.payload.anchorEl,
                menuItems: action.payload.subMenuList
            };
        }
        case SUBMENU.CLEAR: {
            return initialState;
        }

        default:
            return state;
    }
}
