import { getDocumentTemplates, getEmailTemplates, getTaskList } from "../apis/systemMaintenanceApis";
import { TASK_AUTOMATION} from '../_constants'
import { formatDate } from "../_helpers";

const setTaskProjectInfo = (data) => {
    return {
        type: TASK_AUTOMATION.SET_PROJECT_INFO,
        data
    };
}

const setNewTaskName = (data) => {
    return {
        type: TASK_AUTOMATION.SET_TASK_NAME,
        data
    };
}

const toggleTaskDrawer = (data) => {
    return {
        type: TASK_AUTOMATION.TOGGLE_TASK_DRAWER,
        data
    };
}

const clearTaskStore = () => {
    return {
        type: TASK_AUTOMATION.CLEAR,
    };
}

const clearDrawerInfo = () => {
    return {
        type: TASK_AUTOMATION.CLEAR_DRAWER_INFO,
    };
}

const fetchEmailTemplates = (projectId) => {

    return async (dispatch) => {
        dispatch({ type: TASK_AUTOMATION.SET_LOADING_EMAIL_TEMPLATES, data: true });

        try {
            const res = await getEmailTemplates(projectId)
            dispatch({
                type: TASK_AUTOMATION.SET_EMAILS_TEMPLATES_DATA, data: {
                    loading: false,
                    templates: res.data, selectedTemplate: undefined
                }
            });
        } catch (e) {
            dispatch({ type: TASK_AUTOMATION.SET_LOADING_EMAIL_TEMPLATES, data: false });
        }

    }

}
const fetchDocumentTemplates = (projectId) => {

    return async (dispatch) => {
        dispatch({ type: TASK_AUTOMATION.SET_LOADING_DOCUMENT_TEMPLATES, data: true });

        try {
            const res = await getDocumentTemplates(projectId)
            dispatch({
                type: TASK_AUTOMATION.SET_DOCUMENT_TEMPLATES_DATA, data: {
                    loading: false,
                    templates: res.data, selectedTemplate: undefined
                }
            });
        } catch (e) {
            dispatch({ type: TASK_AUTOMATION.SET_LOADING_DOCUMENT_TEMPLATES, data: false });
        }

    }

}

const selectEmailTemplates = (templateId) => {
    return {
        type: TASK_AUTOMATION.SELECT_EMAIL_TEMPLATES,
        data: templateId
    }
}

const setAttachmentFileName = (value) => {
    return {
        type: TASK_AUTOMATION.SET_ATTACHMENT_FILE_NAME,
        data: value
    }
}

const setAttachmentFileContent = (value) => {
    return {
        type: TASK_AUTOMATION.SET_ATTACHMENT_FILE_CONTENT,
        data: value
    }
}

const selectDocumentTemplates = (templateId) => {
    return {
        type: TASK_AUTOMATION.SELECT_DOCUMENT_TEMPLATES,
        data: templateId
    }
}

const fetchTaskLists = (t, app) => {

    return async (dispatch) => {
        dispatch({ type: TASK_AUTOMATION.SET_TASK_LIST_LOADING, data: true });
        try {
            const res = await getTaskList(app)
            const resData = res?.data || [];
            const data = resData.map(({ active, createdOn, id, name, number, owner, startDate, endDate }) => ({
                id,
                taskNumber: number,
                taskName: name,
                owner: owner,
                startDate: startDate,
                endDate: endDate,
                createdOn: createdOn,
                active: active,
                searchField: `${formatDate(new Date(startDate))} ${formatDate(new Date(endDate))} ${formatDate(new Date(createdOn))} ${active ? t("general.yes") : t("general.no")}`
            }));  
            dispatch({
                type: TASK_AUTOMATION.SET_TASK_LIST, data: {
                    loading: false,
                    data
                }
            });
        } catch (e) {
            dispatch({ type: TASK_AUTOMATION.SET_TASK_LIST_LOADING, data: false });
        }

    }

}

export const taskAutomationActions = {
    setTaskProjectInfo,
    setNewTaskName,
    clearTaskStore,
    fetchEmailTemplates,
    toggleTaskDrawer,
    selectEmailTemplates,
    selectDocumentTemplates,
    fetchDocumentTemplates,
    setAttachmentFileName,
    setAttachmentFileContent,
    clearDrawerInfo,
    fetchTaskLists
}