import {  Clear,} from '@mui/icons-material';
import {  Box,Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getBanks, getIndustries } from '../../../apis/profileApis';
import { deNormalizePhoneNumber,  } from '../utils';

const OrganizationProfileBody = ({organisationData , updateOrganisationData, errors, clearError}) => {
  const { t } = useTranslation();

  const [dropdownValues, setDropdownValues] = useState({})
 

  useEffect(() => {
    (async ()=>{
      const calls = [getIndustries(), getBanks()]
      const results = await Promise.all(calls);
      setDropdownValues({industries: results[0].data,banks:results[1].data})
    })()
  }, [])

  const formatAddress = (addressObject) => {
    const { houseNo = "", houseNoAddition = "", place = "", postcode = "", street = "" } = addressObject ?? {}
    let address = ""
    if (street) address += `${street} `
    if (houseNo) address += `${houseNo} `
    if (houseNoAddition) address += `${houseNoAddition} `
    if (postcode) address += `, ${postcode}`
    if (place) address += `${place}`
    return address
  }
  return (<>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.organisation")}</Typography>
        <Typography variant='caption'>{t("general.general")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.searchName")}
        size='small'
        type="text"
        value={organisationData?.searchName}
        onChange={(e)=>{
          updateOrganisationData({searchName: e.target.value }) 
          clearError("searchName")
      }}
        error={errors?.searchName}
        helperText={errors?.searchName}
        slotProps={{
          htmlInput: { maxLength: 150 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.name")}
        size='small'
        type="text"
        value={organisationData?.name}
        onChange={(e)=>{
          updateOrganisationData({name: e.target.value})
          clearError("name")
        }}
        error={errors?.name}
        helperText={errors?.name}
        slotProps={{
          htmlInput: { maxLength: 100 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.namePart")}
        size='small'
        type="text"
        value={organisationData?.namePart}
        onChange={(e)=>{updateOrganisationData({namePart: e.target.value})}}
        slotProps={{
          htmlInput: { maxLength: 100 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.address")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.mailingAddress")}
        size='small'
        type="text"
        value={formatAddress(organisationData?.postalAddress)}
       
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <TextField
        fullWidth
        label={t("general.visitingAddress")}
        size='small'
        type="text"
        value={formatAddress(organisationData?.visitingAddress)}
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.communication")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.telephone")}
        size='small'
        type="text"
        value={deNormalizePhoneNumber(organisationData?.telephone)}
        onChange={(e)=>{updateOrganisationData({
          telephone: deNormalizePhoneNumber(e.target.value)})
          clearError("telephone")
        }}
        error={errors?.telephone}
        helperText={errors?.telephone}
        slotProps={{
          htmlInput: { maxLength: 10 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.email")}
        size='small'
        type="text"
        onChange={(e)=>{
          updateOrganisationData({email: e.target.value})
          clearError("email")
        }}
        value={organisationData?.email}
        error={errors?.email}
        helperText={errors?.email}
        slotProps={{
          htmlInput: { maxLength: 200 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.website")}
        size='small'
        type="text"
        onChange={(e)=>{
          updateOrganisationData({website: e.target.value})
          clearError("website")
        }}
        value={organisationData?.website}
        error={errors?.website}
        helperText={errors?.website}
        slotProps={{
          htmlInput: { maxLength: 200 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.selectionCriteria")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.status")}
        size='small'
        type="text"
        value={t(`organisation.status.${organisationData?.status}`)}
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.industry")}</InputLabel>
        <Select
          value={organisationData?.industry?.id}
          label={t("general.industry")}
          size='small'
          onChange={(e)=>{updateOrganisationData({industry: dropdownValues?.industries?.find((item)=>item?.id === e.target.value)})}}
          sx={{ "& .MuiSelect-iconOutlined": { display: organisationData?.industry?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: organisationData?.industry?.id ? "visible" : "hidden" }}
            onClick={(e) => {
                e.stopPropagation()
                updateOrganisationData({ industry: null })
            }}
          ><Clear /></IconButton>}
        >
            {dropdownValues?.industries?.map((item)=><MenuItem value={item?.id}>{item?.name}</MenuItem>)}
         
        </Select>
      </FormControl>
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.financial")}</Typography>
        <Typography variant='caption'></Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.bank")}</InputLabel>
        <Select
          value={organisationData?.bank?.id}
          label={t("general.bank")}
          size='small'
          onChange={(e)=>{updateOrganisationData({bank: dropdownValues?.banks?.find((item)=>item?.id === e.target.value)})}}
          sx={{ "& .MuiSelect-iconOutlined": { display: organisationData?.bank?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: organisationData?.bank?.id ? "visible" : "hidden" }}
            onClick={(e) => {
                e.stopPropagation()
                updateOrganisationData({ bank: null })
            }}
          ><Clear /></IconButton>}
        >
           {dropdownValues?.banks?.map((item)=><MenuItem value={item?.id}>{item?.name}</MenuItem>)}
        
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label={t("general.bic")}
        size='small'
        type="text"
        value={organisationData?.bic}
        onChange={(e)=>{updateOrganisationData({bic: e.target.value})}}
        slotProps={{
          htmlInput: { maxLength: 15 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.iban")}
        size='small'
        type="text"
        value={organisationData?.iban}
        onChange={(e)=>{updateOrganisationData({iban: e.target.value})}}
        slotProps={{
          htmlInput: { maxLength: 34 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.other")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.cocNumber")}
        size='small'
        type="text"
        value={organisationData?.kvKno}
        onChange={(e)=>{updateOrganisationData({kvKno: e.target.value})}}
        slotProps={{
          htmlInput: { maxLength: 8 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.vatNumber")}
        size='small'
        type="text"
        value={organisationData?.btWno}
        onChange={(e)=>{updateOrganisationData({btWno: e.target.value})}}
        slotProps={{
          htmlInput: { maxLength: 20 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
  </>);
}

export default OrganizationProfileBody