const key = "TASK_DETAILS"

export const TASK_DETAILS = {
        CLEAR: `${key}_CLEAR`,
        SET_LOADING_TASK_DETAILS: `${key}_SET_LOADING_TASK_DETAILS`,
        SET_TASK_DETAILS_DATA:`${key}_SET_TASK_DETAILS_DATA`,
        SET_LOADING_HISTORY_DETAILS: `${key}_SET_HISTORY_LOADING__DETAILS`,
        SET_TASK_HISTORY_DATA:`${key}_SET_HISTORY_TASK_DETAILS_DATA`,
        SET_DATA_TASK_ITEM_BY_STATUS:`${key}_SET_DATA_TASK_ITEM_BY_STATUS`,
        SET_LOADING_TASK_ITEM_BY_STATUS:`${key}_SET_LOADING_TASK_ITEM_BY_STATUS`
};
