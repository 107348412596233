import React from "react";
import { Tabs, Tab } from "@mui/material";

const TabNavigation = ({ activeTab, tabs, handleTabChange }) => {
  return (
    <Tabs
      value={activeTab >= 0 ? activeTab : 0}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      indicatorColor="primary"
      aria-label="scrollable tabs"
      sx={{
        "& .MuiTabs-scrollButtons.Mui-disabled": {
          display: "none",
        },
        boxShadow: 2,
        px: 2
      }}
    >
      {tabs?.map((tab, index) => (
        <Tab
          key={index}
          label={tab.title}
          sx={{
            textTransform: "none",
          }}
        />
      ))}
    </Tabs>
  );
};
export default TabNavigation;