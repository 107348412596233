import { store } from "../..";
import { apps } from "../../_constants";
import { CommunicationContactAddressee, CommunicationMethods, CommunicationStatus, CommunicationTypes } from "../../_helpers";

export const getCommunicationMethods = (t) => {
    return [
        { label: t("communication.method.0"), value: CommunicationMethods.Correspondence.toString() },
        { label: t("communication.method.1"), value: CommunicationMethods.Email.toString() },
        { label: t("communication.method.2"), value: CommunicationMethods.TelephoneCall.toString() },
        { label: t("communication.method.3"), value: CommunicationMethods.Conversation.toString() },
        { label: t("communication.method.4"), value: CommunicationMethods.Visit.toString() },
        { label: t("communication.method.5"), value: CommunicationMethods.Meeting.toString() },
        { label: t("communication.method.6"), value: CommunicationMethods.Upload.toString() },
        { label: t("communication.method.7"), value: CommunicationMethods.Others.toString() },
    ];
};

export const getCommunicationTypes = (t) => {
    return [
        { label: t("communication.type.0"), value: CommunicationTypes.Incoming.toString() },
        { label: t("communication.type.1"), value: CommunicationTypes.Outgoing.toString() },
        { label: t("communication.type.2"), value: CommunicationTypes.NotApplicable.toString() },
    ];
};

export const getCommunicationStatuses = (t) => {
    return [
        { label: t("communication.status.0"), value: CommunicationStatus.Draft.toString() },
        { label: t("communication.status.1"), value: CommunicationStatus.Sent.toString() },
        { label: t("communication.status.2"), value: CommunicationStatus.Registered.toString() },
    ];
};

export const getContactAddressees = (t) => {
    return [
        { label: t("communication.contact.addressee.0"), value: CommunicationContactAddressee.Organisation.toString() },
        { label: t("communication.contact.addressee.1"), value: CommunicationContactAddressee.Relation.toString() },
        { label: t("communication.contact.addressee.2"), value: CommunicationContactAddressee.Person.toString() },
        { label: t("communication.contact.addressee.3"), value: CommunicationContactAddressee.Buyer.toString() },
        { label: t("communication.contact.addressee.4"), value: CommunicationContactAddressee.Building.toString() },
    ];
};

export const getStatusColor = (communicationStatus, theme) => {
    let color = '';
    switch (communicationStatus) {
        case CommunicationStatus.Draft:
            color = theme.palette.text.primary;
            break;
        case CommunicationStatus.Sent:
            color = theme.palette.success.main;
            break;
        case CommunicationStatus.Registered:
            color = theme.palette.error.main;
            break;
        default:
            color = theme.palette.text.primary;
    }
    return color;
}

export const getCommunicationGridNavigationItem = ({ t }) => {
    const { app } = store.getState()
    const to = app === apps.huisinfo ? "/communicatie" : app === apps.aftercare ? "/nazorg/communicatie" : '#'
    return {
        breadCrumbs: [{ breadCrumbTitle: t('general.communication'), title: t('communication.list'), to }],
        tabs: [{ title: t('general.list'), to }]
    }
}

export const getCommunicationDetailsNavigationItem = ({ t, communicationDetails, communicationId }) => {
    const { app } = store.getState();
    const communicationGridNavigationItems = getCommunicationGridNavigationItem({ t })
    const breadCrumbTitle = communicationDetails?.ourFeature
    const to = app === apps.huisinfo ? "/communicatie/" + communicationId : app === apps.aftercare ? "/nazorg/communicatie/" + communicationId : '#'
    const contactTo = app === apps.huisinfo ? `/communicatie/${communicationId}/contact` : app === apps.aftercare ? `/nazorg/communicatie/${communicationId}/contact` : '#'
    const attachmentsTo = app === apps.huisinfo ? `/communicatie/${communicationId}/bijlage` : app === apps.aftercare ? `/nazorg/communicatie/${communicationId}/bijlage` : '#'
    return {
        breadCrumbs: [...communicationGridNavigationItems?.breadCrumbs,
        { title: t("general.communication") + " - " + communicationDetails?.ourFeature, breadCrumbTitle, to },
        ],
        tabs: [...communicationGridNavigationItems?.tabs, { title: t('general.form'), to },
        { title: t('general.contact'), to: contactTo },
        { title: t('general.attachments'), to: attachmentsTo }
        ]
    }
}

export const getContactGridNavigationItem = ({ t, communicationDetails = {}, communicationId }) => {
    const { app } = store.getState()
    const communicationDetailsNavigationItems = getCommunicationDetailsNavigationItem({ t, communicationId, communicationDetails })
    const breadCrumbTitle = t("general.contact")
    const to = app === apps.huisinfo ? `/communicatie/${communicationId}/contact` : app === apps.aftercare ? `/nazorg/communicatie/${communicationId}/contact` : '#'
    return {
        breadCrumbs: [...communicationDetailsNavigationItems?.breadCrumbs, { title: breadCrumbTitle, breadCrumbTitle, to }],
        tabs: [...communicationDetailsNavigationItems?.tabs]
    }
}

export const getContactDetailsNavigationItem = ({ t, communicationDetails, communicationId, contactId, contactDetails }) => {
    const { app } = store.getState();
    const contactGridNavigationItems = getContactGridNavigationItem({ t, communicationDetails, communicationId, contactId })
    const to = app === apps.huisinfo ? "/communicatie/" + communicationId : app === apps.aftercare ? "/nazorg/communicatie/" + communicationId : '#'
    return {
        breadCrumbs: [
            ...contactGridNavigationItems?.breadCrumbs,
            { title: t("general.contact") + " - " + contactDetails?.name, breadCrumbTitle: contactDetails.name, to },
        ],
        tabs: [...contactGridNavigationItems?.tabs]
    }
}

export const getAttachmentGridNavigationItem = ({ t, communicationDetails = {}, communicationId }) => {
    const { app } = store.getState()
    const communicationDetailsNavigationItems = getCommunicationDetailsNavigationItem({ t, communicationId, communicationDetails })
    const breadCrumbTitle = t("general.attachments")
    const to = app === apps.huisinfo ? `/communicatie/${communicationId}/bijlage` : app === apps.aftercare ? `/nazorg/communicatie/${communicationId}/bijlage` : '#'
    return {
        breadCrumbs: [...communicationDetailsNavigationItems?.breadCrumbs, { title: breadCrumbTitle, breadCrumbTitle, to }],
        tabs: [...communicationDetailsNavigationItems?.tabs]
    }
}

export const getAttachmentDetailsNavigationItem = ({ t, communicationDetails, communicationId, attachmentId, attachmentDetails }) => {
    const { app } = store.getState();
    const contactGridNavigationItems = getAttachmentGridNavigationItem({ t, communicationDetails, communicationId, attachmentId })
    const to = app === apps.huisinfo ? "/communicatie/" + communicationId : app === apps.aftercare ? "/nazorg/communicatie/" + communicationId : '#'
    const attachmentName = attachmentDetails?.attachmentName?.split('.')?.[0]
    return {
        breadCrumbs: [
            ...contactGridNavigationItems?.breadCrumbs,
            { title: t("general.attachments") + " - " + attachmentName, breadCrumbTitle: attachmentName, to },
        ],
        tabs: [...contactGridNavigationItems?.tabs]
    }
}
