import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Container,
  Icon,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import useGridDataModalHandler from "../../_hooks/useGridDataModalHandler";
import { apps, LOCAL_STORAGE_GRID_KEYS } from "../../_constants";
import DataGridV2 from "../../components/DataGridV2";
import { getWorkOrders } from "../../apis/aftercareApis";
import { formatDate, getLocalStorageItem, groupBy, history, resolverStatus, setLocalStorageItem } from "../../_helpers";
import { CheckCircleOutlineSharp, DashboardOutlined, WatchLaterSharp } from "@mui/icons-material";
import { getOrganizations, getRepairRequestLocations } from "../../apis/surveyingApis";
import AfterCareWorkOrderRow from "../Aftercare/AfterCareWorkOrderRow";
import { getWorkorderVisibleColumns } from "./workorderUtils";

export const AfterCareResolversGrid = (props) => {
  const classes = useStyles();
  const [optionsDataLoading, setOptionsDataLoading] = useState({
    location: false,
    carryOutAsType: false,
    statusList: false,
  });
  const [organizations, setOrganizations] = useState([]);
  const [relations, setRelations] = useState([]);
  const [locations, setLocations] = useState([]);
  const { t, i18n } = useTranslation();
  const app = useSelector((state) => state.app);
  const apiRef = useGridApiRef();
  const muiTableRef = useRef();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" : app === apps.resolverModule ? "RESOLVER" : app === apps.constructionQuality ? "CONSTRUCTION_QUALITY" : "GENERAL";
  const { selected, all: buildings } = useSelector(state => state.buildings);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const {
    loading,
    loadedRows,
    sortModel,
    handleSort,
    handleCellSort,
    filterModel,
    handleFilter,
    handleSearch,
    getAllRecords,
    getAllExportRecords,
    searchText,
    handleCellFilter,
    totalRecords,
    paginationModel,
    onPaginationModelChange,
  } = useGridDataModalHandler({
    additionalParams: { app },
    apiCall: getWorkOrders,
    rowIdentifier: "id",
    sortModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_SORT[keyPrefix], undefined
    ),
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix], undefined
    ),
    paginationModel: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_PAGINATION[keyPrefix], undefined
    ),
    searchText: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_SEARCH_TEXT[keyPrefix], ""
    ),
  });
  const projectIds = useMemo(
    () => groupBy(buildings, "projectId") || {},
    [buildings]
  );

  useEffect(() => {
    const ids = Object.keys(projectIds)
    if (ids.length > 0) {
      getFilterOptions()
    }
  }, [projectIds])


  useEffect(() => {
    const { location } = props;
    const newFilterModelItems = [];
    if (location.state && location.state.filter) {
      switch (location.state.filter) {
        case "newWorkOrder": {
          newFilterModelItems.push(
            {
              field: "resolverStatus",
              operator: "is",
              value: resolverStatus.New.toString()
            },
          )
          break;
        }
        case "openWorkOrders": {
          newFilterModelItems.push(
            {
              field: "resolverStatus",
              operator: "isAnyOf",
              value: [resolverStatus.New?.toString(), resolverStatus.Informed?.toString()]
            },
          )
          break;
        }
        case "inProgressWorkOrders": {
          newFilterModelItems.push(
            {
              field: "resolverStatus",
              operator: "is",
              value: resolverStatus.Informed?.toString()
            },
          )
          break;
        }
        case "overdueWorkOrders": {
          newFilterModelItems.push(
            {
              field: "overdue",
              operator: "is",
              value: "true",
            },
          )
          break;
        }
        case "building": {
          newFilterModelItems.push(
            {
              field: "buildingNoIntern",
              operator: "equals",
              value: location.state.selectedProject.buildingNoIntern,
            },
          )
          break;
        }
        default: { break }
      }
      if (location.state.selectedProject) {
        newFilterModelItems.push(
          {
            field: "projectId",
            operator: "is",
            value: location.state.selectedProject.projectId,
          },
        )
      }
      if (location.state.contractor && app !== apps.resolverModule) {
        newFilterModelItems.push(
          {
            field: "organisatonId",
            operator: "is",
            value: location.state.contractor.organisatonId,
          },
        )
      }
    }
    handleFilter(newFilterModelItems.length > 0 ? {
      items: newFilterModelItems,
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    } : filterModel ?? {
      "items": [],
      "logicOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    })
  }, [props?.location?.state]);



  const getFilterOptions = async () => {
    try {
      setOptionsDataLoading({
        location: true,
        organization: true,
      });
      const ids = Object.keys(projectIds)
     let organizations, locationRes;
      if (app !== apps.resolverModule) {
        organizations = await getOrganizations(ids)
        setOrganizations(
          organizations.data.map((singleOrganization) => {
            return {
              label: singleOrganization.name,
              value: singleOrganization.id,
            };
          })
        );
        var rels = []
        organizations.data.map(item => {
          item.relations.map(rel => {
            rels.push({
              label: rel.name,
              value: rel.id,
            });
          });
        });
        setRelations(rels);
      }
        locationRes = await getRepairRequestLocations()
      
      const locations = locationRes?.data?.map((x) => {
        return { label: x.name, value: x.name };
      });   
      setLocations(locations);
    } catch (e) {
      console.log(e);
    } finally {
      setOptionsDataLoading({
        location: false,
        organization: false,
      });
    }
  };

  const getWorkOrdersForPrintAndDownload = async ({ selectedColumnsNames = [], isAllSelected = true }) => {
    selectedColumnsNames = selectedColumnsNames.map( field => {
      if (field === "organisatonId") {
        return "name";
      } else if (field === "projectId") {
        return "projectName";
      }else if(field === "relationId"){
        return "relationName";
      }
      return field;
    });

    const {
      data: { results },
    } = await (selectedColumnsNames.length > 0 ? getAllExportRecords({
      exportColumns: [...selectedColumnsNames],
      additionalCondition: isAllSelected ? [] : [{
        field: "id",
        operator: "isAnyOf",
        value: [
          ...(rowSelectionModel)
        ],
      }]
    }) : getAllRecords());
    const data = results?.map((result) => {
      const singleWorkOrdersReqObj = { ...result }
      if (singleWorkOrdersReqObj?.["targetCompletionDate"]) singleWorkOrdersReqObj["targetCompletionDate"] = formatDate(new Date(singleWorkOrdersReqObj["targetCompletionDate"]))
      if (singleWorkOrdersReqObj?.["appointmentDateTime"]) singleWorkOrdersReqObj["appointmentDateTime"] = formatDate(new Date(singleWorkOrdersReqObj["appointmentDateTime"]), true)
      if (singleWorkOrdersReqObj?.["resolverStatus"]?.toString()) singleWorkOrdersReqObj["resolverStatus"] = t(`resolver.status.${singleWorkOrdersReqObj["resolverStatus"]}`)
      if (singleWorkOrdersReqObj?.["name"]?.toString()) singleWorkOrdersReqObj["organisatonId"] = singleWorkOrdersReqObj["name"]
      if (singleWorkOrdersReqObj?.["relationName"]?.toString()) singleWorkOrdersReqObj["relationId"] = singleWorkOrdersReqObj["relationName"]
      if (singleWorkOrdersReqObj?.["projectName"]?.toString()) singleWorkOrdersReqObj["projectId"] = singleWorkOrdersReqObj["projectName"]
      if (singleWorkOrdersReqObj?.["hasSignature"] !== undefined) {
        const label = singleWorkOrdersReqObj?.hasSignature ? t("general.yes") : t("general.no");
        singleWorkOrdersReqObj["hasSignature"] = label;
      }
      return singleWorkOrdersReqObj;
    });
    return data;
  };

  const listToMapRedirectHandler = useCallback((_, selectedRequest) => {
    history.push({
      pathname: app === apps.aftercare  ? "/meldingen/map" : app === apps.constructionQuality ? `/werk/${selected.projectNo}/kwaliteitsborging/meldingen/map` : `/werk/${selected.projectNo}/meldingen/map`,        
      state: {
            selectedRequest: {
                ...selectedRequest,
                desc: selectedRequest.description,
                textResolvers: [selectedRequest.name],
                attachments: selectedRequest.repairRequestImages || [],
                number: selectedRequest.workOrderNumber,
                status: t("resolver.status." + selectedRequest.resolverStatus),
            },
            requests: loadedRows.map(p => ({
                ...p,
                desc: selectedRequest.description,
                textResolvers: [p.name],
                attachments: p.repairRequestImages || [],
                number: p.workOrderNumber,
                status: t("resolver.status." + p.resolverStatus),
            })),
            buildingId: selectedRequest.buildingId
        }
    })
},[app, loadedRows, selected.projectNo, t])
  const columns = useMemo(() => {
    const projectNames = Object.keys(projectIds).map((projectId) => ({
      value: projectId,
      label: projectIds?.[projectId]?.[0]?.projectName,
    })).sort((a, b) => (a.label < b.label ? -1 : 1))
    return getWorkorderVisibleColumns({
      t,
      i18n,
      classes,
      handleCellFilter,
      apiRef,
      projectNames,
      handleCellSort,
      isAfterCareApp: app === apps.aftercare,
      keyPrefix,
      selected,
      app,
      theme,
      listToMapRedirectHandler,
      organizations,
      locations,
      relations
    });
  }, [apiRef, app, classes, handleCellFilter, handleCellSort, i18n, keyPrefix, t, selected, theme, organizations, locations, optionsDataLoading, relations , listToMapRedirectHandler]);


  const onFilterModelChange = useCallback((newFilterModel) => {
    handleFilter(newFilterModel, (newFilterModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix],
        newFilterModel
      );
    })
  }, [handleFilter, keyPrefix])

  const handleApplyStatusQuickFilter = useCallback((selectedStatus) => {
    let cloneFilterModal = filterModel;

    // step1 filter is available not undefined
    if (cloneFilterModal?.items?.length > 0) {
      //step3 check status is available or not in filter
      let isStatusFilterAvailableIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "resolverStatus"
      );
      isStatusFilterAvailableIndex = typeof isStatusFilterAvailableIndex === "number" ? isStatusFilterAvailableIndex : -1
      const isStatusFilterAvailable = isStatusFilterAvailableIndex !== -1 ?
        cloneFilterModal?.items?.[isStatusFilterAvailableIndex] : undefined;
      // step4 if available
      if (isStatusFilterAvailable && isStatusFilterAvailable.value) {
        // step6 check new status is available or not
        const isNewStatusPresent = Array.isArray(isStatusFilterAvailable.value)
          ? isStatusFilterAvailable.value.includes(selectedStatus)
          : isStatusFilterAvailable.value === selectedStatus;
        // step7 if new status available
        if (isNewStatusPresent) {
          // step 10 check value is array or not, if array then check lenght is > 2 then remove from it else filter that status
          if (
            Array.isArray(isStatusFilterAvailable.value) &&
            isStatusFilterAvailable.value.length > 1
          ) {
            const values = isStatusFilterAvailable.value.filter(
              (val) => val !== selectedStatus
            );
            cloneFilterModal.items[isStatusFilterAvailableIndex].value =
              values;
          } else {
            // step 11 if stystus length 1 or only value then
            const newFilterItems = cloneFilterModal?.items?.filter(
              (item) => item.field !== "resolverStatus"
            );
            cloneFilterModal = {
              ...cloneFilterModal,
              items: newFilterItems,
            };
          }
        } else {
          // step8 if new status not available
          if (
            Array.isArray(
              cloneFilterModal.items[isStatusFilterAvailableIndex].value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAvailableIndex].value.push(
              selectedStatus
            );
          } else {
            cloneFilterModal.items[isStatusFilterAvailableIndex] = {
              field: "resolverStatus",
              operator: "isAnyOf",
              value: [
                selectedStatus,
                cloneFilterModal.items[isStatusFilterAvailableIndex].value,
              ],
            };
          }
        }
      } else {
        // step5 if not available then add that
        if (isStatusFilterAvailable) {
          cloneFilterModal.items[isStatusFilterAvailableIndex] = {
            field: "resolverStatus",
            operator: "is",
            value: selectedStatus,
          };
        } else {
          cloneFilterModal.items.push({
            field: "resolverStatus",
            operator: "is",
            value: selectedStatus,
          });
        }
      }
    } else {
      // step2 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "resolverStatus",
            operator: "is",
            value: selectedStatus,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix],
        newFilter
      );
    });
  }, [filterModel, handleFilter, keyPrefix]);

  const overViewHandler = () => {
    history.push(app === apps.aftercare ? "/nazorg/werkbonnen/onderaannemer" : `/werk/${selected?.projectNo}/werkbonnen/onderaannemer`)
}
  const handleTooLateFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal?.items?.length > 0) {
      const tooLateFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "overdue"
      );
      if (tooLateFilterIndex !== -1 && cloneFilterModal?.items[tooLateFilterIndex].value) {
        if (cloneFilterModal?.items[tooLateFilterIndex].value?.toString() === "true") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "overdue"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[tooLateFilterIndex].value = "true"
        }

      } else {
        cloneFilterModal.items.push({
          field: "overdue",
          operator: "is",
          value: "true",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "overdue",
            operator: "is",
            value: "true",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix],
        newFilter
      );
    });
  }, [filterModel, handleFilter]);

  const handleCompletedRequestFilter = useCallback(() => {
    let cloneFilterModal = JSON.parse(JSON.stringify(filterModel));
    // step1 filter is available not undefined
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      //step3 check status is available or not in filter
      let isStatusFilterAvailableIndex = filterModel?.items?.findIndex(
        (item) => item.field === "resolverStatus"
      );
      isStatusFilterAvailableIndex = typeof isStatusFilterAvailableIndex === "number" ? isStatusFilterAvailableIndex : -1
      const isStatusFilterAvailable = isStatusFilterAvailableIndex !== -1 ?
        filterModel?.items?.[isStatusFilterAvailableIndex] : undefined;
      // step4 if available and its array
      if (
        isStatusFilterAvailable &&
        isStatusFilterAvailable.value &&
        Array.isArray(isStatusFilterAvailable.value)
      ) {
        // step6 check both present in value or not
        if (
          isStatusFilterAvailable.value.includes(resolverStatus.Completed.toString()) &&
          isStatusFilterAvailable.value.includes(resolverStatus.TurnedDown.toString())
        ) {
          if (isStatusFilterAvailable.value.length === 2) {
            const newFilterItems = filterModel?.items?.filter(
              (item) => item.field !== "resolverStatus"
            );
            cloneFilterModal = {
              ...filterModel,
              items: newFilterItems,
            };
          } else {
            const values = isStatusFilterAvailable.value.filter(
              (val) => ![resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString()].includes(val)
            );
            cloneFilterModal.items[isStatusFilterAvailableIndex].value =
              values;
          }
        } else {
          cloneFilterModal.items[isStatusFilterAvailableIndex].value = [
            ...new Set([
              ...cloneFilterModal.items[isStatusFilterAvailableIndex].value,
              resolverStatus.Completed.toString(),
              resolverStatus.TurnedDown.toString(),
            ]),
          ];
        }
      } else {
        // step5 if ststus available and its value also available
        if (isStatusFilterAvailable && isStatusFilterAvailable.value) {
          // if value is completed or rejected
          if (
            [resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString()].includes(
              isStatusFilterAvailable.value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAvailableIndex] = {
              field: "resolverStatus",
              operator: "isAnyOf",
              value: [resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString()],
            };
          } else {
            cloneFilterModal.items[isStatusFilterAvailableIndex] = {
              field: "resolverStatus",
              operator: "isAnyOf",
              value: [
                resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString(),
                isStatusFilterAvailable.value,
              ],
            };
          }
        } else if (isStatusFilterAvailable) {
          cloneFilterModal.items[isStatusFilterAvailableIndex] = {
            field: "resolverStatus",
            operator: "isAnyOf",
            value: [resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString()],
          };
        } else {
          cloneFilterModal.items.push({
            field: "resolverStatus",
            operator: "isAnyOf",
            value: [resolverStatus.Completed.toString(), resolverStatus.TurnedDown.toString()],
          });
        }
      }
    } else {
      // step2 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "resolverStatus",
            operator: "isAnyOf",
            value: [resolverStatus.TurnedDown.toString(), resolverStatus.Completed.toString()],
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix],
        newFilter
      );
    });
  }, [filterModel, handleFilter, keyPrefix]);

  const handleCheckboxChange = useCallback((itemId) => {
    setRowSelectionModel((prevCheckedItems) => {
      if (prevCheckedItems.includes(itemId)) {
        return prevCheckedItems.filter((id) => id !== itemId);
      }
      return [...prevCheckedItems, itemId];
    });
  }, []);

  const getToolbarProps = useMemo(() => {
    const status = filterModel?.items?.find(
      (item) => item.field === "resolverStatus"
    )?.value;

    const isNewFilterSelected = status || status === 0
      ? Array.isArray(status)
        ? status.includes(resolverStatus.New.toString())
        : resolverStatus.New.toString() === status
      : undefined;

    const isInProgressFilterSelected = status
      ? Array.isArray(status)
        ? status.includes(resolverStatus.Informed.toString())
        : resolverStatus.Informed.toString() === status
      : undefined;


    const isTooLateSelected = filterModel?.items?.find(
      (item) => item.field === "overdue"
    )?.value?.toString() === "true";


    const isCompletedFilterSelected = status
      ? Array.isArray(status)
        ? status.includes(resolverStatus.Completed.toString())
        : resolverStatus.Completed.toString() === status
      : undefined;
    const isRejectedFilterSelected = status
      ? Array.isArray(status)
        ? status.includes(resolverStatus.TurnedDown.toString())
        : resolverStatus.TurnedDown.toString() === status
      : undefined;

    const quickFilterItems = [{
      title: t("resolver.status." + resolverStatus.New.toString()),
      onClick: () => handleApplyStatusQuickFilter(resolverStatus.New.toString()),
      isSelected: isNewFilterSelected,
      iconText: "N"
    },
    {
      title: t("resolver.status." + resolverStatus.Informed),
      onClick: () => handleApplyStatusQuickFilter(resolverStatus.Informed.toString()),
      isSelected: isInProgressFilterSelected,
      icon: (props)=> <Icon baseClassName="fas"   className="fa fa-spinner" {...props}/>
    },
    {
      title: t("general.overdue"),
      onClick: handleTooLateFilter,
      isSelected: isTooLateSelected,
      icon: (props)=> <WatchLaterSharp {...props}/>
    },
    {
      title: t("workOrders.quickFilter.closedOrCompleted"),
      onClick: handleCompletedRequestFilter,
      isSelected: isCompletedFilterSelected && isRejectedFilterSelected,
      icon: (props)=>  <CheckCircleOutlineSharp {...props} />

    },
    ]
    const toolbarProps = {
      apiRef: apiRef,
      columns: columns,
      title: t("general.workOrders"),
      totalRecords: totalRecords,
      getData: getWorkOrdersForPrintAndDownload,
      handleSearch: (value) => {
        handleSearch(value, (value) => {
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_SEARCH_TEXT[keyPrefix],
            value
          );
        });
      },
      searchText: searchText,
      selectedRows: rowSelectionModel,
      quickFilterItems,
      additionalRightSideButtons:[
        {
          title: t("workOrders.overview.label"),
          onClick: overViewHandler,
          icon: (props)=> <DashboardOutlined {...props}/>,
          iconColor:'default'
        },
      ]
    ,
    };
    return toolbarProps;
  }, [apiRef, columns, filterModel, getWorkOrdersForPrintAndDownload, handleSearch, keyPrefix, rowSelectionModel, searchText, t, totalRecords,]);

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
      MobileCustomRowComponent={
            (rest) => <AfterCareWorkOrderRow
                {...rest}
                selectedCheckBox={rowSelectionModel}
                 handleCheckboxChange={handleCheckboxChange}
                 listToMapRedirectHandler={listToMapRedirectHandler}
            />
       }
        customRowHeight= { app === apps.constructionQuality ? 62 : isMobile ? 80 : 62}
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_SEARCH_TEXT[keyPrefix]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_ORDER[keyPrefix]}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_WIDTH[keyPrefix]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_VISIBILITY[keyPrefix]}
        localDataGridViewIdentifier={LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_DATAGRID_VIEW[keyPrefix]}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
        columns={columns}
        ref={muiTableRef}
        apiRef={apiRef}
        loading={loading}
        toolbarProps={getToolbarProps}
        rowCount={totalRecords}
        rows={loadedRows}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModal) =>
          onPaginationModelChange(paginationModal, (paginationModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_PAGINATION[keyPrefix],
              paginationModal
            );
          })}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) =>
          handleSort(newSortModel, (newSortModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_SORT[keyPrefix],
              newSortModal
            );
          })
        }
        filterMode="server"
        paginationMode="server"
        onFilterModelChange={onFilterModelChange}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        {...(!!filterModel && { filterModel })}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  lineThrough:{
    textDecoration:'line-through'
  }
}));

