import React from 'react';
import { Box, IconButton, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from '@mui/material';
import { West } from '@mui/icons-material';
import {  useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Breadcrumbs = () => {
    const { items } = useSelector(state => state.breadcrumbNavigation)
    const history = useHistory();
    return (
        <Stack flexGrow={1} flexDirection="row" minHeight={40}>
            {items.length > 1 && <Box>
                <IconButton edge="start" color="inherit" onClick={() => {
                    history.push(items?.[items?.length - 2]?.to)
                }}>
                    <West />
                </IconButton>
            </Box>}
            <Stack
                direction="row"
                flexGrow={1}
                overflow="auto"
                alignItems="center"
            >
                <MuiBreadcrumbs maxItems={3} aria-label="breadcrumb" color="inherit" sx={{
                    '& .MuiBreadcrumbs-ol': {
                        flexWrap: 'nowrap',
                        width: 0,
                    },
                }}>
                    {items.map((item, index) => {
                        const isActive = index === items.length - 1
                        return (
                            <Typography
                                sx={{
                                    color: isActive ? 'text.primary' : 'text.secondary',
                                    display: 'flex',
                                    width: 'max-content',
                                    flexWrap: 'nowrap',
                                    textDecoration: 'none',
                                }}
                            >
                                {item.breadCrumbTitle}
                            </Typography>
                        );
                    })}
                </MuiBreadcrumbs>
            </Stack>
        </Stack>
    );
};

export default Breadcrumbs;