import { Box,  Typography } from "@mui/material";
import {
  Chat,
  Image,
  MapOutlined,
  PriorityHigh,
  Schedule,
} from "@mui/icons-material";
import { getGridContextMenuList } from "../../_helpers/getGridContextMenuList";
import { formatDate, nl2br, resolverStatus } from "../../_helpers";
import { apps, LOCAL_STORAGE_GRID_KEYS } from "../../_constants";
import { Attention, HoverImage, LabelColumnV2, LinkColumnV2, Messages, Overdue } from "../../components/DataGridV2/DataColumnsV2";

export const getWorkorderVisibleColumns = ({
  t,
  i18n,
  listToMapRedirectHandler,
  selected,
  locations,
  app,
  theme,
  projectNames,
  classes,
  handleCellFilter,
  apiRef,
  handleCellSort,
  keyPrefix,
  organizations,
  relations
}) => {
  const getOptions = getGridContextMenuList({
    handleCellFilter, filterKey: LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_FILTER[keyPrefix],
    handleCellSort, sortKey: LOCAL_STORAGE_GRID_KEYS.WORK_ORDERS_COLUMN_SORT[keyPrefix],
    i18n,
    t,
    apiRef,
    getValueForFilter: (rowData, extraData) => {
      let value = ""
      switch (extraData.fieldName) {
        case "resolverStatus":
          value = rowData?.resolverStatus?.toString()
          break;
        case "hasSignature":
          value = rowData?.hasSignature?.toString()
          break;
        case "overdue":
          value = rowData?.overdue?.toString()
          break;
        case "isRequiredHandling":
          value = rowData?.isRequiredHandling?.toString()
          break;
        default:
          value = rowData?.[extraData?.fieldName]
          break;
      }
      return value
    },
    getValueForCopy: (rowData, extraData) => {
      let text = ""
      switch (extraData.fieldName) {
        case "resolverStatus":
          text = t(`resolver.status.${rowData?.resolverStatus}`)
          break;
        case "targetCompletionDate":
          text = rowData?.targetCompletionDate ? formatDate(new Date(rowData?.targetCompletionDate)) : ""
          break;
        case "appointmentDateTime":
          text = rowData?.appointmentDateTime ? formatDate(new Date(rowData?.appointmentDateTime), true) : ""
          break;
        case "hasSignature":
          text = rowData?.hasSignature ? t("general.yes") : t("general.no")
          break;
        case "projectId":
          text = rowData?.projectName
          break;
        case "organisatonId":
          text = rowData?.name
          break;
        case "relationId":
          text = rowData?.relationName
          break;

        default:
          text = rowData?.[extraData?.fieldName]
          break;
      }
      return text
    }
  })
 return [
  {
    download: true,
    print: true,
    field: "unreadMessagesCount",
    headerName: t("chat.messages"),
    type: "number",
    editable: false,
    maxWidth: 45,
    forceApplyWidth: true,
    minWidth: 45,
    resizable: false,
    sortable: false,
    disableRowSelectionOnClick: true,
    flex: 1,
    headerAlign: "center",
    filterable: true,
    renderHeader: () => (
      <Box sx={{
        mr: 0.6
      }}>
        <Chat size="small" />
      </Box>
    ),
    renderCell: (params) => {
      const rowData = params.row;
      return <Messages request={rowData} t={t} options={getOptions({ sortable: false, field: "unreadMessagesCount", isQuickFilterDisplay: Boolean(rowData.unreadMessagesCount) })} />
    },
  },
  {
    download: false,
    print: true,
    field: "isRequiredHandling",
    headerName: t("general.attention.label"),
    maxWidth: 45,
    minWidth: 45,
    forceApplyWidth: true,
    resizable: false,
    sortable: false,
    flex: 1,
    type: "boolean",
    renderHeader: () => (
      <Box sx={{
        mr: 0.6
      }}>
        <PriorityHigh color="secondary" size="small" />
      </Box>
    ),
    renderCell: (params) => {
      const rowData = params.row;
      return (
        <Attention rowData={rowData} t={t} options={getOptions({ sortable: false, field: "isRequiredHandling", isQuickFilterDisplay:typeof rowData.isRequiredHandling === "boolean" })} />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "workOrderNumber",
    headerName: t("general.number"),
    flex: 1,
    minWidth: 108,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LinkColumnV2
          label={rowData.workOrderNumber}
          to={{ pathname: (app === apps.aftercare ? "/nazorg/werkbon/" : `/werk/${selected?.projectNo}/werkbon/`) + rowData.id, state: { fromWorkOrderGrids: true } }}
          cellSubMenuOptions={{ options: getOptions({ field: "workOrderNumber", isQuickFilterDisplay: Boolean(rowData.workOrderNumber) }), extraData: { fieldName: "workOrderNumber" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "projectId",
    type: "singleSelect",
    valueOptions: projectNames,
    headerName: t("general.projectName"),
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.projectName}
          cellSubMenuOptions={{ options: getOptions({ field: "projectId" }), extraData: { fieldName: "projectId", type: "singleSelect" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "projectNo",
    headerName: t("general.projectNumber"),
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.projectNo}
          cellSubMenuOptions={{ options: getOptions({ field: "projectNo", isQuickFilterDisplay: Boolean(rowData.projectNo) }), extraData: { fieldName: "projectNo" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "description",
    headerName: t("general.description"),
    flex: 1,
    minWidth: 265,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.description}
          tooltipLabel={<>
            {nl2br(rowData.workOrderText)}
            {rowData.explanation && ( <> <br />{nl2br(rowData.explanation)}</> )}
        </>}
          cellSubMenuOptions={{ options: getOptions({ field: "description", isQuickFilterDisplay: Boolean(rowData.description) }), extraData: { fieldName: "description" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "resolverStatus",
    headerName: t("general.status"),
    type: "singleSelect",
    valueOptions: Object.values(resolverStatus).map((workOrdersStatusKey) => ({ value: workOrdersStatusKey.toString(), label: t(`resolver.status.${workOrdersStatusKey}`) })),
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={t(`resolver.status.${rowData.resolverStatus}`)}
          cellSubMenuOptions={{ options: getOptions({ field: "resolverStatus", isQuickFilterDisplay: Boolean(rowData.resolverStatus?.toString()) }), extraData: { fieldName: "resolverStatus", type: "singleSelect" } }}
          request={rowData}
          color={rowData && rowData.overdue && theme.palette.error.dark}
        />
      );
    },
  },
  {
    download: false,
    print: true,
    field: "firstFileId",
    headerName: t("general.image"),
    editable: false,
    disableColumnMenu: true,
    flex: 1,
    align: "center",
    filterable: false,
    minWidth: 45,
    maxWidth: 45,
    forceApplyWidth: true,
    sortable: false,
    renderCell: (params) => {
      const imageId = params?.row?.firstFileId;
      return (
        <HoverImage defaultSize={20} imageId={imageId} index={params.id} />
      );
    },
    renderHeader: () => {
      return (
        (<Box component="div" sx={{
          textAlign: "center"
        }}>
          <Image />
        </Box>)
      );
    },
  },
  {
    download: true,
    print: true,
    field: "buildingNoIntern",
    headerName: t("general.buildingNumber"),
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.buildingNoIntern}
          cellSubMenuOptions={{ options: getOptions({ field: "buildingNoIntern", isQuickFilterDisplay: Boolean(rowData.buildingNoIntern) }), extraData: { fieldName: "buildingNoIntern" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "addressText",
    headerName: t("general.objectAddress"),
    flex: 1,
    align: "start",
    editable: false,
    minWidth: 200,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.addressText}
          cellSubMenuOptions={{ options: getOptions({ field: "addressText", isQuickFilterDisplay: Boolean(rowData.addressText) }), extraData: { fieldName: "addressText" } }}
          request={rowData}
          sx={{ minWidth: "200px" }}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "addressPostcode",
    headerName: t("general.address.postcode"),
    flex: 1,
    align: "start",
    minWidth: 75,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.addressPostcode}
          cellSubMenuOptions={{ options: getOptions({ field: "addressPostcode", isQuickFilterDisplay: Boolean(rowData.addressPostcode) }), extraData: { fieldName: "addressPostcode" } }}
          request={rowData}
          sx={{ minWidth: "75px" }}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "addressPlace",
    headerName: t("general.address.city"),
    flex: 1,
    minWidth: 90,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.addressPlace}
          cellSubMenuOptions={{ options: getOptions({ field: "addressPlace", isQuickFilterDisplay: Boolean(rowData.addressPlace) }), extraData: { fieldName: "addressPlace" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "targetCompletionDate",
    headerName: t("repairRequest.targetCompletionDate.shortLabel"),
    flex: 1,
    minWidth: 90,
    type: "date",
    valueGetter: (params) => {
      if (!params) return "";
      return new Date(params);
    },
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          date={rowData.targetCompletionDate}
          cellSubMenuOptions={{ options: getOptions({ field: "targetCompletionDate", isQuickFilterDisplay: Boolean(rowData.targetCompletionDate) }), extraData: { fieldName: "targetCompletionDate", type: "date" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "appointmentDateTime",
    headerName: t("general.appointment"),
    flex: 1,
    align:'center',
    minWidth: 90,
    type: 'dateTime',
    valueGetter: (params) => {
      if (!params) return "";
      return new Date(params);
    },
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      const appointmentDateTime = rowData?.appointmentDateTime
      ? formatDate(new Date(rowData?.appointmentDateTime), true)
        : null;
      return (
        <LabelColumnV2
          spanClass={rowData?.appointmentCancelled && classes.lineThrough}
          label={ rowData?.appointmentDateTime ? appointmentDateTime : "-"}
          color={rowData?.appointmentCancelled && theme.palette.error.dark}
          cellSubMenuOptions={{options: getOptions({field: "appointmentDateTime",isQuickFilterDisplay: Boolean(appointmentDateTime), }), extraData: { fieldName: "appointmentDateTime", type: "dateTime" }, }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "hasSignature",
    headerName: t("general.signed"),
    flex: 1,
    minWidth: 90,
    type: "boolean",
    renderCell: (params) => {
      const rowData = params.row;
      const label = rowData?.hasSignature ? t("general.yes") : t("general.no")
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={label}
          cellSubMenuOptions={{ options: getOptions({ field: "hasSignature", isQuickFilterDisplay: typeof rowData.hasSignature === "boolean", }), extraData: { fieldName: "hasSignature", type: "boolean" }, }}
          request={rowData}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "relationId",
    headerName: t("workOrders.relation"),
    flex: 1,
    align: "start",
    type: "singleSelect",
    valueOptions: relations,
    filterable:app !== apps.resolverModule,
    minWidth: 75,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.relationName}
          cellSubMenuOptions={{ options: getOptions({ field: "relationId", isQuickFilterDisplay: app !== apps.resolverModule && Boolean(rowData.relationId),filterable :  app !== apps.resolverModule}), extraData: { fieldName: "relationId", type: "singleSelect" } }}
          request={rowData}
          sx={{ minWidth: "75px" }}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "organisatonId",
    headerName: t("general.name"),
    filterable: app !== apps.resolverModule,
    flex: 1,
    align: "start",
    type: "singleSelect",
    valueOptions: organizations,
    editable: false,
    minWidth: 108,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.name}
          cellSubMenuOptions={{ options: getOptions({ field:"organisatonId", isQuickFilterDisplay: app !== apps.resolverModule && Boolean(rowData.organisatonId),filterable :  app !== apps.resolverModule}), extraData: { fieldName: "organisatonId", type: "singleSelect" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: false,
    print: false,
    field: "locationIcon",
    headerName: t("repairRequest.pinLocation"),
    editable: false,
    flex: 1,
    align: "center",
    filterable: false,
    minWidth: 45,
    maxWidth: 45,
    forceApplyWidth: true,
    sortable: false,
    renderHeader: () => {
      return (
        (<Box component="div" sx={{
          textAlign: "center"
        }}>
          <MapOutlined color="primary"/>
        </Box>)
      );
    },
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          Icon={MapOutlined}
          showIcon={rowData.drawingPinLocation}
          clickable
          onClick={(e) =>
            !rowData.disabled && listToMapRedirectHandler(e, rowData)
          }
          request={rowData}
          options={getOptions({ sortable: false, field: "locationIcon"  })}
        />
      );
    },
  },
  {
    download: true,
    print: true,
    field: "repairRequestLocation",
    headerName: t("general.location"),
    flex: 1,
    align: "start",
    minWidth: 108,
    editable: false,
    disableRowSelectionOnClick: true,
    type: "singleSelect",
    valueOptions: locations,
    renderCell: (params) => {
      const rowData = params.row;
      if (!rowData) return "";
      return (
        <LabelColumnV2
          label={rowData.repairRequestLocation}
          sx={{ minWidth: "108px" }}
          cellSubMenuOptions={{ options: getOptions({ field: "repairRequestLocation", isQuickFilterDisplay: Boolean(rowData.repairRequestLocation) }), extraData: { fieldName: "repairRequestLocation", type: "singleSelect" } }}
          request={rowData}
        />
      );
    },
  },
  {
    download: false,
    print: true,
    field: "overdue",
    headerName: t("general.overdue"),
    maxWidth: 45,
    minWidth: 45,
    forceApplyWidth: true,
    resizable: false,
    sortable: false,
    flex: 1,
    type: "boolean",
    renderHeader: () => (
      <Box sx={{
        mr: 0.6
      }}>
        <Schedule size="small" />
      </Box>
    ),
    renderCell: (params) => {
      const rowData = params.row;
      return (
        <Overdue rowData={rowData}  t={t} options={getOptions({ sortable: false, field: "overdue", isQuickFilterDisplay: typeof rowData.overdue === "boolean" })} classes={classes} />
      );
    },
   },
   {
     field: "id",
     headerName: t("general.primaryKey"),
     download: false,
     print: false,
     filterable: false,
     sortable: false,
     hidden: true,
   },
 ]
};