import React, { useCallback, useState } from 'react'
import { AppBar, Button, Dialog, DialogContent, IconButton, Paper, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Template from './Template';
import { Cancel, Close, Save } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import Link from './Link';
import TechInfo from '../../../components/TechInfo';
import {TemplateTypes } from '../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateTemplate } from '../../../apis/systemMaintenanceApis';
import { templateDetailsActions, toastActions } from '../../../_actions';
import TabNavigation from '../../../components/TabNavigation';

const EditTemplateDetailsDialog = ({ isEditTemplateDetailsModalOpen, handleEditTemplateModal }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { templateId } = useParams();
    const { templateDetails } = useSelector(state => state.systemMaintenance.templateDetailsStore)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(() => templateDetails)
    const [errors, setErrors] = useState({})
    const [tabValue, setTabValue] = useState(["template", ""].includes(isEditTemplateDetailsModalOpen?.tabId) ? 0 : isEditTemplateDetailsModalOpen?.tabId === "links" ? 1 : 2);
    const history = useHistory();
    const isMobileOrTab = useMediaQuery(theme.breakpoints.down('md'));

    const tabs = [
        { title: t("general.template") },
        { title: t("template.links") },
        { title: t("general.techInfo") },
    ];

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
        history.push(tabs[newValue]?.to)
    };

    const handleChange = ({ keyName, value, extraData }) => {
        switch (keyName) {
            default:
                setValues({ ...values, [keyName]: value })
                break;
        }
    };

    const updateTemplateDetails = async (data) => {
        try {
            setLoading(true)
            await updateTemplate(templateId, data)
            dispatch(toastActions.success(t("template.update.success")))
        } catch (error) {
            dispatch(toastActions.error(t("general.failedToUpdate")))
        }
        finally {
            setLoading(false)
            dispatch(templateDetailsActions.fetchTemplateDetails(templateId))
            handleEditTemplateModal("")
        }
    }

    const clearError = (name) => {
        const nameParts = name.split('.');
        const key = nameParts[0];
        const nestedKey = nameParts.slice(1).join('.');
        setErrors((prevErrors) => {
            if (nestedKey) {
                const newErrors = { ...prevErrors };
                delete newErrors[key]?.[nestedKey];
                if (Object.keys(newErrors[key] || {}).length === 0) {
                    delete newErrors[key];
                }
                return newErrors;
            } else {
                const { [key]: _, ...rest } = prevErrors;
                return rest;
            }
        });
    };


    const validateTemplateDetails = () => {
        const newErrors = {};

        // template field validation
        const { desc, templateType, subject, documentCode } = values || {};

        if (!desc?.trim()) {
            newErrors.desc = t("general.field.error");
        }

        if (templateType === TemplateTypes.Email && !subject?.trim()) {
            newErrors.subject = t("general.field.error");
        }

        if (!templateType?.toString()?.trim()) {
            newErrors.type = t("general.field.error");
        }

        if (templateType?.toString() === TemplateTypes.Document && !documentCode?.trim()) {
            newErrors.documentCode = t("general.field.error");
        }

        // Update errors in state
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors,
        }));

        return{isValid: Object.keys(errors).length === 0 , errors: {...errors ,...newErrors}};
    };

    const handleSave = useCallback(async () => {
        const validateFields = validateTemplateDetails()
        if (validateFields.isValid && Object.keys(validateFields.errors).length === 0) {
            const diff = [];
            Object.keys(values).forEach((keyName) => {
                switch (keyName) {
                    case "active":
                    case "standard":
                        if (values?.[keyName] !== templateDetails?.[keyName]) {
                            diff.push({ id: keyName, name: values?.[keyName]?.toString() })
                        }
                        break;
                    default:
                        // Handle other cases where fields might need comparison
                        if (values?.[keyName] !== templateDetails?.[keyName]) {
                            diff.push({
                                id: keyName,
                                name: values?.[keyName]
                            });
                        }
                        break;
                }
            });
            // If there are changes, call the function to update the template
            if (diff.length > 0) {
                await updateTemplateDetails(diff)
            }
            handleEditTemplateModal('')
        }
        else{
            if(["desc" ,"subject"].includes(Object.keys(validateFields.errors)?.[0])){
                setTabValue(0)
            }
        }
    }, [values, errors, templateDetails]);

    return (
        <>
            <Dialog
                open={isEditTemplateDetailsModalOpen?.open}
                onClose={(event, reason) => reason === 'backdropClick' ? event.stopPropagation() : handleEditTemplateModal("")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        maxWidth: "none",
                        width: "1200px",
                        height: { md: "650px" }
                    }
                }}
                fullScreen={isMobileOrTab}
            >
                <AppBar position='relative' sx={{ boxShadow: 'none' }} >
                    <Toolbar>
                        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => handleEditTemplateModal("")}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                <Typography sx={{ ml: 2 }} variant="h6" component="div">
                                    {t('general.template')}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <IconButton onClick={() => handleEditTemplateModal("")} sx={{ display: { sm: "none" } }} color='inherit' >
                                    <Cancel />
                                </IconButton>
                                <Button onClick={() => handleEditTemplateModal("")} size='small' sx={{ display: { xs: 'none', sm: 'inline-flex' } }} variant="text" color='inherit' startIcon={<Cancel />} >
                                    {t("general.cancel")}
                                </Button>
                                <IconButton onClick={handleSave} sx={{ display: { sm: "none" } }} color='inherit' >
                                    <Save />
                                </IconButton>
                                <Button onClick={handleSave} sx={{ display: { xs: 'none', sm: 'inline-flex' } }} size='small' variant="text" color='inherit' startIcon={<Save />}>
                                    {t("general.save")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <TabNavigation tabs={tabs} activeTab={tabValue} handleTabChange={handleTabChange} />
                <DialogContent sx={{ bgcolor: grey[100], mt: 0.3, p: 2 }}>
                    <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                        {loading && <Loader backdrop />}
                        {tabValue === 0 && <Template handleChange={handleChange} values={values} errors={errors} clearError={clearError} />}
                        {tabValue === 1 && <Link handleChange={handleChange} values={values} errors={errors} clearError={clearError} />}
                        {tabValue === 2 && <TechInfo title={t('general.techInfo')} subtitle={t("general.techInfo")} data={values} />}
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditTemplateDetailsDialog