import React from 'react'
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainHeader from '../components/MainHeader';

const SubLayout = ({children}) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer} >
      <Box height={"100%"} display={"flex"} flexDirection={"column"} className={classes.subContainer}>
        <Box>
          <MainHeader/>
        </Box>
        <Box flexGrow={1} overflow={"auto"} mt={0.25}>
         {children}
        </Box>
      </Box>
    </Box>
  )
}

export default SubLayout

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    },
    height: "100%",
  },
  subContainer: {
    backgroundColor: theme.palette.common.white
  }
}));
