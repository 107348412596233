import { Box, Paper, Stack } from '@mui/material'
import React, { useState } from 'react'
import { grey } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import { getLinksInfo,getTemplateInfo } from './util.js'
import { store } from '../../../index.js'
import TechInfo from '../../../components/TechInfo'
import EditTemplateDetailsDialog from '../EditTemplateDetailsDialog'
import TemplateCreationInfoWrapper from '../TemplateInfoWrapper'

const TemplateDetails = () => {
    const { t } = useTranslation()
    const { systemMaintenance: { templateDetailsStore: { templateDetails } } } = store.getState()
    const [isEditTemplateDetailsModalOpen, setIsEditTemplateDetailsModalOpen] = useState({ open: false, tabId: "" })
    const handleEditTemplateModal = (value) => {
        setIsEditTemplateDetailsModalOpen({ open: value ? true : false, tabId: value })
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: grey[100],
                p: 2,
                overflow: "auto",
                height: "100%",
            }}>
            <Stack sx={{
                flexGrow: 1,
                my: 2,
            }} spacing={2}>
                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <TemplateCreationInfoWrapper title={t("general.template")} details={getTemplateInfo(t)} handleEditTemplateModal={handleEditTemplateModal} />
                </Paper>
                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <TemplateCreationInfoWrapper title={t("template.links")} details={getLinksInfo(t)} handleEditTemplateModal={handleEditTemplateModal} />
                </Paper>
                <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
                    <TechInfo title={t('general.techInfo')} data={templateDetails} />
                </Paper>
            </Stack>
            {isEditTemplateDetailsModalOpen.open && <EditTemplateDetailsDialog isEditTemplateDetailsModalOpen={isEditTemplateDetailsModalOpen} handleEditTemplateModal={handleEditTemplateModal} />}
        </Box>
    )
}

export default TemplateDetails