import React, { useEffect, useState } from 'react'
import { ArrowBack, ArrowLeft, ArrowRight, ContentCopy, Edit, Warning, Cancel, CheckCircle, HomeWork, House, } from '@mui/icons-material';
import { AppBar, Box, Divider,  Grid2 as Grid, Stack, Button, IconButton, Toolbar, Tooltip, Typography, Paper, List, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Snackbar, Alert, CircularProgress, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import EllipsisTypography from '../../components/EllipsisTypography';
import LinkButton from '../../components/LinkButton';
import { approveOrRejectLoginRequest, getLoginRequestDetails, getLoginRequestNavigationInfo, updateLoginRequestObject, updateLoginRequestStatus } from '../../apis/loginRequestApi';
import { history, LoginRequestStatusKeys, nl2br } from '../../_helpers';
import { copyToClipboard } from '../../_helpers/copyToClipboardHelper';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useSelector } from "react-redux";
import ObjectModal from './components/ObjectModal';
import RowDetails from '../../components/RowDetails';
import TechInfo from '../../components/TechInfo';

export const getStatusColor = (loginRequestStatus, theme) => {
  let color = '';
  switch (loginRequestStatus) {
    case 0:
      color = theme.palette.text.primary;
      break;
    case 1:
      color = theme.palette.warning.main;
      break;
    case 2:
      color = theme.palette.success.main;
      break;
    case 3:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.text.primary;
  }
  return color;
}

const LoginRequestDetails = () => {
  const { loginRequestId } = useParams();
  const theme = useTheme()
  const { all = [] } = useSelector(
    (state) => state.buildings
  );
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [isAddressMatching, setIsAddressMatching] = useState(true);
  const [selectedBuildingId, setSelectedBuildingId] = useState("")
  const [isBuyerRenterExist, setIsBuyerRenterExist] = useState(false);
  const [objectAddress, setObjectAddress] = useState("");
  const [dialogType, setDialogType] = useState(null);
  const [isObjectModalOpen, setIsObjectModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginRequestData, setLoginRequestData] = useState({})
  const [loginRequestNavigationInfo, setLoginRequestNavigationInfo] = useState({
    loginRequestPrev: null,
    loginRequestNext: null
  })
  const [rejectedReason, setRejectedReason] = useState("")
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "error"
  })
  const { buyerRenterInfo, address } = loginRequestData || {};
  const { org, p1, p2 } = buyerRenterInfo || {};
  const isPerson1EmailExist = loginRequestData?.person1?.isExistingEmail
  const isPerson2EmailExist = loginRequestData?.person2?.isExistingEmail
  const isApproveBtnDisabled = isPerson1EmailExist || isPerson2EmailExist

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getLoginRequestDetails(loginRequestId);
        if (response.status === 200) {
          setLoginRequestData(response.data);
          const loginReqNavigationInfo = await getLoginRequestNavigationInfo(loginRequestId)
          if (loginReqNavigationInfo.status === 200) {
            setLoginRequestNavigationInfo({
              loginRequestPrev: loginReqNavigationInfo.data?.prev?.loginRequestId,
              loginRequestNext: loginReqNavigationInfo.data?.next?.loginRequestId
            })
          }
          await updateLoginRequestStatus(loginRequestId);
        }
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false);
      }
    })();
  }, [loginRequestId]);

  const getCopyTextComponent = ({ clipBoardValue = "", toastValue = "" }) => {
    return (
      <Tooltip title={t("general.copy")}>
        <IconButton
          color="inherit"
          aria-label="copy"
          size="small"
          onClick={() => {
            copyToClipboard(clipBoardValue).then(() => {
              setToast({
                open: true,
                message: t("general.copiedText", { text: toastValue || clipBoardValue }),
                type: "success",
              });
            });
          }}
        >
          <ContentCopy htmlColor={theme.palette.action.active} />
        </IconButton>
      </Tooltip>
    );
  };

  const applyFilterAndRedirect = (type, filter) => {
    let url = `/nazorg/`;
    let data = {};
    switch (type) {
      case "repairRequest": {
        url += "meldingen";
        data = { selectedProject: { projectId: loginRequestData?.projectId, buildingNoIntern: loginRequestData?.buildingNoIntern }, filter };
        break;
      }
      default:
        break;
    }
    history.push(url, data);
  };
  const applicantGeneralInfo = [
    {
      title: t("loginRequest.applicantInfo.requestNo"),
      value: loginRequestData?.loginRequestNumber || ''
    },
    {
      title: t("general.status"),
      value: <Typography variant='caption' component="p" sx={{
        color: getStatusColor(loginRequestData.loginRequestStatus, theme)
      }}>{t(`loginRequest.status.${loginRequestData.loginRequestStatus}`)}</Typography>,
    },
    {
      title: loginRequestData?.person2 && (<Typography variant='caption' component="p" sx={{
        fontWeight: 'bold'
      }}>{`${t('general.person')} 1`}</Typography>),
      value: ""
    },
    {
      title: t("general.lastName"),
      value: loginRequestData?.person1?.lastName || ''
    },
    {
      title: t("general.namePrefixes"),
      value: loginRequestData?.person1?.middleName || ''
    },
    {
      title: t("general.initials"),
      value: loginRequestData?.person1?.initials || ''
    },
    {
      title: t("general.firstName"),
      value: loginRequestData?.person1?.firstName || ''
    },
    {
      title: t('general.gender'),
      value: loginRequestData?.person1?.gender === 0 ? t("general.male") : loginRequestData?.person1?.gender === 1 ? t("general.female") : ""
    },
    {
      title: t("general.emailPrivate"),
      value: <Tooltip title={isPerson1EmailExist ? t("loginRequest.emailExists.error") : ""}><Typography variant='caption' component="p" sx={[isPerson1EmailExist ? {
        color: "error.main"
      } : {
        color: 'text.primary'
      }]}>{loginRequestData?.person1?.email || ''}
      </Typography>
      </Tooltip>,
    },
    {
      title: t("general.mobileNumber"),
      value: loginRequestData?.person1?.mobile || ''
    },
  ];
  if (loginRequestData?.person2) {
    applicantGeneralInfo.push(
      {
        title: <Typography variant='caption' component="p" sx={{
          fontWeight: 'bold'
        }}>{`${t('general.person')} 2`}</Typography>,
        value: ""
      },
      {
        title: t("general.lastName"),
        value: loginRequestData.person2.lastName || ''
      },
      {
        title: t("general.namePrefixes"),
        value: loginRequestData.person2.middleName || ''
      },
      {
        title: t("general.initials"),
        value: loginRequestData.person2.initials || ''
      },
      {
        title: t("general.firstName"),
        value: loginRequestData.person2.firstName || ''
      },
      {
        title: t('general.gender'),
        value: loginRequestData?.person2?.gender === 0 ? t("general.male") : loginRequestData?.person2?.gender === 1 ? t("general.female") : ""
      },
      {
        title: t("general.emailPrivate"),
        value: <Tooltip title={isPerson2EmailExist ? t("loginRequest.emailExists.error") : ""}><Typography variant='caption' component="p" sx={[isPerson2EmailExist ? {
          color: "error.main"
        } : {
          color: 'text.primary'
        }]}>{loginRequestData?.person2?.email || ''}
        </Typography>
        </Tooltip>
      },
      {
        title: t("general.mobileNumber"),
        value: loginRequestData.person2.mobile || ''
      }
    );
  }


  const applicantAddressInfo = [
    {
      title: t("general.address.postcode"),
      value: address?.postcode || ''
    },
    {
      title: t("general.address.houseNumber"),
      value: address?.houseNo || ''
    },
    {
      title: t("general.address.numberAddition"),
      value: address?.houseNoAddition || ''
    },
    {
      title: t("general.address"),
      value: address?.fullAddress || ''
    }
  ]

  const objectGeneralInfo = [
    {
      title: t("general.projectNumber"),
      value: loginRequestData?.projectNo
    },
    {
      title: t("general.projectName"),
      value: loginRequestData.projectName
    },
    {
      title: t("general.buildingNumber.internal"),
      value: loginRequestData.buildingNoIntern || ''
    },
    {
      title: t("general.buildingNumber.external"),
      value: loginRequestData.buildingNoExtern || ''
    },
    {
      title: t("loginRequest.objectInfo.objectStatus"),
      value: loginRequestData.buildingStatus || ''
    },
    {
      title: t("general.address"),
      value: loginRequestData.buildingAddress || ''
    },
    {
      title: t("loginRequest.objectInfo.repairRequestLink"),
      value: (
        <>
          <Tooltip title={t("repairRequest.for.project")}>
            <IconButton color="primary">
              <HomeWork onClick={(e) => applyFilterAndRedirect("repairRequest", "project")} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("repairRequest.for.object")}>
            <IconButton color="primary">
              <House onClick={(e) => applyFilterAndRedirect("repairRequest", "buildingNoIntern")} />
            </IconButton>
          </Tooltip>
        </>
      )
    },
  ]

  const buyersAsPerson = [];
  if (p1) {
    buyersAsPerson.push({
      label: `${t('general.person')} 1`,
      details: [
        { title: t('general.lastName'), value: p1.lastName || '' },
        { title: t('general.namePrefixes'), value: p1.middleName || '' },
        { title: t('general.initials'), value: p1.initials || '' },
        { title: t('general.firstName'), value: p1.firstName || '' },
        { title: t('general.gender'), value: p1.gender === 0 ? t("general.male") : p1.gender === 1 ? t("general.female") : "" },
        { title: t('general.emailPrivate'), value: p1.email || '' },
        { title: t('general.mobileNumber'), value: p1.mobile || '' },
      ],
    });
  }

  if (p2) {
    buyersAsPerson.push({
      label: `${t('general.person')} 2`,
      details: [
        { title: t('general.lastName'), value: p2.lastName || '' },
        { title: t('general.namePrefixes'), value: p2.middleName || '' },
        { title: t('general.initials'), value: p2.initials || '' },
        { title: t('general.firstName'), value: p2.firstName || '' },
        { title: t('general.gender'), value: p2.gender === 0 ? t("general.male") : p2.gender === 1 ? t("general.female") : "" },
        { title: t('general.emailPrivate'), value: p2.email || '' },
        { title: t('general.mobileNumber'), value: p2.mobile || '' },
      ],
    });
  }

  const buyersData = org
    ? [
      {
        label: t('loginRequest.organizationInfo'),
        details: [
          { title: t('general.name'), value: org.name || '' },
          { title: t('general.searchName'), value: org.searchName || '' },
          { title: t('general.email'), value: org.email || '' },
          { title: t('general.website'), value: org?.website?.trim()?.length > 0 ? <LinkButton label={org.website} to={org.website && !/^https?:\/\//i.test(org.website) ? `https://${org.website}` : org.website} target="_blank" isExternalLink /> : "" },
          { title: t('general.status'), value: <Typography component='p' variant='caption'>{t(`organisation.status.${org.status}`) || ''}</Typography> },
          { title: t('general.telephone'), value: org.telephone || '' },
        ],
      },
      {
        label: t('loginRequest.relationshipInfo'),
        details: [
          { title: t('loginRequest.relationshipInfo.relationshipName'), value: org.relationName || '' },
          { title: t('general.function'), value: org.relationFunctionName || '' },
          { title: t('general.department'), value: org.relationDepartment || '' },
          { title: t('general.emailPrivate'), value: org.relationEmail || '' },
          { title: t('general.mobileNumber'), value: org.relationMobile || '' },
        ],
      },
    ]
    : buyersAsPerson;

  const handleOpenDialog = (type) => {
    setDialogType(type);
    if (type === "approve" && loginRequestData?.buyerRenterInfo?.buyerRenterId) {
      setIsBuyerRenterExist(true)
    } else if (type === "approve") {
      setIsBuyerRenterExist(false)
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (dialogType === "address-match") {
      setIsObjectModalOpen(true)
    }
    setDialogType(null);
  };

  const handleApproveOrReject = async (type) => {
    try {
      setOpenDialog(false);
      setIsLoading(true);
      const response = await approveOrRejectLoginRequest({
        loginRequestId,
        isApproved: type === "approve",
        ...(type === "reject" && rejectedReason?.trim().length > 0 ? { reason: rejectedReason } : {})
      });
      if (response.status === 200) {
        const loginRequestDetailsRes = await getLoginRequestDetails(loginRequestId);
        if (loginRequestDetailsRes.status === 200) {
          setLoginRequestData(loginRequestDetailsRes?.data);
          setToast({
            open: true,
            message: t(type === "approve" ? "loginRequest.approve.success" : "loginRequest.reject.success"),
            type: 'success'
          });
        } else {
          setToast({
            open: true,
            message: t("general.api.error"),
            type: 'error'
          });
        }
      } else {
        setToast({
          open: true,
          message: t("general.api.error"),
          type: 'error'
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setToast({
        open: true,
        message: t("general.api.error"),
        type: 'error'
      });
    }
  };

  const handleCloseObjectModal = () => {
    setIsObjectModalOpen(false);
  };

  const handleLoginRequestObjectUpdate = async (id) => {
    try {
      setIsLoading(true)
      setOpenDialog(false)
      const response = await updateLoginRequestObject({
        loginRequestId,
        buildingId: id || selectedBuildingId
      })
      if (response.status === 200) {
        const loginRequestDetails = await getLoginRequestDetails(loginRequestId)
        if (loginRequestDetails.status === 200) {
          setLoginRequestData(loginRequestDetails.data);
        }
      }
      setIsLoading(false)
      setIsObjectModalOpen(false)
    } catch (err) {
      setIsLoading(false)
      setIsObjectModalOpen(false)
      console.log(err)
    }
  }

  const handleClickedRowData = async (rowData) => {
    setSelectedBuildingId(rowData?.id ?? "")
    const matchedBuilding = all.find((singleBuilding) => singleBuilding.buildingId === rowData?.id)
    if (matchedBuilding) {
      setObjectAddress(matchedBuilding?.address || "")
      const { addressModel } = matchedBuilding || {};
      const { houseNo = "", postcode = "", houseNoAddition = "" } = addressModel || {}
      if (
        (loginRequestData?.address?.houseNo?.toLowerCase() ?? "") === (houseNo?.toLowerCase() ?? "") &&
        (loginRequestData?.address?.postcode?.toLowerCase() ?? "") === (postcode?.toLowerCase() ?? "") &&
        (loginRequestData?.address?.houseNoAddition?.toLowerCase() ?? "") === (houseNoAddition?.toLowerCase() ?? "")
      ) {
        setIsAddressMatching(true);
        handleLoginRequestObjectUpdate(rowData?.id)
      } else {
        setIsObjectModalOpen(false)
        handleOpenDialog('address-match')
        setIsAddressMatching(false);
      }
    }
  };

  const loginRequestRedirectHelper = (id) => {
    let url = "/nazorg/inlogverzoeken";
    if (id) url += `/${id}`;
    history.push(url);
  }

  return (
    (<Box>
      <AppBar position="sticky" color="inherit"  >
        <Toolbar variant="dense" sx={{ justifyContent: "space-between", }}>
          <Stack direction="row" sx={{
            alignItems: "center"
          }}>
            <IconButton
              color="inherit"
              edge="start"
              aria-label="go back"
              component="span"
              size="small"
              onClick={history.goBack}
            >
              <ArrowBack />
            </IconButton>
            <Tooltip title={t("general.button.previous")} >
              <IconButton
                color="inherit"
                edge="start"
                aria-label="go back"
                component="span"
                size="large"
                disabled={!loginRequestNavigationInfo.loginRequestPrev}
                onClick={() => {
                  if (!!loginRequestNavigationInfo.loginRequestPrev) { loginRequestRedirectHelper(loginRequestNavigationInfo.loginRequestPrev) };
                }}
              >
                <ArrowLeft />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("general.next")}>
              <IconButton
                color="inherit"
                edge="start"
                aria-label="next"
                component="span"
                size="large"
                disabled={!loginRequestNavigationInfo.loginRequestNext}
                onClick={() => {
                  if (!!loginRequestNavigationInfo.loginRequestNext) { loginRequestRedirectHelper(loginRequestNavigationInfo.loginRequestNext) };
                }}
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
            <EllipsisTypography variant={"h6"} title={`${t('loginRequest.title')} - ${loginRequestData?.loginRequestNumber}`} />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
          >
            {
              (loginRequestData.loginRequestStatus !== LoginRequestStatusKeys.Approved && loginRequestData.loginRequestStatus !== LoginRequestStatusKeys.Rejected && !isLoading) && <>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => handleOpenDialog('reject')}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  disabled={isApproveBtnDisabled}
                  color="success"
                  edge="end"
                  size="small"
                  onClick={() => handleOpenDialog('approve')}>

                  <CheckCircle />
                </IconButton>
              </>
            }
          </Stack>
        </Toolbar>
      </AppBar>
      <Dialog
        maxWidth='sm'
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h6' sx={{
            fontWeight: 500
          }}>
            {dialogType === 'approve' && (
              <Stack direction="row" spacing={1} sx={{
                alignItems: "center"
              }}>
                <Warning color="warning" />
                {t('loginRequest.approveModal.title')}
              </Stack>
            )}
            {dialogType === 'reject' && t('loginRequest.rejectModal.title')}
            {dialogType === 'address-match' && t('loginRequest.addressConfirmationModal.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {dialogType === 'approve' && (
            <>
              {isBuyerRenterExist ? (
                <>
                  <Typography id="alert-dialog-description" sx={{
                    color: "text.secondary"
                  }}>
                    {t('loginRequest.approveModal.buyerRenterExist.subTitle1')}
                  </Typography>
                  <Typography
                    id="alert-dialog-description"
                    sx={{
                      mt: 2,
                      color: "text.secondary"
                    }}>
                    {t('loginRequest.approveModal.buyerRenterExist.subTitle2')}
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 3 }}>
                    {[...Array(5)].map((_, index) => (
                      <Typography component='li' key={index} sx={{
                        color: "text.secondary"
                      }}>
                        {t(`loginRequest.approveModal.buyerRenterExist.note.${index + 1}`)}
                      </Typography>
                    ))}
                  </List>
                </>
              ) : (
                <>
                  <Typography id="alert-dialog-description" sx={{
                    color: "text.secondary"
                  }}>
                    {t('loginRequest.approveModal.buyerRenterNotExist.subTitle1')}
                  </Typography>
                  <Typography
                    id="alert-dialog-description"
                    sx={{
                      mt: 2,
                      color: 'text.secondary'
                    }}>
                    {t('loginRequest.approveModal.buyerRenterNotExist.subTitle2')}
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 3 }}>
                    {[...Array(2)].map((_, index) => (
                      <Typography component='li' key={index} sx={{
                        color: "text.secondary"
                      }}>
                        {t(`loginRequest.approveModal.buyerRenterNotExist.note.${index + 1}`)}
                      </Typography>
                    ))}
                  </List>
                </>
              )}
            </>
          )
          }
          {
            !isAddressMatching && dialogType === "address-match" && (
              <Typography id="alert-dialog-description" sx={{
                color: "text.secondary"
              }}>
                {t('loginRequest.addressConfirmationModal.subTitle', { address: objectAddress })}
              </Typography>
            )
          }
          {
            dialogType === "reject" && (
              <>
                <Typography id="alert-dialog-description" sx={{
                  color: "text.secondary"
                }}>
                  {t('loginRequest.rejectModal.subTitle')}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    mt: 2
                  }}>
                  <TextField
                    fullWidth
                    label={t('general.reasonOfRejection')}
                    size="small"
                    type="text"
                    name="message"
                    multiline
                    maxRows={3}
                    rows={3}
                    onChange={(e) => setRejectedReason(e.target.value)}
                    slotProps={{
                      htmlInput: { maxLength: 200 },
                      inputLabel: { shrink: true }
                    }} />
                </Typography>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("general.cancel")}</Button>
          {((dialogType === 'approve' || dialogType === 'reject' || dialogType === 'address-match') && (
            <LoadingButton
              onClick={() => {
                if (dialogType === 'approve' || dialogType === 'reject') {
                  handleApproveOrReject(dialogType);
                } else if (dialogType === 'address-match') {
                  handleLoginRequestObjectUpdate();
                }
              }}
              loading={isLoading}
            >
              {dialogType === 'approve'
                ? t("general.approve")
                : dialogType === 'reject'
                  ? t("general.button.reject")
                  : t("general.confirm")}
            </LoadingButton>
          ))}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={() =>
          setToast({
            open: false,
            message: "",
            type: "error"
          })
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={toast.message}
      >
        <Alert
          onClose={() =>
            setToast({
              open: false,
              message: "",
              type: "error"
            })
          }
          severity={toast.type}
        >
          {toast.message}
        </Alert>
      </Snackbar>
      {
        isLoading && !isObjectModalOpen ? <Stack
          sx={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            p: theme.spacing(1.25),
            height: "50vh"
          }}>
          <CircularProgress size={30} />
        </Stack> :
          <Stack
            spacing={2}
            sx={{
              flex: 1,
              bgcolor: grey[100],
              px: { xs: 0, md: 2 },
              py: { xs: 2, md: 2 }
            }}>
            <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
              <Typography
                variant="h6"
                sx={{
                  px: 2,
                  py: 1.5
                }}>
                {t("loginRequest.applicantInfo")}
              </Typography>
              <Divider />
              <Grid container sx={{
                py: 1
              }}>
                <Grid
                  sx={{
                    borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                  }}
                  size={{
                    xs: 12,
                    md: 4
                  }}>
                  <Grid container spacing={0.5} sx={{
                    px: 2
                  }}
                  >
                    <Grid size={{xs: 12}}>
                      <Typography variant="subtitle2">
                        {t('general.general')}
                      </Typography>
                    </Grid>
                    {applicantGeneralInfo?.map((detail) => {
                      return <Grid size={{xs: 12}}><RowDetails title={detail.title} value={detail.value} /></Grid>;
                    })}
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                  }}
                  size={{
                    xs: 12,
                    md: 4
                  }}>
                  <Grid container spacing={0.5}
                    sx={{
                      px: 2
                    }}
                  >
                    <Grid size={{xs: 12}}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: { xs: 3, md: 0 },
                          mb: { xs: 1.5, md: 0 }
                        }}>
                        {t('general.address')}
                      </Typography>
                    </Grid>
                    {applicantAddressInfo?.map((detail) => {
                      return <Grid size={{xs: 12}}><RowDetails title={detail.title} value={detail.value} /></Grid>;
                    })}
                  </Grid>
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 4
                  }}>
                  <Box sx={{
                    px: 2
                  }}>
                    <Stack direction="row" sx={{
                      justifyContent: "space-between"
                    }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: { xs: 3, md: 0 },
                          mb: { xs: 1.5, md: 0 }
                        }}> {t('general.message')} </Typography>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "center",
                          alignItems: "center"
                        }}>
                        {getCopyTextComponent({ clipBoardValue: loginRequestData?.message })}
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        borderRadius: 1,
                        height: 300,
                        bgcolor: grey[100],
                        p: 1.5
                      }}>
                      <Typography>
                        {loginRequestData.message || ''}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
              <Typography
                variant="h6"
                sx={{
                  px: 2,
                  py: 1.5
                }}>
                {t("general.objectInformation")}</Typography>
              <Divider />
              <Grid container sx={{
                py: 1
              }} size={{xs:12}}>
                <Grid
                  sx={{
                    borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                  }}
                  size={{
                    xs: 12,
                    md: 4
                  }}>
                  <Grid container spacing={0.5}
                    sx={{
                      px: 2
                    }}
                  >
                    <Grid size={{xs: 12}}>
                      <Stack direction="row" sx={{
                        justifyContent: "space-between"
                      }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            mt: { xs: 3, md: 0 },
                            mb: { xs: 1.5, md: 0 }
                          }}>{t("general.general")}</Typography>
                        {
                          (loginRequestData.loginRequestStatus !== LoginRequestStatusKeys.Approved && loginRequestData.loginRequestStatus !== LoginRequestStatusKeys.Rejected) && <IconButton
                            color="inherit"
                            edge="start"
                            aria-label="edit"
                            onClick={() => setIsObjectModalOpen(true)}
                          >
                            <Edit htmlColor={theme.palette.action.active} />
                          </IconButton>
                        }
                      </Stack>
                    </Grid>
                    {objectGeneralInfo?.map((detail) => {
                      return <Grid size={{xs: 12}}><RowDetails title={detail.title} value={detail.value} /></Grid>;
                    })}
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                  }}
                  size={{
                    xs: 12,
                    md: 4
                  }}>
                  <Grid container spacing={0.5}
                    sx={{
                      px: 2
                    }}
                  >
                    <Grid size={{xs: 12}}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: { xs: 3, md: 0 },
                          mb: { xs: 1.5, md: 0 }
                        }}>
                        {t('general.buyerRenter')}
                      </Typography>
                    </Grid>
                    {buyersData.map((buyer) => (
                          <>
                      <Grid size={{xs: 12}}>
                          <Typography variant='caption' component='p' sx={{
                            fontWeight: 500
                          }}>{buyer?.label}</Typography>
                        </Grid>
                        {buyer.details?.map((detail) => (
                          <Grid key={detail.title} size={{xs:12}}>
                            <RowDetails title={detail.title} value={detail.value} />
                          </Grid>
                        ))}
                      </>
                    ))}
                  </Grid>
                </Grid>
                {loginRequestData.loginRequestStatus === LoginRequestStatusKeys.Rejected &&
                  <Grid
                    size={{
                      xs: 12,
                      md: 4
                    }}>
                    <Stack direction="column" sx={{
                      px: 2
                    }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: { xs: 3, md: 0 },
                          mb: { xs: 1.5, md: 0 }
                        }}> {t('general.reasonOfRejection')} </Typography>
                      <Typography variant='caption' component='p'>{nl2br(loginRequestData?.rejectionText)}</Typography>
                    </Stack>
                  </Grid>}
              </Grid>
            </Paper>
            <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
            <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} data={loginRequestData} />
            </Paper>
          </Stack>
      }
      {isObjectModalOpen && <ObjectModal
        handleCloseObjectModal={handleCloseObjectModal}
        isOpenObjectModal={isObjectModalOpen}
        isLoading={isLoading}
        handleClickedRowData={handleClickedRowData}
      />}
    </Box>)
  );
}

export default LoginRequestDetails

