import { TASK_DETAILS } from "../_constants"
import { getTaskDetails, getTaskHistory, getTaskItemsByStatus } from "../apis/systemMaintenanceApis"

export const clearTaskDetailsStore = () => {
    return { type: TASK_DETAILS.CLEAR }
}

const fetchAutomationDetails = (taskId) => {

    return async (dispatch) => {
        dispatch({ type: TASK_DETAILS.SET_LOADING_TASK_DETAILS, data: true });
        try {
            const res = await getTaskDetails(taskId)
            dispatch({
                type: TASK_DETAILS.SET_TASK_DETAILS_DATA, data: res.data
            });
        } catch (e) {
            dispatch({ type: TASK_DETAILS.SET_LOADING_TASK_DETAILS, data: false });
        }

    }

}

const fetchTaskItemsByStatus = (taskId, status,t) => {
    
    return async (dispatch) => {
        dispatch({ type: TASK_DETAILS.SET_LOADING_TASK_ITEM_BY_STATUS, data: true });
        try {
            const res = await getTaskItemsByStatus(taskId, status);
            const resData = res?.data?.map((item) => {
                return {
                    ...item,
                    title: t(item.title) 
                };
            }) || [];
            dispatch({
                type: TASK_DETAILS.SET_DATA_TASK_ITEM_BY_STATUS, 
                data: { resData, loading: false }
            });
        } catch (e) {
            dispatch({ type: TASK_DETAILS.SET_LOADING_TASK_ITEM_BY_STATUS, data: false });
            console.error(e);
        }
    };
};



const fetchHistoryDetails = (taskId) => {
    return async (dispatch) => {
        dispatch({ type: TASK_DETAILS.SET_LOADING_HISTORY_DETAILS, data: true });
        try {
            const res = await getTaskHistory(taskId)
            const resData = res?.data || [];
            dispatch({
                type: TASK_DETAILS.SET_TASK_HISTORY_DATA, data: { data : resData , loading:false}
            });
        } catch (e) {
            dispatch({ type: TASK_DETAILS.SET_LOADING_HISTORY_DETAILS, data: false });
        }
    }
}

export const taskDetailsActions = {
    clearTaskDetailsStore,
    fetchAutomationDetails,
    fetchHistoryDetails,
    fetchTaskItemsByStatus
}