import React from "react";
import { connect } from "react-redux";
import { withTheme } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { Alert, Box, InputAdornment, Snackbar } from "@mui/material";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Hidden
} from "@mui/material";
import {
  Add,
  ArrowBack,
  ArrowLeft,
  ArrowRight,
  Assignment,
  Build,
  Clear,
  Contacts,
  Delete,
  Edit,
  FileCopyOutlined,
  ListAlt,
  Mail,
  PriorityHigh,
  Room,
  Schedule,
  PictureAsPdf,
  HomeWork,
  House,
} from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import Dropzone from "../../components/Dropzone";
import { authHeader, formatDate, getDataTableTextLabels, getMimeTypefromString, history, nl2br, RepairRequestCarryTypes, validateFile } from "../../_helpers";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import ContactCard from "./RepairRequestContactCard";
import { URLS } from "../../apis/urls";
import { getDrawingFiles } from "../../apis/dossiersApi";
import RepairRequestForm from "../Survey/RepairRequestForm";
import { apps, dossiersConstants, userAccountTypeConstants, userAccountRoleConstants } from "../../_constants";
import DocumentViewer from "../../components/DocumentViewer";
import NotificationPanel from "./NotificationPanel";
import { copyToClipboard } from "../../_helpers/copyToClipboardHelper";
import SelectResponsiblePerson from "../Survey/components/SelectResponsiblePerson";
import { updateResponsiblePersonForRepairRequests } from "../../apis/aftercareApis";
import SelectOrganizationModal from "./SelectOrganizationModal";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  locationMarkPreview: {
    position: "absolute",
  },
  locationPin:{
    position: "absolute",
    height : "29px",
    width: "20px"
  },
  locationPreview: {
    maxWidth: "100%",
    // maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
    objectFit: "contain",
  },
  locationContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    padding: theme.spacing(2, 3, 4),
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },

  block: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 3),
  },
  subHeader: {
    padding: theme.spacing(2),
    "& svg": {
      fontSize: 30,
    },
  },
  infoGrid: {
    //padding: theme.spacing(0.5, 2),
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  actionButtons: {
    marginLeft: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  pdfTextWrapper: {
    position: "absolute",
    top: "8px",
    width: "100%",
    textAlign: 'center',
    fontSize: "24px",
    fontWeight: "bolder"
  },
  imageGallery: {
    width: "100%",
    maxHeight: "15vw",
    height: "calc(100% + 4px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "28vw",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "56vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "85vw",
    },
  },
  dropzoneContainer: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    margin: "-2px auto -4px",
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  galleryImageTile: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
  },
  halfWidth: {
    width: "50%",
    verticalAlign: "top",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  alert: {
    width: 350,
  },
  textField: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      width: 350,
      maxWidth: "100%",
    },
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&.big": {
      width: "100%",
      height: 0,
      padding: "50%",
      cursor: "pointer",
      //borderRadius: theme.spacing(1)
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  thumbnailOrg: {
    width: "calc(100% - 16px)",
    margin: theme.spacing(-1, 0),
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: "none",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0, 0, 0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      "& caption": {
        display: "none",
      },
      //marginBottom: theme.spacing(0.5)
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  mainLoaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(1.25),
    height: "50vh",
  },
  addLocationBtn: {
    marginLeft: theme.spacing(1),
    marginTop: 2,
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  locationInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  objectInfoTitle: {
    display: "flex",
    alignItems: "center",
  },
  popperMenu: {
    zIndex: theme.zIndex.drawer,
  },
  listItemIcon: {
    minWidth: 35,
  },


});

class Page extends React.Component {
  state = {
    attachmentIndex: null,
    pdfIndex: null,
    repairRequest: null,
    building: null,
    sendReminderCCEmailList: [],
    sendReminderCCEmailValid: true,
    workorderPopoverOpenLoader: false,
    miniMapCoordinates: { left: 0, top: 0 },
    drawingDossiersList: [],
    currentPage: 1,
    locationPinImageVersion: new Date().getTime(),
    toast:{
      open: false,
      message:"",
      type:"error"
    },
    responsiblePopupAnchorEl: null,
    organizationsLoading: false,
    isSubmitting : true
  };
  objectAdditionalAnchorRef = React.createRef();
  componentDidMount() {
    this.GetRepairRequestLocations();
    this.GetRepairRequestCarryOutAsTypeList();
    this.GetProductServices();
    this.GetSettlementTerm();
    this.GetRepairRequestTypeList();
    this.GetRepairRequestNatureList();
    this.GetRepairRequestCauseList();
    this.GetRepairRequestCauserList();
    this.GetOrganisations();
    this.GetRepairRequestDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId || prevProps.match.params.repairRequestId !== this.props.match.params.repairRequestId) {
      this.GetRepairRequestDetails();
    }
    if ((!prevState.repairRequest && !!this.state.repairRequest) || (!!prevState.repairRequest && !!this.state.repairRequest && prevState.repairRequest.requestId !== this.state.repairRequest.requestId)) {
      if (this.props.selected && this.state.repairRequest.buildingId && this.props.selected.buildingId.toUpperCase() !== this.state.repairRequest.buildingId.toUpperCase()) {
        const selectedItem = this.props.allBuildings.find((x) => x.buildingId.toUpperCase() === this.state.repairRequest.buildingId.toUpperCase());
        if (this.props.app !== apps.aftercare && selectedItem && selectedItem.projectId !== this.props.selected.projectId) {
          history.push(this.redirectHelper());
        } else {
          this.setState({ building: selectedItem });
        }
      } else if (!this.state.repairRequest.buildingId && this.state.repairRequest.projectId) {
        const selectedItem = this.props.allBuildings.find((x) => x.projectId.toUpperCase() === this.state.repairRequest.projectId.toUpperCase());
        if (this.props.app !== apps.aftercare && selectedItem.projectId !== this.props.selected.projectId) {
          history.push(this.redirectHelper());
        } else {
          this.setState({ building: selectedItem });
        }
      } else {
        if (this.props.app !== apps.aftercare) {
          this.setState({ building: this.props.selected });
        } else {
          const selectedItem = this.props.allBuildings.find((x) => {
            return x.projectId.toUpperCase() === this.state.repairRequest.projectId.toUpperCase() && (!this.state.repairRequest.buildingId || x.buildingId.toUpperCase() === this.state.repairRequest.buildingId.toUpperCase());
          });
          this.setState({ building: selectedItem });
        }
      }
    }
    if ((!prevState.building && !!this.state.building) || (!!prevState.building && !!this.state.building && prevState.building.buildingId !== this.state.building.buildingId)) {
      this.GetOrganisations();
    }
  }

  redirectHelper() {
    const url = this.props.app !== apps.aftercare ? "/werk/" + this.props.selected.projectNo + "/kwaliteitsborging" : "/nazorg/meldingen";
    return url;
  }

  repairRequestRedirectHelper(id) {
    let url = this.props.app !== apps.aftercare ? "/werk/" + this.props.selected.projectNo + "/kwaliteitsborging" : "/nazorg/melding";
    if (id) url += `/${id}`;
    history.push(url);
  }

  workOrderRedirectHelper = (resolverId) => {
    const url = this.props.app !== apps.aftercare ? "/werk/" + this.props.selected.projectNo + "/werkbon/" + resolverId : `/nazorg/werkbon/${resolverId}`;
    return url;
  }

  GetRepairRequestLocations() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestLocations";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      locations: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ locations: findResponse });
      });
  }

  GetProductServices() {
    const url = webApiUrl + "api/Home/GetProductServices";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      productServices: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ productServices: findResponse });
      });
  }

  GetSettlementTerm() {
    const url = webApiUrl + "api/RepairRequest/GetSettlementTerm";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      terms: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ terms: findResponse });
      });
  }

  GetRepairRequestCarryOutAsTypeList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestCarryOutAsTypeList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      types: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ carryOutAsTypeList: findResponse });
      });
  }

  GetRepairRequestTypeList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestTypeList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      types: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ typeList: findResponse });
      });
  }

  GetRepairRequestNatureList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestNatureList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      natureList: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ natureList: findResponse });
      });
  }

  GetRepairRequestCauseList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestCauseList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      causeList: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ causeList: findResponse });
      });
  }

  GetRepairRequestCauserList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestCauserList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      causerList: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ causerList: findResponse });
      });
  }

  GetOrganisations() {
    const { building } = this.state;
    if (building) {
      const url = webApiUrl + "api/Organisation/GetOrganisationsByProject/" + encodeURI(building.projectId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            organisationList: findResponse,
          });
        });
    }
  }

  GetEmailsForRepairRequest() {
    const { t } = this.props;
    const { repairRequest } = this.state;
    if (repairRequest && repairRequest.completed !== true && (repairRequest.isAllWorkOrderCompleted === true || repairRequest.isAllWorkOrderDeclined === true)) {
      const url = webApiUrl + "api/RepairRequest/GetEmailsForRepairRequest/" + encodeURI(repairRequest.requestId);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((emails) => {
          let toEmail = null;
          let ccEmails = [];
          if (emails.reporterEmails && emails.reporterEmails.length > 0) {
            toEmail = "reporter";
          }

          const emailModel = [
            {
              key: "reporter",
              title: t("general.reporter"),
              emails: emails.reporterEmails,
            },
            {
              key: "buyer",
              title: t("general.objectUser"),
              emails: emails.buyerEmails,
            },
            {
              key: "client",
              title: t("general.client"),
              emails: emails.clientEmails,
            },
            { key: "vve", title: t("general.vvE"), emails: emails.vvEEmails },
            {
              key: "vveadmin",
              title: t("general.vvEAdministrator"),
              emails: emails.vvEAdministratorEmails,
            },
            {
              key: "propertymanager",
              title: t("general.propertyManager"),
              emails: emails.propertyManagerEmails,
            },
            {
              key: "resolver",
              title: t("general.resolver"),
              emails: emails.resolverEmails,
            },
          ];
          this.setState({ emails, emailModel, toEmail, ccEmails });
        });
    }
  }

  updateRepairRequestLocationPinPoint = (reload) => {
    if (reload) {
      const { repairRequestId } = this.props.match.params;
      this.setState({ repairRequestLoading: true });
      const url = webApiUrl + "api/RepairRequest/AddOrUpdateDrawingPinLocation/" + encodeURI(repairRequestId);

      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          dossierFileId: this.state.selectedDrawingFiles.dossierFileId,
          pageNumber: this.state.currentPage,
          xrel: +this.state.drawingPinLocationCord.xRel.toFixed(5),
          yrel: +this.state.drawingPinLocationCord.yRel.toFixed(5),
        }),
      };
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            openRepairRequestMap: false,
            isCreateRepairRequest: false,
          });
          this.GetRepairRequestDetails(true);
        })
        .catch((er) => {
          this.setState({
            openRepairRequestMap: false,
            isCreateRepairRequest: false,
          });
        });
    } else {
      const selectedFile = this.state.drawingDossiersList.find((p) => p.dossierFileId === (this.state.repairRequest.drawingPinLocation && this.state.repairRequest.drawingPinLocation.dossierFileId)) || this.state.drawingDossiersList[0];
      this.setState({
        currentPage: this.state.repairRequest.drawingPinLocation ? this.state.repairRequest.drawingPinLocation.pageNumber : 1,
        selectedDrawingFiles: {
          ...selectedFile,
          groupBy: selectedFile.isInternal ? "Internal" : "External",
        },
        openRepairRequestMap: false,
        isCreateRepairRequest: false,
      });
    }
  };

  GetRepairRequestDetails = (update = false) => {
    const { repairRequestId } = this.props.match.params;
    const { selected } = this.props;
    if (selected && repairRequestId) {
      this.setState({
        repairRequestLoading: true,
        repairRequestPrev: null,
        repairRequestNext: null,
      });
      this.GetRepairRequestPrevNextInfo(repairRequestId);
      const url = webApiUrl + "api/RepairRequest/GetRepairRequestDetails/" + encodeURI(repairRequestId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      if (update === false) {
        this.setState({
          repairRequest: null,
        });
      }

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          if (findResponse.number) {
            this.setState({
              locationPinImageVersion: new Date().getTime(),
              repairRequest: findResponse,
              completionTextToEdit: findResponse.completionText,
              rejectionTextToEdit: findResponse.rejectionText,
              notesToEdit: findResponse.notes,
              repairRequestLoading: false,
            });
            //this.GetRepairRequestContactInfo();
            this.getDrawingDossierFiles(findResponse);
            this.GetEmailsForRepairRequest();
          }
        })
        .catch((er) => {
          this.setState({ repairRequestLoading: false });
        });
    }
  }

  GetRepairRequestPrevNextInfo(repairRequestId) {
    if (repairRequestId) {
      const url = webApiUrl + "api/RepairRequest/GetRepairRequestNavigationInfo/" + encodeURI(repairRequestId) + "?app=" + this.props.app;
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          if (!!findResponse) {
            this.setState({
              repairRequestPrev: findResponse.prev,
              repairRequestNext: findResponse.next,
            });
          }
        })
        .catch((er) => { });
    }
  }

  updateRepairRequest(key, value) {
    const { t } = this.props;
    const { repairRequest } = this.state;

    if (repairRequest && repairRequest.completed !== true) {
      this.setState({ updating: key });

      const url = webApiUrl + `api/RepairRequest/UpdateRepairRequestByKey/` + encodeURI(repairRequest.requestId);
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify([
          {
            id: key,
            name: value.toString(),
          },
        ]),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ updating: null, edit: null });
            this.GetRepairRequestDetails(true);
          } else {
            this.setState({ updating: null });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ updating: null });
          alert(t("general.api.error"));
        });
    }
  }

  updatePointOfContact = (value) => {
    this.updateRepairRequest("pointofcontact", value);
  };

  getDrawingDossierFiles = (request) => {
    if (request) {
      const { projectId, buildingId, drawingPinLocation } = request;
      this.setState({ drawingDossiersList: [] });
      this.props.dispatch({
        type: dossiersConstants.GET_DRAWING_FILES_LOADING,
      });
      getDrawingFiles(projectId, buildingId)
        .then((res) => {
          this.setState({ drawingDossiersList: res.data });
          if (res.data.length) {
            const selectedFile = res.data.find((p) => p.dossierFileId === (drawingPinLocation && drawingPinLocation.dossierFileId)) || res.data[0];
            this.setState({
              currentPage: drawingPinLocation ? drawingPinLocation.pageNumber : 1,
              selectedDrawingFiles: {
                ...selectedFile,
                groupBy: selectedFile.isInternal ? "Internal" : "External",
              },
            });
          }
          this.props.dispatch({
            type: dossiersConstants.GET_DRAWING_FILES_SUCCESS,
            files: res.data,
          });
        })
        .catch((error) => {
          this.props.dispatch({
            type: dossiersConstants.GET_DRAWING_FILES_ERROR,
            error,
          });
        });
    }
  };

  updateCCEmails = (value) => {
    let { sendReminderCCEmailList } = this.state;
    var sendReminderCCEmailValid = true;
    sendReminderCCEmailList = value.trim() !== "" ? value.replace(/\s/g, "").split(",") : [];
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < sendReminderCCEmailList.length; i++) {
      if (sendReminderCCEmailList[i] == "" || !regex.test(sendReminderCCEmailList[i])) {
        sendReminderCCEmailValid = false;
        break;
      }
    }
    this.setState({
      sendReminderCCEmailList,
      sendReminderCCEmailValid,
    });
  };

  uploadAttachment = (files) => {
    let filesToUpload = [];
    const { t } = this.props;
    const { repairRequest } = this.state;
    const length = repairRequest.attachments.filter((attachment) => !attachment.resolverId).length + repairRequest.pdfAttachments.filter((attachment) => !attachment.resolverId).length
    if (files.length + length > 40) {
      this.setState({
        toast:{
          open : true,
          message:t("files.noOfFilesLimit.error" ,  { noOfFilesAllowed: 40 }),
          type:"error"
        }
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (validateFile({ file: files[i], allowPdf: true, allowImage: true }) === true) {
        filesToUpload.push(files[i]);
      }
    }
    if (filesToUpload.length > 0) {
      const { t } = this.props;
      const { repairRequest } = this.state;
      this.setState({
        uploading: true,
      });

      if (repairRequest && repairRequest.completed !== true) {
        const formData = new FormData();

        for (var i = 0; i < filesToUpload.length; i++) {
          formData.append("files", filesToUpload[i]);
        }

        const url = webApiUrl + `api/RepairRequest/AddRepairRequestAttachments/` + encodeURI(repairRequest.requestId);
        fetch(url, {
          method: "POST",
          headers: authHeader(),
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              this.setState({
                uploading: false,
              });
              this.GetRepairRequestDetails(true);
            } else {
              this.setState({ uploading: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ uploading: false });
            alert(t("general.api.error"));
          });
      }
    }
  };

  AddRepairRequestResolver = (organisationId) => {
    const { t } = this.props;
    const { repairRequest } = this.state;

    if (repairRequest && repairRequest.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/AddRepairRequestResolver/` + encodeURI(repairRequest.requestId);

      const formData = new FormData();
      formData.append("organisationId", organisationId);
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: formData,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true && !!res.resolverId) {
            history.push(this.workOrderRedirectHelper(res.resolverId));
          } else {
            this.setState({ creating: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ creating: false });
          alert(t("general.api.error"));
        });
    }
  };

  copyWorkOrder(organisationId) {
    const { t } = this.props;
    const { repairRequest, selectedResolverIds } = this.state;
    if (selectedResolverIds && selectedResolverIds.length === 1 && repairRequest && repairRequest.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/CopyWorkOrder/${organisationId}/${selectedResolverIds[0]}`;
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true && !!res.resolverId) {
            history.push(this.workOrderRedirectHelper(res.resolverId));
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  DeleteRepairRequestResolvers = () => {
    const { t } = this.props;
    const { selectedResolverIds } = this.state;

    if (selectedResolverIds && selectedResolverIds.length > 0 && this.canDeleteSelectedWorkorders() === true) {
      this.setState({ deletingResolvers: true });

      const url = webApiUrl + `api/RepairRequest/DeleteResolvers/`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
        body: JSON.stringify(selectedResolverIds),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ deletingResolvers: false });
            this.GetRepairRequestDetails(true);
          } else {
            this.setState({ deletingResolvers: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ deletingResolvers: false });
          alert(t("general.api.error"));
        });
    }
  };

  SendReminderToResolvers = () => {
    const { t } = this.props;
    const { selectedResolverIds, sendReminderCCEmailList, sendReminderCCEmailValid } = this.state;

    if (selectedResolverIds && selectedResolverIds.length > 0 && sendReminderCCEmailValid === true && this.canSendReminderSelectedWorkorders() === true) {
      this.setState({ sendingReminder: true });
      let sendReminderModel = {
        resolverIdList: selectedResolverIds,
        ccEmails: sendReminderCCEmailList,
      };
      const url = webApiUrl + `api/RepairRequest/SendReminder/`;
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify(sendReminderModel),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({
              sendingReminder: false,
              sendReminderCCEmailList: [],
              sendReminderCCEmailValid: true,
              sendReminderAnchorEl: null,
            });
            this.GetRepairRequestDetails(true);
          } else {
            this.setState({ sendingReminder: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ sendingReminder: false });
          alert(t("general.api.error"));
        });
    }
  };

  canDeleteSelectedWorkorders() {
    if (this.state.repairRequest && this.state.selectedResolverIds) {
      const resolvers = this.state.repairRequest.resolvers.filter((x) => this.state.selectedResolverIds.includes(x.resolverId));
      for (var i = 0; i < resolvers.length; i++) {
        const resolver = resolvers[i];

        if (resolver.status !== 0 || (resolver.status === 0 && !!resolver.workOrderNumber)) {
          return false;
        }
      }
    }
    return true;
  }

  canSendReminderSelectedWorkorders() {
    if (this.state.repairRequest && this.state.selectedResolverIds) {
      const resolvers = this.state.repairRequest.resolvers.filter((x) => this.state.selectedResolverIds.includes(x.resolverId));
      for (var i = 0; i < resolvers.length; i++) {
        const resolver = resolvers[i];
        if (resolver.status === 3 || resolver.status === 4 || (resolver.status === 0 && !resolver.workOrderNumber)) {
          return false;
        }
      }
    }
    return true;
  }

  initiateHandle = (e, handleType) => {
    if (["complete", "turndown"].includes(handleType)) {
      const { repairRequest } = this.state;
      if (
        !!repairRequest && //handleType == 'complete' &&
        (repairRequest.isAllWorkOrderCompleted === true ||
          //handleType == 'turndown' &&
          repairRequest.isAllWorkOrderDeclined === true)
      ) {
        this.setState({
          anchorElHandleRepairRequest: e.currentTarget,
          handleType,
        });
      }
    }
  };

  handleRepairRequest = () => {
    const { t } = this.props;
    const { repairRequest, handleType, completionTextToEdit, rejectionTextToEdit, notesToEdit } = this.state;
    const completeOrRejectionText = handleType === "complete" ? completionTextToEdit : rejectionTextToEdit;
    if (!!repairRequest && ["complete", "turndown"].includes(handleType) && (repairRequest.isAllWorkOrderCompleted === true || repairRequest.isAllWorkOrderDeclined === true) && !!completeOrRejectionText && completeOrRejectionText.trim() !== "") {
      this.setState({ handling: true });
      let notification = this.createModelForEmailNotify();
      const isComplete = handleType === "complete";
      let model = {
        isComplete,
        completeOrRejectionText,
        notes: notesToEdit,
        notification,
      };

      const url = webApiUrl + `api/RepairRequest/UpdateRepairRequestStatus/` + encodeURI(repairRequest.requestId);
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify(model),
      };
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ handling: false });
            this.GetRepairRequestDetails(true);
          } else {
            this.setState({ handling: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ handling: false });
          alert(t("general.api.error"));
        });
    }
  };

  createModelForEmailNotify = () => {
    const { isNotify, toEmail, ccEmails, emails } = this.state;
    const model = {
      isNotify: isNotify === true,
      toEmails: {
        reporterEmails: toEmail === "reporter" ? emails.reporterEmails : null,
        buyerEmails: toEmail === "buyer" ? emails.buyerEmails : null,
        clientEmails: toEmail === "client" ? emails.clientEmails : null,
        vvEEmails: toEmail === "vve" ? emails.vvEEmails : null,
        vvEAdministratorEmails: toEmail === "vveadmin" ? emails.vvEAdministratorEmails : null,
        propertyManagerEmails: toEmail === "propertymanager" ? emails.propertyManagerEmails : null,
        resolverEmails: toEmail === "resolver" ? emails.resolverEmails : null,
      },
      cCEmails: {
        reporterEmails: ccEmails.includes("reporter") ? emails.reporterEmails : null,
        buyerEmails: ccEmails.includes("buyer") ? emails.buyerEmails : null,
        clientEmails: ccEmails.includes("client") ? emails.clientEmails : null,
        vvEEmails: ccEmails.includes("vve") ? emails.vvEEmails : null,
        vvEAdministratorEmails: ccEmails.includes("vveadmin") ? emails.vvEAdministratorEmails : null,
        propertyManagerEmails: ccEmails.includes("propertymanager") ? emails.propertyManagerEmails : null,
        resolverEmails: ccEmails.includes("resolver") ? emails.resolverEmails : null,
      },
    };

    return model;
  };

  handleImageModalOpen = (index) => {
    this.setState({ attachmentIndex: index });
  };

  handlePdfModalOpen = (index) => {
    this.setState({ pdfIndex: index });
  };

  handleModalClose = () => {
    this.setState({ attachmentIndex: null, pdfIndex: null });
  };

  isProductServiceRequired() {
    const carryOutAsTypeData = this.state.carryOutAsTypeList && this.state.carryOutAsTypeList.find((p) => p.id === this.state.repairRequest.carryOutAsTypeId);
    if (carryOutAsTypeData) return !["Inspectie", "Voorschouw", "Opleverpunt", "Onderhoudstermijn"].some((p) => p === carryOutAsTypeData.name);
  }

  handleClose = (event) => {
    if (this.objectAdditionalAnchorRef.current && this.objectAdditionalAnchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ openObjectAdditionalMenu: false });
  };
  applyFilterAndRedirect = (e, type, filter) => {
    this.handleClose(e);
    let url = `/nazorg/`;
    let data = {};
    switch (type) {
      case "repairRequest": {
        url += "meldingen";
        data = { selectedProject: this.state.building, filter };
        break;
      }
      case "workOrder": {
        url += "werkbonnen";
        data = { selectedProject: this.state.building, filter };
        break;
      }
      // case "dossier": {
      //     url += "";
      //     break;
      // }
      default:
        break;
    }
    history.push(url, data);
  };
  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ openObjectAdditionalMenu: false });
    }
  };

  checkBuildingForRole = (role, building) => building && building.roles && building.roles.includes(role);

  getCopyTextComponent({  clipBoardValue = "", toastValue = "" }) {
    const { t } = this.props;
    return <Tooltip title={t("general.copy")}>
      <IconButton
        aria-label="copy"
        component="span"
        size="small"
        edge="end"
        onClick={(e) => {
          copyToClipboard(clipBoardValue)

          this.setState({
            toast:{
              open: true,
              message:t("general.copiedText", { text: toastValue || clipBoardValue }),
              type:"success"
            }
          });
        }
        }
      >
        <FileCopyOutlined />
      </IconButton>
    </Tooltip>
  }

  renderEditTextbox({title, key, value, multi = false, isCopyTextAllowed = false, disableEdit = false }) {
    const { classes, t } = this.props;
    const { repairRequest, updating } = this.state;
    const textLengths = {
      desc: 60,
      clientreference: 40,
    };
    return (
      (<Box sx={{ wordWrap: "break-word" }} >
        <Box  sx={{float:"right",margin:'-4px -12px -8px 0px'}} >
          {isCopyTextAllowed && this.getCopyTextComponent({ toastValue: title, clipBoardValue: repairRequest[key] })}

          {!disableEdit && repairRequest.completed !== true && (
            <>
              {updating === key ? (
                <Icon color="inherit" fontSize="small" style={{ position: "absolute", right: -15, top: 0 }}>
                  <CircularProgress size="small" />
                </Icon>
              ) : (
                <>
                  <Tooltip title={t("general.editText")}>
                    <IconButton
                      aria-describedby={"edit-" + key}
                      aria-label="edit"
                      component="span"
                      size="small"
                      edge="end"
                      onClick={(e) =>
                        this.setState({
                          edit: { key, value, anchorEl: e.currentTarget },
                        })
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  {this.state.edit && this.state.edit.key === key && (
                    <Popover
                      open={true}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      id={"edit-" + key}
                      anchorEl={this.state.edit.anchorEl}
                      onClose={() => {
                        this.setState({ edit: null });
                      }}
                    >
                      <div style={{ padding: "16px" }}>
                        <Grid container spacing={1} direction="column">
                          <Grid>
                            <Typography variant="h6">{title}</Typography>
                          </Grid>
                          <Grid>
                            <TextField
                              className={classes.textField}
                              value={this.state.edit.value}
                              onChange={(e) =>
                                this.setState({
                                  edit: {
                                    anchorEl: this.state.edit.anchorEl,
                                    key,
                                    value: e.target.value,
                                  },
                                })
                              }
                              margin="dense"
                              variant="outlined"
                              multiline={multi}
                              rows={10}
                              fullWidth
                              autoFocus
                              disabled={repairRequest.completed}
                              slotProps={{
                                htmlInput: {
                                  maxLength: textLengths[key] || 4000
                                }
                              }}
                            />
                          </Grid>
                          <Grid>
                            <Grid container spacing={1} sx={{
                              justifyContent: "flex-end"
                            }}>
                              <Grid>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    this.setState({ edit: null });
                                  }}
                                >
                                  {t("general.cancel")}
                                </Button>
                              </Grid>
                              <Grid>
                                <Button variant="outlined" onClick={() => this.updateRepairRequest(key, this.state.edit.value)}>
                                  {t("general.save")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Popover>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        {value && nl2br(value)}
      </Box>)
    );
  }

  handleRemoveAttachFile(attachmentId) {
    const { t } = this.props;
    const { repairRequest } = this.state;
    if (repairRequest && repairRequest.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestAttachment/${repairRequest.requestId}/${attachmentId}`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true) {
            this.GetRepairRequestDetails(true);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  blockGeneralRepairRequest() {
    const { t, classes, app, user } = this.props;
    const { repairRequest, building } = this.state;
    const isBuyer = user && user.type === userAccountTypeConstants.buyer;
    return (
      (<Box className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Build color="primary" /> &nbsp;
          {t("general.repairRequest")}
          &nbsp;
          {repairRequest ? repairRequest.number : ""}
        </Typography>
        <Grid container>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.date") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.date && formatDate(new Date(repairRequest.date))}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          {(isNaN(repairRequest.receivedVia) !== true || repairRequest.receivedVia !== 2) && (
            <>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("repairRequest.adoptedBy.label") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.adoptedBy && repairRequest.adoptedBy}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.reporter") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.reporter && repairRequest.reporter.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("repairRequest.repairRequestReceivedVia") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.receivedVia !== null && repairRequest.receivedVia >= 0 && repairRequest.receivedVia <= 7 && t("repairRequest.receivedvia." + repairRequest.receivedVia)}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          {!!repairRequest.preferredAppointmentTime && repairRequest.preferredAppointmentTime.trim() !== "" && (
            <>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("repairRequest.preferredAppointmentTime") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.preferredAppointmentTime}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
            </>
          )}
          {
            <Grid container>
              {app === apps.aftercare && !isBuyer && (
                <>
                  <Grid size={{xs:12}}>
                    <Grid container className={classes.infoGridRow}>
                      <Grid size={{xs:6}}>
                        {t("general.projectName") + ":"}
                      </Grid>
                      <Grid size={{xs:6}}>
                        <Button
                          sx={{ padding: 0, minWidth: "auto", textTransform: "none" }}
                          component={Link}
                          to={{
                            pathname: "/dashboard",
                            state: { filter: building && building.projectName },
                          }}
                          color="primary"
                        >
                          {building && building.projectName.replace(`${building.projectNo} - `, "")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Divider />
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Grid container className={classes.infoGridRow}>
                      <Grid size={{xs:6}}>
                        {t("general.projectNumber") + ":"}
                      </Grid>
                      <Grid size={{xs:6}}>
                        <Button
                          style={{ padding: 0, minWidth: "auto" }}
                          component={Link}
                          to={{
                            pathname: "/dashboard",
                            state: { filter: building && building.projectName },
                          }}
                          color="primary"
                        >
                          {building && building.projectNo}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Divider />
                  </Grid>
                </>
              )}

              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.streetAndHouseNumber") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.address &&
                      (repairRequest.address.street ? repairRequest.address.street : "") +
                      " " +
                      (repairRequest.address.houseNo ? repairRequest.address.houseNo : "") +
                      (repairRequest.address.houseNoAddition ? " " + repairRequest.address.houseNoAddition : "")}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.address.postcodeAndCity") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.address && (
                      <>
                        {repairRequest.address.postcode ? repairRequest.address.postcode + " " : ""}
                        &nbsp;
                        {repairRequest.address.place ? repairRequest.address.place : ""}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.buildingNumber") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {building && (repairRequest.buildingId ? building.buildingNoIntern : building.projectName)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.deliveryDate") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.deliveryDate && formatDate(new Date(repairRequest.deliveryDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("repairRequests.secondSignatureDate") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.secondSignatureDate && formatDate(new Date(repairRequest.secondSignatureDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.endOfMaintenancePeriod") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.maintenanceEndDate && formatDate(new Date(repairRequest.maintenanceEndDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("workOrders.endOfWarrantyPeriod") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {repairRequest.warrantyEndDate && formatDate(new Date(repairRequest.warrantyEndDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.repairRequests") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    <Tooltip title={t("repairRequest.for.project")}>
                      <IconButton color="primary">
                        <HomeWork onClick={(e) => this.applyFilterAndRedirect(e, "repairRequest", "project")} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("repairRequest.for.object")}>
                      <IconButton color="primary">
                        <House onClick={(e) => this.applyFilterAndRedirect(e, "repairRequest", "buildingNoIntern")} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("general.workOrders") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    <Tooltip title={t("workOrders.for.project")}>
                      <IconButton color="primary">
                        <HomeWork onClick={(e) => this.applyFilterAndRedirect(e, "workOrder", "project")} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("workOrders.for.object")}>
                      <IconButton color="primary">
                        <House onClick={(e) => this.applyFilterAndRedirect(e, "workOrder", "building")} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>)
    );
  }

  blockStatus() {
    const { t, classes } = this.props;
    const { repairRequest, building } = this.state;

    const isSiteManagerBuilding = this.checkBuildingForRole(userAccountRoleConstants.siteManager, building);
    const isAftercareEmployeeBuilding = this.checkBuildingForRole(userAccountRoleConstants.aftercareEmployee, building) && building.isAfterCareStarted;

    return (
      (<div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Schedule color="primary" /> &nbsp;
          {t("general.status")}
        </Typography>
        <Grid container>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.status") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.status && repairRequest.status}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.completedOn") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.settledOn && formatDate(new Date(repairRequest.settledOn))}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          {repairRequest.completed === true && (
            <>
              {!!repairRequest.completionText && repairRequest.completionText.trim() !== "" && (
                <>
                  <Grid size={{xs:12}}>
                    <Grid container className={classes.infoGridRow}>
                      <Grid size={{xs:6}}>
                        {t("workOrders.solution") + ":"}
                      </Grid>
                      <Grid size={{xs:6}}>
                        {nl2br(repairRequest.completionText)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Divider />
                  </Grid>
                </>
              )}
              {!!repairRequest.rejectionText && repairRequest.rejectionText.trim() !== "" && (
                <>
                  <Grid size={{xs:12}}>
                    <Grid container className={classes.infoGridRow}>
                      <Grid size={{xs:6}}>
                        {t("general.reasonOfRejection") + ":"}
                      </Grid>
                      <Grid size={{xs:6}}>
                        {nl2br(repairRequest.rejectionText)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Divider />
                  </Grid>
                </>
              )}
            </>
          )}
          {(isSiteManagerBuilding || isAftercareEmployeeBuilding) && (
            <>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("repairRequest.internalNotes") + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    {this.renderEditTextbox({ title: t("repairRequest.internalNotes"), key: "notes", value: repairRequest.notes, multi: true, isCopyTextAllowed: true })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.company") + ":"}
              </Grid>
              <Grid sx={{ wordWrap: "break-word" }} size={{xs:6}}>
                {repairRequest?.responsiblePerson?.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.responsible") + ":"}
              </Grid>
              <Grid sx={{ wordWrap: "break-word" }} size={{xs:6}}>
                {
                  repairRequest?.status !== "Afgehandeld" &&
                  <Box sx={{ float: "right", margin: '-4px -12px -8px 0px' }}>
                    <Tooltip title={t("general.edit")}>
                      <IconButton
                        aria-label="edit"
                        component="span"
                        size="small"
                        edge="end"
                        onClick={(e) => this.setState({ responsiblePopupAnchorEl: e.currentTarget })}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                {repairRequest?.responsiblePerson?.relationName}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div >)
    );
  }

  blockObjectInfo() {
    const { t, classes, app, user } = this.props;
    const { repairRequest, building, openObjectAdditionalMenu, locations, uploading } = this.state;
    const isBuyer = user && user.type === userAccountTypeConstants.buyer;
    let repairRequestAttachments = { images: [], pdfs: [] }
    let resolverAttachments = { images: [], pdfs: [] }
    repairRequest.attachments.forEach((attachment) => {
      if (attachment.resolverId) {
        resolverAttachments = {
          ...resolverAttachments,
          images: [...resolverAttachments.images, attachment]
        }
      } else {
        repairRequestAttachments = {
          ...repairRequestAttachments,
          images: [...repairRequestAttachments.images, attachment]
        }
      }
    })

    repairRequest.pdfAttachments.forEach((attachment) => {
      if (attachment.resolverId) {
        resolverAttachments = {
          ...resolverAttachments,
          pdfs: [...resolverAttachments.pdfs, attachment]
        }
      } else {
        repairRequestAttachments = {
          ...repairRequestAttachments,
          pdfs: [...repairRequestAttachments.pdfs, attachment]
        }
      }
    })

    return (
      (<div className={classes.block}>
        <div className={classes.objectInfoTitle}>
          <Typography component="h2" variant="h6" className={clsx(classes.subHeader, classes.grow)}>
            <Build color="primary" /> &nbsp;
            {t("repairRequest.repairRequestInformation")}
          </Typography>
        </div>
        <Grid size={{xs:12}}>
          <Grid container className={classes.infoGridRow}>
            <Grid size={{xs:6}}>
              {t("general.description") + ":"}
            </Grid>
            <Grid size={{xs:6}}>
              {this.renderEditTextbox({ title: t("general.description"), key: "desc", value: repairRequest.desc, isCopyTextAllowed: true })}
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{xs:12}}>
          <Divider />
        </Grid>
        <Grid size={{xs:12}}>
          <Grid container className={classes.infoGridRow}>
            <Grid size={{xs:6}}>
              {t("repairRequest.originalRepairRequest") + ":"}
            </Grid>
            <Grid size={{xs:6}}>
              {this.renderEditTextbox({ title: t("repairRequest.originalRepairRequest"), key: "detailDesc", value: repairRequest.detailDesc, multi: true, isCopyTextAllowed: true, disableEdit: !(repairRequest.status === "Nieuw" && !!repairRequest.surveyId && (repairRequest.surveyStatus === 0 || repairRequest.surveyStatus === 1)) })
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{xs:12}}>
          <Divider />
        </Grid>
        <Grid size={{xs:12}}>
          <Grid container className={classes.infoGridRow}>
            <Grid size={{xs:6}}>
              {t("general.location") + ":"}
            </Grid>

            <Grid size={{xs:6}}>
              <Grid size={{xs:12}}>
                <div className={classes.locationInfoContainer}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={repairRequest.completed}
                  >
                    <InputLabel id="demo-simple-select-label">{t("general.location")}</InputLabel>
                    <Select size="small" labelId="demo-simple-select-label" id="demo-simple-select" value={repairRequest.locationId} onChange={(e) => this.updateRepairRequest("locationid", e.target.value)} label={t("general.location")}>
                      {(!locations || locations.filter((x) => x.id === repairRequest.locationId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                      {locations &&
                        locations.map((location, index) => (
                          <MenuItem key={index} value={location.id}>
                            {location.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {!repairRequest.completed && !repairRequest.drawingPinLocation && !!this.state.drawingDossiersList.length && (
                    <IconButton
                      className={classes.addLocationBtn}
                      aria-label="delete"
                      onClick={() => {
                        this.setState({
                          openRepairRequestMap: true,
                          isCreateRepairRequest: true,
                        })
                      }}
                      size="small"
                    >
                      <Room color="primary" />
                    </IconButton>
                  )}
                </div>
                {repairRequest.drawingPinLocation ? (
                  <Grid className={classes.locationContainer} size={{xs:12}}>
                    <img
                      alt="map"
                      src={`${webApiUrl}api/Dossier/GetDossierPdfImage/${repairRequest.drawingPinLocation.dossierFileId}?page=${repairRequest.drawingPinLocation.pageNumber}`}
                      className={classes.locationPreview}
                      onClick={() => {
                        this.setState({
                          openRepairRequestMap: true,
                          isCreateRepairRequest: false,
                        })
                      }}
                    />
                    <div
                      className={classes.locationMarkPreview}
                      style={{
                        left: `calc(${repairRequest.drawingPinLocation.xrel} * 100% - 10px)`,
                        top: `calc(${repairRequest.drawingPinLocation.yrel} * 100% - 29px)`,
                      }}
                    >
                      <img
                        alt={"location-marker"}
                        src={"/Content/icon/pinLocation.png"}
                        className={classes.locationPin}
                      />
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{xs:12}}>
          <Divider />
        </Grid>
        <Grid size={{xs:12}}>
          <Grid container className={classes.infoGridRow}>
            <Grid size={{xs:12}}>
              {t("general.attachments") + ":"}
            </Grid>
          </Grid>
          <Grid container className={classes.infoGridRow}>
            <Grid size={{xs:12}}>
              <div className={classes.imageGallery}>
                {repairRequest.completed !== true && (
                  <div className={classes.dropzoneContainer}>
                    <Dropzone onFilesAdded={this.uploadAttachment} disabled={repairRequest.completed === true} uploading={uploading} accept="image/*, application/pdf " />
                  </div>
                )}
                {repairRequestAttachments.images &&
                  repairRequestAttachments.images.length > 0 &&
                  repairRequestAttachments.images.map((file, index) => (
                    <div className={classes.galleryImageTile}>
                      <div
                        onClick={() => this.handleImageModalOpen(index)}
                        style={{
                          backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                        }}
                      ></div>
                      {repairRequest.completed !== true && (
                        <Tooltip title={t("general.remove")}>
                          <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                {repairRequestAttachments.pdfs &&
                  repairRequestAttachments.pdfs.length > 0 &&
                  repairRequestAttachments.pdfs.map((file, index) => (
                    <div style={{ position: "relative" }}>
                      <div className={classes.galleryImageTile}>
                        <div
                          onClick={() => this.handlePdfModalOpen(index)}
                          style={{
                            backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                          }}
                        >
                          <Typography className={classes.pdfTextWrapper} ><PictureAsPdf color={"error"} /></Typography>
                        </div>
                        {repairRequest.completed !== true && (
                          <Tooltip title={t("general.remove")}>
                            <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                              <Clear />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:12}}>
                {t("repairRequest.solutionAttachments") + ":"}
              </Grid>
            </Grid>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:12}}>
                <div className={classes.imageGallery}>
                  {resolverAttachments.images &&
                    resolverAttachments.images.length > 0 &&
                    resolverAttachments.images.map((file, index) => (
                      <div className={classes.galleryImageTile}>
                        <div
                          onClick={() => this.handleImageModalOpen(index)}
                          style={{
                            backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                          }}
                        ></div>
                        {repairRequest.completed !== true && (
                          <Tooltip title={t("general.remove")}>
                            <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                              <Clear />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    ))}
                  {resolverAttachments.pdfs &&
                    resolverAttachments.pdfs.length > 0 &&
                    resolverAttachments.pdfs.map((file, index) => (
                      <div style={{ position: "relative" }}>
                        <div className={classes.galleryImageTile}>
                          <div
                            onClick={() => this.handlePdfModalOpen(index)}
                            style={{
                              backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                            }}
                          >
                            <Typography className={classes.pdfTextWrapper} ><PictureAsPdf color={"error"} /></Typography>
                          </div>
                          {repairRequest.completed !== true && (
                            <Tooltip title={t("general.remove")}>
                              <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                <Clear />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
        </Grid>
      </div>)
    );
  }

  blockContactInfo() {
    const { t, classes } = this.props;
    const { repairRequest } = this.state;
    return (
      (<div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Contacts color="primary" /> &nbsp;
          {t("general.contactDetails")}
        </Typography>
        <Grid container spacing={1}>
          {repairRequest && repairRequest.contactInfo && (
            <>
              {repairRequest.contactInfo.buyer && (
                <>
                  {repairRequest.contactInfo.buyer.type === 0 && (
                    <>
                      <Grid size={{xs:12}}>
                        <ContactCard
                          hidePointOfContactCheckbox={repairRequest.completed}
                          updatePointOfContact={this.updatePointOfContact}
                          object={repairRequest.contactInfo.buyer.p1}
                          pointOfContactType={0}
                          selectedPointOfContactType={repairRequest.pointOfContact}
                          subTitle={t("general.buyer1")}
                        />
                      </Grid>
                      {repairRequest.contactInfo.buyer.p2 && (
                        <Grid size={{xs:12}}>
                          <ContactCard
                            hidePointOfContactCheckbox={repairRequest.completed}
                            updatePointOfContact={this.updatePointOfContact}
                            object={repairRequest.contactInfo.buyer.p2}
                            pointOfContactType={0}
                            selectedPointOfContactType={repairRequest.pointOfContact}
                            subTitle={t("general.buyer2")}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {repairRequest.contactInfo.buyer.type === 1 && (
                    <Grid size={{xs:12}}>
                      <ContactCard
                        hidePointOfContactCheckbox={repairRequest.completed}
                        updatePointOfContact={this.updatePointOfContact}
                        object={repairRequest.contactInfo.buyer.org}
                        isOrg
                        pointOfContactType={0}
                        selectedPointOfContactType={repairRequest.pointOfContact}
                        subTitle={t("general.buyerOrganization")}
                      />
                    </Grid>
                  )}
                </>
              )}
              {repairRequest.contactInfo.client && (
                <Grid size={{xs:12}}>
                  <ContactCard
                    hidePointOfContactCheckbox={repairRequest.completed}
                    updatePointOfContact={this.updatePointOfContact}
                    object={repairRequest.contactInfo.client}
                    isOrg
                    pointOfContactType={1}
                    selectedPointOfContactType={repairRequest.pointOfContact}
                    subTitle={t("repairRequest.client")}
                  />
                </Grid>
              )}
              {repairRequest.contactInfo.vvE && (
                <Grid size={{xs:12}}>
                  <ContactCard
                    hidePointOfContactCheckbox={repairRequest.completed}
                    updatePointOfContact={this.updatePointOfContact}
                    object={repairRequest.contactInfo.vvE}
                    isOrg
                    pointOfContactType={2}
                    selectedPointOfContactType={repairRequest.pointOfContact}
                    subTitle={t("general.vvE")}
                  />
                </Grid>
              )}
              {repairRequest.contactInfo.vvEAdministrator && (
                <Grid size={{xs:12}}>
                  <ContactCard
                    hidePointOfContactCheckbox={repairRequest.completed}
                    updatePointOfContact={this.updatePointOfContact}
                    object={repairRequest.contactInfo.vvEAdministrator}
                    isOrg
                    pointOfContactType={3}
                    selectedPointOfContactType={repairRequest.pointOfContact}
                    subTitle={t("general.vvEAdministrator")}
                  />
                </Grid>
              )}
              {repairRequest.contactInfo.propertyManagers &&
                repairRequest.contactInfo.propertyManagers.map((propertyManager, index) => (
                  <Grid key={index} size={{xs:12}}>
                    <ContactCard
                      hidePointOfContactCheckbox={repairRequest.completed}
                      updatePointOfContact={(value) => {
                        let result = value;
                        if (!!propertyManager.relationId) {
                          result = value + "," + propertyManager.organisatonId + "," + propertyManager.relationId;
                        }
                        this.updatePointOfContact(result);
                      }}
                      object={propertyManager}
                      isOrg
                      pointOfContactType={4}
                      selectedPointOfContactType={index === 0 && repairRequest.pointOfContact}
                      subTitle={t("general.propertyManager")}
                    />
                  </Grid>
                ))}
              {repairRequest.contactInfo.employee && (
                <Grid size={{xs:12}}>
                  <ContactCard
                    hidePointOfContactCheckbox={repairRequest.completed}
                    updatePointOfContact={this.updatePointOfContact}
                    object={repairRequest.contactInfo.employee}
                    isOrg
                    pointOfContactType={5}
                    selectedPointOfContactType={repairRequest.pointOfContact}
                    subTitle={t("general.employee")}
                  />
                </Grid>
              )}
              {repairRequest.resolvers &&
                repairRequest.resolvers.map((resolver, index) => (
                  <Grid key={index} size={{xs:12}}>
                    <ContactCard
                      hidePointOfContactCheckbox
                      object={resolver}
                      isOrg
                      pointOfContactType={-1}
                      subTitle={t("general.resolver") + " - " + t("general.workOrder") + " " + (!resolver.workOrderNumber ? "(" + t("general.draft") + ")" : resolver.workOrderNumber)}
                    />
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </div>)
    );
  }

  blockRepairRequestWorkOrder() {
    const { t, classes } = this.props;
    const { repairRequest, productServices, carryOutAsTypeList, terms, highlightErrors } = this.state;
    const selectedProductService = productServices && repairRequest && productServices.find((x) => x.id === repairRequest.productServiceId);
    const selectedSubProductService = selectedProductService && selectedProductService.subProductServiceList && selectedProductService.subProductServiceList.find((x) => x.id === repairRequest.subProductService1Id);
    const selectedSubProductService1 = selectedSubProductService && selectedSubProductService.subProductServiceList && selectedSubProductService.subProductServiceList.find((x) => x.id === repairRequest.subProductService2Id);
    /*Disable in case of "Wkb", "Inspectie", "Voorschouw" or "Oplevering"*/
    const isCarryOutAsTypeDisabled = repairRequest.completed || !!repairRequest.surveyId || !!repairRequest.assignmentId;
    return (
      (<div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Assignment color="primary" /> &nbsp;
          {t("general.additionalInformation")}
        </Typography>
        <Grid container>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("repairRequest.productOrService") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                <Autocomplete
                  id="product-service-select"
                  fullWidth
                  disabled={repairRequest.completed}
                  options={productServices && productServices}
                  value={productServices && selectedProductService}
                  onChange={(event, newValue) => {
                    if (!!newValue) {
                      this.updateRepairRequest("productserviceid", newValue.id)
                    }
                  }}
                  getOptionLabel={(option) => option.code + " - " + option.description}
                  renderInput={(params) => <TextField {...params} label={t("repairRequest.productOrService")} variant="outlined" size="small" error={highlightErrors === true && this.isProductServiceRequired() && !selectedProductService} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          {selectedProductService && !!selectedProductService.subProductServiceList && selectedProductService.subProductServiceList.length > 0 && (
            <>
              <Grid size={{xs:12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs:6}}>
                    {t("repairRequest.subProductServiceWithNumber", {
                      Number: 1,
                    }) + ":"}
                  </Grid>
                  <Grid size={{xs:6}}>
                    <Autocomplete
                      id="product-subservice1-select"
                      fullWidth
                      disabled={repairRequest.completed}
                      value={selectedProductService && selectedSubProductService}
                      options={selectedProductService.subProductServiceList}
                      onChange={(event, newValue) => {
                        if (!!newValue) {
                          this.updateRepairRequest("subproductservice1id", newValue.id)
                        }
                      }}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => <TextField {...params} size="small" label={t("repairRequest.subProductServiceWithNumber", { Number: 1 })} variant="outlined" />}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs:12}}>
                <Divider />
              </Grid>
              {selectedSubProductService && selectedSubProductService.subProductServiceList && selectedSubProductService.subProductServiceList.length > 0 && (
                <>
                  <Grid size={{xs:12}}>
                    <Grid container className={classes.infoGridRow}>
                      <Grid size={{xs:6}}>
                        {t("repairRequest.subProductServiceWithNumber", {
                          Number: 2,
                        }) + ":"}
                      </Grid>
                      <Grid size={{xs:6}}>
                        <Autocomplete
                          id="product-subservice2-select"
                          fullWidth
                          disabled={repairRequest.completed}
                          value={selectedSubProductService && selectedSubProductService1}
                          options={selectedSubProductService.subProductServiceList}
                          onChange={(event, newValue) => {
                            if (!!newValue) {
                              this.updateRepairRequest("subproductservice2id", newValue.id)
                            }
                          }}
                          getOptionLabel={(option) => `${option.description}`}
                          renderInput={(params) => <TextField {...params} label={t("repairRequest.subProductServiceWithNumber", { Number: 2 })} variant="outlined" margin="dense" />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <Divider />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("general.type") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                <FormControl fullWidth disabled={isCarryOutAsTypeDisabled} sx={{ marginTop: 1, marginBottom: 1 }}>
                  <InputLabel id="carry-out-as-type-select-label">{t("general.type")}</InputLabel>
                  <Select
                    size="small"
                    variant="outlined"
                    labelId="carry-out-as-type-select-label"
                    id="carry-out-as-type-select"
                    value={repairRequest.carryOutAsTypeId}
                    onChange={(e) => this.updateRepairRequest("carryoutastypeid", e.target.value)}
                    label={t("general.type")}
                    error={highlightErrors === true && !repairRequest.carryOutAsTypeId}
                  >
                    {(!carryOutAsTypeList || carryOutAsTypeList.filter((x) => x.id === repairRequest.carryOutAsTypeId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {carryOutAsTypeList &&
                      carryOutAsTypeList.map((t, index) => (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("workOrders.workOrderText") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {this.renderEditTextbox({ title: t("workOrders.workOrderText"), key: "workOrderText", value: repairRequest.workOrderText, multi: true, isCopyTextAllowed: true })}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("repairRequest.priority") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                <FormControl
                  fullWidth
                  disabled={repairRequest.completed}
                  sx={{ marginTop: 1, marginBottom: 1 }}
                >
                  <InputLabel id="priority-select-label">{t("repairRequest.priority")}</InputLabel>
                  <Select size="small" labelId="priority-select-label" id="priority-select" value={repairRequest.priority} onChange={(e) => this.updateRepairRequest("priority", e.target.value)} label={t("repairRequest.priority")}>
                    {(!repairRequest.priority || repairRequest.priority < 0 || repairRequest.priority > 2) && <MenuItem value="">&nbsp;</MenuItem>}

                    <MenuItem value={0}>{t("repairRequest.priority.0")}</MenuItem>
                    <MenuItem value={1}>{t("repairRequest.priority.1")}</MenuItem>
                    <MenuItem value={2}>{t("repairRequest.priority.2")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:12}}>
                {t("repairRequest.completionTerm") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                <FormControl
                  fullWidth
                  disabled={repairRequest.completed}
                  sx={{ marginTop: 1, marginBottom: 1 }}
                >
                  <InputLabel id="terms-select-label">{t("repairRequest.completionTerm")}</InputLabel>
                  <Select size="small" labelId="terms-select-label" id="terms-select" value={repairRequest.completionTermId} onChange={(e) => this.updateRepairRequest("completiontermid", e.target.value)} label={t("repairRequest.completionTerm")}>
                    {(!terms || terms.filter((x) => x.termId === repairRequest.completionTermId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {terms &&
                      terms.map((term, index) => (
                        <MenuItem key={index} value={term.termId}>
                          {term.description + " (" + term.noOfDays + " " + (term.workingDays === true ? t("repairRequest.businessDays") : t("repairRequest.calenderDays")) + ")"}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
          <Grid size={{xs:12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs:6}}>
                {t("repairRequest.targetCompletionDate.label") + ":"}
              </Grid>
              <Grid size={{xs:6}}>
                {repairRequest.targetCompletionDate && formatDate(new Date(repairRequest.targetCompletionDate))}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs:12}}>
            <Divider />
          </Grid>
        </Grid>
      </div>)
    );
  }

  blockExtraInfo() {
    const { t, classes } = this.props;
    const { repairRequest, typeList, natureList, causeList, causerList, organisationList } = this.state;
    return (
      (<div className={classes.block}>
        <Grid container sx={{
          alignItems: "center"
        }}>
          <Grid className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <ListAlt color="primary" /> &nbsp; {t("repairRequest.extraInformation")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid con size={{xs: 6}}>
                {t("general.type") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                <FormControl variant="outlined" size="small" fullWidth disabled={repairRequest.completed}>
                  <InputLabel id="type-select-label">{t("general.type")}</InputLabel>
                  <Select variant="outlined" labelId="type-select-label" id="type-select" value={repairRequest.typeId} onChange={(e) => this.updateRepairRequest("typeid", e.target.value)} label={t("general.type")}>
                    {(!typeList || typeList.filter((x) => x.id === repairRequest.typeId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {typeList &&
                      typeList.map((t, index) => (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.nature.label") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                <FormControl variant="outlined" size="small" fullWidth disabled={repairRequest.completed}>
                  <InputLabel id="nature-select-label">{t("repairRequest.nature.label")}</InputLabel>
                  <Select labelId="nature-select-label" id="nature-select" value={repairRequest.natureId} onChange={(e) => this.updateRepairRequest("natureid", e.target.value)} label={t("repairRequest.nature.label")}>
                    {(!natureList || natureList.filter((x) => x.id === repairRequest.natureId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {natureList &&
                      natureList.map((t, index) => (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.cause") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                <FormControl variant="outlined" size="small" fullWidth disabled={repairRequest.completed}>
                  <InputLabel id="cause-select-label">{t("repairRequest.cause")}</InputLabel>
                  <Select variant="outlined" labelId="cause-select-label" id="cause-select" value={repairRequest.causeId} onChange={(e) => this.updateRepairRequest("causeid", e.target.value)} label={t("repairRequest.cause")}>
                    {(!causeList || causeList.filter((x) => x.id === repairRequest.causeId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {causeList &&
                      causeList.map((t, index) => (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.causer") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                <FormControl variant="outlined" size="small" fullWidth disabled={repairRequest.completed}>
                  <InputLabel id="causer-select-label">{t("repairRequest.causer")}</InputLabel>
                  <Select variant="outlined" labelId="causer-select-label" id="causer-select" value={repairRequest.causerId} onChange={(e) => this.updateRepairRequest("causerid", e.target.value)} label={t("repairRequest.causer")}>
                    {(!causerList || causerList.filter((x) => x.id === repairRequest.causerId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                    {causerList &&
                      causerList.map((t, index) => (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          {repairRequest.isCauserOrganisationVisible === true && (
            <>
              <Grid size={{xs: 12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs: 6}}>
                    {t("general.organisation") + ":"}
                  </Grid>
                  <Grid size={{xs: 6}}>
                    <FormControl variant="standard" margin="dense" fullWidth disabled={repairRequest.completed}>
                      <InputLabel id="organisation-select-label">{t("general.organisation")}</InputLabel>
                      <Select
                        labelId="organisation-select-label"
                        id="organisation-select"
                        value={repairRequest.causerOrganisationId}
                        onChange={(e) => this.updateRepairRequest("causerorganisationid", e.target.value)}
                        label={t("general.organisation")}
                      >
                        {(!organisationList || organisationList.filter((x) => x.id === repairRequest.causerOrganisationId).length === 0) && <MenuItem value="">&nbsp;</MenuItem>}
                        {organisationList &&
                          organisationList.sort((a, b) => a.name.localeCompare(b.name)).map((t, index) => (
                            <MenuItem key={index} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs: 12}}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.bookingPeriod") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                {repairRequest.bookingPeriod && repairRequest.bookingPeriod}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.leadTime") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                {repairRequest.leadTime && repairRequest.leadTime}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          <Grid size={{xs: 12}}>
            <Grid container className={classes.infoGridRow}>
              <Grid size={{xs: 6}}>
                {t("repairRequest.clientReference") + ":"}
              </Grid>
              <Grid size={{xs: 6}}>
                {this.renderEditTextbox({ title: t("repairRequest.clientReference"), key: "clientreference", value: repairRequest.clientReference })}
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{xs: 12}}>
            <Divider />
          </Grid>
          {(repairRequest.surveyType === 0 || repairRequest.surveyType === 1 || repairRequest.surveyType === 2) && (
            <>
              <Grid size={{xs: 12}}>
                <Grid container className={classes.infoGridRow}>
                  <Grid size={{xs: 6}}>
                    {t("survey.label") + ":"}
                  </Grid>
                  <Grid size={{xs: 6}}>
                    {t("survey.type." + repairRequest.surveyType)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs: 12}}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>)
    );
  }

  blockWorkorder() {
    const { t, classes } = this.props;
    const { repairRequest, productServices, highlightErrors, building } = this.state;
    const hasErrors = (this.isProductServiceRequired() && !repairRequest.productServiceId) || !repairRequest.carryOutAsTypeId;

    const columns = [
      {
        name: "resolverId",
        options: {
          display: "excluded",
          filter: false,
        },
      },
      {
        name: "isRequiredHandling",
        label: " ",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var resolverId = tableMeta.rowData[0];
            var resolver = !!repairRequest && repairRequest.resolvers.find((x) => x.resolverId === resolverId);
            return (
              <Typography noWrap>
                {value === true && (
                  <Tooltip title={t("general.actionRequired")}>
                    <PriorityHigh color="secondary" size="small" />
                  </Tooltip>
                )}
                {resolver.overdue ? (
                  <Tooltip title={t("general.overdue")}>
                    <Schedule fontSize="small" color="error" />
                  </Tooltip>
                ) : resolver.is48HoursReminder ? (
                  <Tooltip title={t("general.48hours")}>
                    <Schedule fontSize="small" className={classes.warning} />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Typography>
            );
          },
        },
      },
      {
        name: "targetCompletionDate",
        label: t("repairRequest.targetCompletionDate.shortLabel"),
        options: {
          filter: true,
          customBodyRender: (v) => v && formatDate(new Date(v)),
        },
      },
      {
        name: "workOrderNumber",
        label: t("workOrders.workOrderNumber.label"),
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var resolverId = tableMeta.rowData[0];
            return (
              <Button style={{ padding: 0, minWidth: "auto", textTransform: "none" }} component={Link} to={this.workOrderRedirectHelper(resolverId)} color="primary">
                {!value ? t("general.draft") : value}
              </Button>
            );
          },
        },
      },
      {
        name: "name",
        label: t("general.resolver"),
        options: {
          filter: true,
        },
      },
      {
        name: "appointmentDateTime",
        label: t("general.appointment"),
        options: {
          customBodyRender: (appointmentDateTime, tableMeta) => {
            const resolverId = tableMeta.rowData[0];
            const currentWorkOrder = repairRequest?.resolvers.find((resolver) => resolver?.resolverId === resolverId)
            const formatedDate = currentWorkOrder?.appointmentCancelled && appointmentDateTime ? <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 400,
                color: "error.main",
                textDecoration: 'line-through'
              }}>{formatDate(new Date(appointmentDateTime) , true)}</Typography> : appointmentDateTime ? formatDate(new Date(appointmentDateTime), true) : '-'
            return (
              <Box>{formatedDate}</Box>
            );
          },
        },
      },
      {
        name: "status",
        label: t("general.status"),
        options: {
          filter: true,
          customBodyRender: (v) => v >= 0 && v <= 4 && t("resolver.status." + v),
        },
      },
    ];

    const options = {
      //filterType: 'dropdown',
      //responsive: 'vertical',
      download: false,
      // filter: true,
      print: false,
      search: false,
      viewColumns: false,
      pagination: false,
      customToolbar: () => (
        <>
          <Tooltip title={t("workOrders.create")}>
            <IconButton
              aria-describedby={"select-organisation"}
              color="inherit"
              aria-label="Add"
              component="span"
              disabled={(hasErrors && highlightErrors) || this.state.workorderPopoverOpenLoader || repairRequest.completed === true}
               onClick={() => {
                  if (!!hasErrors) this.setState({ highlightErrors: true });
                   else {
                     this.setState({ addWorkOrderModalOpen: true });
                   }
                 }}
              size="large"
            >
              {this.state.workorderPopoverOpenLoader ? <CircularProgress size={20} /> : <Add />}
            </IconButton>
          </Tooltip>
        </>
      ),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <div style={{ paddingRight: 4 }}>
          {repairRequest && repairRequest.completed !== true && this.state.selectedResolverIds && this.state.selectedResolverIds.length === 1 && (
            <>
              <Tooltip title={t("workOrders.copyWorkOrder")}>
                <IconButton
                  color="inherit"
                  aria-label="CopyWorkOrder"
                  component="span"
                  onClick={(e) => {
                    this.setState({
                      copyWorkOrderModalOpen: true,
                    })
                  }}
                  size="large"
                >
                  <FileCopyOutlined />
                </IconButton>
              </Tooltip>
            </>
          )}

          <Tooltip title={t("repairRequest.sendReminder")}>
            <IconButton
              color="inherit"
              aria-label="Reminder"
              component="span"
              disabled={repairRequest.completed === true || this.canSendReminderSelectedWorkorders() === false}
              onClick={(e) => {
                this.setState({ sendReminderAnchorEl: e.currentTarget });
              }}
              size="large"
            >
              <Mail />
            </IconButton>
          </Tooltip>
          {!!this.state.sendReminderAnchorEl && (
            <Dialog
              open={true}
              onClose={() =>
                this.setState({
                  sendReminderCCEmailList: [],
                  sendReminderCCEmailValid: true,
                  sendReminderAnchorEl: null,
                })
              }
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="send_reminder">{t("repairRequest.sendReminder")}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  disabled={this.state.sendingReminder === true}
                  error={this.state.sendReminderCCEmailValid !== true}
                  rows={2}
                  variant="outlined"
                  onChange={(e) => this.updateCCEmails(e.target.value)}
                  margin="dense"
                  id="ccemail"
                  label="CC"
                  type="email"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.sendingReminder === true}
                  onClick={() => {
                    this.setState({
                      sendReminderCCEmailList: [],
                      sendReminderCCEmailValid: true,
                      sendReminderAnchorEl: null,
                    });
                  }}
                  color="primary"
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  disabled={this.state.sendReminderCCEmailValid !== true || this.state.sendingReminder === true}
                  onClick={() => {
                    this.SendReminderToResolvers();
                  }}
                  color="primary"
                >
                  {t("repairRequest.sendReminder")}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Tooltip title={t("datatable.label.selectedrows.delete")}>
            <IconButton
              color="inherit"
              aria-label="Delete"
              component="span"
              disabled={repairRequest.completed === true || this.canDeleteSelectedWorkorders() === false}
              onClick={() => {
                this.DeleteRepairRequestResolvers();
                setSelectedRows([]);
              }}
              size="large"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
        let selectedResolverIds = [];
        for (var i = 0; i < rowsSelected.length; i++) {
          const resolver = repairRequest.resolvers[rowsSelected[i]];
          selectedResolverIds.push(resolver.resolverId);
        }
        this.setState({ selectedResolverIds });
      },
      textLabels: getDataTableTextLabels(t),
    };

    return (repairRequest && (<div className={classes.block}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <MUIDataTable
            className={classes.dataTable}
            title={
              <Typography component="h2" variant="h6" className="header">
                <Build color="primary" /> &nbsp; {t("general.workOrders")}
              </Typography>
            }
            data={!!repairRequest.resolvers ? repairRequest.resolvers : []}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </div>));
  }

  getPreviewFileAtributes = () => {
    const { attachmentIndex, pdfIndex, repairRequest } = this.state;
    const isImage = typeof attachmentIndex === "number"
    const isPdf = typeof pdfIndex === "number"
    if (isImage || isPdf) {
      const files = repairRequest && (isImage ? repairRequest.attachments : repairRequest.pdfAttachments)
      const index = isImage ? attachmentIndex : pdfIndex;
      const fileId = files?.[index]?.attachmentId || ""
      const isValidForOpenPreview = files && index != null && index >= 0 && index < files.length;
      const mimeType = getMimeTypefromString(isImage ? ".jpg" : ".pdf");

      return { isValidForOpenPreview, mimeType, fileId, index }
    }

    return undefined
  }

  render() {
    const { t, classes, app } = this.props;
    const { repairRequest, isNotify, toEmail, ccEmails, emailModel, repairRequestLoading, building, notesToEdit, repairRequestPrev, repairRequestNext } = this.state;
    const textBoxValue = this.state.handleType === "complete" ? this.state.completionTextToEdit : this.state.rejectionTextToEdit;
    const textBoxLabel = this.state.handleType === "complete" ? t("workOrders.solution") : t("general.reasonOfRejection");
    const textWarning = this.state.handleType === "complete" ? t("repairRequest.action.completionTextWarning") : t("repairRequest.action.rejectionTextWarning");
    let previewAttributes = this.getPreviewFileAtributes()

    const  handleResponsiblePerson = async (data) => {
      try {
        const response = await updateResponsiblePersonForRepairRequests(app, data.loginId, [repairRequest.requestId])
        if (response.status === 200) {
          this.GetRepairRequestDetails(true)
          this.setState({
            isSubmitting : false,
            responsiblePopupAnchorEl: null,
            toast: {
              open: true,
              message: t("repairRequest.updateResponsiblePerson.success"),
              type: "success"
            }
          });
        } else {
          this.setState({
            isSubmitting : false,
            responsiblePopupAnchorEl: null,
            toast: {
              open: true,
              message: t("general.failedToUpdate"),
              type: "error"
            }
          });
        }
      } catch (error) {
        this.setState({
          responsiblePopupAnchorEl: null,
          isSubmitting : false,
          toast: {
            open: true,
            message: t("general.failedToUpdate"),
            type: "error"
          }
        });
      }
    }
    return (
      (<Container maxWidth={false} className={classes.mainContainer} >
        <Grid container>
          <Grid container className={classes.container} size={{xs:12}}>
            <AppBar position="sticky" color="inherit">
              <Toolbar variant="dense">
                {building && (
                  <IconButton
                    color="inherit"
                    edge="start"
                    aria-label="go back"
                    component="span"
                    onClick={() => {
                      history.goBack();
                    }}
                    size="large"
                  >
                    <ArrowBack className={classes.arrowBackIcon} />
                  </IconButton>
                )}
                {
                  <Tooltip title={`${t("general.button.previous")} ${repairRequestPrev && repairRequestPrev.number}`}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      aria-label="previous"
                      disabled={!repairRequestPrev}
                      component="span"
                      onClick={() => {
                        if (!!repairRequestPrev) this.repairRequestRedirectHelper(repairRequestPrev.requestId);
                      }}
                      size="large"
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={`${t("general.button.next")} ${repairRequestNext && repairRequestNext.number}`}>
                    <IconButton
                      color="inherit"
                      edge="start"
                      aria-label="next"
                      disabled={!repairRequestNext}
                      component="span"
                      onClick={() => {
                        if (!!repairRequestNext) this.repairRequestRedirectHelper(repairRequestNext.requestId);
                      }}
                      size="large"
                    >
                      <ArrowRight />
                    </IconButton>
                  </Tooltip>
                }
                <Typography className={clsx(classes.bold, classes.grow)} noWrap><Hidden mdDown>{t("repairRequest.repairRequestDetails") + " "}</Hidden>{(repairRequest ? repairRequest.number : "")}</Typography>
                {repairRequest && repairRequest.completed !== true && (
                  <>
                    {(!repairRequest.resolvers || !repairRequest.resolvers.length || (repairRequest.resolvers.length > 0 && (repairRequest.isAllWorkOrderDeclined || repairRequest.isAllWorkOrderCompleted))) && (
                      <>
                        <Button aria-describedby="handleRepairRequestPopup" variant="outlined" color="primary" className={classes.actionButtons} onClick={(e) => this.initiateHandle(e, "turndown")}>
                          {t("general.button.reject")}
                        </Button>
                        <Button aria-describedby="handleRepairRequestPopup" variant="outlined" color="primary" className={classes.actionButtons} onClick={(e) => this.initiateHandle(e, "complete")}>
                          {t("general.button.complete")}
                        </Button>
                      </>
                    )}
                    {!!this.state.anchorElHandleRepairRequest && this.state.handleType && (
                      <Popover
                        open={true}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        id={"handleRepairRequestPopup"}
                        anchorEl={this.state.anchorElHandleRepairRequest}
                        onClose={() => {
                          this.setState({
                            anchorElHandleRepairRequest: null,
                          });
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid>
                              <Alert className={classes.alert} severity="warning">
                                {textWarning}
                              </Alert>
                            </Grid>
                            <Grid>
                              <Typography variant="h6">{t("repairRequest.button." + this.state.handleType)}</Typography>
                            </Grid>
                            <Grid>
                              <TextField
                                label={textBoxLabel}
                                className={classes.textField}
                                value={textBoxValue}
                                onChange={(e) => {
                                  if (this.state.handleType === "complete")
                                    this.setState({
                                      completionTextToEdit: e.target.value,
                                    });
                                  else
                                    this.setState({
                                      rejectionTextToEdit: e.target.value,
                                    });
                                }}
                                margin="dense"
                                variant="outlined"
                                multiline
                                rows={10}
                                fullWidth
                                autoFocus
                                error={!textBoxValue || textBoxValue.trim() === ""}
                                disabled={repairRequest.completed}
                                slotProps={{
                                  input: {
                                    endAdornment : (
                                      <InputAdornment position="end">
                                       {textBoxValue && this.getCopyTextComponent({ toastValue:textBoxLabel, clipBoardValue: textBoxValue })}
                                      </InputAdornment>
                                    ),
                                  }
                                }}
                              />
                            </Grid>
                            <Grid>
                              <TextField
                                label={t("repairRequest.internalNotes")}
                                className={classes.textField}
                                value={notesToEdit}
                                onChange={(e) => {
                                  this.setState({
                                    notesToEdit: e.target.value,
                                  });
                                }}
                                margin="dense"
                                variant="outlined"
                                multiline
                                rows={10}
                                fullWidth
                                error={!notesToEdit || notesToEdit.trim() === ""}
                                slotProps={{
                                  input: {
                                    endAdornment : (
                                      <InputAdornment position="end">
                                       {notesToEdit && this.getCopyTextComponent({ toastValue:t("repairRequest.internalNotes"), clipBoardValue: notesToEdit })}
                                      </InputAdornment>
                                    ),
                                  }
                                }}
                              />
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isNotify === true}
                                    onChange={(e) =>
                                      this.setState({
                                        isNotify: e.target.checked === true,
                                      })
                                    }
                                    name="notify"
                                    color="primary"
                                  />
                                }
                                label={t("workOrders.notifyNow")}
                              />
                            </Grid>
                            <Collapse in={isNotify} timeout="auto" unmountOnExit>
                              <Grid>
                                <FormControl component="fieldset" className={classes.formControlCheckList} required error={!toEmail || toEmail.trim() === ""}>
                                  <FormLabel component="legend">{t("general.email.to")}</FormLabel>
                                  {(!toEmail || toEmail.trim() === "") && <FormHelperText>{t("workOrders.noEmailKnown")}</FormHelperText>}
                                  <RadioGroup
                                    aria-label="toEmail"
                                    name="toEmail"
                                    value={toEmail}
                                    onChange={(e) => {
                                      this.setState({
                                        toEmail: e.target.value,
                                        ccEmails: ccEmails.filter((x) => x !== e.target.value),
                                      });
                                    }}
                                  >
                                    {emailModel &&
                                      emailModel
                                        .filter((x) => x.emails && x.emails.length > 0 && x.key !== "resolver")
                                        .map((item, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={item.key}
                                            control={<Radio />}
                                            label={
                                              <>
                                                {item.title}
                                                {toEmail === item.key && (
                                                  <>
                                                    {item.emails &&
                                                      item.emails.map((email, index1) => (
                                                        <React.Fragment key={index1}>
                                                          <br />
                                                          <Typography variant="caption">{email}</Typography>
                                                        </React.Fragment>
                                                      ))}
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid>
                                <FormControl component="fieldset" className={classes.formControlCheckList}>
                                  <FormLabel component="legend">{t("general.email.cc")}</FormLabel>
                                  <FormGroup>
                                    {emailModel &&
                                      emailModel
                                        .filter((x) => x.key !== toEmail && x.emails && x.emails.length > 0)
                                        .map((item, index) => (
                                          <FormControlLabel
                                            key={index}
                                            control={
                                              <Checkbox
                                                checked={ccEmails.includes(item.key)}
                                                color="primary"
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    let ccEmailsToUpdate = ccEmails.slice();
                                                    ccEmailsToUpdate.push(item.key);
                                                    this.setState({
                                                      ccEmails: ccEmailsToUpdate,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      ccEmails: ccEmails.filter((x) => x !== item.key),
                                                    });
                                                  }
                                                }}
                                                name={"cc-" + item.key}
                                              />
                                            }
                                            label={
                                              <>
                                                {item.title}
                                                {ccEmails.includes(item.key) && (
                                                  <>
                                                    {item.emails &&
                                                      item.emails.map((email, index1) => (
                                                        <React.Fragment key={index1}>
                                                          <br />
                                                          <Typography variant="caption">{email}</Typography>
                                                        </React.Fragment>
                                                      ))}
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </Collapse>
                            <Grid>
                              <Grid container spacing={1} sx={{
                                justifyContent: "flex-end"
                              }}>
                                <Grid>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      this.setState({
                                        anchorElHandleRepairRequest: null,
                                      });
                                    }}
                                  >
                                    {t("general.cancel")}
                                  </Button>
                                </Grid>
                                <Grid>
                                  <Button variant="outlined" disabled={(isNotify === true && (!toEmail || toEmail.trim() === "")) || !textBoxValue || textBoxValue.trim() === ""} onClick={() => this.handleRepairRequest()}>
                                    {t("repairRequest.button." + this.state.handleType)}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
            {repairRequestLoading ? (
              <div className={classes.mainLoaderContainer}>
                <CircularProgress size={30} />
              </div>
            ) : (
              repairRequest && (
                <Grid size={{xs: 12}}>
                  <Grid container spacing={2} className={classes.innerContainer} sx={{
                    alignItems: "flex-start"
                  }}>
                    {
                      //<Grid item xs={12}>
                      //    <Typography variant="h6">{t('general.generalInformation')}</Typography>
                      //</Grid>
                    }
                    <Grid
                      size={{
                        xs: 12,
                        md: 6,
                        lg: 4
                      }}>
                      <Grid container spacing={2}>
                        <Grid size={{xs: 12}}>
                          {this.blockGeneralRepairRequest()}
                        </Grid>
                        <Grid size={{xs: 12}}>
                          {this.blockObjectInfo()}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Hidden lgDown>
                      <Grid size={{xs:4}}>
                        <Grid container spacing={2}>
                          <Grid size={{xs: 12}}>
                            {this.blockRepairRequestWorkOrder()}
                          </Grid>
                          <Grid size={{xs: 12}}>
                            {this.blockExtraInfo()}
                          </Grid>
                          <Grid size={{xs: 12}}>
                            {this.blockWorkorder()}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>

                    <Grid
                      size={{
                        xs: 12,
                        md: 6,
                        lg: 4
                      }}>
                      <Grid container spacing={2}>
                        <Grid size={{xs: 12}}>
                          {this.blockStatus()}
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <div className={classes.block}>
                              <NotificationPanel hasChats={repairRequest.hasChats} repairRequestId={repairRequest.requestId} repairRequestNumber={repairRequest.number} resolverId={repairRequest.resolverId}
                                generateWorkOrderPageLink={this.workOrderRedirectHelper} hideWorkOrderLink={false} expandViewTitle={`${repairRequest.number} - ${repairRequest.desc}`} canInitiateChat={repairRequest?.canInitiateChat}  refreshParentState={()=>this.GetRepairRequestDetails(true)} />
                            </div>
                        </Grid>
                        <Grid size={{xs: 12}}>
                          {this.blockContactInfo()}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Hidden lgUp>
                      <Grid
                        size={{
                          xs: 12,
                          md: 6
                        }}>
                        {this.blockRepairRequestWorkOrder()}
                      </Grid>
                      <Grid
                        size={{
                          xs: 12,
                          md: 6
                        }}>
                        {this.blockExtraInfo()}
                      </Grid>
                      <Grid
                        size={{
                          xs: 12,
                          md: 6
                        }}>
                        {this.blockWorkorder()}
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <SelectResponsiblePerson
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          customTitle={'general.responsible'}
          onSubmit={(values) => handleResponsiblePerson(values)}
          setLoading={(val) => { this.setState({ organizationsLoading: val, isSubmitting: true }) }}
          loading={this.state.organizationsLoading}
          forRepairRequests
          repairRequestIds={[repairRequest?.requestId]}
          anchorEl={this.state.responsiblePopupAnchorEl}
          onClose={() => {
            this.setState({ responsiblePopupAnchorEl: null })
          }}
          isSubmitting={this.state.isSubmitting}
        />
        {previewAttributes?.isValidForOpenPreview && (
          <DocumentViewer
            radius={classes.docRadius}
            isSurveying
            open={previewAttributes?.index != null}
            onClose={this.handleModalClose}
            documents={[
              {
                uri: URLS.GET_ATTACHMENT + encodeURI(previewAttributes?.fileId),
                fileType: previewAttributes?.mimeType, //TODO: use mimetype from server after api changes
                name: "",
              },
            ]}
          />
        )}
        {this.state.repairRequest && (
          <RepairRequestForm
            isDrawingLocation
            setSelectedDrawingFiles={(val) => this.setState({ selectedDrawingFiles: val, currentPage: 1 })}
            setCurrentPage={(val) => this.setState({ currentPage: val })}
            selectedDrawingFiles={this.state.selectedDrawingFiles}
            projectId={this.state.repairRequest.projectId}
            currentPage={this.state.currentPage}
            requestId={this.state.repairRequest.requestId}
            setCordinates={(drawingPinLocationCord) => this.setState({ drawingPinLocationCord })}
            drawingLocation={this.state.repairRequest.drawingPinLocation}
            openRepairRequestMap={this.state.openRepairRequestMap}
            onClose={this.updateRepairRequestLocationPinPoint}
            updateOrAddLocation
            isCreateRepairRequest={!repairRequest.completed}
            isReadOnly={repairRequest.completed}
          />
        )}
        {(this.state.addWorkOrderModalOpen || this.state.copyWorkOrderModalOpen) && (
          <SelectOrganizationModal
            handleCloseOrganizationModal={() => this.setState({ addWorkOrderModalOpen: false, copyWorkOrderModalOpen: false })}
            setLoading={(val) => this.state.addWorkOrderModalOpen && this.setState({ workorderPopoverOpenLoader: val })}
            projectId={building && building.projectId}
            onSelect={(item) => this.state.addWorkOrderModalOpen  ? this.AddRepairRequestResolver(item.itemId)  : this.state.copyWorkOrderModalOpen   ? this.copyWorkOrder(item.itemId)   : null }  
          />
        )}
        <Snackbar
          open={this.state.toast.open}
          autoHideDuration={6000}
          onClose={() =>
            this.setState({
              toast:{
                open: false,
                message:"",
                type:"error"
              }
            })
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          key={this.state.toast.message}
        >
          <Alert
            onClose={() =>
              this.setState({
                toast:{
                  open: false,
                  message:"",
                  type:"error"
                }
              })
            }
            severity={this.state.toast.type}
          >
            {this.state.toast.message}
          </Alert>
        </Snackbar>
      </Container>)
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, surveying, app } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  const { selectedMoment, selectedAssignment } = surveying;
  const allBuildings = all;
  return {
    user,
    selected,
    allBuildings,
    selectedMoment,
    selectedAssignment,
    app,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as RepairRequestWorkOrdersPage };
