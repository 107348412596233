import { TAB_NAVIGATION } from '../_constants';

const initialState = {
  activeTab: undefined,
  tabs: []
}

export function tabNavigationReducer(state = initialState, action) {
  switch (action.type) {

    case TAB_NAVIGATION.SET_TABS: {
      return { ...state, activeTab: action?.data?.activeTab?.toString() ? action?.data?.activeTab : action.data.tabs.length - 1, tabs: action.data.tabs }
    }

    case TAB_NAVIGATION.CLEAR:
      return initialState
    default:
      return state
  }
}