import { AutomationDateField, AutomationForDays } from "../../../_helpers";

export const getAutomationDateFields= (t)=>{
   return [
            { label: t("taskAutomation.dateFieldType.0"), value: AutomationDateField.PreDelivery },
            { label: t("taskAutomation.dateFieldType.1"), value: AutomationDateField.Delivery },
            { label: t("taskAutomation.dateFieldType.2"), value: AutomationDateField.SecondSignature },
            { label: t("taskAutomation.dateFieldType.3"), value: AutomationDateField.EndOfMaintenancePeriod }
        ];
}

export const getAutomationForDays= (t)=>{
    return [
            { label: t("taskAutomation.dateOffsetType.1"), value: AutomationForDays.Before },
            { label: t("taskAutomation.dateOffsetType.2"), value: AutomationForDays.After },
            { label: t("taskAutomation.dateOffsetType.0"), value: AutomationForDays.On },
        ]
}