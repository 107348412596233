// NOTE: If any changes are made to the MobileGridHeader.jsx file, please ensure that the DesktopGridHeader.jsx file is also updated accordingly.
import { useEffect } from "react";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridCloseIcon,
  GridSearchIcon,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import { Badge, Box,List, IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { grey } from "@mui/material/colors";

const MobileGridHeader = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  handleSearch,
  searchText,
  setSearchText,
  quickFilterItems,
  handleExportModal,
  toolbarStartActions,
  toolbarEndActions,
  rightGroupedButtons,
  currentView,
  filterAnchorEl,
  setFilterAnchorEl,
  hideExportBtn,
  searchInputRef
}) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const hideGridToolbarOptionsForTabletOrMobile = !(isTabletOrMobile && currentView === "list") // hide column selection and density option for mobile or tablet
  const open = Boolean(filterAnchorEl);
  
  useEffect(() => {
    if (searchText) {
      searchInputRef?.current?.focus();
    }
  }, [searchInputRef, searchText]); 

  return (
    (<GridToolbarContainer sx={{ paddingBottom: !hideGridToolbarOptionsForTabletOrMobile && '8px',}}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          display: { xs: "block" }
        }}>
        <Box
          sx={{
            width: "100%",
            padding: "1px 5px",
            borderRadius: "7px",
            boxShadow: theme.shadows[1],
            display: "flex",
            alignItems: "center"
          }}>
          <TextField
            fullWidth
            id="standard-search"
            placeholder={t("datatable.label.toolbar.search")}
            type="text"
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText?.(e.target.value);
              handleSearch?.(e.target.value);
            }}
            inputRef={searchInputRef}
            sx={{ width: "100%", fontSize: "15px" }}
            slotProps={{
              input: {
                disableUnderline: true,
                startAdornment: (
                  <IconButton size="small">
                    <GridSearchIcon
                      sx={{ margin: "0px 5px", color: "primary.main" }}
                    />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (searchText !== "") {
                        handleSearch?.("");
                        setSearchText?.("");
                      }
                    }}
                  >
                    {searchText && (
                      <GridCloseIcon
                        fontSize="small"
                        sx={{ margin: "0px 5px", color: "primary.main" }}
                      />
                    )}
                  </IconButton>
                ),
              }
            }}
          />
        </Box>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent:"space-between",
            mt: 1,
            px:0.5
          }}>
            
         <Stack direction="row" sx={{
            pt: 0.25,
            overflow:'auto',
            [theme.breakpoints.down("sm")]: {
              width: `calc(100% - ${toolbarEndActions?.length * 40}px)`,
            },
          }}>
            <Stack direction={'row'}>
          {toolbarStartActions?.map((customToolbarStartAction , i) => {
              const IconComponent = customToolbarStartAction.icon;
              return (
                <IconButton  onClick={customToolbarStartAction.onClick}>
                  <IconComponent  color={customToolbarStartAction.iconColor || "primary"} />
                </IconButton>
              );
            })} 
            {
              hideGridToolbarOptionsForTabletOrMobile &&
            <GridToolbarColumnsButton slotProps={{ button:{ sx :{minWidth:0 , alignItems:"center", padding:1,"& .MuiButton-startIcon":{
              margin:0,
              "& .MuiSvgIcon-root": {
                fontSize:24
              }
            }}} }} />
            }
            <GridToolbarFilterButton slotProps={{ button:{ sx:{  minWidth:0 , alignItems:"center", padding:1, "& .MuiButton-startIcon":{
              margin:0,
              "& .MuiSvgIcon-root": {
                fontSize:24
              }
            } }}}} />
            {
              hideGridToolbarOptionsForTabletOrMobile &&
              <GridToolbarDensitySelector slotProps={{ button:{ sx:{  minWidth:0 , alignItems:"center", padding:1,"& .MuiButton-startIcon":{
              margin:0,
             "& .MuiSvgIcon-root": {
               fontSize:24
             }
            }}}}}/>
            }
            {!hideExportBtn && <GridToolbarExportContainer slotProps={{button: { sx: {  minWidth: 0, alignItems: "center", padding: 1, "& .MuiButton-startIcon": {
              margin: 0,
             },
             "& .MuiSvgIcon-root": {
               fontSize:24
             } }}}}>
              <GridCsvExportMenuItem
                onClick={(e) => {
                  setColumnSelectorType("download-csv");
                  setOpenColumnsSelector(true);
                }}
              />
              <MenuItem
                onClick={handleExportModal}
              >
                {t("datatable.label.toolbar.downloadExcel")}
              </MenuItem>
              <GridPrintExportMenuItem
                onClick={(e) => {
                  setColumnSelectorType("print");
                  setOpenColumnsSelector(true);
                }}
              />
            </GridToolbarExportContainer>}
               {toolbarEndActions?.map((customToolbarEndAction) => {
              const IconComponent = customToolbarEndAction.icon;
              return (
                <IconButton
                  onClick={customToolbarEndAction.onClick}
                >
                  <IconComponent color={customToolbarEndAction.iconColor || "primary"} />
                </IconButton>
              );
            })}
            </Stack>
          </Stack>
          <Stack direction={'row'}>
            {rightGroupedButtons?.map((customButton, index) => {
              const IconComponent = customButton.icon;
              return (
                <IconButton
                  key={index}
                  onClick={customButton.onClick}
                >
                  {customButton.badgeCount > 0 ? (
                    <Badge badgeContent={customButton.badgeCount} color="secondary">
                      <IconComponent color={customButton.iconColor || "primary"} />
                    </Badge>
                  ) : (
                    <IconComponent color={customButton.iconColor || "primary"} />
                  )}
                </IconButton>
              );
            })}
          </Stack>
      
        </Stack>
              <Menu
                anchorEl={filterAnchorEl}
                id="filter-menu"
                open={open}
                onClose={()=>setFilterAnchorEl(null)}
            >
                <Typography px={2} pb={1}>{t("general.quickFilter")}</Typography>
                 { quickFilterItems.length  &&  quickFilterItems?.map((item) => {
                    return <>
                    <List aria-label="filter-items" dense sx={{py:0}}>
                        <ListItemButton  sx={{color: item.isSelected ? theme.palette.primary.main : grey[600]}}  selected={item.isSelected}   onClick={item.onClick}  >
                            <ListItemIcon sx={{minWidth:40,color: "inherit"}}>
                              {item.iconText ? <Box
                                sx={{
                                  fontSize: 24,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 24,
                                  height: 24,
                                  fontWeight:700
                                }}>
                                {item.iconText}
                              </Box>
                             :<item.icon />
                              }
                            </ListItemIcon>

                            <ListItemText primary={item?.title} />
                        </ListItemButton>
                        </List>
                    </>
                })}
            </Menu>
      </Stack>
    </GridToolbarContainer>)
  );
};

export default MobileGridHeader;
