import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { ArrowBack } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from "@mui/styles/makeStyles";
import { getMimeTypefromString } from '../../../_helpers'
import { URLS } from '../../../apis/urls'

const PreviewTemplate = ({ isPreview, setIsPreview }) => {

    const { t } = useTranslation()
    const classes = useStyles();
    const previewTemplateData = isPreview?.data
    const previewTemplateId = isPreview?.data?.id
    const docs = previewTemplateId ? [{
        uri: URLS.GET_DOCUMENT_TEMPLATE_PREVIEW + previewTemplateId,
        fileType: getMimeTypefromString(".pdf"),
        fileName: previewTemplateData?.subject ? previewTemplateData?.subject : ""
    }] : [];

    return (
        <>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <IconButton onClick={() => setIsPreview({ open: false, type: "" })}>
                    <ArrowBack />
                </IconButton>
                <Typography>
                    {t("general.viewOtherTemplate")}
                </Typography>
            </Stack>
            <Box sx={{
                height: "calc(100% - 92px)"
            }}>
                {isPreview?.type === "DOCUMENT" && <DocViewer
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                        },
                        pdfVerticalScrollByDefault: true
                    }}
                    documents={docs}
                    activeDocument={docs[0]}
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                        disableThemeScrollbar: true,
                    }}
                    className={classes.documentComponent}
                />}

                {isPreview?.type === "EMAIL" &&
                    <Box height={'100%'} overflow={'auto'} >
                        <div dangerouslySetInnerHTML={{ __html: isPreview?.data?.template }} />
                    </Box>

                }
            </Box>
        </>

    )
}

export default memo(PreviewTemplate)  

const useStyles = makeStyles((theme) => ({
    documentComponent: {
        '& div:nth-last-child(1)': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400]
            },
        },
        '& #pdf-controls': {
            background: theme.palette.common.white,
            zIndex: 3
        }
    },
}));

