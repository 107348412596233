import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { store } from "../../.."
import { formatDate } from "../../../_helpers"
import { apps } from "../../../_constants"
import { getTemplateGridNavigationItem } from "../TemplatesGrid/util"

const getGeneral = (t) => {
    const { systemMaintenance: { templateDetailsStore: { templateDetails } } } = store.getState()
    if (!templateDetails) return []

    return [
        {
            title: t('general.templateId'),
            value: templateDetails?.id
        },
        {
            title: t('general.template.name'),
            value: templateDetails?.desc
        },
        {
            title: t("general.subject"),
            value: templateDetails?.subject
        },
        {
            title: t("general.type"),
            value: t(`template.type.${templateDetails?.templateType}`)
        },
        {
            title: t("general.documentCode"),
            value: templateDetails?.documentCode
        },
        {
            title: t("template.standard"),
            value: templateDetails?.standard ? <CheckBox color="disabled" /> : <CheckBoxOutlineBlank color="disabled" />
        },
        {
            title: t("general.active"),
            value: templateDetails?.active ? <CheckBox color="disabled" /> : <CheckBoxOutlineBlank color="disabled" />
        },
    ]
}
const getLinks = (t) => {
    const { systemMaintenance: { templateDetailsStore: { templateDetails } } } = store.getState()
    if (!templateDetails) return []

    return [
        {
            title: t("general.project"),
            value: templateDetails?.projectName
        },
    ]
}



export const getTemplateInfo = (t) => [
    {
        id: "general",
        type: "template",
        details: getGeneral(t),
        isHiddenEditIcon: true,

    },
    {
        id: "template",
        type: "template",
        isHiddenEditIcon: false,
        isPreviewDisplay: true,
    },

]

export const getLinksInfo = (t) => [
    {
        title: t("template.links"),
        type: "links",
        details: getLinks(t),
        isHiddenEditIcon: true,

    },

]

export const getTemplateDetailsNavigationItem = (t) => {
    const { systemMaintenance: { templateDetailsStore: { templateDetails } } } = store.getState()
    const { app } = store.getState();
    const templateGridNavigationItems = getTemplateGridNavigationItem(t)
    const breadCrumbTitle = templateDetails?.desc
    const to = app === apps.huisinfo ? "/systeemonderhound/sjablonen/" + templateDetails?.id : app === apps.aftercare ? "/nazorg/systeemonderhound/sjablonen/" + templateDetails?.id : '#'
    return {
        breadCrumbs: [...templateGridNavigationItems?.breadCrumbs, { title: t("general.template") + " - " + breadCrumbTitle, breadCrumbTitle, to }],
        tabs: [...templateGridNavigationItems?.tabs, { title: t('general.form'), to }]
    }
}