import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  DialogContent,
  AppBar,
  Typography,
  Toolbar,
} from "@mui/material";
import { Close, GroupsSharp, Inventory2Sharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-pro"
import { authHeader, getLocalStorageItem, setLocalStorageItem } from "../../_helpers";
import { apps, LOCAL_STORAGE_GRID_KEYS } from "../../_constants";
import DataGridV2 from "../../components/DataGridV2";
import { LabelColumnV2, LinkColumnV2 } from "../../components/DataGridV2/DataColumnsV2";
import { getGridContextMenuList } from "../../_helpers/getGridContextMenuList";
import i18n from "../../i18n";
import useGridDataModalHandler from "../../_hooks/useGridDataModalHandler";
const { webApiUrl } = window.appConfig;

const SelectOrganizationModal = ({
  setLoading,
  handleCloseOrganizationModal,
  projectId,
  onSelect
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [searchResolversResult, setSearchResolversResult] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const app = useSelector((state) => state.app);
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" : app === apps.constructionQuality ? "CONSTRUCTION_QUALITY" : "GENERAL";
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_PAGINATION[keyPrefix], undefined
  ))
  const {
    filterModel,
    handleFilter,
  } = useGridDataModalHandler({
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix], undefined
    ),
  });
  const GetResolversList = () => {
    setLoading && setLoading(true);
    setIsLoading(true)
    const url =
      webApiUrl +
      "api/Organisation/GetAllOrganisations/" +
      encodeURI(projectId)
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        setSearchResolversResult(findResponse.map((item) => ({
          ...item,
          id: item.id + item.productServiceId,
          isOwnOrganisation: item.isOwnOrganisation,
          searchField: `${item.isOwnOrganisation ? t("general.yes") : t("general.no")}`,
          itemId: item.id
        })));
        setLoading && setLoading(false);
        setIsLoading(false)
      })
      .catch((err) => {
        setLoading && setLoading(false);
      });
  };

  useEffect(() => {
    GetResolversList();
  }, []);

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix],
    i18n,
    t,
    apiRef,
    mode: "client"
  })
  const columns = useMemo(() => {
    return [
      {
        field: "productServiceName",
        headerName: t("repairRequest.productOrService"),
        flex: 1,
        sortable: false,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              tooltipLabel={rowData.productServiceName}
              label={rowData.productServiceName}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "productServiceName", isQuickFilterDisplay: Boolean(rowData.productServiceName), sortable: false }), extraData: { fieldName: "productServiceName" } }}
            />
          );
        },
      },
      {
        field: "name",
        headerName: t("general.organisation"),
        flex: 1,
        sortable: false,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LinkColumnV2
              tooltipLabel={rowData.name}
              label={rowData.name}
              onClick={() => {
                onSelect(rowData)
                handleCloseOrganizationModal()
              }}
              to="#"
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "name", isQuickFilterDisplay: Boolean(rowData.name), sortable: false }), extraData: { fieldName: "name" } }}
            />
          );
        },
      },
      {
        field: "involvedPartyType",
        headerName: t("general.type"),
        flex: 1,
        sortable: false,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              tooltipLabel={rowData.involvedPartyType}
              label={rowData.involvedPartyType}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "involvedPartyType", isQuickFilterDisplay: Boolean(rowData.involvedPartyType), sortable: false }), extraData: { fieldName: "involvedPartyType" } }}
            />
          );
        },
      },
      {
        field: "isOwnOrganisation",
        headerName: t("organization.search.method.1"),
        flex: 1,
        sortable: false,
        download: true,
        type: "boolean",
        getApplyQuickFilterFn: (searchVal) => {
          return (value, row) => {
            return row?.searchField?.toLowerCase().includes(searchVal.toLowerCase());
          };
        },
        renderCell: (params) => {
          const rowData = params.row;
          const isOwnOrganisation = rowData.isOwnOrganisation === true;
          return (
            <LabelColumnV2
              color={isOwnOrganisation ? theme.palette.success.main : theme.palette.error.main}
              label={isOwnOrganisation ? t("general.yes") : t("general.no")}
              cellSubMenuOptions={{ options: getOptions({ field: "isOwnOrganisation", isQuickFilterDisplay: typeof rowData.isOwnOrganisation === "boolean", sortable: false }), extraData: { fieldName: "isOwnOrganisation", type: "boolean" } }}
              request={rowData}
            />
          );
        },
      },
    ];
  }, [t, getOptions]);

  const onFilterModelChange = useCallback((newFilterMModel) => {
    handleFilter(newFilterMModel, (newFilterMModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix],
        newFilterMModel
      );
    })
  }, [handleFilter, keyPrefix])

  const handleClickProductServiceName = () => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const productServiceNameFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "productServiceName"
      );
      if (productServiceNameFilterIndex !== -1) {
        if (cloneFilterModal.items[productServiceNameFilterIndex].operator === "isNotEmpty") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "productServiceName"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[productServiceNameFilterIndex].operator = "isNotEmpty"
        }
      } else {
        cloneFilterModal.items.push({
          field: "productServiceName",
          operator: "isNotEmpty",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "productServiceName",
            operator: "isNotEmpty",
          }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix],
        newFilter
      );
    });
  };
  const handleClickInvolvedPartyTypes = () => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const involvedPartyTypeFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "involvedPartyType"
      );
      if (involvedPartyTypeFilterIndex !== -1) {
        if (cloneFilterModal.items[involvedPartyTypeFilterIndex].operator === "isNotEmpty") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "involvedPartyType"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[involvedPartyTypeFilterIndex].operator = "isNotEmpty"
        }
      } else {
        cloneFilterModal.items.push({
          field: "involvedPartyType",
          operator: "isNotEmpty",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "involvedPartyType",
            operator: "isNotEmpty",
          }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix],
        newFilter
      );
    });
  };
  const quickFilterItems = [
    {
      title: t("organization.search.method.0"),
      isSelected: filterModel?.items?.find(
        (item) => item.field === "involvedPartyType"
      )?.operator === "isNotEmpty",
       onClick: handleClickInvolvedPartyTypes,
       icon: (props)=> <GroupsSharp  {...props}/>
    },
    {
      title: t("repairRequest.productOrService"),
      onClick: handleClickProductServiceName,
      isSelected: filterModel?.items?.find(
        (item) => item.field === "productServiceName"
      )?.operator === "isNotEmpty",
      icon: (props)=> <Inventory2Sharp  {...props}/>
    },
  ]

  const getToolbarProps = useMemo(() => {
    const toolbarProps = {
      apiRef: apiRef,
      columns: columns,
      quickFilterItems: quickFilterItems,
      selectedRows: rowSelectionModel

    };
    return toolbarProps;
  }, [apiRef, quickFilterItems, rowSelectionModel, t]);


  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_PAGINATION[keyPrefix],
      newPaginationModel
    );
  }, [keyPrefix])
  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      open={true}
      onClose={handleCloseOrganizationModal}
      PaperProps={{
        sx: {
          maxWidth: "none",
          width: 1200,
          height: { md: 650 }
        }
      }}
    >
      <AppBar position='relative' sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseOrganizationModal}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2 }} variant="h6" component="div">
            {t("general.resolver")}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ p: 2 }}>
        <Box height={'100%'}>
          <DataGridV2
            localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_SEARCH_TEXT[keyPrefix]}
            localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_FILTER[keyPrefix]}
            localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_ORDER[keyPrefix]}
            localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_WIDTH[keyPrefix]}
            localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.SELECT_ORIGINATION_MODAL_COLUMN_VISIBILITY[keyPrefix]}
            apiRef={apiRef}
            hideExportBtn={true}
            loading={isLoading}
            rows={searchResolversResult}
            columns={columns}
            pagination
            {...(!!paginationModel && { paginationModel })}
            onPaginationModelChange={onPaginationModelChange}
            toolbarProps={getToolbarProps}
            filterMode="client"
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            {...(!!filterModel && { filterModel })}
            onFilterModelChange={onFilterModelChange}
          />
        </Box>
      </DialogContent>
    </Dialog>

  );
};

export default SelectOrganizationModal;
