export const validateCommaSeparatedEmails = ({ emailString, t }) => {
    if (!emailString) return { isValid: false, errorMessage: t("general.field.error") }
    // Split the email string by commas
    const emails = emailString.split(",").map(email => email.trim());
    let isValid = true;
    let errorMessage = '';

    // Regular expression for basic email validation
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    // Check each email for validity
    emails.every((email) => {
        if (email && !emailPattern.test(email)) {
            isValid = false;
            errorMessage = t('taskAutomation.commaSeparatedEmails.error')
            return false;
        }
        return true;
    });

    return { isValid, errorMessage };
};