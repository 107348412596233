import { TEMPLATE_DETAILS } from "../_constants"
import { getTemplateDetails } from "../apis/systemMaintenanceApis"

export const clearTemplateDetailsStore = () => {
    return { type: TEMPLATE_DETAILS.CLEAR }
}

const fetchTemplateDetails = (templateId) => {
    return async (dispatch) => {
        dispatch({ type: TEMPLATE_DETAILS.SET_LOADING_TEMPLATE_DETAILS, data: true });
        try {
            const res = await getTemplateDetails(templateId)
            dispatch({
                type: TEMPLATE_DETAILS.SET_TEMPLATE_DETAILS_DATA, data: res.data
            });
        } catch (e) {
            dispatch({ type: TEMPLATE_DETAILS.SET_LOADING_TEMPLATE_DETAILS, data: false });
        }
    }
}

export const templateDetailsActions = {
    clearTemplateDetailsStore,
    fetchTemplateDetails,
}