export const searchMergedFields = (mergeFields, searchText) => {
    if (!searchText) return mergeFields; // Return all fields if searchText is empty
   
    return mergeFields
      .filter(field =>
        field.children?.some(child =>
          child.text.toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .map(field => ({
        ...field,
        children: field.children?.filter(child =>
          child.text.toLowerCase().includes(searchText.toLowerCase())
        ) || []
      }));
  };