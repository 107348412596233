import { Clear, CloudUpload } from '@mui/icons-material'
import { Box, Button, Divider, Grid2, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toBase64 } from '../../../_helpers'
import { validateCommaSeparatedEmails } from '../../../_helpers/emailHelper'
import { toastActions } from '../../../_actions'

const TemplatesSetting = ({ values, handleChange, errors, setErrors, clearError }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = useSelector(state => state)

    return (
        <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {t('taskAutomation.templateSettings')}
            </Typography>
            <Divider sx={{
                my: 0
            }} />
            <Grid2 container sx={{
                py: 1
            }} spacing={{ xs: 2, md: 0 }}>
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}` } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>
                                {t('general.email')}
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('taskAutomation.availableTemplates')}
                                        value={taskDetails?.emailTemplate?.desc}
                                        size='small'
                                        fullWidth
                                        disabled
                                        slotProps={{
                                            htmlInput: { maxLength: 100 }
                                        }}
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        disabled
                                        id="outlined-multiline-flexible"
                                        label={t("general.templateId")}
                                        value={taskDetails?.emailTemplate?.id}
                                        size='small'
                                        fullWidth
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('general.subject')}
                                        value={values?.emailTemplate?.subject}
                                        size='small'
                                        fullWidth
                                        autoComplete='off'
                                        required
                                        error={errors.emailTemplate?.subject}
                                        onChange={(e) => {
                                            handleChange({ keyName: "emailTemplate.subject", value: e.target.value })
                                            clearError("emailTemplate.subject")
                                        }}
                                        slotProps={{
                                            htmlInput: { maxLength: 120 }
                                        }}
                                        helperText={errors?.emailTemplate?.subject}
                                    />
                                </Grid2>



                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('general.email.cc')}
                                        autoComplete='off'
                                        name="cc"
                                        value={values?.cc}
                                        size='small'
                                        error={errors?.cc}
                                        helperText={errors?.cc}
                                        fullWidth
                                        placeholder={t('general.email.cc')}
                                        slotProps={{
                                            htmlInput: { maxLength: 500 }
                                        }}
                                        onChange={(e) => {
                                            const emailString = e.target.value;
                                            const { isValid, errorMessage } = validateCommaSeparatedEmails({ emailString, t });
                                            !isValid && emailString ? setErrors({ ...errors, cc: errorMessage }) : clearError("cc")
                                            handleChange({ keyName: "cc", value: emailString });
                                        }}
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('general.email.bcc')}
                                        name="bcc"
                                        autoComplete='off'
                                        value={values?.bcc}
                                        error={errors?.bcc}
                                        helperText={errors?.bcc}
                                        size='small'
                                        fullWidth
                                        placeholder={t('general.email.bcc')}
                                        onChange={(e) => {
                                            const emailString = e.target.value;
                                            const { isValid, errorMessage } = validateCommaSeparatedEmails({ emailString, t });
                                            !isValid && emailString ? setErrors({ ...errors, bcc: errorMessage }) : clearError("bcc")
                                            handleChange({ keyName: "bcc", value: emailString });
                                        }}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <Box display={"flex"}>
                                        {values?.generalAttachment?.name?.length ?
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Typography
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    variant="body2"
                                                    color={"info"}
                                                >
                                                    {values?.generalAttachment?.name}
                                                </Typography>
                                                <IconButton onClick={() => {
                                                    handleChange({
                                                        keyName: "generalAttachment", value: {
                                                            name: "",
                                                            content: ""
                                                        }
                                                    });
                                                }} >
                                                    <Clear color='error' />
                                                </IconButton>
                                            </Box>
                                            :
                                            <Button
                                                key={values?.generalAttachment?.name}
                                                variant="contained"
                                                component="label"
                                                startIcon={<CloudUpload />}
                                                sx={{
                                                    flexShrink: 0,
                                                }}
                                            >
                                                {t('general.attachments')}
                                                <input
                                                    type="file"
                                                    name='generalAttachment'
                                                    onChange={async (event) => {
                                                        const selectedFile = event?.target?.files?.[0];
                                                        if (selectedFile?.size > 8 * 1024 * 1024) {
                                                            handleChange({
                                                                keyName: "generalAttachment", value: {
                                                                    name: "",
                                                                    content: ""
                                                                }
                                                            });
                                                            dispatch(toastActions.error(t("general.filesLargerThanMBNotAllowed", { maxFileSizeInMB: 8 })))
                                                            return;
                                                        }
                                                        const url = await toBase64(selectedFile)
                                                        handleChange({
                                                            keyName: "generalAttachment", value: {
                                                                name: selectedFile?.name,
                                                                content: url?.split(",")?.[1]
                                                            }
                                                        });
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </Button>}
                                    </Box>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}` } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>{t('taskAutomation.templateSettings.document')}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('taskAutomation.availableTemplates')}
                                        value={taskDetails?.documentTemplate?.desc}
                                        size='small'
                                        fullWidth
                                        disabled
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('general.documentCode')}
                                        value={taskDetails?.documentTemplate?.documentCode}
                                        size='small'
                                        fullWidth
                                        disabled
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t("general.templateId")}
                                        value={taskDetails?.documentTemplate?.id}
                                        size='small'
                                        fullWidth
                                        disabled
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Paper>
    )
}

export default TemplatesSetting
