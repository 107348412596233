import { combineReducers } from 'redux';
import { taskAutomationReducer } from './taskAutomation.reducer';
import { taskDetailsReducer } from './taskDetails.reducer';
import { templateReducer } from './template.reducer';
import { templateDetailsReducer } from './templateDetails.reducer';

export const systemMaintenanceReducer = combineReducers({
    taskStore : taskAutomationReducer,
    taskDetailsStore:taskDetailsReducer,
    templateStore : templateReducer,
    templateDetailsStore : templateDetailsReducer,
});
