const key = `TASK_AUTOMATION`

export const TASK_AUTOMATION = {

    CLEAR: `${key}_TASK_AUTOMATION_CLEAR`,
     SET_PROJECT_INFO: `${key}_SET_PROJECT_INFO`,
     SET_TASK_NAME:`${key}_SET_TASK_NAME`,
     SET_LOADING_EMAIL_TEMPLATES:`${key}_LOADING_EMAIL_TEMPLATES`,
     SET_DOCUMENT_LABEL_SELECT:`${key}_SET_DOCUMENT_LABEL_SELECT`,
     SET_ATTACHMENT_LABEL_SELECT:`${key}_SET_ATTACHMENT_LABEL_SELECT`,
     SET_EMAILS_TEMPLATES_DATA:`${key}_SET_EMAILS_TEMPLATES_DATA`,
     SELECT_EMAIL_TEMPLATES:`${key}_SELECT_EMAIL_TEMPLATES`,
     SET_LOADING_DOCUMENT_TEMPLATES:`${key}_LOADING_DOCUMENT_TEMPLATES`,
     SET_DOCUMENT_TEMPLATES_DATA:`${key}_SET_DOCUMENT_TEMPLATES_DATA`,
     SELECT_DOCUMENT_TEMPLATES:`${key}_SELECT_DOCUMENT_TEMPLATES`,
     SET_ATTACHMENT_FILE_NAME:`${key}_SET_ATTACHMENT_FILE_NAME`,
     SET_ATTACHMENT_FILE_CONTENT:`${key}_SET_ATTACHMENT_FILE_CONTENT`,
     TOGGLE_TASK_DRAWER:`${key}_TOGGLE_TASK_DRAWER`,
     CLEAR_DRAWER_INFO:`${key}_CLEAR_DRAWER_INFO`,
     SET_TASK_LIST_LOADING:`${key}_SET_TASK_LIST_LOADING`,
     SET_TASK_LIST:`${key}_SET_TASK_LIST`
};
