import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';

const RowDetails = ({ title, value, disabled = false }) => {
  return (
    (<Grid container spacing={2}>
      <Grid size={{ xs: 5 }} >
        <Typography
          variant="caption"
          component="p"
          overflow="auto"
          sx={{
            color: disabled ? "text.disabled" : "text.secondary",
            wordWrap: 'break-word'
          }}>
          {title}
        </Typography>
      </Grid>
      <Grid size={{ xs: 7 }} >
        {!React.isValidElement(value) ? (
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: disabled ? "text.disabled" : "text.primary",
                wordWrap: 'break-word'
              }}>
              {value?.toString()}
            </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>)
  );
};

export default RowDetails;
