import { TEMPLATES } from "../_constants";
import { getTemplates } from "../apis/systemMaintenanceApis";

const fetchTemplatesList = ({t, app}) => {
    return async (dispatch) => {
        dispatch({ type: TEMPLATES.SET_TEMPLATE_LIST_LOADING, data: true });
        try {
            const payload =  { // FIXME: remove the hardcode value and fix the logic
                "object": {  
                  "app": app
                },
                "sortModel": {},
                "conditions": []
              }
            const res = await getTemplates(payload)
            const resData = res?.data?.results || [];
            const data = resData.map(({ active, desc, id, projectName, templateType, documentCode }) => ({
                id,
                projectName,
                templateType,
                documentCode,
                active,
                desc,
                searchField:active ? t("general.yes") : t("general.no")
            }));
            dispatch({
                type: TEMPLATES.SET_TEMPLATE_LIST, data: {
                    loading: false,
                    data
                }
            });
        } catch (e) {
            dispatch({ type: TEMPLATES.SET_TEMPLATE_LIST_LOADING, data: false });
        }
    }
}

const clearTemplateListStore = () => {
    return {
        type: TEMPLATES.CLEAR,
    };
}

export const templateGridActions = {
    fetchTemplatesList,
    clearTemplateListStore
}