import React from "react";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
    AppBar,
    Dialog,
    DialogContent,
    IconButton,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { SYSTEM_MAINTENANCE_CONSTANTS } from "../../_constants";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Preview = ({ previewState, setPreviewState }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const docs = previewState?.type === SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT ? [{
        uri: previewState?.previewUrl,
        fileType: previewState?.fileType,
        fileName: previewState?.fileName
    }] : [];

    return (
        <Dialog
            onClose={() =>
                setPreviewState({ open: false, data: undefined, type: "" })
            }
            fullScreen
            open={previewState?.open}
        >
            <AppBar position="relative">
                <Toolbar>
                    <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Stack direction={"row"} alignItems={"center"}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() =>
                                    setPreviewState({
                                        open: false,
                                        data: undefined,
                                        type: "",
                                    })
                                }
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                            <Typography sx={{ ml: 2 }} variant="h6" component="div">
                                {t("general.preview")}
                            </Typography>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                {previewState?.type === "PREVIEW_EMAIL" && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `${previewState?.data}`,
                        }}
                    />
                )}

                {previewState?.type === "PREVIEW_DOCUMENT" && (
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: true,
                            },
                            pdfVerticalScrollByDefault: true
                        }}
                        documents={docs?.map((doc) => ({ ...doc, uri: doc?.uri?.startsWith("/") ? `${window.location.origin}${doc?.uri}` : doc?.uri }))}
                        activeDocument={docs[0]}
                        theme={{
                            disableThemeScrollbar: true,
                        }}
                        className={classes.documentComponent}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default Preview;

const useStyles = makeStyles((theme) => ({
    documentComponent: {
        "& div:nth-last-child(1)": {
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey[400],
            },
        },
        "& #pdf-controls": {
            background: theme.palette.common.white,
            zIndex: 3,
        },
    },
}));