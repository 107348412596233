import { Autocomplete, Stack, TextField } from '@mui/material'
import React, {useMemo, } from 'react'
import EmailTemplate from './EmailTemplate'
import DocumentTemplate from './DocumentTemplate'
import AttachDocument from './AttachDocument'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { groupBy } from '../../../../_helpers'
import { taskAutomationActions } from '../../../../_actions'

const GenerateTask = ({ setIsPreview  }) => {
  const { t } = useTranslation()
  const { buildings :{all: allBuildings} , systemMaintenance:{taskStore :{drawerInfo}}} = useSelector(state => state)
  const selectedProjectDetails = drawerInfo?.selectedProjectDetails
  const dispatch = useDispatch();
  const emailTemplates = drawerInfo?.emailTemplates?.templates
  
  const getProjectLabel = (projectInfo) => `${projectInfo?.projectNo} - ${projectInfo?.projectName}`

  const projectsList = useMemo(()=>{
    const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
    return projectsSoFar.map((projectInfo) => ({ label: getProjectLabel(projectInfo[0]), projectInfo: projectInfo?.[0] }))
  } ,[allBuildings])
  

  return (
    <Stack spacing={1}>
      <Stack spacing={2}>
        <TextField
          id="Automation-flow"
          label={t('taskAutomation.generalSettings.automationFlow')}
          value={drawerInfo?.taskName}
          onChange={(e) => { dispatch(taskAutomationActions.setNewTaskName(e.target.value)) }}
          size='small'
          fullWidth
        />
        <Autocomplete
          size="small"
          id="work-number"
          options={projectsList}
          renderInput={(params) => <TextField {...params} label={t('general.projectNumber')}
            placeholder={t('general.projectNumber')}
            slotProps={{
              inputLabel: {
                shrink: true,
              }
            }} />
          }
          value={projectsList.find(project => project.label === selectedProjectDetails?.selectedProjectLabel)}
          onChange={(event, newValue) => {
            dispatch(taskAutomationActions.setTaskProjectInfo({ selectedProjectInfo: newValue?.projectInfo, 
              selectedProjectLabel:  newValue?.label, 
              selectedProjectId: newValue?.projectInfo?.projectId}))
              if (newValue?.projectInfo?.projectId) {
                dispatch(taskAutomationActions.fetchEmailTemplates(newValue?.projectInfo?.projectId));
                dispatch(taskAutomationActions.fetchDocumentTemplates(newValue?.projectInfo?.projectId));
              }
          }}
        />
      </Stack>
      {selectedProjectDetails?.selectedProjectId && <>
        <EmailTemplate setIsPreview={setIsPreview} />
        <DocumentTemplate  setIsPreview={setIsPreview} />
        {(emailTemplates?.length > 0) && <AttachDocument />}
      </>}
    </Stack>
  )
}

export default GenerateTask
