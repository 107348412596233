export const commonConstants = {
    GETALL_REQUEST: 'BUILDINGS_GETALL_REQUEST',
    GETALL_SUCCESS: 'BUILDINGS_GETALL_SUCCESS',
    GETALL_FAILURE: 'BUILDINGS_GETALL_FAILURE',

    GET_AVAILABLE_SURVEY_MODULES: 'GET_AVAILABLE_SURVEY_MODULES',
    GET_AVAILABLE_SURVEY_MODULES_LOADING: 'GET_AVAILABLE_SURVEY_MODULES_LOADING',

    CHANGE_SUCCESS: 'BUILDINGS_SELECT_SUCCESS',
    UPDATE_RIGHTS_SUCCESS: 'UPDATE_RIGHTS_SUCCESS',

    CHANGE_APP_SUCCESS: 'CHANGE_APP_SUCCESS',
    GET_CURRENT_VERSION_DATE: 'GET_CURRENT_VERSION_DATE',

    GETCOUNT_SUCCESS: 'GETCOUNT_SUCCESS',
    GETCOUNT_ERROR: 'GETCOUNT_ERROR',
    GETCOUNT_LOADING: 'GETCOUNT_LOADING',

    CHANGE_MELDINGEN_FILTER_SUCCESS: 'CHANGE_MELDINGEN_FILTER_SUCCESS',

    GET_WORK_ORDERS_COUNTS_LOADING: "GET_WORK_ORDERS_COUNTS_LOADING",
    GET_WORK_ORDERS_COUNTS_ERROR: "GET_WORK_ORDERS_COUNTS_ERROR",
    GET_WORK_ORDERS_COUNTS_SUCCESS: "GET_WORK_ORDERS_COUNTS_SUCCESS",
};

export const LOCAL_STORAGE_GRID_KEYS = {
    REPAIR_REQUEST_COLUMN_VISIBILITY: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_VISIBILITY",
        GENERAL: "REPAIR_REQUEST_COLUMN_VISIBILITY",
    },
    REPAIR_REQUEST_DATAGRID_VIEW: {
        AFTERCARE: "AFTERCARE_REPAIR_REQUEST_DATAGRID_VIEW",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_DATAGRID_VIEW",
        GENERAL: "REPAIR_REQUEST_DATAGRID_VIEW",
    },
    REPAIR_REQUEST_COLUMN_SORT: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_SORT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_SORT",
        GENERAL: "REPAIR_REQUEST_COLUMN_SORT",
    },
    REPAIR_REQUEST_COLUMN_FILTER: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_FILTER",
        GENERAL: "REPAIR_REQUEST_COLUMN_FILTER",
    },
    REPAIR_REQUEST_COLUMN_ORDER: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_ORDER",
        GENERAL: "REPAIR_REQUEST_COLUMN_ORDER",
    },
    REPAIR_REQUEST_COLUMN_WIDTH: {
        AFTERCARE: "REPAIR_REQUEST_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_COLUMN_WIDTH",
        GENERAL : "REPAIR_REQUEST_COLUMN_WIDTH",
    },
    REPAIR_REQUEST_PAGINATION: {
        AFTERCARE: "REPAIR_REQUEST_PAGINATION_MODAL",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_PAGINATION",
        GENERAL : "REPAIR_REQUEST_PAGINATION",
    },
    REPAIR_REQUEST_SEARCH_TEXT: {
        AFTERCARE: "REPAIR_REQUEST_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_REPAIR_REQUEST_SEARCH_TEXT",
        GENERAL : "REPAIR_REQUEST_SEARCH_TEXT",
    },
    DASHBOARD_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_VISIBILITY",
        GENERAL: "DASHBOARD_COLUMN_VISIBILITY",
    },
    DASHBOARD_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_SORT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_SORT",
        GENERAL: "DASHBOARD_COLUMN_SORT",
    },
    DASHBOARD_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_FILTER",
        GENERAL: "DASHBOARD_COLUMN_FILTER",
    },
    DASHBOARD_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_ORDER",
        GENERAL: "DASHBOARD_COLUMN_ORDER",
    },
    DASHBOARD_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_DASHBOARD_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_COLUMN_WIDTH",
        GENERAL : "DASHBOARD_COLUMN_WIDTH",
    },
    DASHBOARD_PAGINATION: {
        AFTERCARE: "AFTERCARE_DASHBOARD_PAGINATION",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_PAGINATION",
        GENERAL : "DASHBOARD_PAGINATION",
    },
    DASHBOARD_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_DASHBOARD_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_DASHBOARD_SEARCH_TEXT",
        GENERAL : "DASHBOARD_SEARCH_TEXT",
    },
    WORK_ORDERS_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_COLUMN_VISIBILITY",
        RESOLVER:"RESOLVER_WORK_ORDERS_COLUMN_VISIBILITY",
        GENERAL:"WORK_ORDERS_COLUMN_VISIBILITY"
    },
    WORK_ORDERS_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_COLUMN_SORT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_COLUMN_SORT",
        RESOLVER:"RESOLVER_WORK_ORDERS_COLUMN_SORT",
        GENERAL:"WORK_ORDERS_COLUMN_SORT"
    },
    WORK_ORDERS_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_COLUMN_FILTER",
        RESOLVER:"RESOLVER_WORK_ORDERS_COLUMN_FILTER",
        GENERAL:"WORK_ORDERS_COLUMN_FILTER"
    },
    WORK_ORDERS_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_COLUMN_ORDER",
        RESOLVER:"RESOLVER_WORK_ORDERS_COLUMN_ORDER",
        GENERAL:"WORK_ORDERS_COLUMN_ORDER"
    },
    WORK_ORDERS_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_COLUMN_WIDTH",
        RESOLVER:"RESOLVER_WORK_ORDERS_COLUMN_WIDTH",
        GENERAL:"WORK_ORDERS_COLUMN_WIDTH"
    },
    WORK_ORDERS_PAGINATION: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_PAGINATION",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_PAGINATION",
        RESOLVER:"RESOLVER_WORK_ORDERS_PAGINATION",
        GENERAL:"WORK_ORDERS_PAGINATION"
    },
    WORK_ORDERS_DATAGRID_VIEW: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_DATAGRID_VIEW",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_DATAGRID_VIEW",
        RESOLVER:"RESOLVER_WORK_ORDERS_DATAGRID_VIEW",
        GENERAL: "WORK_ORDERS_DATAGRID_VIEW",
    },
    WORK_ORDERS_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_WORK_ORDERS_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_WORK_ORDERS_SEARCH_TEXT",
        RESOLVER:"RESOLVER_WORK_ORDERS_SEARCH_TEXT",
        GENERAL:"WORK_ORDERS_SEARCH_TEXT"
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_VISIBILITY",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_VISIBILITY",
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT",
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER",
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_ORDER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_ORDER"
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_WIDTH",
        GENERAL: "SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_WIDTH",
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION"
    },
    SYSTEM_MAINTENANCE_TASK_AUTOMATION_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_AUTOMATION_SEARCH_TEXT",
        GENERAL: "SYSTEM_MAINTENANCE_TASK_AUTOMATION_SEARCH_TEXT",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_VISIBILITY",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_VISIBILITY",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_ORDER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_ORDER",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_WIDTH",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_WIDTH",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_SEARCH_TEXT",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_SEARCH_TEXT",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_VISIBILITY",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_VISIBILITY",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_SORT",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_FILTER",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_ORDER",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_ORDER",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_WIDTH",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_COLUMN_WIDTH",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_PAGINATION",
    },
    SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_SEARCH_TEXT",
        GENERAL : "SYSTEM_MAINTENANCE_TASK_HISTORY_FAILED_SEARCH_TEXT",
    },
    SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_VISIBILITY",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_VISIBILITY"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_SORT"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_FILTER"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_ORDER",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_ORDER"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_WIDTH",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_COLUMN_WIDTH"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_PAGINATION"
    },
    SYSTEM_MAINTENANCE_TEMPLATES_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_SYSTEM_MAINTENANCE_TEMPLATES_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_SYSTEM_MAINTENANCE_TEMPLATES_SEARCH_TEXT",
        GENERAL: "SYSTEM_MAINTENANCE_TEMPLATES_SEARCH_TEXT"
    },
    LOGIN_REQUEST_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_COLUMN_VISIBILITY",
        GENERAL: "LOGIN_REQUEST_COLUMN_VISIBILITY",
    },
    LOGIN_REQUEST_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_COLUMN_SORT",
        GENERAL: "LOGIN_REQUEST_COLUMN_SORT"
    },
    LOGIN_REQUEST_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_COLUMN_FILTER",
        GENERAL: "LOGIN_REQUEST_COLUMN_FILTER"
    },
    LOGIN_REQUEST_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_COLUMN_ORDER",
        GENERAL: "LOGIN_REQUEST_COLUMN_ORDER"
    },
    LOGIN_REQUEST_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_COLUMN_WIDTH",
        GENERAL: "LOGIN_REQUEST_COLUMN_WIDTH"
    },
    LOGIN_REQUEST_PAGINATION: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_PAGINATION",
        GENERAL: "LOGIN_REQUEST_PAGINATION"
    },
    LOGIN_REQUEST_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_LOGIN_REQUEST_SEARCH_TEXT",
        GENERAL: "LOGIN_REQUEST_SEARCH_TEXT"
    },
    OBJECT_MODAL_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_COLUMN_VISIBILITY",
        GENERAL : "OBJECT_MODAL_COLUMN_VISIBILITY",
    },
    OBJECT_MODAL_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_COLUMN_SORT",
        GENERAL: "OBJECT_MODAL_COLUMN_SORT",
    },
    OBJECT_MODAL_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_COLUMN_FILTER",
        GENERAL: "OBJECT_MODAL_COLUMN_FILTER",
    },
    OBJECT_MODAL_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_COLUMN_ORDER",
        GENERAL: "OBJECT_MODAL_COLUMN_ORDER",
    },
    OBJECT_MODAL_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_COLUMN_WIDTH",
        GENERAL: "OBJECT_MODAL_COLUMN_WIDTH",
    },
    OBJECT_MODAL_PAGINATION: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_PAGINATION",
        GENERAL: "OBJECT_MODAL_PAGINATION",
    },
    OBJECT_MODAL_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_OBJECT_MODAL_SEARCH_TEXT",
        GENERAL: "OBJECT_MODAL_SEARCH_TEXT",
    },
    SELECT_ORIGINATION_MODAL_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_COLUMN_VISIBILITY",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_COLUMN_VISIBILITY",
        GENERAL: "SELECT_ORIGINATION_MODAL_COLUMN_VISIBILITY",
    },
    SELECT_ORIGINATION_MODAL_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_COLUMN_FILTER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_COLUMN_FILTER",
        GENERAL: "SELECT_ORIGINATION_MODAL_COLUMN_FILTER",
    },
    SELECT_ORIGINATION_MODAL_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_COLUMN_ORDER",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_COLUMN_ORDER",
        GENERAL: "SELECT_ORIGINATION_MODAL_COLUMN_ORDER",
    },
    SELECT_ORIGINATION_MODAL_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_COLUMN_WIDTH",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_COLUMN_WIDTH",
        GENERAL: "SELECT_ORIGINATION_MODAL_COLUMN_WIDTH",
    },
    SELECT_ORIGINATION_MODAL_PAGINATION: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_PAGINATION",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_PAGINATION",
        GENERAL: "SELECT_ORIGINATION_MODAL_PAGINATION",
    },
    SELECT_ORIGINATION_MODAL_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_SELECT_ORIGINATION_MODAL_SEARCH_TEXT",
        CONSTRUCTION_QUALITY: "CONSTRUCTION_QUALITY_SELECT_ORIGINATION_MODAL_SEARCH_TEXT",
        GENERAL: "SELECT_ORIGINATION_MODAL_SEARCH_TEXT",
    },
    COMMUNICATION_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_COLUMN_VISIBILITY",
        GENERAL: "COMMUNICATION_COLUMN_VISIBILITY",
    },
    COMMUNICATION_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_COLUMN_SORT",
        GENERAL: "COMMUNICATION_COLUMN_SORT",
    },
    COMMUNICATION_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_COLUMN_FILTER",
        GENERAL: "COMMUNICATION_COLUMN_FILTER",
    },
    COMMUNICATION_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_COLUMN_ORDER",
        GENERAL: "COMMUNICATION_COLUMN_ORDER",
    },
    COMMUNICATION_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_COLUMN_WIDTH",
        GENERAL: "COMMUNICATION_COLUMN_WIDTH",
    },
    COMMUNICATION_PAGINATION: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_COMMUNICATION_PAGINATION",
        GENERAL: "COMMUNICATION_PAGINATION",
    },
    COMMUNICATION_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_COMMUNICATION_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYER_GUID_COMMUNICATION_SEARCH_TEXT",
        GENERAL: "COMMUNICATION_SEARCH_TEXT",
    },
    CONTACTS_COMMUNICATION_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_COLUMN_VISIBILITY",
        BUYERS_GUIDE: "BUYER_GUID_CONTACTS_COMMUNICATION_COLUMN_VISIBILITY",
        GENERAL: "CONTACTS_COMMUNICATION_COLUMN_VISIBILITY",
    },
    CONTACTS_COMMUNICATION_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_COLUMN_SORT",
        GENERAL: "CONTACTS_COMMUNICATION_COLUMN_SORT",
    },
    CONTACTS_COMMUNICATION_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_COLUMN_FILTER",
        GENERAL: "CONTACTS_COMMUNICATION_COLUMN_FILTER",
    },
    CONTACTS_COMMUNICATION_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_COLUMN_ORDER",
        GENERAL: "CONTACTS_COMMUNICATION_COLUMN_ORDER",
    },
    CONTACTS_COMMUNICATION_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_COLUMN_WIDTH",
        GENERAL: "CONTACTS_COMMUNICATION_COLUMN_WIDTH",
    },
    CONTACTS_COMMUNICATION_PAGINATION: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_PAGINATION",
        GENERAL: "CONTACTS_COMMUNICATION_PAGINATION",
    },
    CONTACTS_COMMUNICATION_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_CONTACTS_COMMUNICATION_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_CONTACTS_COMMUNICATION_SEARCH_TEXT",
        GENERAL: "CONTACTS_COMMUNICATION_SEARCH_TEXT",
    },
    ATTACHMENT_COMMUNICATION_COLUMN_VISIBILITY: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_COLUMN_VISIBILITY",
        BUYERS_GUIDE: " BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_COLUMN_VISIBILITY",
        GENERAL: "ATTACHMENT_COMMUNICATION_COLUMN_VISIBILITY",
    },
    ATTACHMENT_COMMUNICATION_COLUMN_SORT: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_COLUMN_SORT",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_COLUMN_SORT",
        GENERAL: "ATTACHMENT_COMMUNICATION_COLUMN_SORT",
    },
    ATTACHMENT_COMMUNICATION_COLUMN_FILTER: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_COLUMN_FILTER",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_COLUMN_FILTER",
        GENERAL: "ATTACHMENT_COMMUNICATION_COLUMN_FILTER",
    },
    ATTACHMENT_COMMUNICATION_COLUMN_ORDER: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_COLUMN_ORDER",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_COLUMN_ORDER",
        GENERAL: "ATTACHMENT_COMMUNICATION_COLUMN_ORDER",
    },
    ATTACHMENT_COMMUNICATION_COLUMN_WIDTH: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_COLUMN_WIDTH",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_COLUMN_WIDTH",
        GENERAL: "ATTACHMENT_COMMUNICATION_COLUMN_WIDTH",
    },
    ATTACHMENT_COMMUNICATION_PAGINATION: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_PAGINATION",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_PAGINATION",
        GENERAL: "ATTACHMENT_COMMUNICATION_PAGINATION",
    },
    ATTACHMENT_COMMUNICATION_SEARCH_TEXT: {
        AFTERCARE: "AFTERCARE_ATTACHMENT_COMMUNICATION_SEARCH_TEXT",
        BUYERS_GUIDE: "BUYERS_GUIDE_ATTACHMENT_COMMUNICATION_SEARCH_TEXT",
        GENERAL: "ATTACHMENT_COMMUNICATION_SEARCH_TEXT",
    },
};