import axios from "axios";
import { URLS } from "./urls";
import { createCancelableRequest } from "../_helpers/createCancelableRequest";

export const getCommunications = (data) => createCancelableRequest(
    axios.post,
    URLS.GET_COMMUNICATIONS,
    data
  );
export const getCommunicationDetails = (communicationId) => axios.get(URLS.GET_COMMUNICATION_DETAILS + communicationId);
export const getContacts = (communicationId) => axios.get(URLS.GET_CONTACTS + communicationId);
export const getContactDetails = (contactId) => axios.get(URLS.GET_CONTACT_DETAILS + contactId);
export const getAttachments = (communicationId) => axios.get(URLS.GET_ATTACHMENTS + communicationId);
export const getAttachmentDetails = (attachmentId) => axios.get(URLS.GET_ATTACHMENT_DETAILS + attachmentId);