import { Box,Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import DataGridV2 from '../../../components/DataGridV2'
import { LabelColumnV2 } from '../../../components/DataGridV2/DataColumnsV2'
import { taskDetailsActions } from '../../../_actions'
import { formatDate, getLocalStorageItem, setLocalStorageItem } from '../../../_helpers'
import { apps, LOCAL_STORAGE_GRID_KEYS } from '../../../_constants'
import { getGridContextMenuList } from '../../../_helpers/getGridContextMenuList'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import TaskHistoryFailed from './TaskHistoryFailed'

const TaskHistoryGrid = () => {
    const dispatch = useDispatch()
    const {  taskHistory } = useSelector(state => state.systemMaintenance.taskDetailsStore)
    const {taskAutomationId} = useParams()
    const apiRef = useGridApiRef();
    const theme = useTheme()
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [taskHistoryFailedDialog, setTaskHistoryFailedDialog] = useState({ open: false, taskHistoryId: undefined });

    const app = useSelector((state) => state.app);
    const keyPrefix = app === apps.aftercare ? "AFTERCARE" :  app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
    const { t ,i18n} = useTranslation();

    const { loading: buildingLoading } = useSelector(
        (state) => state.buildings
    );
     const getOptions = getGridContextMenuList({
          filterKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER[keyPrefix],
          sortKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT[keyPrefix],
          i18n,
          t,
          apiRef,
          mode: "client"
        })
    useEffect(() => {
        if (!buildingLoading) {
            dispatch(taskDetailsActions.fetchHistoryDetails(taskAutomationId))
        }
    }, [buildingLoading, dispatch, t, taskAutomationId]);

    const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION[keyPrefix], undefined
    ))

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "startDateTime",
                headerName: t('general.startDateTime'),
                flex: 1,
                minWidth: 90,
                type: "dateTime",
                download: true,
                valueGetter: (params) => {
                    if (!params) return "";
                    return new Date(params);
                },
                renderCell: (params) => {
                    const rowData = params?.row;
                    return <LabelColumnV2 label={rowData?.startDateTime ? formatDate(new Date(rowData?.startDateTime), true, "-", false) : ""} 
                    cellSubMenuOptions={{ options: getOptions({ field: "startDateTime", isQuickFilterDisplay: rowData?.startDateTime}), extraData: { fieldName: "startDateTime",type : "dateTime" } }}
                     request={rowData}
                    />
                },
            },
            {
                field: "duration",
                headerName:t('general.duration'),
                flex: 1,
                minWidth: 90,
                download: true,
                renderCell: (params) => {
                    const rowData = params?.row;
                    return <LabelColumnV2 label={rowData?.duration}
                    cellSubMenuOptions={{ options: getOptions({ field: "duration", isQuickFilterDisplay: Boolean(rowData.duration) }), extraData: { fieldName: "duration", } }}
                    request={rowData}
                     />
                },
            },
            {
                field: "successCount",
                headerName:t('taskAutomation.history.successful'),
                flex: 1,
                minWidth: 90,
                type: "number",
                download: true,
                renderCell: (params) => {
                    const rowData = params?.row;
                    return <LabelColumnV2 color={theme.palette.success.main}   label={rowData?.successCount}
                    cellSubMenuOptions={{ options: getOptions({ field: "successCount", isQuickFilterDisplay: typeof(rowData.successCount) === 'number' }), extraData: { fieldName: "successCount",type : "number" } }}
                    request={rowData}
                    />
                },
            },
            {
                field: "failueCount",
                headerName: t('taskAutomation.history.failed'),
                flex: 1,
                minWidth: 90,
                type: "number",
                download: true,
                renderCell: (params) => {
                    const rowData = params?.row;
                    return (
                        <LabelColumnV2 
                            onLabelClick={() => {
                                if (rowData?.failueCount > 0) {
                                    handleOpenTaskHistoryFailedDialog(rowData?.id);  
                                }
                            }}
                            cellSubMenuOptions={{ options: getOptions({ field: "failueCount", isQuickFilterDisplay: typeof(rowData.failueCount) === 'number' }), extraData: { fieldName: "failueCount",type : "number" } }}
                            color={theme.palette.error.main} 
                            label={rowData?.failueCount} 
                            request={rowData}
                        />
                    );
                }}
        ];
        return baseColumns
    }, [])

    const getTaskHistoryPrintAndDownload = ({ isAllSelected = true }) => {
        const userSelectedRows = isAllSelected ? taskHistory?.data : rowSelectionModel?.map((id) => taskHistory?.data.find(item => item?.id === id));
    
        const results = userSelectedRows?.map((item) => {
            let result = {};
            Object.keys(item).forEach((key) => {
                const isDateTimeField = !!columns.find(x => x.field === key && x.type === 'dateTime');
                
                if (isDateTimeField) {
                    result = {
                        ...result, 
                        [key]: item[key] ? formatDate(new Date(item[key]), true, "-", false) : "" 
                    };
                } else {
                    result = { ...result, [key]: item[key] };
                }
            });
            return result;
        });
        return results;
    };
    

    const onPaginationModelChange = useCallback((newPaginationModel) => {
        setPaginationModel(newPaginationModel)
        setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_PAGINATION[keyPrefix],
            newPaginationModel
        );
    }, [keyPrefix])

    const handleOpenTaskHistoryFailedDialog = (taskHistoryId) => {
        setTaskHistoryFailedDialog({ open: true, taskHistoryId });
    };

    const handleCloseTaskHistoryFailedDialog = () => {
        setTaskHistoryFailedDialog({ open: false, taskHistoryId: undefined });
    };
    return (
        <Box width={"100%"}>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {t('taskAutomation.history')}
            </Typography>
            <Box  >
                <DataGridV2
                    sx={{  height: 400, p:1}}
                    localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_SEARCH_TEXT[keyPrefix]}
                    localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_FILTER[keyPrefix]}
                    localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_SORT[keyPrefix]}
                    localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_ORDER[keyPrefix]}
                    localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_WIDTH[keyPrefix]}
                    localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_HISTORY_COLUMN_VISIBILITY[keyPrefix]}
                    apiRef={apiRef}
                    loading={buildingLoading || taskHistory?.loading}
                    checkboxSelection
                    rows={taskHistory?.data}
                    columns={columns}
                    toolbarProps={{
                        title: t("taskAutomation.history"), 
                        selectedRows: rowSelectionModel,
                        getData: getTaskHistoryPrintAndDownload,
                    }}
                    pagination
                    rowSelectionModel={rowSelectionModel}
                    {...(!!paginationModel && { paginationModel })}
                    onPaginationModelChange={onPaginationModelChange}
                    filterMode="client"
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                />
            </Box>
            {taskHistoryFailedDialog.open && (
                <TaskHistoryFailed 
                    handleClose={handleCloseTaskHistoryFailedDialog} 
                    taskHistoryId={  taskHistoryFailedDialog.taskHistoryId}  
                />
            )} 
        </Box>
    )
}

export default TaskHistoryGrid
