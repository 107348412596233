import { BREADCRUMB_NAVIGATION } from '../_constants';

const initialState = {
    activeBreadCrumb: 0,
    items: []
}

export function breadcrumbReducer(state = initialState, action) {
    switch (action.type) {

        case BREADCRUMB_NAVIGATION.SET_ITEM : {
            return { ...state, activeBreadCrumb: action.data.length - 1, items: action.data }
        }

        case BREADCRUMB_NAVIGATION.CLEAR:
            return initialState
        default:
            return state
    }
}