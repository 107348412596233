import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridV2 from "../../components/DataGridV2";
import { apps, LOCAL_STORAGE_GRID_KEYS } from "../../_constants";
import { formatDate, getLocalStorageItem, setLocalStorageItem } from "../../_helpers";
import { LabelColumnV2, LinkColumnV2 } from "../../components/DataGridV2/DataColumnsV2";
import useGridDataModalHandler from "../../_hooks/useGridDataModalHandler";
import { getGridContextMenuList } from "../../_helpers/getGridContextMenuList";
import { getCommunicationGridNavigationItem, getCommunicationMethods, getCommunicationStatuses, getCommunicationTypes, getStatusColor } from "./util";
import { getCommunications } from "../../apis/communicationApi";
import { breadcrumbAction, tabNavigationAction } from "../../_actions";
import { useLocation } from 'react-router-dom';

const CommunicationGrid = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const app = useSelector((state) => state.app);
  const apiRef = useGridApiRef();
  const muiTableRef = useRef();
  const dispatch = useDispatch()
  const keyPrefix = app === apps.aftercare ? "AFTERCARE" :  app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
  const theme = useTheme()
  const location = useLocation();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const {
    loading,
    loadedRows,
    sortModel,
    handleSort,
    filterModel,
    handleFilter,
    handleSearch,
    handleCellSort,
    searchText,
    getAllRecords,
    totalRecords,
    paginationModel,
    handleCellFilter,
    onPaginationModelChange,
    getAllExportRecords
  } = useGridDataModalHandler({
    additionalParams: { app },
    rowIdentifier: "id",
    apiCall: getCommunications,
    sortModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_SORT[keyPrefix], undefined
    ),
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_FILTER[keyPrefix], undefined
    ),
    paginationModel: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_PAGINATION[keyPrefix], undefined
    ),
    searchText: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_SEARCH_TEXT[keyPrefix], ""
    ),
    dateTimeFields: ["createdOn", "modifiedOn"]
  });

  useEffect(() => {
    const locationState = location?.state;

    const newFilterModelItems = [];
    if (locationState?.filter) {
      newFilterModelItems.push({
        field: locationState.filter.field,
        operator: locationState.filter.operator,
        value: locationState.filter.value, 
      });
    }

    handleFilter(
      newFilterModelItems.length > 0
        ? {
            items: newFilterModelItems,
            logicOperator: "and",
            quickFilterValues: [],
            quickFilterLogicOperator: "and",
          }
        : filterModel ?? {
            items: [],
            logicOperator: "and",
            quickFilterValues: [],
            quickFilterLogicOperator: "and",
          }
    );
}, [location?.state]); 



  const getCommunicationsForPrintAndDownload = async ({ selectedColumnsNames = [], isAllSelected = true }) => {
    const {
      data: { results },
    } = await (selectedColumnsNames.length > 0 ? getAllExportRecords({
      additionalObjectPayload: { exportColumns: selectedColumnsNames },
      additionalCondition: isAllSelected ? [] : [{
        field: "id",
        operator: "isAnyOf",
        value: [
          ...(rowSelectionModel)
        ],
      }]
    }) : getAllRecords());
    const data = results?.map((result) => {
      const singleCommunicationObj = { ...result }
      if (singleCommunicationObj?.["communicationMethod"]?.toString()) singleCommunicationObj["communicationMethod"] = t(`communication.method.${singleCommunicationObj["communicationMethod"]}`)
      if (singleCommunicationObj?.["communicationType"]?.toString()) singleCommunicationObj["communicationType"] = t(`communication.type.${singleCommunicationObj["communicationType"]}`)
      if (singleCommunicationObj?.["communicationStatus"]?.toString()) singleCommunicationObj["communicationStatus"] = t(`communication.status.${singleCommunicationObj["communicationStatus"]}`)
      return singleCommunicationObj;
    });
    return data;
  };

  const getOptions = getGridContextMenuList({
    handleCellSort,
    handleCellFilter,
    filterKey: LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_FILTER[keyPrefix],
    sortKey: LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_SORT[keyPrefix],
    i18n,
    t,
    apiRef,
    getValueForFilter: (rowData, extraData) => {
      let value = ""
      switch (extraData.fieldName) {
        case "date":
          value = formatDate(new Date(rowData.date)).split("-").reverse().join("-")
          break;
        case "communicationStatus":
        case "communicationMethod":
        case "communicationType":
          value = rowData[extraData.fieldName]?.toString()
          break;
        default:
          value = rowData[extraData.fieldName]
          break;
      }
      return value
    },
    getValueForCopy: (rowData, extraData) => {
      let text = ""
      switch (extraData.fieldName) {
        case "date":
          text = formatDate(new Date(rowData.date))
          break;
        case "communicationStatus":
          text = t(`communication.status.${rowData.communicationStatus}`)
          break;
        case "communicationMethod":
          text = t(`communication.method.${rowData.communicationMethod}`)
          break;
        case "communicationType":
          text = t(`communication.type.${rowData.communicationType}`)
          break;
        default:
          text = rowData[extraData.fieldName]
          break;
      }
      return text
    }
  })

  useEffect(() => {
    const communicationGridNavigation = getCommunicationGridNavigationItem({ t })
    dispatch(tabNavigationAction.setNavigationTab(communicationGridNavigation.tabs))
    dispatch(breadcrumbAction.setBreadcrumbItem(communicationGridNavigation.breadCrumbs))
  }, [dispatch, t])

  const columns = useMemo(() => {
    return [
      {
        field: "date",
        download: true,
        type: "date",
        valueGetter: (params) => {
          if (!params) return "";
          return new Date(params);
        },
        headerName: t("general.date"),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LinkColumnV2 to={app === apps.huisinfo ? "/communicatie/" + params?.row?.id : `/nazorg/communicatie/${rowData.id}`} label={rowData?.date ? formatDate(new Date(rowData?.date)) : ""}
              cellSubMenuOptions={{ options: getOptions({ field: "date", isQuickFilterDisplay: rowData?.date }), extraData: { fieldName: "date", type: "date" } }}
              request={rowData}
            />
          )
        },
      },
      {
        field: "communicationMethod",
        download: true,
        headerName: t('general.method'),
        flex: 1,
        minWidth: 90,
        type: "singleSelect",
        valueOptions: getCommunicationMethods(t),
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={t(`communication.method.${rowData.communicationMethod}`)}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "communicationMethod", isQuickFilterDisplay: Boolean(rowData.communicationMethod.toString()) }), extraData: { fieldName: "communicationMethod", type: "singleSelect" } }}
            />
          )
        },
      },
      {
        field: "communicationType",
        download: true,
        headerName: t('communication.type'),
        flex: 1,
        minWidth: 90,
        type: "singleSelect",
        valueOptions: getCommunicationTypes(t),
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={t(`communication.type.${rowData.communicationType}`)}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "communicationType", isQuickFilterDisplay: Boolean(rowData.communicationType) }), extraData: { fieldName: "communicationType", type: "singleSelect" } }}
            />
          )
        },
      },
      {
        field: "employeeName",
        download: true,
        minWidth: 100,
        headerName: t('general.employee'),
        flex: 1,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={rowData.employeeName}
              cellSubMenuOptions={{ options: getOptions({ field: "employeeName", isQuickFilterDisplay: Boolean(rowData.employeeName) }), extraData: { fieldName: "employeeName" } }}
              request={params?.row}
            />
          )
        },
      },
      {
        field: "subject",
        download: true,
        headerName: t('general.subject'),
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={rowData.subject}
              cellSubMenuOptions={{ options: getOptions({ field: "subject", isQuickFilterDisplay: Boolean(rowData.subject) }), extraData: { fieldName: "subject" } }}
              request={params?.row}
            />
          )
        },
      },
      {
        field: "ourFeature",
        download: true,
        headerName: t("communication.ourFeature"),
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={rowData.ourFeature}
              cellSubMenuOptions={{ options: getOptions({ field: "ourFeature", isQuickFilterDisplay: Boolean(rowData.ourFeature) }), extraData: { fieldName: "ourFeature" } }}
              request={params?.row}
            />
          )
        },
      },
      {
        field: "projectNo",
        download: true,
        headerName: t('general.projectNumber'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={rowData.projectNo}
              cellSubMenuOptions={{ options: getOptions({ field: "projectNo", isQuickFilterDisplay: Boolean(rowData.projectNo) }), extraData: { fieldName: "projectNo" } }}
              request={params?.row}
            />
          )
        },
      },
      {
        field: "projectName",
        download: true,
        headerName: t('general.projectName'),
        flex: 1,
        minWidth: 120,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.projectName}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "projectName", isQuickFilterDisplay: Boolean(rowData.projectName) }), extraData: { fieldName: "projectName" } }}
            />
          )
        },
      },
      {
        field: "buildingNoIntern",
        download: true,
        type: 'number',
        headerName: t('general.objects'),
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.buildingNoIntern}
              cellSubMenuOptions={{ options: getOptions({ field: "buildingNoIntern", isQuickFilterDisplay: Boolean(rowData.buildingNoIntern?.toString()) }), extraData: { fieldName: "buildingNoIntern", type: "number" } }}
              request={rowData}
            />
          )
        },
      },
      {
        field: "taskName",
        download: true,
        headerName: t("communication.taskName"),
        flex: 1,
        minWidth: 90,
        type: "singleSelect",
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.taskName}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "taskName", isQuickFilterDisplay: Boolean(rowData.taskName) }), extraData: { fieldName: "taskName" } }}
            />
          )
        },
      },
      {
        field: "taskNumber",
        headerName: t('communication.taskNumber'),
        flex: 1,
        minWidth: 90,
        download: true,
         renderCell: (params) => {
                  const rowData = params.row;
                  return (
                    <LabelColumnV2
                      label={rowData.taskNumber}
                      request={rowData}
                      cellSubMenuOptions={{
                        options: getOptions({ field: "taskNumber", isQuickFilterDisplay: Boolean(rowData.taskNumber) }),
                        extraData: { fieldName: "taskNumber" }
                    }}
                    />
                  );
                },
    },
      {
        field: "documentCode",
        download: true,
        headerName: t('general.documentCode'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.documentCode}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "documentCode", isQuickFilterDisplay: Boolean(rowData.documentCode) }), extraData: { fieldName: "documentCode" } }}
            />
          )
        },
      },
      {
        field: "startTime",
        download: true,
        headerName: t('general.startTime'),
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={rowData.time}
              request={rowData}
              cellSubMenuOptions={{
                options: getOptions({ field: "startTime", isQuickFilterDisplay: Boolean(rowData.time) }), extraData: { fieldName: "startTime" }
              }}
            />
          )
        },
      },
      {
        field: "communicationStatus",
        download: true,
        headerName: t('general.status'),
        flex: 1,
        minWidth: 90,
        type: "singleSelect",
        valueOptions: getCommunicationStatuses(t),
        renderCell: (params) => {
          const rowData = params?.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2 label={t(`communication.status.${rowData.communicationStatus}`)}
              request={params?.row}
              color={getStatusColor(rowData.communicationStatus, theme)}
              cellSubMenuOptions={{ options: getOptions({ field: "communicationStatus", isQuickFilterDisplay: Boolean(rowData.communicationStatus?.toString()) }), extraData: { fieldName: "communicationStatus", type: "singleSelect" } }}
            />
          )
        },
      },
    ];
  }, [getOptions, t, theme]);

  const onFilterModelChange = useCallback((newFilterMModel) => {
    handleFilter(newFilterMModel, (newFilterMModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_FILTER[keyPrefix],
        newFilterMModel
      );
    })
  }, [handleFilter])

  const getToolbarProps = useMemo(() => {
    const toolbarProps = {
      apiRef: apiRef,
      columns: columns,
      title: t("communication.list"),
      totalRecords: totalRecords,
      getData: getCommunicationsForPrintAndDownload,
      handleSearch: (value) => {
        handleSearch(value, (value) => {
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_SEARCH_TEXT[keyPrefix],
            value
          );
        });
      },
      searchText: searchText,
      selectedRows: rowSelectionModel
    };
    return toolbarProps;
  }, [apiRef, columns, handleSearch, rowSelectionModel, searchText, t, totalRecords]);

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_SEARCH_TEXT[keyPrefix]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_ORDER[keyPrefix]}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_WIDTH[keyPrefix]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_VISIBILITY[keyPrefix]}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
        columns={columns}
        ref={muiTableRef}
        apiRef={apiRef}
        loading={loading}
        toolbarProps={getToolbarProps}
        rowCount={totalRecords}
        rows={loadedRows}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModal) =>
          onPaginationModelChange(paginationModal, (paginationModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_PAGINATION[keyPrefix],
              paginationModal
            );
          })}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) =>
          handleSort(newSortModel, (newSortModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.COMMUNICATION_COLUMN_SORT[keyPrefix],
              newSortModal
            );
          })
        }
        filterMode="server"
        paginationMode="server"
        onFilterModelChange={onFilterModelChange}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        {...(!!filterModel && { filterModel })}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));

export default CommunicationGrid;