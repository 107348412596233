import { SUBMENU } from "../_constants";

const openSubMenu = (anchorEl, subMenuList) => {
    return {
        type: SUBMENU.OPEN,
        payload: { anchorEl, subMenuList },
    };
};

const clearSubMenu = () => {
    return {
        type: SUBMENU.CLEAR,
    };
};

export const subMenuActions = {
    openSubMenu,
    clearSubMenu,
};