import React, { useState } from 'react'
import TemplateInfo from './TemplateInfo'
import { Divider, Grid2, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import EditAndPreview from '../../../components/EditAndPreview';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../../_constants';
import { templateDetailsActions, toastActions } from '../../../_actions';
import { useTranslation } from 'react-i18next';
import { updateTemplate } from '../../../apis/systemMaintenanceApis';
import { URLS } from '../../../apis/urls';

const TemplateInfoWrapper = ({ title, details, handleEditTemplateModal }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { templateDetails } = useSelector((state) => state.systemMaintenance.templateDetailsStore);
    const [loading, setLoading] = useState(false)
    const [editAndPreviewTemplate, setEditAndPreviewTemplate] = useState({ open: false, type: "" });

    const info = {
        template: templateDetails?.template || "",
        initialContent: templateDetails?.template ? `${templateDetails?.template}` : "",
        subject: templateDetails?.subject,
        title:  templateDetails?.desc,
        documentUrl : URLS.GET_DOCUMENT_TEMPLATE_PREVIEW + templateDetails?.id
    }

    const updateTemplateDetail = async (data) => {
        try {
          setLoading(true);
          await updateTemplate(templateDetails?.id, data);
        } catch (error) {
        } finally {
          setLoading(false);
          setEditAndPreviewTemplate({ type: "", open: false });
          dispatch(
            templateDetailsActions.fetchTemplateDetails(templateDetails?.id)
          );
        }
      };

    const handleSave = (plainText, emailEditorValue, container) => {
        switch (editAndPreviewTemplate?.type) {
            case SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL:
                if (plainText?.length === 0) {
                    dispatch(toastActions.success(t("general.field.error")))
                } else {
                    updateTemplateDetail([{ id: "template", name: emailEditorValue }]);
                }
                break;
            case SYSTEM_MAINTENANCE_CONSTANTS.EDIT_DOCUMENT:
                updateTemplateDetail([
                    { id: "template", name: container?.current?.documentEditor?.serialize() },
                ]);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {title}
            </Typography>
            <Divider />
            <Grid2 container sx={{
                py: 1
            }}>
                {details?.map((item) => {
                    return <TemplateInfo
                        key={item.title}
                        item={item}
                        handleEditTemplateModal={handleEditTemplateModal}
                        setEditAndPreviewTemplate={setEditAndPreviewTemplate}
                    />
                })}
            </Grid2>
            {editAndPreviewTemplate.open && <EditAndPreview editAndPreviewTemplate={editAndPreviewTemplate} setEditAndPreviewTemplate={setEditAndPreviewTemplate} loading={loading} handleSave={handleSave} info={info} />}
        </div>
    )
}

export default TemplateInfoWrapper