export const getEmptySfdt = () => {
    return `{"sections": [{"blocks": [{"inlines": [{"characterFormat": {"bold": true,"italic": true},"text": ""}]}],"headersFooters": {}}]}`
}

export const getMergedFields = ({t,getCopyTextComponent})=>{
    return  [
        { 
            text: t('general.project'), 
            children: [
                { 
                    text: t('general.projectNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werknummer', toastValue: t('general.projectNumber')}) 
                },
                { 
                    text: t('general.projectName'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werknaam', toastValue: t('general.projectName') }) 
                },
                { 
                    text: t('general.address.city'), 
                    icon: getCopyTextComponent({ clipBoardValue:"werk_plaats", toastValue: t('general.address.city') }) 
                },
                { 
                    text: t('general.warranty'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_planregistratienummer', toastValue: t('general.warranty')}) 
                },
                { 
                    text: t('general.salesStartDate'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_start_verkoop', toastValue: t('general.salesStartDate') }) 
                },
                { 
                    text: t('general.salesStartDate') + ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_start_verkoop_lang', toastValue: t('general.salesStartDate') + ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.projectStartDate'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_start_bouw', toastValue: t('taskAutomation.mergeField.projectStartDate') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.projectStartDate')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_start_bouw_lang', toastValue: t('taskAutomation.mergeField.projectStartDate')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.projectEndDate'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_eind_bouw', toastValue: t('taskAutomation.mergeField.projectEndDate') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.projectEndDate')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_datum_eind_bouw_lang', toastValue:  t('taskAutomation.mergeField.projectEndDate')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('general.website'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_website', toastValue: t('general.website'),   }) 
                },
                { 
                    text: t('general.warrantyScheme'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_garantieregeling', toastValue:  t('general.warrantyScheme')}) 
                },
                { 
                    text: t('general.endOfMaintenancePeriod'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'werk_onderhoudstermijn', toastValue: t('general.endOfMaintenancePeriod') }) 
                },
            ] 
        },
        { 
            text: t('general.object'), 
            children: [
                { 
                    text: t('general.buildingNumber.internal'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bouwnummer_intern', toastValue: t('general.buildingNumber.internal') }) 
                },
                { 
                    text: t('general.buildingNumber.external'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bouwnummer_extern', toastValue:  t('general.buildingNumber.external')}) 
                },
                { 
                    text: t('general.buyerRenter'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'volledige_naam', toastValue: t('general.buyerRenter') }) 
                },
                { 
                    text:t('general.country'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_land', toastValue: t('general.country')}) 
                },
                { 
                    text: t('general.address.city'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_plaats', toastValue: t('general.address.city')}) 
                },
                { 
                    text: t('general.address.postcode'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_postcode', toastValue: t('general.address.postcode') }) 
                },
                { 
                    text: t('general.address.houseNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_huisnummer', toastValue: t('general.address.houseNumber') }) 
                },
                { 
                    text: t('general.address.street'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_straat', toastValue: t('general.address.street') }) 
                },
                { 
                    text: t('general.eanCodeElectricity'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'ean_elektriciteit', toastValue: t('general.eanCodeElectricity')}) 
                },
                { 
                    text: t('general.eanCodeGas'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'ean_gas', toastValue: t('general.eanCodeGas') }) 
                },
                { 
                    text: t('general.startDate'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_datum_start_bouw', toastValue: t('general.startDate') }) 
                },
                { 
                    text: t('general.startDate')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'object_datum_start_bouw_lang', toastValue: t('general.startDate')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.startDateObjectOrProject'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_start_bouw', toastValue:t('taskAutomation.mergeField.startDateObjectOrProject') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.startDateObjectOrProject')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_start_bouw_lang', toastValue:t('taskAutomation.mergeField.startDateObjectOrProject')+ ' ' + t('taskAutomation.mergeField.long')}) 
                },
                { 
                    text: t('general.preDelivery'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_voorschouw', toastValue:t('general.preDelivery') }) 
                },
                { 
                    text: t('general.preDelivery')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_voorschouw_lang', toastValue:t('general.preDelivery')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('general.delivery'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_oplevering', toastValue: t('general.delivery') }) 
                },
                { 
                    text: t('general.delivery')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_oplevering_lang', toastValue: t('general.delivery')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('general.secondSignature'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_tweede_handtekening', toastValue: t('general.secondSignature') }) 
                },
                { 
                    text: t('general.secondSignature')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'datum_tweede_handtekening_lang', toastValue: t('general.secondSignature')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.lastQuotationClosingDate'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'laatste_offerte', toastValue: t('taskAutomation.mergeField.lastQuotationClosingDate') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.lastQuotationClosingDate')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'laatste_offerte_lang', toastValue: t('taskAutomation.mergeField.lastQuotationClosingDate')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },
                { 
                    text: t('general.warrantyScheme'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'garantieregeling', toastValue: t('general.warrantyScheme') }) 
                },
                { 
                    text: t('general.endOfMaintenancePeriod'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'einde_onderhoudstermijn', toastValue: t('general.endOfMaintenancePeriod') }) 
                },
                { 
                    text: t('general.endOfMaintenancePeriod')+ ' ' + t('taskAutomation.mergeField.long'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'einde_onderhoudstermijn_lang', toastValue: t('general.endOfMaintenancePeriod')+ ' ' + t('taskAutomation.mergeField.long') }) 
                },

            ] 
        },
        { 
            text: t('general.company'), 
            children: [
                { 
                    text: t('general.name'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_naam', toastValue: t('general.name') }) 
                },
                { 
                    text:t('taskAutomation.mergeField.company.visitingAddressCountry'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_bezoekadres_land', toastValue: t('taskAutomation.mergeField.company.visitingAddressCountry')}) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.visitingAddressNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue:  'bedrijf_bezoekadres_nummer', toastValue: t('taskAutomation.mergeField.company.visitingAddressNumber')}) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.visitingAddressCity'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_bezoekadres_plaats', toastValue: t('taskAutomation.mergeField.company.visitingAddressCity') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.visitingAddressPostCode'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_bezoekadres_postcode', toastValue: t('taskAutomation.mergeField.company.visitingAddressPostCode') }) 
                },

                { 
                    text: t('taskAutomation.mergeField.company.visitingAddressStreet'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_bezoekadres_straat' , toastValue: t('taskAutomation.mergeField.company.visitingAddressStreet') }) 
                },
                { 
                    text:t('taskAutomation.mergeField.company.mailingAddressCountry'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_postadres_land', toastValue: t('taskAutomation.mergeField.company.mailingAddressCountry')}) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.mailingAddressNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue:  'bedrijf_postadres_nummer', toastValue: t('taskAutomation.mergeField.company.mailingAddressNumber')}) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.mailingAddressCity'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_postadres_plaats', toastValue: t('taskAutomation.mergeField.company.mailingAddressCity') }) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.mailingAddressPostCode'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_postadres_postcode', toastValue: t('taskAutomation.mergeField.company.mailingAddressPostCode')}) 
                },
                { 
                    text: t('taskAutomation.mergeField.company.mailingAddressStreet') , 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_postadres_straat', toastValue: t('taskAutomation.mergeField.company.mailingAddressStreet') }) 
                },
                { 
                    text: t('general.telephone'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_telefoon', toastValue: t('general.telephone') }) 
                },
                { 
                    text: t('general.email'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_email', toastValue:  t('general.email') }) 
                },
                { 
                    text: t('general.website'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_website', toastValue: t('general.website') }) 
                },
                { 
                    text: t('general.bank'), 
                    icon: getCopyTextComponent({ clipBoardValue:'bedrijf_banknaam', toastValue: t('general.bank')}) 
                },
                { 
                    text: t('general.bic'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_bic', toastValue: t('general.bic') }) 
                },
                { 
                    text: t('general.iban'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_iban', toastValue: t('general.iban') }) 
                },
                { 
                    text: t('general.cocNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue: 'bedrijf_kvk_nummer', toastValue: t('general.cocNumber') }) 
                },
                { 
                    text: t('general.vatNumber'), 
                    icon: getCopyTextComponent({ clipBoardValue:'bedrijf_btw_nummer', toastValue: t('general.vatNumber') }) 
                }, 

            ] 
        },
              
    ];
}