import { Edit, ListAlt, ZoomIn } from '@mui/icons-material';
import { Box, Checkbox, Grid2, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { URLS } from '../../../../apis/urls';
import RowDetails from '../../../../components/RowDetails';
import { apps, SYSTEM_MAINTENANCE_CONSTANTS } from '../../../../_constants';
import { history } from '../../../../_helpers';

const CardInfo = ({ item, handleEditTaskModal, setEditAndPreviewTemplate }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { taskDetails } = useSelector(state => state.systemMaintenance.taskDetailsStore);

    const {taskAutomationId} = useParams()
     const app = useSelector((state) => state.app);

     const applyFilterAndRedirect = (type, value) => {
        let url = app === apps.huisinfo ? "/" : app === apps.aftercare ? "/nazorg/" : '#';
        let data = {};
        switch (type) {
          case "communication": {
            url += "communicatie";
            data = { filter :{ field:"taskNumber" , value , operator: "equals"} }; 
            break;
          }
          default:
            break;
        }
        history.push(url, data);
    };
    
    return (
        <Grid2
            sx={{
                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` },
                display: "flex", flexDirection: "column", justifyContent: "space-between"
            }}
            size={{
                xs: 12,
                md: 4
            }}>
            <Grid2 container spacing={0.5} sx={{
                px: 2
            }}
            >
                <Grid2 size={{ xs: 12 }}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            {!item?.isHiddenCheckBox && <Checkbox checked={true} disabled={item?.isDisabled} />}
                            <Typography variant="subtitle2" color={item?.isDisabled && 'textDisabled'}>
                                {item?.title}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                            {!item?.isHiddenListItemIcon && <IconButton onClick={(e) => applyFilterAndRedirect("communication", taskDetails.number)} >
                                <ListAlt />
                            </IconButton>}
                            <IconButton disabled={item?.isDisabled} onClick={() => {
                                handleEditTaskModal(item?.tabIndex)
                            }}
                            >
                                <Edit />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Grid2>
                {item?.details?.map((detail) => {
                    return <Grid2 key={detail?.title} size={{ xs: 12 }}>
                        <RowDetails disabled ={item?.isDisabled} title={detail?.title} value={!item?.isDisabled ? detail.value : ""} />
                    </Grid2>;
                })}

            </Grid2>
            {item?.isPreviewDisplay && <Grid2 size={{ xs: 12 }} spacing={0.5} sx={{
                px: 2, py: 1.5
            }}>
                <Stack direction="row" sx={{
                    py: 1,
                    justifyContent: "space-between"
                }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            mt: { xs: 3, md: 0 },
                            mb: { xs: 1.5, md: 0 }
                        }}> {t("general.preview")} </Typography>
                    <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <IconButton color="inherit"
                            onClick={() => {
                                setEditAndPreviewTemplate({ open: true, data: "", type: item?.id === "emailSettings" ? SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL : item?.id === "document" ? SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT : "" })
                            }}
                            aria-label="ZoomIn"
                            disabled={item?.isDisabled}
                            size="small">
                            <ZoomIn htmlColor={(item?.isEditDisabled || item?.isDisabled) ? theme.palette.action.disabled : theme.palette.action.active} />
                        </IconButton>
                        <IconButton color="inherit"
                            onClick={() => {
                                setEditAndPreviewTemplate({ open: true, data: "", type: item?.id === "emailSettings" ?  SYSTEM_MAINTENANCE_CONSTANTS.EDIT_EMAIL : item?.id === "document" ? SYSTEM_MAINTENANCE_CONSTANTS.EDIT_DOCUMENT : "" })
                            }}
                            disabled={item?.isDisabled || item?.isEditDisabled} 
                            aria-label="Edit"
                            size="small">
                            <Edit htmlColor={(item?.isEditDisabled || item?.isDisabled ) ? theme.palette.action.disabled : theme.palette.action.active} />
                        </IconButton>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        borderRadius: 1,
                        height: 300,
                        bgcolor: grey[100],
                        p: 1.5,
                        overflow: "auto",
                    }}>
                    {item?.id === "emailSettings" && taskDetails?.emailTemplate?.template  && <Box onClick={() => {
                                setEditAndPreviewTemplate({ open: true, data: "", type: item?.id === "emailSettings" ? SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_EMAIL : "" })
                            }} sx={{cursor:"pointer", fontSize : 12}}   dangerouslySetInnerHTML={{
                        __html: `${taskDetails?.emailTemplate?.template}`
                    }} />}

                    {(item?.id === "document" && taskDetails?.documentTemplate?.id) &&
                        <Box
                            component="img"
                            src={`${URLS.GET_TASK_DOCUMENT_THUMBNAIL + taskAutomationId}?v=${new Date().getTime()}`}
                            alt="Image"
                            sx={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                objectFit:"contain",
                                cursor:"pointer"
                            }}
                            onClick={() => {
                                setEditAndPreviewTemplate({ open: true, data: "", type: item?.id === "document" ? SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT : "" })
                            }}
                        />}
                </Box>
            </Grid2>}
        </Grid2>
    )
}

export default CardInfo