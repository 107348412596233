import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid2, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { getTemplateTypes } from '../util';
import { TemplateTypes } from '../../../_helpers';

const Template = ({ values, handleChange, errors, clearError }) => {
    const { t } = useTranslation();
    const templateTypes = getTemplateTypes(t)

    return (
         <>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {t('general.template')}
            </Typography>
            <Divider sx={{
                my: 0
            }} />
            <Grid2 container sx={{
                py: 1
            }} spacing={{ xs: 2, md: 0 }}>
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}`, display: "flex", flexDirection: "column", justifyContent: "space-between" } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>{t('general.general')}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t("general.templateId")}
                                        disabled
                                        value={values?.id}
                                        size='small'
                                        fullWidth
                                        error={errors?.id}
                                        helperText={errors?.id}
                                        onChange={(e) => {
                                            handleChange({ keyName: "id", value: e.target.value })
                                            clearError("id")
                                        }}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t("general.template.name")}
                                        value={values?.desc}
                                        size='small'
                                        autoComplete='off'
                                        fullWidth
                                        error={errors?.desc}
                                        helperText={errors?.desc}
                                        onChange={(e) => {
                                            handleChange({ keyName: "desc", value: e.target.value })
                                            clearError("desc")
                                        }}
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t("general.subject")}
                                        value={values?.subject}
                                        size='small'
                                        autoComplete='off'
                                        fullWidth
                                        error={errors?.subject}
                                        helperText={errors?.subject}
                                        onChange={(e) => {
                                            handleChange({ keyName: "subject", value: e.target.value })
                                            clearError("subject")
                                        }}
                                    />
                                </Grid2>

                                <Grid2 size={{ xs: 12 }}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            {t('general.type')}
                                        </InputLabel>
                                        <Select
                                            disabled
                                            size='small'
                                            name="templateType"
                                            fullWidth
                                            input={<OutlinedInput label={t('general.type')} />}
                                            value={values?.templateType}
                                            error={errors?.templateType}
                                            onChange={handleChange}
                                        >
                                            {templateTypes?.map((template) => <MenuItem value={template?.value}>{template?.label}</MenuItem>)}
                                        </Select>
                                        <FormHelperText error={errors.templateType}>{errors?.templateType}</FormHelperText>
                                    </FormControl>
                                </Grid2>

                                {values?.templateType === TemplateTypes.Document && <Grid2 size={{ xs: 12 }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t("general.documentCode")}
                                        value={values?.documentCode}
                                        size='small'
                                        fullWidth
                                        error={errors?.documentCode}
                                        helperText={errors?.documentCode}
                                        onChange={(e) => {
                                            handleChange({ keyName: "documentCode", value: e.target.value })
                                            clearError("documentCode")
                                        }}
                                    />
                                </Grid2>}
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControlLabel sx={{ m: 0 }} control={<Checkbox checked={values?.active} onChange={(e) => {
                                        handleChange({ keyName: "active", value: e.target.checked })
                                        clearError("active")
                                    }} />} label={t('general.active')} />
                                </Grid2>
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControlLabel sx={{ m: 0 }} control={<Checkbox checked={values?.standard} onChange={(e) => {
                                        handleChange({ keyName: "standard", value: e.target.checked })
                                        clearError("standard")
                                    }} />} label={t('template.standard')} />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    )
}

export default Template