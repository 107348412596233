import React, { useEffect, useState } from 'react'
import { CheckBox, CheckBoxOutlineBlank, ZoomIn } from '@mui/icons-material';
import { Box, Divider, Grid2 as Grid, Stack, IconButton, Typography, Paper, CircularProgress, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
import { getAttachmentDetails, getCommunicationDetails } from '../../../apis/communicationApi';
import {formatDate, getFileExtentionFromFileName, getMimeTypefromString } from '../../../_helpers';
import { URLS } from '../../../apis/urls';
import { SYSTEM_MAINTENANCE_CONSTANTS } from '../../../_constants';
import RowDetails from '../../../components/RowDetails';
import Preview from '../Preview';
import { breadcrumbAction, tabNavigationAction } from '../../../_actions';
import { useDispatch } from 'react-redux';
import { getAttachmentDetailsNavigationItem, getCommunicationDetailsNavigationItem } from '../util';
import TechInfo from '../../../components/TechInfo';

const AttachmentDetails = () => {
    const { attachmentId, communicationId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const location = useLocation();
    const theme = useTheme()
    const [attachmentDetails, setAttachmentDetails] = useState({})
    const [previewState, setPreviewState] = useState({ open: false, previewUrl: undefined, type: "", fileType: "", fileName: "" })
    const [isLoading, setIsLoading] = useState(false);
    const [communicationDetails, setCommunicationDetails] = useState({})

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await getCommunicationDetails(communicationId);
                if (response.status === 200) {
                    setCommunicationDetails(response.data)
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
                setIsLoading(false);
            }
        })();
    }, [communicationId]);

    useEffect(() => {
        if (!isLoading) {
            const communicationDetailsNavigation = getAttachmentDetailsNavigationItem({ t, communicationDetails, communicationId, attachmentId, attachmentDetails })
            console.log({ communicationDetailsNavigation })
            dispatch(tabNavigationAction.setNavigationTab(communicationDetailsNavigation.tabs, communicationDetailsNavigation.tabs.findIndex((tab) => tab.to === location.pathname.split('/').slice(0, -1).join('/'))))
            dispatch(breadcrumbAction.setBreadcrumbItem(communicationDetailsNavigation.breadCrumbs))
        }
    }, [communicationId, dispatch, t, location.pathname, attachmentId, isLoading, communicationDetails, attachmentDetails])

    useEffect(() => {
        const communicationDetailsNavigation = getCommunicationDetailsNavigationItem({ t, communicationId, attachmentId })
        dispatch(tabNavigationAction.setNavigationTab(communicationDetailsNavigation.tabs, communicationDetailsNavigation.tabs.findIndex((tab) => tab.to === location.pathname.split('/').slice(0, -1).join('/'))))
        dispatch(breadcrumbAction.setBreadcrumbItem(communicationDetailsNavigation.breadCrumbs))
    }, [communicationId, dispatch, t, location.pathname, attachmentId])

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await getAttachmentDetails(attachmentId);
                if (response.status === 200) {
                    setAttachmentDetails(response.data)
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
                setIsLoading(false);
            }
        })();
    }, [attachmentId]);

    const attachmentGeneralInfo = [
        {
            title: t("general.header"),
            value: attachmentDetails?.header
        },
        {
            title: t('attachment.original'),
            value: attachmentDetails?.attachmentName
        },
        {
            title: t('general.description'),
            value: attachmentDetails?.description
        },
    ];

    const attachmentSpecificationInfo = [
        {
            title: t('attachment.linkTo'),
            value: t(`attachment.linkTo.${attachmentDetails?.linkTo}`)
        },
        {
            title: t('general.communication'),
            value: attachmentDetails?.communicationDesc
        },
        {
            title: t('attachment.code'),
            value: attachmentDetails?.code
        },
        {
            title: t('attachment.version'),
            value: attachmentDetails?.version
        },
        {
            title: t('general.date'),
            value: attachmentDetails?.date ? formatDate(new Date(attachmentDetails?.date), true, "-", false) : ''
        },
        {
            title: t('general.published'),
            value: attachmentDetails?.published ? <CheckBox color='disabled' /> : <CheckBoxOutlineBlank color='disabled' />
        },
    ]

    const handlePreview = () => {
        setPreviewState({
            open: true, previewUrl: URLS.GET_ATTACHMENT + attachmentId, type: SYSTEM_MAINTENANCE_CONSTANTS.PREVIEW_DOCUMENT, fileType: getMimeTypefromString(getFileExtentionFromFileName(attachmentDetails?.attachmentName)), fileName: attachmentDetails?.attachmentName
        })
    }

    return (
        <>
            {isLoading ? <Stack
                sx={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    p: theme.spacing(1.25),
                    height: "50vh"
                }}>
                <CircularProgress size={30} />
            </Stack> : <Stack height={"100%"}
                spacing={2}
                sx={{
                    flex: 1,
                    bgcolor: grey[100],
                    px: { xs: 0, md: 2 },
                    py: { xs: 2, md: 2 }
                }}>
                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <Typography
                        variant="h6"
                        sx={{
                            px: 2,
                            py: 1.5
                        }}>
                        {t('general.attachments')}
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid
                            sx={{
                                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                            }}
                            size={{
                                xs: 12,
                                md: 4
                            }}>
                            <Grid container spacing={0.5} sx={{
                                px: 2
                            }}
                            >
                                <Grid size={{ xs: 12 }} >
                                    <Typography variant="subtitle2" py={1.5}>
                                        {t('general.general')}
                                    </Typography>
                                </Grid>
                                {attachmentGeneralInfo?.map((detail) => {
                                    return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                                })}
                                <Grid size={{ xs: 12 }} py={3}>
                                    <Grid size={{ xs: 12 }} pb={1.5}>
                                        <Typography variant="subtitle2" >
                                            {t('attachment.specification')}
                                        </Typography>
                                    </Grid>
                                    {attachmentSpecificationInfo?.map((detail) => {
                                        return <Grid size={{ xs: 12 }}><RowDetails title={detail.title} value={detail.value || ''} /></Grid>;
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                borderRight: { xs: "none", md: `1px dashed ${grey[200]}` }
                            }}
                            size={{
                                xs: 12,
                                md: 4
                            }}>
                            <Grid container spacing={0.5}
                                sx={{
                                    px: 2
                                }}
                            >
                                <Grid pb={2}
                                    size={{
                                        xs: 12,
                                        md: 12
                                    }}>
                                    <Box >
                                        <Stack direction="row" sx={{
                                            justifyContent: "space-between"
                                        }}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    py: 1.5
                                                }}>
                                                {t('general.preview')}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                sx={{
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                <IconButton size="small" onClick={handlePreview} ><ZoomIn /></IconButton>
                                            </Stack>
                                        </Stack>
                                        <Box
                                            sx={{
                                                borderRadius: 1,
                                                height: 300,
                                                bgcolor: grey[100],
                                                p: 1.5,
                                                overflow: "auto",
                                            }}>
                                            <Box
                                                component="img"
                                                src={`${URLS.GET_ATTACHMENT_THUMBNAIL}${attachmentId}`}
                                                alt="Image"
                                                onClick={handlePreview}
                                                sx={{
                                                    display: "block",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
                    <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} data={attachmentDetails} />
                </Paper>
            </Stack>
            }
            <Preview previewState={previewState} setPreviewState={setPreviewState} />
        </>
    );
}

export default AttachmentDetails

