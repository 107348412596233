import React, { useState } from 'react'
import { Box, Paper, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import TaskHistoryGrid from './TaskHistoryGrid'
import EditTaskDetailsDialog from '../EditTaskDetailsDialog'
import { getGeneralSettingsInfo,getTemplateSettingInfo } from './utils'
import { useTranslation } from 'react-i18next'
import CardInfoWrapper from './CardInfo/CardInfoWrapper'
import TechInfo from '../../../components/TechInfo'
import { store } from '../../..'

const TaskAutomationDetails = () => {
    const [editTaskDetailsDialogStatus, setEditTaskDetailsDialogStatus] = useState({ open: false, tabId: "" })
    const { t } = useTranslation()
    const { systemMaintenance: { taskDetailsStore: { taskDetails } } } = store.getState()
    const handleEditTaskModal = (value) => {
        setEditTaskDetailsDialogStatus({ open: typeof value === "number" ? true : false, tabId: value })
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: grey[100],
                p: 2,
                overflow: "auto",
                height: "100%",
            }}>
            <Stack sx={{
                flexGrow: 1,
                my: 2,
            }} spacing={2}>

                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <CardInfoWrapper title={t('taskAutomation.generalSettings')} details={getGeneralSettingsInfo(t)} handleEditTaskModal={handleEditTaskModal} />
                </Paper>

                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <CardInfoWrapper title={t('taskAutomation.templateSettings')} details={getTemplateSettingInfo(t)} handleEditTaskModal={handleEditTaskModal} />
                </Paper>

                <Paper sx={{ width: "100%", bgcolor: "common.white" }} elevation={0}>
                    <TaskHistoryGrid />
                </Paper>
                <Paper sx={{ width: '100%', bgcolor: 'common.white' }} elevation={0} >
                    <TechInfo title={t('general.techInfo')} subtitle={t("general.mutationHistory")} data={taskDetails} />
                </Paper>
            </Stack>

            {editTaskDetailsDialogStatus.open && <EditTaskDetailsDialog dialogStatus={editTaskDetailsDialogStatus} handleDialog={handleEditTaskModal} />}

        </Box>
    )
}

export default TaskAutomationDetails
