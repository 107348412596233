import React, { useEffect, useState } from "react";
import { Box,  Divider, Drawer, IconButton, Stack, TextField, Typography,  Button,Autocomplete} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBack, CheckCircle, Close, Visibility } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import {  getMimeTypefromString, groupBy } from "../../../_helpers";
import { createContract, getAllTemplates,} from "../../../apis/contractApis";
import { LoadingButton } from "@mui/lab";
import { blue } from "@mui/material/colors";
import { URLS } from "../../../apis/urls";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Loader from "../../ContactDetails/Components/Loader";
import { makeStyles } from "@mui/styles";
const CreateContractDrawer = ({ openGenerateContact, handleGenerateContractDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { all: allBuildings, selected } = useSelector(state => state.buildings)
  const [templates, setTemplates] = useState({ loading: false, data: [], selectedtemplate: undefined })
  const [projects, setProjects] = useState({ selectedProjectInfo: undefined, selectedProjectLable: undefined, list: [] })
  const [objects, setObjects] = useState({ selectedObjectInfo: undefined, selectedObjectLable: undefined, selectedProjectId: undefined, list: [] })
  const [previewTemplateId, setPreviewTemplateId] = useState("");
  const [isCreatingContract, setIsCreatingContract] = useState(false)
  const getProjectLable = (projectInfo) => `${projectInfo?.projectNo} - ${projectInfo?.projectName}`
  const {t} = useTranslation()
  const handleCreateContract = async () => {
    if (projects?.selectedProjectInfo && objects?.selectedObjectInfo && templates?.selectedtemplate) {
      try {
        setIsCreatingContract(true)
        const response = await createContract({ buildingId: objects.selectedObjectInfo.buildingId, templateId: templates?.selectedtemplate?.id });
        history.push("/contract/" + response.data?.contractId)
      } catch (e) {
        console.log(e)
      } finally {
        setIsCreatingContract(false)
      }
    }
  }
  useEffect(() => {
    const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
    const projectList = projectsSoFar.map((projectInfo) => ({ label: getProjectLable(projectInfo[0]), projectInfo: projectInfo?.[0] }))
    setProjects({ selectedProjectInfo: selected, selectedProjectLable: getProjectLable(selected), list: projectList })
    const objects = allBuildings.map((building) => ({ label: building.buildingNoIntern, objectInfo: building }))
    setObjects({ selectedObjectInfo: undefined, selectedObjectLable: undefined, list: objects })
  }, [])
  useEffect(() => {
    (async () => {
      try {
        setTemplates({ loading: true, data: [] })
        const response = await getAllTemplates();
        setTemplates({ loading: false, data: response.data, selectedtemplate: undefined })
      } catch (error) {
        console.log(error)
        setTemplates({ loading: false, data: [], selectedtemplate: undefined })
      }
    })()
  }, [])
  const previewTemplateData = templates.data?.find((template)=>template?.id === previewTemplateId)
  const docs = previewTemplateId ? [{
    uri: URLS.GET_TEMPLATE_PREVIEW + previewTemplateId,
    fileType: getMimeTypefromString(".pdf"),
    fileName: previewTemplateData?.name ? previewTemplateData.name : "" 
  }] : [];
  return (
    (<Drawer
          hideBackdrop
          anchor="right"
          open={openGenerateContact}
          PaperProps={{
            sx: {
              width: {
                xs: "100%", md: "508px"
              },
              height: {
                top: "64px",
                xs: "calc(100% - 64px)", md: "calc(100% - 64px)"
              }
            },
          }}
          onClose={handleGenerateContractDrawer}>
      <Box sx={{height:"100%" }}>
        <Stack
          direction='row'
          sx={{
            justifyContent: 'space-between',
            px: 2.5,
            py: 1,
            alignItems: "center"
          }}>
          <Box>{t("contract.overview.createContract")}</Box>
          <Stack direction="row" sx={{
            columnGap: 1
          }}>
           
            <IconButton onClick={handleGenerateContractDrawer}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Box
          sx={[{
            height: "calc(100% - 58px)"
          }, previewTemplateId ? {
            p: 1
          } : {
            p: 4.5
          }]}>
          {
            previewTemplateId ?
              <>
              <Typography> <IconButton onClick={() => setPreviewTemplateId("")}> 
              <ArrowBack sx={{ mr: 1 }} /></IconButton>{t("general.viewOtherTemplate")}</Typography>
              <Box sx={{
                height: "calc(100% - 32px)"
              }}>
                <DocViewer
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                    },
                    pdfVerticalScrollByDefault: true
                  }}
                  documents={docs}
                  activeDocument={docs[0]}
                  pluginRenderers={DocViewerRenderers}
                  theme={{
                    disableThemeScrollbar: true,
                  }}
                  className={classes.documentComponent}
                  />
              </Box>
              </>
              :
              <>
                <Stack sx={{
                  rowGap: 3.5
                }}>
                  <Autocomplete
                    size="small"
                    id="work-number"
                    options={projects.list}
                    renderInput={(params) => <TextField {...params} label={t("general.projectNumber")}
                      placeholder={t("general.projectNumber")}  
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        }
                      }} />
                    }
                    value={[projects?.selectedProjectLable]}
                    onChange={(event, newValue) => {
                      console.log(newValue?.projectInfo?.projectId, { objects })
                      if (newValue?.projectInfo?.projectId && (newValue?.projectInfo?.projectId !== objects?.selectedObjectInfo?.projectId)) {
                        setObjects({ ...objects, selectedObjectInfo: undefined, selectedObjectLable: undefined })
                      }
                      setProjects({ ...projects, selectedProjectInfo: newValue?.projectInfo, selectedProjectLable: newValue?.label })
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="object-number"
                    options={projects?.selectedProjectInfo?.projectId ? objects?.list?.filter(item => item?.objectInfo?.projectId === projects?.selectedProjectInfo?.projectId && item?.objectInfo?.buyerRenterName && item?.objectInfo?.roles?.includes("BuyersGuide")) : objects?.list?.filter((item) => item?.objectInfo?.buyerRenterName && item?.objectInfo?.roles?.includes("BuyersGuide"))}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label={t("general.objectNumber")}
                      placeholder={t("general.objectNumber")}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        }
                      }} />
                    }
                    value={[objects?.selectedObjectLable]}
                    onChange={(event, newValue) => {
                      if (newValue?.objectInfo?.projectId && (newValue?.objectInfo?.projectId !== projects?.selectedProjectInfo?.projectId)) {
                        const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
                        const projectInfo = projectsSoFar.map((projectInfo) => ({ label: getProjectLable(projectInfo[0]), projectInfo: projectInfo?.[0] }))?.find((item) => item.projectInfo?.projectId === newValue?.objectInfo?.projectId)
                        setProjects({ ...projects, selectedProjectInfo: projectInfo, selectedProjectLable: projectInfo.label })
                      }
                      setObjects({ ...objects, selectedObjectInfo: newValue?.objectInfo, selectedObjectLable: newValue?.label, selectedProjectId: newValue?.projectId })
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    mt: 3,
                    mb: 1
                  }}>{t("contract.overview.createContract.selectTemplate")}:</Typography>
                <Stack
                  direction='row'
                  sx={{
                    columnGap: 1,
                    overflow: "scroll"
                  }}>
                  {templates?.loading ? <Box
                    sx={{
                      height: 200,
                      width: "100%"
                    }}><Loader /></Box> : 
                  <>
                    {
                      templates?.data?.map((template, index) => {
                        const currentSelectedTemplate = templates.selectedtemplate?.id === template?.id
                        const selectedTemplateBg = currentSelectedTemplate ? { opacity: 1, background: "rgba(0, 0, 0, 0.25)" } : { opacity: 0 }
                        return (
                          (<Box key={index}>
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'inline-block',
                                width: 123,
                                height: 174,
                                borderRadius: 0.5,
                                ':hover': { cursor: 'pointer', opacity: 1 }
                              }}>
                             {/* TODO: In Future contractTemplate.svg come from the backend */}
                              <Box
                                component="img"
                                src={'/Content/Background/contract-template.png'}
                                alt="Image"
                                sx={{
                                  display: "block",
                                  width: "100%",
                                  height: "100%"
                                }} />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: 118,
                                  height: 168,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: 0.5,
                                  ...selectedTemplateBg,
                                  backdropFilter: 'blur(2px)',
                                  ':hover': { opacity: 1, background: "rgba(0, 0, 0, 0.25)" }
                                }}>
                                <Stack
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      textAlign: 'center',
                                      color: 'white',
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "6rem"
                                    }}>{template.name}</Typography>
                                  <Stack direction='row' sx={{
                                    columnGap: 1
                                  }}>
                                    <IconButton onClick={() => {
                                      setTemplates({ ...templates, selectedtemplate: templates.selectedtemplate && currentSelectedTemplate ? undefined : template })
                                    }
                                    } sx={{ p: 0 }}>
                                      <CheckCircle sx={[currentSelectedTemplate ? {
                                        color: blue[700]
                                      } : {
                                        color: "white"
                                      }]} />
                                    </IconButton>
                                    <IconButton sx={{ p: 0 }} onClick={() => setPreviewTemplateId(template.id)}>
                                      <Visibility sx={{ color: "white" }} />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </Box>
                            </Box>
                          </Box>)
                        );
                      })
                    }
                  </>}
                </Stack>
                <Stack
                  direction="row-reverse"
                  sx={{
                    mt: 6,
                    columnGap: 1
                  }}>
                  <LoadingButton loading={isCreatingContract} variant="contained" onClick={handleCreateContract}>{t("general.continue")}</LoadingButton>
                  <Button variant="outlined" onClick={handleGenerateContractDrawer} >{t("general.cancel")}</Button>
                </Stack>
              </>
          }
        </Box>
      </Box>
    </Drawer>)
  );
}
export default CreateContractDrawer



const useStyles = makeStyles((theme) => ({
  documentComponent: {
      '& div:nth-last-child(1)': {
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[400]
          },
      },
      '& #pdf-controls': {
        background: theme.palette.common.white,
        zIndex: 3
      }
  },
}));