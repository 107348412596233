import { TOAST } from "../_constants"

const initialState = {
  type: "", 
  message: "",
  open:false
}

export function toastReducer(state = initialState, action) {
    switch (action.type) {
      case TOAST.SUCCESS:{
        return { ...state, type: "success", message: action.message, open:true }
      }
        case TOAST.ERROR:{
            return { ...state, type: "error", message: action.message, open:true }
        }
        case TOAST.CLEAR:{
            return initialState
        }
        default:
            return state
    }
}