import { TEMPLATES } from '../_constants';

const initialState = {
  templateList: {
    loading: false,
    data: []
  },
}

export function templateReducer(state = initialState, action) {
  switch (action.type) {

    case TEMPLATES.SET_TEMPLATE_LIST_LOADING:
      return { ...state, templateList: { ...state.templateList, loading: action.data } }

    case TEMPLATES.SET_TEMPLATE_LIST: {
      return { ...state, templateList: action.data }
    }

    case TEMPLATES.CLEAR:
      return initialState

    default:
      return state
  }
}