import { CircularProgress, Container, Stack, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataGridV2 from '../../../components/DataGridV2'
import { Add} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import SelectTemplatesDrawer from '../SelectTemplatesDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { formatDate, getLocalStorageItem, setLocalStorageItem } from '../../../_helpers';
import { apps, LOCAL_STORAGE_GRID_KEYS } from '../../../_constants';
import { taskAutomationActions,tabNavigationAction ,breadcrumbAction} from '../../../_actions';
import { getTaskAutomationGridColumns, getTaskAutomationGridNavigationItem } from './utils';
import makeStyles from "@mui/styles/makeStyles";

const TaskAutomationGrid = () => {
    const classes = useStyles();
    const app = useSelector((state) => state.app);
    const keyPrefix = app === apps.aftercare ? "AFTERCARE" :  app === apps.huisinfo ? "BUYERS_GUIDE" : "GENERAL";
    const theme = useTheme()
    const { t, i18n} = useTranslation();
    const [conFirm, setConFirm] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION[keyPrefix], undefined
    ))
    const apiRef = useGridApiRef();
    const dispatch = useDispatch()
    const { systemMaintenance:{taskStore : {taskList , drawerInfo}} } = useSelector(state => state )

    const { loading: buildingLoading } = useSelector(
        (state) => state.buildings
    );
    
  useEffect(() => {
    const taskAutomationGridNavigation = getTaskAutomationGridNavigationItem(t)
    dispatch(tabNavigationAction.setNavigationTab(taskAutomationGridNavigation.tabs))
    dispatch(breadcrumbAction.setBreadcrumbItem(taskAutomationGridNavigation.breadCrumbs))
  }, [dispatch, t])

  useEffect(()=>{return taskAutomationActions.clearTaskStore},[])

    useEffect(() => {
        if (!buildingLoading) {
            dispatch(taskAutomationActions.fetchTaskLists(t, app))
        }
    }, [buildingLoading, dispatch, t]);

    const columns = getTaskAutomationGridColumns({t,theme,keyPrefix,i18n,apiRef})

    const handleConFirm = useCallback((event) => {
        setConFirm(!conFirm);
    }, [conFirm])

    const onPaginationModelChange = useCallback((newPaginationModel) => {
        setPaginationModel(newPaginationModel)
        setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_PAGINATION[keyPrefix],
            newPaginationModel
        );
    }, [keyPrefix])


    const getToolbarProps = useMemo(() => {
        const toolbarProps = {
            toolbarStartActions: [
                {
                    title: t("general.add"),
                    icon: Add,
                    onClick: () => dispatch(taskAutomationActions.toggleTaskDrawer(true))
                }
            ],
        };
        return toolbarProps;
    }, [dispatch, handleConFirm, t]);

    if (buildingLoading)
        return (
            (<Stack
                sx={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <CircularProgress size={25} />
            </Stack>)
        );
  
    const getTaskAutomationForPrintAndDownload = ({ isAllSelected = true }) => {
        const userSelectedRows = isAllSelected ? taskList?.data : rowSelectionModel?.map((id)=>taskList?.data.find(item => item?.id === id));

        const results = userSelectedRows?.map((item) => {
            let result = {}
            Object.keys(item).forEach((key) => {
                let value = item[key];
               
                const isDateField = columns.some(x => x.field === key && x.type === 'date');
                const isDateTimeField = columns.some(x => x.field === key && x.type === 'dateTime');
               
                if (isDateField) {
                  value = item[key] ? formatDate(new Date(item[key])) : "";
                } else if (isDateTimeField) {
                  value = item[key] ? formatDate(new Date(item[key]), true, "-", false) : "";
                } else if (key === "active") {
                  value = item[key] ? t("general.yes") : t("general.no");
                }
               
                result[key] = value;
              });
            return result
        })
        return results
    }

    return (
        <Container maxWidth={false} className={classes.mainContainer}>
            <DataGridV2
                localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_SEARCH_TEXT[keyPrefix]}
                localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER[keyPrefix]}
                localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT[keyPrefix]}
                localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_ORDER[keyPrefix]}
                localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_WIDTH[keyPrefix]}
                localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_VISIBILITY[keyPrefix]}
                apiRef={apiRef}
                loading={buildingLoading || taskList?.loading}
                checkboxSelection
                rows={taskList?.data}
                columns={columns}
                toolbarProps={{
                    ...getToolbarProps,
                    selectedRows: rowSelectionModel,
                    getData: getTaskAutomationForPrintAndDownload,
                    title: t("taskAutomation.title"),
                }}
                pagination
                rowSelectionModel={rowSelectionModel}
                {...(!!paginationModel && { paginationModel })}
                onPaginationModelChange={onPaginationModelChange}
                filterMode="client"
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
            />
          
          {drawerInfo?.toggleTaskDrawer&&  <SelectTemplatesDrawer />}
          </Container>
    )
}

const useStyles = makeStyles((theme) => ({
        mainContainer: {
        height: "100%",
        width: "100%",
        overflow: "auto",
        padding: 0,

    },
}));
export default TaskAutomationGrid
