import { apps, LOCAL_STORAGE_GRID_KEYS } from "../../../_constants";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";
import { formatDate } from "../../../_helpers";
import { LabelColumnV2, LinkColumnV2  } from "../../../components/DataGridV2/DataColumnsV2";
import { store } from "../../..";

export const getTaskAutomationGridColumns = ({ t,
    theme,
    keyPrefix,
    i18n,
    apiRef
  }) => {
    const getOptions = getGridContextMenuList({
        filterKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_FILTER[keyPrefix],
        sortKey: LOCAL_STORAGE_GRID_KEYS.SYSTEM_MAINTENANCE_TASK_AUTOMATION_COLUMN_SORT[keyPrefix],
        i18n,
        t,
        apiRef,
        getValueForCopy: (rowData, extraData) => {
            let text = ""
            switch (extraData.fieldName) {
              case "createdOn":
                text = formatDate(new Date(rowData?.createdOn), true, "-", false)
                break;
              case "startDate":
                text = formatDate(new Date(rowData?.startDate))
                break;
              case "endDate":
                text = formatDate(new Date(rowData?.endDate))
                break;
              case "active":
                text = rowData[extraData.fieldName] ? t("general.yes") : t("general.no")
                break;
              default:
                text = rowData[extraData.fieldName]
                break;
            }
            return text
          },
        mode: "client"
      })   
      const {app} = store.getState()
    
    return [
        {
            field: "taskNumber",
            headerName: t('general.number'),
            flex: 1,
            minWidth: 90,
            download: true,
             renderCell: (params) => {
                      const rowData = params.row;
                      return (
                        <LinkColumnV2
                          to={app === apps.huisinfo ? "/systeemonderhound/taakautomatisering/" + params?.row?.id : app === apps.aftercare ?  "/nazorg/systeemonderhound/taakautomatisering/"+ params?.row?.id : '#'}
                          label={rowData.taskNumber}
                          cellSubMenuOptions={{
                            options: getOptions({ field: "taskNumber", isQuickFilterDisplay: Boolean(rowData.taskNumber) }),
                            extraData: { fieldName: "taskNumber" } 
                        }}
                        request={rowData}
                        />
                      );
                    },
        },
        {
            field: "taskName",
            headerName: t('general.name'),
            flex: 1,
            minWidth: 110,
            download: true,
            getApplyQuickFilterFn:(searchVal)=>{
                return (value, row) => {
                    return value?.toLowerCase().includes(searchVal.toLowerCase()) || row?.searchField?.toLowerCase().includes(searchVal.toLowerCase());
                };
            },
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2
                 label={rowData.taskName}
                 cellSubMenuOptions={{ options: getOptions({ field: "taskName", isQuickFilterDisplay: Boolean(rowData.taskName) }), extraData: { fieldName: "taskName" } }}
                 request={rowData}
                 />
            },
        },
        {
            field: "owner",
            headerName: t('taskAutomation.owner'),
            flex: 1,
            minWidth: 90,
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2 
                label={rowData.owner}
                cellSubMenuOptions={{ options: getOptions({ field: "owner", isQuickFilterDisplay: Boolean(rowData.owner) }), extraData: { fieldName: "owner" } }}
                request={rowData}
                 />
            },
        },
        {
            field: "createdOn",
            headerName: t('general.creationDate'),
            flex: 1,
            minWidth: 90,
            type: "dateTime",
            valueGetter: (params) => {
                if (!params) return "";
                return new Date(params);
            },
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2
                 label={rowData?.createdOn ? formatDate(new Date(rowData?.createdOn), true, "-", false) : ""}
                 cellSubMenuOptions={{ options: getOptions({ field: "createdOn", isQuickFilterDisplay: rowData?.createdOn }), extraData: { fieldName: "createdOn" ,type : "dateTime"} }}
                 request={rowData}
                  />
            },
        },
        {
            field: "startDate",
            headerName: t('general.startDate'),
            flex: 1,
            minWidth: 90,
            type: "date",
            valueGetter: (params) => {
                if (!params) return "";
                return new Date(params);
            },
            download: true,
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2  date={rowData.startDate}
                cellSubMenuOptions={{ options: getOptions({ field: "startDate", isQuickFilterDisplay: rowData?.startDate }), extraData: { fieldName: "startDate",type : "date" } }}
                request={rowData}
                 />
            },
        },
        {
            field: "endDate",
            headerName: t('general.endDate'),
            flex: 1,
            minWidth: 90,
            type: "date",
            download: true,
            valueGetter: (params) => {
                if (!params) return "";
                return new Date(params);
            },
            renderCell: (params) => {
                const rowData = params.row;
                return <LabelColumnV2 date={rowData.endDate}
                cellSubMenuOptions={{ options: getOptions({ field: "endDate", isQuickFilterDisplay: rowData?.endDate }), extraData: { fieldName: "endDate" ,type : "date"} }}
                 request={rowData}
                />
            },
        },
        {
            field: "active",
            headerName: t('general.active'),
            flex: 1,
            minWidth: 90,
            download: true, 
            type: "boolean",
            renderCell: (params) => {
                const rowData = params.row;
                const isActive = rowData.active ===  true;
                return (
                    <LabelColumnV2
                        color={isActive ? theme.palette.success.main : theme.palette.error.main}
                        label={isActive ? t("general.yes") : t("general.no")}
                        cellSubMenuOptions={{ options: getOptions({ field: "active", isQuickFilterDisplay: typeof rowData.active === "boolean" }), extraData: { fieldName: "active" ,type : "boolean"} }}
                        request={rowData}  
                    />
                );
            },
        }
    ];
}


export const getTaskAutomationGridNavigationItem = (t )=>{
    const {app} =store.getState()
    const to = app === apps.huisinfo ? "/systeemonderhound/taakautomatisering" : app === apps.aftercare ? "/nazorg/systeemonderhound/taakautomatisering" : '#'
    return {
        breadCrumbs: [{ breadCrumbTitle: t('taskAutomation.title'),title:t('taskAutomation.title'), to}],
        tabs: [{title:t('general.list') , to}]
    }
}