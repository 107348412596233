import React, { useMemo } from 'react'
import { Divider, FormControl, FormHelperText, Grid2, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { groupBy } from '../../../_helpers';
import { useSelector } from 'react-redux';

const Link = ({ values, handleChange, errors, clearError }) => {
    const { t } = useTranslation();
    const { buildings: { all: allBuildings } } = useSelector(state => state)
    const projectsList = useMemo(() => {
        const projectsSoFar = Object.values(groupBy(allBuildings, "projectId"))
        return projectsSoFar.map((projectInfo) => ({ label: projectInfo[0]?.projectName, projectInfo: projectInfo?.[0] }))
    }, [allBuildings])

    return (
        <>
            <Typography
                variant="h6"
                sx={{
                    px: 2,
                    py: 1.5
                }}>
                {t("template.links")}
            </Typography>
            <Divider sx={{
                my: 0
            }} />
            <Grid2 container sx={{
                py: 1
            }} spacing={{ xs: 2, md: 0 }}>
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ borderRight: { xs: "none", sm: `1px dashed ${grey[200]}`, display: "flex", flexDirection: "column", justifyContent: "space-between" } }}>
                    <Grid2 container spacing={1} sx={{ px: 2 }}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1'>{t("template.links")}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }} pb={1}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12 }}>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            {t("general.project")}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            name="type"
                                            fullWidth
                                            input={<OutlinedInput label={t("general.projectNumber")} />}
                                            value={values?.projectId}
                                            onChange={(e) => {
                                                handleChange({ keyName: "projectId", value: e.target.value })
                                                clearError("type")
                                            }}
                                            error={errors?.projectId}
                                        >
                                            {projectsList?.map((item) => <MenuItem value={item.projectInfo?.projectId}>{item?.label}</MenuItem>)}
                                        </Select>
                                        <FormHelperText error={errors?.projectId}>{errors?.projectId}</FormHelperText>
                                    </FormControl>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    )
}

export default Link