import { TEMPLATE_DETAILS } from '../_constants';

const initialState = {
  templateId: undefined,
  loadingTemplateDetails: false,
  templateDetails: undefined,
}

export function templateDetailsReducer(state = initialState, action) {
  switch (action.type) {

    case TEMPLATE_DETAILS.SET_LOADING_TEMPLATE_DETAILS:
      return { ...state, loadingTemplateDetails: action.data }

    case TEMPLATE_DETAILS.SET_TEMPLATE_DETAILS_DATA:
      return { ...state, templateDetails: action.data, loadingTemplateDetails: false }

    case TEMPLATE_DETAILS.CLEAR:
      return initialState
      
    default:
      return state
  }
}